import MapaTecho from "./PlayScene/MapaTecho";
import MapaPared from "./PlayScene/MapaPared";
import MapaPuerta from "./PlayScene/MapaPuerta";
import MapaVentana from "./PlayScene/MapaVentana";
import MapaCasa from "./PlayScene/MapaCasa";

import { Posiciones } from "../common/schema/Posiciones";

import { materialesPuerta } from "../common/schema/Piezas";

import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";

import { funcionActualizarDatosFB, generateUUID } from "../common/utils";

const COLOR_BASE = "#0B2167";

const Random = Phaser.Math.Between;
class Tutorial extends Phaser.Scene {
  rexUI;
  arrayTotal;
  centroCanvas;
  extremoCanvas;
  zonasGeneral;
  objetos;
  carritoPuertoCompleta;
  carritoMuroCompleta;
  carritoTechoCompleta;
  carritoVentanaCompleta;
  carrito;
  carritoTecho;
  carritoPared;
  carritoVentana;
  arrayPosicionTemporal;
  incluidoPared;
  elementosPared;
  posicionesEstablecidas;
  objetosDropzone;
  posicionAdecuadaJsonP;
  posicionAdecuadaJsonT;
  posicionAdecuadaJsonV;
  posicionAdecuadaJsonpa8;
  posicionAdecuadaJsonpa4;
  posicionAdecuadaJsonpa3;
  posicionAdecuadaJsonpa1;
  casasRealizadas;
  etapaD;
  etapa;
  etapaEscena;
  tipoEs;
  piezasStock;
  molestosBorrados;
  tipoEscena;
  confirmInitial;
  titleMejora;
  abrir;
  objetosUsados;
  trabajandoPuerta;
  addColaPuerta;
  colaPuerta;
  trabajandoVentana;
  addColaVentana;
  colaVentana;
  trabajoPared;
  addColaPared;
  colaPared;
  trabajoTecho;
  addColaTecho;
  colaTecho;
  tiemposCarritoPuerta;
  tiemposCarritoVentana;
  tiemposCarritosTecho;
  tiemposCarritosPared;
  piezasCompradas;
  allDropzones;
  zonasPintadas;
  gastosPorEtapa;
  casasMalasCapturas;
  piezasShowImageError;
  allColisiones;
  allTimout;
  botonesComprar;
  dineroBodegaje;
  dineroDevolucion;
  piezasTotalUsadas;
  depthActual;
  tableMono1;
  tableMono2;
  tableMono3;
  tableMono4;
  setPositionGrid;
  arrayPosicionActualArray;
  temporalItems;
  espacios;
  music;
  background;
  grupo;
  mapaTechoModal;
  mapaParedModal;
  mapaPuertaModal;
  mapaVentanaModal;
  mapaCasaModal;
  toptitle;
  config;
  audioI;
  menu;
  intervalRegresivo;
  info;
  reloj;
  base_moneda;
  moneda;
  aream1;
  aream2;
  aream3;
  aream4;
  relojMono;
  relojTecho;
  relojPared;
  relojVentana;
  btnEnviar;
  btnMejoraAccept;
  modalConfirmEtapas;
  dineroBase;
  hourText;
  separadorHora;
  segundosText;
  textTotalCasa;
  primero;
  grupomono1;
  grupomono2;
  grupomono3;
  etapaModal;
  count;
  element;
  taladro1;
  taladro2;
  guincha;
  guincha2;
  martillo;
  martillo2;
  martillo3;
  perno;
  perno2;
  casco;
  casco2;
  audifono;
  audifono2;
  lampa;
  lampa2;
  cuadro;
  cuadro2;
  lapiz;
  lapiz2;
  balde;
  balde2;
  balde3;
  removeTaladro1;
  rmgrupo1;
  removeTaladro2;
  rmgrupo2;
  removeGuincha;
  rmgrupo3;
  removeGuincha2;
  rmgrupo4;
  removeMartillo;
  rmgrupo5;
  removeMartillo2;
  rmgrupo6;
  removeMartillo3;
  rmgrupo7;
  removePerno;
  rmgrupo8;
  removePerno2;
  rmgrupo9;
  removeCasco;
  rmgrupo10;
  removeCasco2;
  rmgrupo11;
  removeAudifono;
  rmgrupo12;
  removeAudifono2;
  rmgrupo13;
  removeLampa;
  rmgrupo14;
  removeLampa2;
  rmgrupo15;
  removeCuadro;
  rmgrupo16;
  removeCuadro2;
  rmgrupo17;
  removeLapiz;
  rmgrupo18;
  removeLapiz2;
  rmgrupo19;
  removeBalde;
  rmgrupo20;
  removeBalde2;
  rmgrupo21;
  removeBalde3;
  rmgrupo22;
  isTrabajando;
  clickCountTecho;
  intervaloClockTecho;
  tableMono31;
  tableMono21;
  tableMono22;
  clickCountPared;
  intervaloClockPared;
  clickCountVentana;
  intervaloClockVentana;
  relojMonoVentana;
  p1;
  p3;
  p4;
  t1;
  t2;
  t3;
  t4;
  t5;
  t2n;
  v1;
  v2;
  v3;
  b3;
  pa1;
  pa2;
  pa3;
  pa4;
  pa5;
  pa6;
  pa7;
  pa8;
  pa9;
  pa2n;
  pa8n;
  Scrent1;
  Scrent2;
  Scrent21;
  Scrent22;
  Scrent23;
  Scrent31;
  Scrent4;
  Scrent5;
  Screnpa1;
  Screnpa2;
  Screnpa21;
  Screnpa3;
  Screnpa31;
  Screnpa4;
  Screnpa5;
  Screnpa6;
  Screnpa7;
  Screnpa71;
  Screnpa8;
  Screnpa0;
  Screnpa01;
  Screnpa02;
  Screnpa03;
  Screnpa04;
  Screnpa222;
  Screnpa2221;
  Screnp3;
  Screnp4;
  Screnv1;
  Screnv11;
  Screnv2;
  Screnv3;
  errt1;
  errt5;
  errt3;
  errt2;
  errt8;
  errt6;
  errt7;
  errt4;
  errp1;
  errp2;
  errp3;
  errp4;
  errv1;
  errv4;
  errv3;
  errv2;
  errpa1;
  errpa10;
  errpa11;
  errpa12;
  errpa13;
  errpa2;
  errpa3;
  errpa14;
  errpa4;
  errpa15;
  errpa5;
  errpa6;
  errpa7;
  errpa8;
  errpa16;
  errpa9;
  imagensParaPantallazo;
  eliminarPiezasCompradas;
  poco_tiempo;
  imagenBG;
  shape1;
  transition;
  music2;
  intervaloClock;
  isTyping;
  isLastPage;
  mostrarOtro;
  intervaloConversa;
  timeRemaining;
  intervalRegresivoCuenta;
  minutos;
  paso;
  segundos;
  pasarEtapa;
  finalizo;
  elementsMonos;
  grupoMono1;
  countPieza;
  countDrag;
  renew;
  pasarNivelPop;
  botonesEstacion;
  clickBotonEstacion;
  tweensPiezas;
  nubesGeneral;
  msjBtnCom;
  accionesItems;
  btnGeneralComprar;
  totalCasa;
  textJugar;
  botonFin;
  textoFin;
  btnGrupo;
  etiquetaP;
  etiquetaT;
  etiquetaPA;
  etiquetaVE;
  botonFinSalir;
  textoFinSalir;
  quinto;
  primerob;
  segundo;
  segundoe;
  segundof;
  cuarto;
  grupoMono;
  segundoh;
  clickCountPuerta;
  p2;
  pk1;
  pk2;
  pk3;
  pk4;
  tests;
  btnComprarPuerta;
  audio_mesa;
  cada_ronda;
  aca_podras_ver;
  aqui_encontraras;
  cada_fabrica_tiene;
  cuando_termines;
  pon_cada_pieza;
  selecciona_comprar;
  selecciona_mas;
  estas_son_fabricas;
  constructor(
    handle: any,
    parent: any,
    zonasGeneral: any,
    objetos: any,
    carritoMuroCompleta: any,
    carritoTechoCompleta: any,
    carritoPuertoCompleta: any,
    carritoVentanaCompleta: any,
    carrito: any,
    carritoTecho: any,
    carritoPared: any,
    carritoVentana: any,
    arrayPosicionTemporal: any,
    incluidoPared: any,
    elementosPared: any,
    posicionesEstablecidas: any,
    objetosDropzone: any,
    posicionAdecuadaJsonP: any,
    posicionAdecuadaJsonT: any,
    posicionAdecuadaJsonV: any,
    posicionAdecuadaJsonpa8: any,
    posicionAdecuadaJsonpa4: any,
    posicionAdecuadaJsonpa3: any,
    posicionAdecuadaJsonpa1: any,
    casasRealizadas: any,
    etapaD: any,
    etapa: any,
    etapaEscena: any,
    tipoEs: any,
    piezasStock: any,
    molestosBorrados: any,
    tipoEscena: any,
    confirmInitial: any,
    titleMejora: any,
    abrir: any,
    objetosUsados: any,
    trabajandoPuerta: any,
    addColaPuerta: any,
    colaPuerta: any,
    trabajandoVentana: any,
    addColaVentana: any,
    colaVentana: any,
    trabajoPared: any,
    addColaPared: any,
    colaPared: any,
    trabajoTecho: any,
    addColaTecho: any,
    colaTecho: any,
    tiemposCarritoPuerta: any,
    tiemposCarritoVentana: any,
    tiemposCarritosTecho: any,
    tiemposCarritosPared: any,
    piezasCompradas: any,
    allDropzones: any,
    zonasPintadas: any,
    gastosPorEtapa: any,
    casasMalasCapturas: any,
    piezasShowImageError: any,
    allColisiones: any,
    allTimout: any,
    botonesComprar: any,
    dineroBodegaje: any,
    dineroDevolucion: any,
    piezasTotalUsadas: any,
    depthActual: any,
    tableMono1: any,
    tableMono2: any,
    tableMono3: any,
    tableMono4: any,
    setPositionGrid: any,
    arrayPosicionActualArray: any,
    temporalItems: any,
    espacios: any,
    music: any,
    background: any,
    grupo: any,
    mapaTechoModal: any,
    mapaParedModal: any,
    mapaPuertaModal: any,
    mapaVentanaModal: any,
    mapaCasaModal: any,
    toptitle: any,
    config: any,
    audioI: any,
    menu: any,
    intervalRegresivo: any,
    info: any,
    reloj: any,
    base_moneda: any,
    moneda: any,
    aream1: any,
    aream2: any,
    aream3: any,
    aream4: any,
    relojMono: any,
    relojTecho: any,
    relojPared: any,
    relojVentana: any,
    btnEnviar: any,
    btnMejoraAccept: any,
    modalConfirmEtapas: any,
    dineroBase: any,
    hourText: any,
    separadorHora: any,
    segundosText: any,
    textTotalCasa: any,
    primero: any,
    grupomono1: any,
    grupomono2: any,
    grupomono3: any,
    etapaModal: any,
    count: any,
    element: any,
    taladro1: any,
    taladro2: any,
    guincha: any,
    guincha2: any,
    martillo: any,
    martillo2: any,
    martillo3: any,
    perno: any,
    perno2: any,
    casco: any,
    casco2: any,
    audifono: any,
    audifono2: any,
    lampa: any,
    lampa2: any,
    cuadro: any,
    cuadro2: any,
    lapiz: any,
    lapiz2: any,
    balde: any,
    balde2: any,
    balde3: any,
    removeTaladro1: any,
    rmgrupo1: any,
    removeTaladro2: any,
    rmgrupo2: any,
    removeGuincha: any,
    rmgrupo3: any,
    removeGuincha2: any,
    rmgrupo4: any,
    removeMartillo: any,
    rmgrupo5: any,
    removeMartillo2: any,
    rmgrupo6: any,
    removeMartillo3: any,
    rmgrupo7: any,
    removePerno: any,
    rmgrupo8: any,
    removePerno2: any,
    rmgrupo9: any,
    removeCasco: any,
    rmgrupo10: any,
    removeCasco2: any,
    rmgrupo11: any,
    removeAudifono: any,
    rmgrupo12: any,
    removeAudifono2: any,
    rmgrupo13: any,
    removeLampa: any,
    rmgrupo14: any,
    removeLampa2: any,
    rmgrupo15: any,
    removeCuadro: any,
    rmgrupo16: any,
    removeCuadro2: any,
    rmgrupo17: any,
    removeLapiz: any,
    rmgrupo18: any,
    removeLapiz2: any,
    rmgrupo19: any,
    removeBalde: any,
    rmgrupo20: any,
    removeBalde2: any,
    rmgrupo21: any,
    removeBalde3: any,
    rmgrupo22: any,
    isTrabajando: any,
    clickCountTecho: any,
    intervaloClockTecho: any,
    tableMono31: any,
    tableMono21: any,
    tableMono22: any,
    clickCountPared: any,
    intervaloClockPared: any,
    clickCountVentana: any,
    intervaloClockVentana: any,
    relojMonoVentana: any,
    p1: any,
    p3: any,
    p4: any,
    t1: any,
    t2: any,
    t3: any,
    t4: any,
    t5: any,
    t2n: any,
    v1: any,
    v2: any,
    v3: any,
    b3: any,
    pa1: any,
    pa2: any,
    pa3: any,
    pa4: any,
    pa5: any,
    pa6: any,
    pa7: any,
    pa8: any,
    pa9: any,
    pa2n: any,
    pa8n: any,
    Scrent1: any,
    Scrent2: any,
    Scrent21: any,
    Scrent22: any,
    Scrent23: any,
    Scrent31: any,
    Scrent4: any,
    Scrent5: any,
    Screnpa1: any,
    Screnpa2: any,
    Screnpa21: any,
    Screnpa3: any,
    Screnpa31: any,
    Screnpa4: any,
    Screnpa5: any,
    Screnpa6: any,
    Screnpa7: any,
    Screnpa71: any,
    Screnpa8: any,
    Screnpa0: any,
    Screnpa01: any,
    Screnpa02: any,
    Screnpa03: any,
    Screnpa04: any,
    Screnpa222: any,
    Screnpa2221: any,
    Screnp3: any,
    Screnp4: any,
    Screnv1: any,
    Screnv11: any,
    Screnv2: any,
    Screnv3: any,
    errt1: any,
    errt5: any,
    errt3: any,
    errt2: any,
    errt8: any,
    errt6: any,
    errt7: any,
    errt4: any,
    errp1: any,
    errp2: any,
    errp3: any,
    errp4: any,
    errv1: any,
    errv4: any,
    errv3: any,
    errv2: any,
    errpa1: any,
    errpa10: any,
    errpa11: any,
    errpa12: any,
    errpa13: any,
    errpa2: any,
    errpa3: any,
    errpa14: any,
    errpa4: any,
    errpa15: any,
    errpa5: any,
    errpa6: any,
    errpa7: any,
    errpa8: any,
    errpa16: any,
    errpa9: any,
    imagensParaPantallazo: any,
    eliminarPiezasCompradas: any,
    poco_tiempo: any,
    imagenBG: any,
    shape1: any,
    transition: any,
    music2: any,
    intervaloClock: any,
    isTyping: any,
    isLastPage: any,
    mostrarOtro: any,
    intervaloConversa: any,
    minutos: any,
    arrayTotal: any,
    paso: any,
    segundos: any,
    pasarEtapa: any,
    finalizo: any,
    elementsMonos: any,
    grupoMono1: any,
    countPieza: any,
    countDrag: any,
    renew: any,
    pasarNivelPop: any,
    botonesEstacion: any,
    clickBotonEstacion: any,
    tweensPiezas: any,
    nubesGeneral: any,
    msjBtnCom: any,
    accionesItems: any,
    btnGeneralComprar: any,
    totalCasa: any,
    textJugar: any,
    botonFin: any,
    textoFin: any,
    btnGrupo: any,
    etiquetaP: any,
    etiquetaT: any,
    etiquetaPA: any,
    etiquetaVE: any,
    botonFinSalir: any,
    textoFinSalir: any,
    quinto: any,
    primerob: any,
    segundo: any,
    segundoe: any,
    segundof: any,
    cuarto: any,
    grupoMono: any,
    segundoh: any,
    clickCountPuerta: any,
    p2: any,
    pk1: any,
    pk2: any,
    pk3: any,
    pk4: any,
    tests: any,
    btnComprarPuerta: any,
    rexUI: RexUIPlugin,
    audio_mesa: any,
    cada_ronda: any,
    aca_podras_ver: any,
    aqui_encontraras: any,
    cada_fabrica_tiene: any,
    cuando_termines: any,
    pon_cada_pieza: any,
    selecciona_comprar: any,
    selecciona_mas: any,
    estas_son_fabricas: any
  ) {
    super({ key: "Tutorial" });
    this.btnComprarPuerta = btnComprarPuerta;
    this.arrayTotal = arrayTotal;
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
    this.carritoPuertoCompleta = carritoPuertoCompleta;
    this.zonasGeneral = zonasGeneral;
    this.objetos = objetos;
    this.carritoMuroCompleta = carritoMuroCompleta;
    this.carritoTechoCompleta = carritoTechoCompleta;
    this.carritoVentanaCompleta = carritoTechoCompleta;
    this.carrito = carrito;
    this.carritoTecho = carritoTecho;
    this.carritoPared = carritoPared;
    this.carritoVentana = carritoVentana;
    this.arrayPosicionTemporal = arrayPosicionTemporal;
    this.incluidoPared = incluidoPared;
    this.elementosPared = elementosPared;
    this.posicionesEstablecidas = posicionesEstablecidas;
    this.objetosDropzone = objetosDropzone;
    this.posicionAdecuadaJsonP = posicionAdecuadaJsonP;
    this.posicionAdecuadaJsonT = posicionAdecuadaJsonT;
    this.posicionAdecuadaJsonV = posicionAdecuadaJsonV;
    this.posicionAdecuadaJsonpa8 = posicionAdecuadaJsonpa8;
    this.posicionAdecuadaJsonpa4 = posicionAdecuadaJsonpa4;
    this.posicionAdecuadaJsonpa3 = posicionAdecuadaJsonpa3;
    this.posicionAdecuadaJsonpa1 = posicionAdecuadaJsonpa1;
    this.casasRealizadas = casasRealizadas;
    this.etapaD = etapaD;
    this.etapa = etapa;
    this.etapaEscena = etapaEscena;
    this.tipoEs = tipoEs;
    this.piezasStock = piezasStock;
    this.molestosBorrados = molestosBorrados;
    this.tipoEscena = tipoEscena;
    this.confirmInitial = confirmInitial;
    this.titleMejora = titleMejora;
    this.abrir = abrir;
    this.objetosUsados = objetosUsados;
    this.trabajandoPuerta = trabajandoPuerta;
    this.addColaPuerta = addColaPuerta;
    this.colaPuerta = colaPuerta;
    this.trabajandoVentana = trabajandoVentana;
    this.addColaVentana = addColaVentana;
    this.colaVentana = colaVentana;
    this.trabajoPared = trabajoPared;
    this.addColaPared = addColaPared;
    this.colaPared = colaPared;
    this.trabajoTecho = trabajoTecho;
    this.addColaTecho = addColaTecho;
    this.colaTecho = colaTecho;
    this.tiemposCarritoPuerta = tiemposCarritoPuerta;
    this.tiemposCarritoVentana = tiemposCarritoVentana;
    this.tiemposCarritosTecho = tiemposCarritosTecho;
    this.tiemposCarritosPared = tiemposCarritosPared;
    this.piezasCompradas = piezasCompradas;
    this.allDropzones = allDropzones;
    this.zonasPintadas = zonasPintadas;
    this.gastosPorEtapa = gastosPorEtapa;
    this.casasMalasCapturas = casasMalasCapturas;
    this.piezasShowImageError = piezasShowImageError;
    this.allColisiones = allColisiones;
    this.allTimout = allTimout;
    this.botonesComprar = botonesComprar;
    this.dineroBodegaje = dineroBodegaje;
    this.dineroDevolucion = dineroDevolucion;
    this.piezasTotalUsadas = piezasTotalUsadas;
    this.depthActual = depthActual;
    this.tableMono1 = tableMono1;
    this.tableMono2 = tableMono2;
    this.tableMono3 = tableMono3;
    this.tableMono4 = tableMono4;
    this.setPositionGrid = setPositionGrid;
    this.arrayPosicionActualArray = arrayPosicionActualArray;
    this.temporalItems = temporalItems;
    this.espacios = espacios;
    this.music = music;
    this.background = background;
    this.grupo = grupo;
    this.mapaTechoModal = mapaTechoModal;
    this.mapaParedModal = mapaParedModal;
    this.mapaPuertaModal = mapaPuertaModal;
    this.mapaVentanaModal = mapaVentanaModal;
    this.mapaCasaModal = mapaCasaModal;
    this.toptitle = toptitle;
    this.config = config;
    this.audioI = audioI;
    this.menu = menu;
    this.intervalRegresivo = intervalRegresivo;
    this.info = info;
    this.reloj = reloj;
    this.base_moneda = base_moneda;
    this.moneda = moneda;
    this.aream1 = aream1;
    this.aream2 = aream2;
    this.aream3 = aream3;
    this.aream4 = aream4;
    this.relojMono = relojMono;
    this.relojTecho = relojTecho;
    this.relojPared = relojPared;
    this.relojVentana = relojVentana;
    this.btnEnviar = btnEnviar;
    this.btnMejoraAccept = btnMejoraAccept;
    this.modalConfirmEtapas = modalConfirmEtapas;
    this.dineroBase = dineroBase;
    this.hourText = hourText;
    this.separadorHora = separadorHora;
    this.segundosText = segundosText;
    this.textTotalCasa = textTotalCasa;
    this.primero = primero;
    this.grupomono1 = grupomono1;
    this.grupomono2 = grupomono2;
    this.grupomono3 = grupomono3;
    this.etapaModal = etapaModal;
    this.count = count;
    this.element = element;
    this.taladro1 = taladro1;
    this.taladro2 = taladro2;
    this.guincha = guincha;
    this.guincha2 = guincha2;
    this.martillo = martillo;
    this.martillo2 = martillo2;
    this.martillo3 = martillo3;
    this.perno = perno;
    this.perno2 = perno2;
    this.casco = casco;
    this.casco2 = casco2;
    this.audifono = audifono;
    this.audifono2 = audifono2;
    this.lampa = lampa;
    this.lampa2 = lampa2;
    this.cuadro = cuadro;
    this.cuadro2 = cuadro2;
    this.lapiz = lapiz;
    this.lapiz2 = lapiz2;
    this.balde = balde;
    this.balde2 = balde2;
    this.balde3 = balde3;
    this.removeTaladro1 = removeTaladro1;
    this.rmgrupo1 = rmgrupo1;
    this.removeTaladro2 = removeTaladro2;
    this.rmgrupo2 = rmgrupo2;
    this.removeGuincha = removeGuincha;
    this.rmgrupo3 = rmgrupo3;
    this.removeGuincha2 = removeGuincha2;
    this.rmgrupo4 = rmgrupo4;
    this.removeMartillo = removeMartillo;
    this.rmgrupo5 = rmgrupo5;
    this.removeMartillo2 = removeMartillo2;
    this.rmgrupo6 = rmgrupo6;
    this.removeMartillo3 = "" as any;
    this.rmgrupo7 = "" as any;
    this.removePerno = "" as any;
    this.rmgrupo8 = "" as any;
    this.removePerno2 = "" as any;
    this.rmgrupo9 = "" as any;
    this.removeCasco = "" as any;
    this.rmgrupo10 = "" as any;
    this.removeCasco2 = "" as any;
    this.rmgrupo11 = "" as any;
    this.removeAudifono = "" as any;
    this.rmgrupo12 = "" as any;
    this.removeAudifono2 = "" as any;
    this.rmgrupo13 = "" as any;
    this.removeLampa = "" as any;
    this.rmgrupo14 = "" as any;
    this.removeLampa2 = "" as any;
    this.rmgrupo15 = "" as any;
    this.removeCuadro = "" as any;
    this.rmgrupo16 = "" as any;
    this.removeCuadro2 = "" as any;
    this.rmgrupo17 = "" as any;
    this.removeLapiz = "" as any;
    this.rmgrupo18 = "" as any;
    this.removeLapiz2 = "" as any;
    this.rmgrupo19 = "" as any;
    this.removeBalde = "" as any;
    this.rmgrupo20 = "" as any;
    this.removeBalde2 = "" as any;
    this.rmgrupo21 = "" as any;
    this.removeBalde3 = "" as any;
    this.rmgrupo22 = "" as any;
    this.isTrabajando = "" as any;
    this.clickCountTecho = "" as any;
    this.intervaloClockTecho = "" as any;
    this.tableMono31 = "" as any;
    this.tableMono21 = "" as any;
    this.tableMono22 = "" as any;
    this.clickCountPared = "" as any;
    this.intervaloClockPared = "" as any;
    this.clickCountVentana = "" as any;
    this.intervaloClockVentana = "" as any;
    this.relojMonoVentana = "" as any;
    this.p1 = "" as any;
    this.p3 = "" as any;
    this.p4 = "" as any;
    this.t1 = "" as any;
    this.t2 = "" as any;
    this.t3 = "" as any;
    this.t4 = "" as any;
    this.t5 = "" as any;
    this.t2n = "" as any;
    this.v1 = "" as any;
    this.v2 = "" as any;
    this.v3 = "" as any;
    this.b3 = "" as any;
    this.pa1 = "" as any;
    this.pa2 = "" as any;
    this.pa3 = "" as any;
    this.pa4 = "" as any;
    this.pa5 = "" as any;
    this.pa6 = "" as any;
    this.pa7 = "" as any;
    this.pa8 = "" as any;
    this.pa9 = "" as any;
    this.pa2n = "" as any;
    this.pa8n = "" as any;
    this.Scrent1 = "" as any;
    this.Scrent2 = "" as any;
    this.Scrent21 = "" as any;
    this.Scrent22 = "" as any;
    this.Scrent23 = "" as any;
    this.Scrent31 = "" as any;
    this.Scrent4 = "" as any;
    this.Scrent5 = "" as any;
    this.Screnpa1 = "" as any;
    this.Screnpa2 = "" as any;
    this.Screnpa21 = "" as any;
    this.Screnpa3 = "" as any;
    this.Screnpa31 = "" as any;
    this.Screnpa4 = "" as any;
    this.Screnpa5 = "" as any;
    this.Screnpa6 = "" as any;
    this.Screnpa7 = "" as any;
    this.Screnpa71 = "" as any;
    this.Screnpa8 = "" as any;
    this.Screnpa0 = "" as any;
    this.Screnpa01 = "" as any;
    this.Screnpa02 = "" as any;
    this.Screnpa03 = "" as any;
    this.Screnpa04 = "" as any;
    this.Screnpa222 = "" as any;
    this.Screnpa2221 = "" as any;
    this.Screnp3 = "" as any;
    this.Screnp4 = "" as any;
    this.Screnv1 = "" as any;
    this.Screnv11 = "" as any;
    this.Screnv2 = "" as any;
    this.Screnv3 = "" as any;
    this.errt1 = "" as any;
    this.errt5 = "" as any;
    this.errt3 = "" as any;
    this.errt2 = "" as any;
    this.errt8 = "" as any;
    this.errt6 = "" as any;
    this.errt7 = "" as any;
    this.errt4 = "" as any;
    this.errp1 = "" as any;
    this.errp2 = "" as any;
    this.errp3 = "" as any;
    this.errp4 = "" as any;
    this.errv1 = "" as any;
    this.errv4 = "" as any;
    this.errv3 = "" as any;
    this.errv2 = "" as any;
    this.errpa1 = "" as any;
    this.errpa10 = "" as any;
    this.errpa11 = "" as any;
    this.errpa12 = "" as any;
    this.errpa13 = "" as any;
    this.errpa2 = "" as any;
    this.errpa3 = "" as any;
    this.errpa14 = "" as any;
    this.errpa4 = "" as any;
    this.errpa15 = "" as any;
    this.errpa5 = "" as any;
    this.errpa6 = "" as any;
    this.errpa7 = "" as any;
    this.errpa8 = "" as any;
    this.errpa16 = "" as any;
    this.errpa9 = "" as any;
    this.imagensParaPantallazo = "" as any;
    this.eliminarPiezasCompradas = "" as any;
    this.poco_tiempo = "" as any;
    this.imagenBG = "" as any;
    this.shape1 = "" as any;
    this.transition = "" as any;
    this.music2 = "" as any;
    this.intervaloClock = "" as any;
    this.isTyping = "" as any;
    this.isLastPage = "" as any;
    this.mostrarOtro = "" as any;
    this.intervaloConversa = "" as any;
    this.timeRemaining = 0; // En segundos
    this.intervalRegresivoCuenta = null as any;
    this.minutos = minutos;
    this.segundos = segundos;
    this.paso = paso;
    this.pasarEtapa = pasarEtapa;
    this.finalizo = finalizo;
    this.elementsMonos = elementsMonos;
    this.grupoMono1 = grupoMono1;
    this.countPieza = countPieza;
    this.countDrag = countDrag;
    this.renew = renew;
    this.pasarNivelPop = pasarNivelPop;
    this.botonesEstacion = botonesEstacion;
    this.clickBotonEstacion = clickBotonEstacion;
    this.tweensPiezas = tweensPiezas;
    this.nubesGeneral = nubesGeneral;
    this.msjBtnCom = msjBtnCom;
    this.accionesItems = accionesItems;
    this.btnGeneralComprar = btnGeneralComprar;
    this.totalCasa = totalCasa;
    this.textJugar = textJugar;
    this.botonFin = botonFin;
    this.textoFin = textoFin;
    this.btnGrupo = btnGrupo;
    this.etiquetaP = etiquetaP;
    this.etiquetaT = etiquetaT;
    this.etiquetaPA = etiquetaPA;
    this.etiquetaVE = etiquetaVE;
    this.botonFinSalir = botonFinSalir;
    this.textoFinSalir = textoFinSalir;
    this.quinto = quinto;
    this.primerob = primerob;
    this.segundo = segundo;
    this.segundoe = segundoe;
    this.segundof = segundof;
    this.cuarto = cuarto;
    this.grupoMono = grupoMono;
    this.segundoh = segundoh;
    this.clickCountPuerta = clickCountPuerta;
    this.p2 = p2;
    this.pk1 = pk1;
    this.pk2 = pk2;
    this.pk3 = pk3;
    this.pk4 = pk4;
    this.tests = tests;
    this.rexUI = rexUI;
    this.audio_mesa = audio_mesa;
    this.cada_ronda = cada_ronda;
    this.aca_podras_ver = aca_podras_ver;
    this.aqui_encontraras = aqui_encontraras;
    this.cada_fabrica_tiene = cada_fabrica_tiene;
    this.cuando_termines = cuando_termines;
    this.pon_cada_pieza = pon_cada_pieza;
    this.selecciona_comprar = selecciona_comprar;
    this.selecciona_mas = selecciona_mas;
    this.estas_son_fabricas = estas_son_fabricas;
  }

  preload() {
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexuiplugin.min.js",
      sceneKey: "rexUI",
    });
    this.load.image(
      "nextPage",
      "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/assets/images/arrow-down-left.png"
    );
  }
  init(data: any) {
    console.log("Inicio tutorial");
    this.paso = 1;
    this.arrayTotal = [];
    this.centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    this.extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };
    this.zonasGeneral = [];
    this.objetos = {
      puerta: [
        [1, 1, 1],
        [1, 1, 1],
        [1, 1, 1],
        [1, 1, 1],
      ],
      temporalPuerta: {
        primero: {
          active: false,
          posicion: 0,
        },
        segundo: {
          active: false,
          posicion: 0,
        },
        tercer: {
          active: false,
          posicion: 0,
        },
        cuarto: {
          active: false,
          posicion: 0,
        },
        arrayPuerta: [
          [null, null, null],
          [null, null, null],
          [null, null, null],
          [null, null, null],
        ],
      },
    };
    this.carrito = [];
    this.carritoTecho = [];
    this.carritoPared = [];
    this.carritoVentana = [];
    this.arrayPosicionTemporal = [];
    this.incluidoPared = [];
    this.elementosPared = [];
    this.posicionesEstablecidas = {
      t1: false,
      t2: false,
      t3: false,
      t4: false,
      t5: false,
      pa1: false,
      pa2: false,
      pa3: false,
      pa4: false,
      pa5: false,
      pa6: false,
      pa7: false,
      pa8: false,
      pa9: false,
      p1: false,
      p2: false,
      p3: false,
      v1: false,
      v2: false,
      v3: false,
    };
    this.objetosDropzone = [];
    /// objetos repetidos
    this.posicionAdecuadaJsonP = { 149: false, 181: false };
    this.posicionAdecuadaJsonT = { 53: false, 55: false, 57: false, 59: false };
    this.posicionAdecuadaJsonV = { 138: false, 140: false };
    this.posicionAdecuadaJsonpa8 = { 116: false, 121: false };
    this.posicionAdecuadaJsonpa4 = { 153: false, 141: false };
    this.posicionAdecuadaJsonpa3 = { 164: false, 120: false };
    this.posicionAdecuadaJsonpa1 = {
      148: false,
      168: false,
      186: false,
      173: false,
      125: false,
    };
    this.casasRealizadas = [
      {
        buenas: 0,
        malas: 0,
      },
    ];
    this.pasarEtapa = false;
    this.finalizo = data.finalizo;
    this.elementsMonos = { mono1: [], mono2: [], mono3: [], mono4: [] };
    this.grupoMono1 = undefined;
    this.countPieza = 0;
    this.piezasStock = {
      puerta: { p2: 0, p3: 0, p4: 0 },
      techo: { t1: 0, t2: 0, t3: 0, t4: 0 },
      pared: {
        pa0: 0,
        pa1: 0,
        pa2: 0,
        pa3: 0,
        pa4: 0,
        pa5: 0,
        pa6: 0,
        pa7: 0,
        pa8: 0,
      },
      ventana: { v1: 0, v2: 0, v3: 0 },
    };
    localStorage.setItem("piezasStock", JSON.stringify(this.piezasStock));
    this.countDrag = 0;
    this.renew = data.renew;
    this.pasarNivelPop = data.pasarNivel;
    this.botonesEstacion = [];
    this.clickBotonEstacion = false;
    this.piezasCompradas = [];
    this.tweensPiezas = [];
    this.nubesGeneral = [];
    this.msjBtnCom = false;
    this.accionesItems = [];
    this.btnGeneralComprar = "";
  }

  create() {
    localStorage.setItem("escenaActual", "Tutorial");
    funcionActualizarDatosFB({ escenaActual: "Tutorial" });
    this.setPositionGrid = [];
    this.arrayPosicionActualArray = [];
    this.temporalItems = [];
    this.background = this.add.image(0, 0, "background").setOrigin(0, 0);

    //audios
    this.audio_mesa = this.sound.add("mesa_trabajo");
    this.cada_ronda = this.sound.add("cada_ronda");
    this.aca_podras_ver = this.sound.add("aca_podras_ver");
    this.aqui_encontraras = this.sound.add("aqui_encontraras");
    this.cada_fabrica_tiene = this.sound.add("cada_fabrica_tiene");
    this.cuando_termines = this.sound.add("cuando_termines");
    this.pon_cada_pieza = this.sound.add("pon_cada_pieza");
    this.selecciona_comprar = this.sound.add("selecciona_comprar");
    this.selecciona_mas = this.sound.add("selecciona_mas");
    this.estas_son_fabricas = this.sound.add("estas_son_fabricas");
    (this as any).hola_terricola = this.sound.add("hola_terricola");
    (this as any).casa_construir = this.sound.add("casa_construir");
    //

    this.music = this.sound.add("music_comienzo", { volume: 0.03 });
    this.music.setLoop(true);
    this.music.play();
    console.log(this.extremoCanvas.width);
    this.add
      .renderTexture(
        0,
        0,
        this.extremoCanvas.width * 2,
        this.extremoCanvas.height * 2
      )
      .fill(0x211844, 0.7)
      .setDepth(10);

    this.setZone(this, 256);

    this.grupo = this.add.group({
      key: "circlemap",
      repeat: 4,
      setXY: {
        x: this.centroCanvas.width - 170,
        y: 110,
        stepX: 85,
      },
    });

    //  let countGrupo = 0;

    this.grupo.getChildren().forEach((element: any, index: any) => {
      element.name = `area${index}`;
      element.setInteractive({ cursor: "pointer" });
      if (element.name === "area0") {
        element.setDepth(1);
      }
      element.on(
        "pointerdown",
        (pointer: any, localX: any, localY: any, event: any) => {
          // this.btnGrupo.setVisible(1)
          /*                this.mostrarOtro = true
                if(countGrupo == 0){
                    if(this.primerob){
                        this.primerob.destroy()
                        this.primeroc = this.createTextBox(this, 410, 180
                            , {
                            wrapWidth: 200,
                        }, this.add.image(0,0,"tutorialnube2"), 4, 'center' )
                        .start("Puedes verlos cada vez que lo necesites", 50);
                        this.primeroc.setDepth(11)   
                        countGrupo++
                    }
                } */
          if (element.name === "area0") {
            this.mapaTechoModal = this.createWindow(MapaTecho, true);
            this.scene.pause();
          }
          if (element.name === "area1") {
            this.mapaParedModal = this.createWindow(MapaPared, true);
            this.scene.pause();
          }
          if (element.name === "area2") {
            this.mapaPuertaModal = this.createWindow(MapaPuerta, true);
            this.scene.pause();
          }
          if (element.name === "area3") {
            this.mapaVentanaModal = this.createWindow(MapaVentana, true);
            this.scene.pause();
          }
          if (element.name === "area4") {
            this.mapaCasaModal = this.createWindow(MapaCasa, true);
            this.scene.pause();
          }
        }
      );
    });

    this.config = this.add
      .image(50, 50, "config")
      .setInteractive({ cursor: "pointer" });
    this.menu = this.add.image(110, 50, "menu");
    this.info = this.add.image(170, 50, "info");

    this.reloj = this.add.image(this.extremoCanvas.width - 50, 50, "reloj");
    this.hourText = this.add.text(this.extremoCanvas.width - 78, 45, "10", {
      fontFamily: "font1",
      fontSize: 17,
      color: COLOR_BASE,
    });
    this.separadorHora = this.add.text(this.extremoCanvas.width - 58, 45, ":", {
      fontFamily: "font1",
      fontSize: 17,
      color: COLOR_BASE,
    });
    this.segundosText = this.add.text(this.extremoCanvas.width - 54, 45, "00", {
      fontFamily: "font1",
      fontSize: 17,
      color: COLOR_BASE,
    });

    //moneda
    this.base_moneda = this.add.image(
      this.extremoCanvas.width - 160,
      50,
      "base_moneda"
    );
    this.dineroBase = this.add.text(
      this.extremoCanvas.width - 180,
      42,
      "12345",
      { fontFamily: "font1" }
    );
    this.moneda = this.add.sprite(
      this.extremoCanvas.width - 204,
      50,
      "moneda",
      0
    );
    this.anims.create({
      key: "moneda_anims",
      frames: this.anims.generateFrameNames("moneda", {
        start: 0,
        end: 10,
      }),
      repeat: -1,
      frameRate: 17,
    });

    this.anims.play("moneda_anims", this.moneda);
    this.totalCasa = this.add
      .image(740, 50, "totalcasa")
      .setDepth(4)
      .setScale(0.9);
    this.textTotalCasa = this.add
      .text(740, 41, `0/5`, {
        fontFamily: "font1",
        fontSize: 18,
        color: "#FFFF",
      })
      .setDepth(4);

    //areas
    this.aream1 = this.add.image(105, 190, "aream");
    this.aream2 = this.add.image(105, 620, "aream");
    this.aream3 = this.add.image(920, 190, "aream");
    this.aream4 = this.add.image(920, 620, "aream");
    //monos
    this.createMono("mono1", "salto", 2, 55, 185, 45, 1, 175, 180, 185);
    this.createMono(
      "mono2",
      "salto2",
      2,
      this.extremoCanvas.width - 157,
      185,
      50,
      0.045,
      175,
      185,
      null
    );
    this.createMono("mono3", "salto3", 2, 55, 610, 50, 0.045, 600, 610, null);
    this.createMono(
      "mono4",
      "salto4",
      2,
      this.extremoCanvas.width - 157,
      610,
      50,
      0.045,
      600,
      610,
      null
    );

    this.btnEnviar = this.add
      .image(620, 650, "boton1")
      .setInteractive({ cursor: "pointer" });
    this.textJugar = this.add.text(580, 640, "ENVIAR", {
      fontFamily: "font1",
      fontSize: 18,
    });

    /*         this.input.on('pointerdown', (pointer, gameObject, localy, event) => 
            {   
                if(gameObject.length == 0){
                    this.nextSteph(this.paso)
                    console.log("sss", this.paso)
                }
            }) */

    this.botonFin = this.add
      .image(this.centroCanvas.width - 120, 40, "comprar_btn")
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.botonFin.setScale(0.9);
        this.sound.play("click");
        this.nextSteph(this.paso);
      })
      .on("pointerup", () => {
        this.botonFin.setScale(1);
      });
    this.textoFin = this.add.text(
      this.centroCanvas.width - 210,
      22,
      `SIGUIENTE`,
      { fontFamily: "font1", fontSize: 32 }
    );

    this.btnGrupo = this.add.group();
    this.btnGrupo.add(this.botonFin);
    this.btnGrupo.add(this.textoFin);
    this.btnGrupo.setDepth(11);
    this.btnGrupo.setVisible(0);

    this.zonasGeneral.forEach((element: any, x: any) => {
      element.forEach((el: any, elx: any) => {
        el.graphics.setDepth(11);
      });
    });

    (this as any).holograma = this.add
      .sprite(this.centroCanvas.width - 200, this.centroCanvas.height, "holomu")
      .setScale(0.5)
      .setDepth(11);
    (this as any).holograma.anims.play("playholomu");

    (this as any).beforePrimero = this.createTextBox(
      this,
      420,
      350,
      {
        wrapWidth: 420,
      },
      this.add.image(0, 0, "tutorialnube1"),
      null,
      "right",
      () => {
        (this as any).hola_terricola.play();
      },
      () => {
        this.btnGrupo.setVisible(1);
      }
    ).start(
      "¡Hola, ex terrícola! Me alegro de ver que llegaste bien.No hay tiempo que perder, te enseñaré cómo funciona todo.Este es el taller de construcción",
      50
    );

    (this as any).beforePrimero.setDepth(11);

    this.mostrarOtro = true;

    this.eventosDrag();

    this.etiquetaP = this.add
      .text(70, 220, "PUERTA", {
        fontFamily: "font1",
        fontSize: 18,
        color: COLOR_BASE,
      })
      .setDepth(11)
      .setVisible(0 as any);
    this.etiquetaT = this.add
      .text(70, 648, "TECHO", {
        fontFamily: "font1",
        fontSize: 18,
        color: COLOR_BASE,
      })
      .setDepth(11)
      .setVisible(0 as any);
    this.etiquetaPA = this.add
      .text(this.extremoCanvas.width - 140, 220, "MURO", {
        fontFamily: "font1",
        fontSize: 18,
        color: COLOR_BASE,
      })
      .setDepth(11)
      .setVisible(0 as any);
    this.etiquetaVE = this.add
      .text(this.extremoCanvas.width - 155, 648, "VENTANA", {
        fontFamily: "font1",
        fontSize: 18,
        color: COLOR_BASE,
      })
      .setDepth(11)
      .setVisible(0 as any);

    if (this.renew) {
      this.botonFinSalir = this.add
        .image(150, this.centroCanvas.height - 345, "rojo_btn")
        .setInteractive({ cursor: "pointer" })
        .setDepth(11)
        .on("pointerdown", () => {
          this.sound.play("click");
          setTimeout(() => {
            if (this.tableMono1) {
              this.tableMono1.destroy();
              this.tableMono1 = false;
            }
            if (this.quinto) {
              this.quinto.destroy();
              this.quinto = false;
            }
            this.sound.stopAll();
            this.scene.start("Levels");
          }, 200);
        });
      this.textoFinSalir = this.add
        .text(100, this.centroCanvas.height - 363, `SALIR`, {
          fontFamily: "font1",
          fontSize: 32,
        })
        .setDepth(12);
    }
  }

  update() {}

  parpadeoMapas() {
    for (let index = 0; index < 5; index++) {
      (this as any)[`graphc${index}`] = this.add.graphics({
        x: 310 + 85 * index,
        y: 80,
      });
      (this as any)[`graphc${index}`].fillStyle(0xffff00, 0.75);
      (this as any)[`graphc${index}`].setDepth(16);
      (this as any)[`graphc${index}`].fillRoundedRect(0, 0, 65, 65, 32.5);
      (this as any).tweens.add({
        targets: (this as any)[`graphc${index}`],
        alpha: 0,
        ease: "Cubic.easeOut",
        duration: 600,
        repeat: -1,
        yoyo: true,
      });
    }
  }
  removeParpadeoMapas() {
    for (let index = 0; index < 5; index++) {
      (this as any)[`graphc${index}`].destroy();
    }
  }

  //funciones propias
  nextSteph(step: any) {
    switch (step) {
      case 1:
        (this as any).beforePrimero.destroy();
        (this as any).holograma.destroy();
        (this as any).casamodal = this.add
          .image(
            this.centroCanvas.width - 120,
            this.centroCanvas.height - 80,
            "casamodalf"
          )
          .setScale(0.8)
          .setDepth(999);
        (this as any).piedras = this.add
          .image(
            this.centroCanvas.width + 150,
            this.centroCanvas.height - 80,
            "piedras"
          )
          .setScale(0.2)
          .setDepth(999);
        (this as any).beforePrimero = this.createTextBox(
          this,
          this.centroCanvas.width - 200,
          this.centroCanvas.height + 70,
          {
            wrapWidth: 450,
          },
          this.add.image(0, 0, "tutorialnube2"),
          null,
          "center",
          () => {
            this.btnGrupo.setVisible(0);
            (this as any).hola_terricola.pause();
            (this as any).casa_construir.play();
          },
          () => {
            this.btnGrupo.setVisible(1);
          }
        )
          .start(
            "Y esta es la casa que vas a construir. Parece una casa normal, pero está hecha de kivi estelar. Un increíble material que solo los síodas saben extraer",
            50
          )
          .setDepth(99);
        this.paso++;
        break;
      case 2:
        (this as any).casamodal.destroy();
        (this as any).piedras.destroy();
        (this as any).casamodal.destroy();
        (this as any).beforePrimero.destroy();
        this.primero = this.createTextBox(
          this,
          10,
          480,
          {
            wrapWidth: 250,
          },
          this.add.image(0, 0, "tutorialnube"),
          null,
          "left",
          () => {
            this.btnGrupo.setVisible(0);
            (this as any).casa_construir.pause();
            (this as any).audio_mesa.play();
          },
          () => {
            this.btnGrupo.setVisible(1);
          }
        )
          .start(
            "Esta es tu mesa de trabajo, donde armarás las casas una a una.",
            50
          )
          .setDepth(999);
        this.paso++;
        break;
      case 3:
        this.primero.destroy();
        this.audio_mesa.pause();
        this.zonasGeneral.forEach((element: any, x: any) => {
          element.forEach((el: any, elx: any) => {
            el.graphics.setDepth(4);
          });
        });
        this.btnGrupo.setVisible(1);
        this.primero = this.createTextBox(
          this,
          590,
          20,
          {
            wrapWidth: 250,
          },
          this.add.image(0, 0, "tutorialnube"),
          4,
          "left",
          () => {
            this.btnGrupo.setVisible(0);
            (this as any).cada_ronda.play();
          },
          () => {
            this.btnGrupo.setVisible(1);
          }
        ).start(
          "Cada ronda de trabajo dura 10 minutos, que debes aprovechar al máximo",
          50
        );
        this.primero.setDepth(11);
        this.reloj.setDepth(11);
        this.hourText.setDepth(11);
        this.separadorHora.setDepth(11);
        this.segundosText.setDepth(11);
        this.paso++;
        this.mostrarOtro = true;
        break;
      case 4:
        this.primero.destroy();
        this.cada_ronda.pause();
        this.grupo.getChildren().forEach((element: any, index: any) => {
          element.setDepth(11);
        });
        this.zonasGeneral.forEach((element: any, x: any) => {
          element.forEach((el: any, elx: any) => {
            el.graphics.setDepth(1);
          });
        });

        this.primerob = this.createTextBox(
          this,
          340,
          180,
          {
            wrapWidth: 300,
          },
          this.add.image(0, 0, "tutorialnube2").setScale(0.4),
          4,
          "center",
          () => {
            this.btnGrupo.setVisible(0);

            (this as any).aqui_encontraras.play();
          },
          () => {
            this.btnGrupo.setVisible(1);
          }
        ).start(
          "Aquí encontrarás los planos de fabricación, que podrás ver siempre que los necesites",
          50
        );

        this.parpadeoMapas();
        //this.btnGrupo.setVisible(1);
        this.mostrarOtro = false;
        this.primerob.setDepth(11);
        this.paso++;
        this.pasarEtapa = false;
        this.reloj.setDepth(4);
        this.hourText.setDepth(4);
        this.separadorHora.setDepth(4);
        this.segundosText.setDepth(4);
        break;
      case 5:
        this.aqui_encontraras.pause();
        this.removeParpadeoMapas();
        if (this.primerob) {
          this.primerob.destroy();
        }
        // this.primeroc.destroy()
        this.aream1.setDepth(11);
        //this.createMono('mono1', 'salto', 2, 55, 200, 45, 1, 190, 200, 11)
        this.grupo.getChildren().forEach((element: any, index: any) => {
          element.setInteractive({ cursor: "pointer" });
          element.setDepth(1);
          element.removeAllListeners("pointerover");
          element.removeAllListeners("pointerout");
          element.removeAllListeners("pointerdown");
        });

        this.elementsMonos["mono1"].forEach((element: any) => {
          element.setDepth(11);
        });

        setTimeout(() => {
          this.aream3.setDepth(11);
          this.etiquetaPA.setVisible(1);
          this.elementsMonos["mono2"].forEach((element: any) => {
            element.setDepth(11);
          });
          setTimeout(() => {
            this.aream2.setDepth(11);
            this.elementsMonos["mono3"].forEach((element: any) => {
              element.setDepth(11);
            });

            this.etiquetaT.setVisible(1);
            setTimeout(() => {
              this.aream4.setDepth(11);
              this.elementsMonos["mono4"].forEach((element: any) => {
                element.setDepth(11);
              });
              this.etiquetaVE.setVisible(1);
              setTimeout(() => {
                this.aream2.setDepth(1);
                this.aream3.setDepth(1);
                this.aream4.setDepth(1);
                this.elementsMonos["mono2"].forEach((element: any) => {
                  element.setDepth(1);
                });
                this.elementsMonos["mono3"].forEach((element: any) => {
                  element.setDepth(1);
                });
                this.elementsMonos["mono4"].forEach((element: any) => {
                  element.setDepth(1);
                });

                this.segundo.destroy();
                this.segundoe = this.createTextBox(
                  this,
                  180,
                  200,
                  {
                    wrapWidth: 280,
                  },
                  this.add.image(0, 0, "tutorialnube1"),
                  7,
                  null
                ).start("Para obtener materiales, elige una fábrica.", 50);
                this.elementsMonos["mono1"].forEach(
                  (element: any, index: any) => {
                    element.setDepth(11);
                    element.setTint(0xffff00);

                    (this as any)[`tweensM${index}`] = this.tweens.add({
                      targets: element,
                      alpha: 0.3,
                      ease: "Cubic.easeOut",
                      duration: 300,
                      repeat: -1,
                      yoyo: true,
                    });
                  }
                );

                this.segundoe.setDepth(11);
                this.grupoMono1.getChildren().forEach((element: any) => {
                  element.on("pointerdown", () => {
                    this.estas_son_fabricas.pause();
                    console.log(this.tableMono1);
                    if (!this.tableMono1) {
                      this.elementsMonos["mono1"].forEach(
                        (element: any, index: any) => {
                          element.clearTint();
                          (this as any)[`tweensM${index}`].stop();
                          element.setAlpha(1);
                        }
                      );
                      this.segundoe.destroy();
                      let isMob = this.isMobile();

                      this.segundof = this.createTextBox(
                        this,
                        isMob ? 660 : 200,
                        300,
                        {
                          wrapWidth: 280,
                        },
                        this.add.image(0, 0, "tutorialnube1").setScale(1.2),
                        6,
                        null,
                        () => {
                          (this as any).selecciona_mas.play();
                        }
                      ).start(
                        "Selecciona “+” o “–“ para elegir cuántas piezas comprar. Cada una de ellas cuesta $1 crédito estelar",
                        50
                      );
                      this.segundof.setDepth(11);
                      const callbackFunction = (
                        escena: any,
                        orientation: any
                      ) => {
                        return this.GetFooterSizer(escena, orientation);
                      };
                      this.tableMono1 = this.createPanelGeneral(
                        this,
                        materialesPuerta,
                        105,
                        350,
                        "carrito",
                        callbackFunction,
                        null,
                        null,
                        "trabajandoPuerta",
                        "addColaPuerta",
                        "colaPuerta",
                        350,
                        350,
                        null
                      );

                      this.accionesItems.forEach((element: any, index: any) => {
                        //element.setTint(0xffff00)

                        (this as any)[`tweensA${index}`] = this.tweens.add({
                          targets: element,
                          alpha: 0.3,
                          ease: "Cubic.easeOut",
                          duration: 300,
                          repeat: -1,
                          yoyo: true,
                        });
                      });
                      console.log(this.accionesItems, "aca estan lasacciones");

                      if (this.tableMono2) {
                        this.tableMono2.visible = false;
                      }
                      if (this.tableMono3) {
                        this.tableMono3.visible = false;
                      }
                      if (this.tableMono4) {
                        this.tableMono4.visible = false;
                      }
                    } else {
                      this.tableMono1.active = true;
                      this.tableMono1.setDepth(15);
                      this.tableMono1.visible = true;
                      if (this.tableMono2) {
                        this.tableMono2.visible = false;
                      }
                      if (this.tableMono3) {
                        this.tableMono3.visible = false;
                      }
                      if (this.tableMono4) {
                        this.tableMono4.visible = false;
                      }
                    }
                  });
                });
              }, 2500);
            }, 1500);
          }, 1500);
        }, 4500);

        this.segundo = this.createTextBox(
          this,
          180,
          200,
          {
            wrapWidth: 280,
          },
          this.add.image(0, 0, "tutorialnube1").setScale(1.2),
          6,
          null,
          () => {
            this.btnGrupo.setVisible(0);
            (this as any).estas_son_fabricas.play();
          }
        ).start(
          "Estas son las fábricas. Cada una entrega las piezas necesarias para armar una parte de la casa: muro, techo, puerta y ventana.",
          50
        );

        this.etiquetaP.setVisible(1);
        this.segundo.setDepth(11);
        this.paso++;
        this.pasarEtapa = false;
        break;
      case 6:
        this.pon_cada_pieza.pause();
        this.segundoh.destroy();
        this.cuarto = this.createTextBox(
          this,
          680,
          580,
          {
            wrapWidth: 250,
          },
          this.add.image(0, 0, "tutorialnube1").setScale(1.2),
          5,
          null,
          () => {
            this.btnGrupo.setVisible(0);
            this.pon_cada_pieza.pause();
            this.cuando_termines.play();
          }
        ).start(
          "Cuando termines de construir cada casa, asegúrate de que esté correcta y presiona enviar.",
          50
        );
        this.botonFin.x = this.centroCanvas.width;
        this.botonFin.y = 220;
        this.textoFin.x = this.centroCanvas.width - 90;
        this.textoFin.y = 200;
        this.cuarto.setDepth(11);
        this.textJugar.setDepth(11);
        this.btnEnviar.setDepth(11);
        this.paso++;
        this.pasarEtapa = false;
        //this.btnGrupo.setVisible(1);
        this.btnEnviar
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", () => {
            if (!this.quinto) {
              this.cuarto.destroy();
              this.quinto = this.createTextBox(
                this,
                340,
                10,
                {
                  wrapWidth: 250,
                },
                this.add.image(0, 0, "tutorialnube").setScale(1.2),
                6,
                "left",
                () => {
                  (this as any).aca_podras_ver.play();
                },
                () => {
                  this.btnGrupo.setVisible(1);
                }
              ).start(
                "Acá podrás ver el número de casas que construyas correctamente, y por cada una recibirás $50 créditos estelares.",
                50
              );
              this.quinto.setDepth(11);
              this.totalCasa.setDepth(11);
              this.textTotalCasa.setDepth(11);
              this.moneda.setDepth(11);
              this.dineroBase.setDepth(11);
              this.base_moneda.setDepth(11);
              this.textTotalCasa.setDepth(11);
              this.btnEnviar.setDepth(2);
              this.textJugar.setDepth(2);
              this.mostrarOtro = true;
            }
          });
        break;
      case 7:
        if (this.tableMono1) {
          this.tableMono1.destroy();
          this.tableMono1 = false;
        }
        if (this.quinto) {
          this.quinto.destroy();
          this.quinto = false;
        }
        this.sound.stopAll();
        this.scene.start("TutorialLevel", { etapa: 1, confirm: true });
        /*                 this.quinto.destroy()
                this.btnEnviar.setDepth(4)
                this.textJugar.setDepth(4)
                this.quinto.setDepth(2)
                this.totalCasa.setDepth(2)
                this.textTotalCasa.setDepth(2)    
                this.moneda.setDepth(2)
                this.dineroBase.setDepth(2)
                this.base_moneda.setDepth(2)
                this.textTotalCasa.setDepth(2)
                this.add.image(this.centroCanvas.width, this.centroCanvas.height, "fondopop").setDepth(11)
                this.add.text(this.centroCanvas.width -180, this.centroCanvas.height - 120, "¡Ya has aprendido a construir!", {fontFamily:'font1', fontSize:23, color:COLOR_BASE}).setDepth(11)
                this.add.text(this.centroCanvas.width -215, this.centroCanvas.height - 70, "Puedes volver a ver este tutorial cada vez que lo necesites", {fontFamily:'font2', fontSize:23, color:COLOR_BASE,boundsAlignH: "center", boundsAlignV: "middle",align:'center',wordWrap:{width: 430, useAdvancedWrap: true}}).setDepth(11)
                this.add.text(this.centroCanvas.width -80, this.centroCanvas.height, "¿Estás listo?", {fontFamily:'font1', fontSize:23, color:COLOR_BASE}).setDepth(11)
         
                this.add.image(this.centroCanvas.width, this.centroCanvas.height+90, 'boton1').setInteractive({cursor:'pointer'}).setDepth(11).setScale(1.2).on("pointerdown", ()=>{
                    this.music.destroy()
                    this.sound.play("click")
                    this.sound.stopAll()
                    this.scene.start('Levels');
                    ///this.scene.remove()
                })
                this.add.text(this.centroCanvas.width - 93 , this.centroCanvas.height+80, "¡Vamos a construir!", {fontFamily:'font1', fontSize:18}).setDepth(11)
                this.pasarEtapa= false
                this.btnGrupo.setVisible(1) */
        break;
      case 8:
        break;
    }
  }

  setZone(sceneName: any, num: any) {
    let zone;
    let ax = 25;
    let ay = 25;
    let count = 1;
    for (let i = 0; i < Math.sqrt(num); i++) {
      this.zonasGeneral[i] = [];
      for (let j = 0; j < Math.sqrt(num); j++) {
        zone = sceneName.add
          .zone(
            this.centroCanvas.width - 200 + i * ax + ax / 2,
            this.centroCanvas.height - 170 + j * ay + ay / 2,
            ax,
            ay
          )
          .setDropZone();
        zone.setName(j * Math.sqrt(num) + i + 1);
        sceneName.graphics = sceneName.add.graphics();

        sceneName.graphics.lineStyle(1.003, 0xfcdcea);
        sceneName.graphics.fillStyle(0x47427c, 0.7);
        sceneName.graphics.fillRect(
          zone.x - zone.input.hitArea.width / 2,
          zone.y - zone.input.hitArea.height / 2,
          zone.input.hitArea.width,
          zone.input.hitArea.height
        );
        sceneName.graphics.strokeRect(
          zone.x - zone.input.hitArea.width / 2,
          zone.y - zone.input.hitArea.height / 2,
          zone.input.hitArea.width,
          zone.input.hitArea.height
        );
        sceneName.graphics.setDepth(2);
        //sceneName.graphics.fillPath();
        zone.graphics = sceneName.graphics;
        zone.posicion = count;
        zone.indexPJ = j;
        zone.indexPI = i;
        this.zonasGeneral[i][j] = zone;
        count++;
      }
    }
  }

  createMono(
    key: any,
    nameAnimation: any,
    repeat: any,
    positionX: any,
    positionY: any,
    stepX: any,
    scale: any,
    overPositionY: any,
    outOverPositionY: any,
    zIndex: any
  ) {
    let count = 0;
    this.grupoMono = this.add.group({
      key: key,
      repeat: repeat,
      setXY: {
        x: positionX,
        y: positionY,
        stepX: stepX,
      },
    });
    this.grupoMono.getChildren().forEach((element: any) => {
      element.posicionElement = count;
      count++;
      this.elementsMonos[key].push(element);
      element
        .setInteractive({ cursor: "pointer" })
        .on(
          "pointerover",
          (pointer: any, localX: any, localY: any, event: any) => {
            element.anims.play(nameAnimation);
            element.setY(overPositionY);
            setTimeout(() => {
              element.setY(outOverPositionY);
            }, 500);
            event.stopPropagation();
          }
        );
    });
    if (key === "mono1") {
      this.grupoMono1 = this.grupoMono;
    }
  }
  createMonoEspecial(
    key: any,
    nameAnimation: any,
    repeat: any,
    positionX: any,
    positionY: any,
    stepX: any,
    scale: any,
    overPositionY: any,
    outOverPositionY: any
  ) {
    let count = 0;
    (this as any)[`grupo${key}`] = this.add.group({
      key: key,
      repeat: repeat,
      setXY: {
        x: positionX,
        y: positionY,
        stepX: stepX,
      },
    });
    (this as any)[`grupo${key}`].getChildren().forEach((element: any) => {
      element.posicionElement = count;
      count++;
      element.setDepth(10);
      element.setScale(scale);
      element.nameAnim = nameAnimation;
      element.tipo = "monos";
      element
        .setInteractive({ cursor: "pointer" })
        .on(
          "pointerover",
          (pointer: any, localX: any, localY: any, event: any) => {
            element.anims.play(nameAnimation);
            /*                     element.setY(overPositionY)
                    setTimeout(()=>{
                        element.setY(positionY)
                    }, 500)  */
            event.stopPropagation();
          }
        );

      //  this.input.setDraggable([element])
    });
  }
  monoEspecial() {
    this.createMonoEspecial("mono1", "salto", 2, 55, 190, 35, 0.9, 180, 190);
    this.createMonoEspecial(
      "mono2",
      "salto2",
      2,
      this.extremoCanvas.width - 157,
      190,
      50,
      0.9,
      180,
      180
    );
    this.createMonoEspecial("mono3", "salto3", 2, 55, 620, 50, 0.9, 610, 610);
    this.createMonoEspecial(
      "mono4",
      "salto4",
      2,
      this.extremoCanvas.width - 162,
      620,
      37,
      0.9,
      610,
      610
    );
  }

  isMobile() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  }

  createPanelGeneral(
    escena: any,
    materiales: any,
    posicionTableX: any,
    posicionTableY: any,
    carrito: any,
    getFooter: any,
    orientacion: any,
    scaleObject: any,
    isTrabajando: any,
    addCola: any,
    cola: any,
    alturaMobile: any,
    anchoMobile: any,
    scaleExtra: any
  ) {
    let isMobile = escena.isMobile();
    var scrollMode = 0;
    let dataMateriales = materiales;
    let itemsPuerta: any = [];
    var gridTable = this.rexUI.add
      .gridTable({
        x: isMobile ? escena.centroCanvas.width : posicionTableX,
        y: isMobile ? escena.centroCanvas.height + 15 : posicionTableY,
        width: isMobile ? anchoMobile : 180,
        height: isMobile ? alturaMobile : 250,
        scrollMode: scrollMode as any,
        background: this.add.image(0, 0, "fondo_panel").setDepth(11),
        slider: null as any,
        table: {
          cellWidth: undefined,
          cellHeight: isMobile ? 75 : 50,
          columns: 2,
          mask: {
            padding: 0,
          },
          interactive: true,
          reuseCellContainer: true,
        },
        header: this.rexUI.add
          .label({
            width: scrollMode === 0 ? 60 : 30,
            height: scrollMode === 0 ? undefined : 30,
            align: "right",
            space: {
              right: 5,
            },
            orientation: scrollMode as any,
            text: this.add
              .image(0, 0, "close")
              .setScale(isMobile ? 0.075 : 0.045)
              .setInteractive({ cursor: "pointer" })
              .on("pointerdown", function () {
                escena.sound.play("click");
                if (escena.tableMono1) {
                  escena.tableMono1.visible = false;
                }
                if (escena.tableMono2) {
                  escena.tableMono2.visible = false;
                }
                if (escena.tableMono3) {
                  escena.tableMono3.visible = false;
                }
                if (escena.tableMono4) {
                  escena.tableMono4.visible = false;
                }
              }),
          })
          .setDepth(25),
        footer: getFooter(this, 0, isTrabajando, addCola, cola),
        space: {
          left: 15,
          right: 15,
          top: 20,
          bottom: 14,
          table: { bottom: 0, left: 20, right: 20 },
          header: 0,
          footer: 0,
        },
        expand: {
          header: true,
          footer: false,
        },
        createCellContainerCallback: function (cell: any, cellContainer: any) {
          if (cellContainer == null) {
            var scene = cell.scene,
              width = cell.width,
              height = cell.height,
              item = cell.item,
              index = cell.index;
            const posicion: any = dataMateriales[cell.index].indexLoop;
            const priceObject =
              dataMateriales[cell.index] && dataMateriales[cell.index].precio;
            if (cellContainer === null && index % 2 === 0) {
              const nameObject =
                dataMateriales[cell.index] && dataMateriales[cell.index].objeto;
              cellContainer = scene.rexUI.add
                .label({
                  width: width,
                  height: height,
                  orientation: scrollMode,
                  icon: scene.add
                    .image(0, 0, nameObject)
                    .setScale(
                      scaleObject
                        ? isMobile
                          ? scaleExtra
                            ? scaleExtra
                            : 1
                          : scaleObject
                        : isMobile
                        ? scaleExtra
                          ? scaleExtra
                          : 1
                        : 0.6
                    )
                    .setDepth(99),
                  space: {
                    icon: 10,
                    left: scrollMode === 0 ? 0 : 0,
                    top: scrollMode === 0 ? 0 : 15,
                  },
                })
                .setDepth(11);
            } else if (cellContainer === null && index % 2 === 1) {
              let dash = scene.rexUI.add.label({
                name: `dash${index}`,
                text: scene.add
                  .text(0, 0, "-", {
                    color: "#92278F",
                    fontWeight: 700,
                    fontSize: isMobile ? 80 : 30,
                    align: "center",
                    padding: 0,
                    fontFamily: "font1",
                  })
                  .setDepth(20)
                  .setInteractive({ cursor: "pointer" }),
              });
              let number = scene.rexUI.add
                .label({
                  name: `number${index}`,
                  text: scene.add
                    .text(0, 0, "00", {
                      color: "#0B2167",
                      fontWeight: 700,
                      fontSize: isMobile ? 50 : 20,
                      align: "center",
                      padding: 0,
                      fontFamily: "font1",
                    })
                    .setDepth(20)
                    .setInteractive({ cursor: "pointer" }),
                })
                .setDepth(20);
              let plus = scene.rexUI.add.label({
                name: `plus${index}`,
                text: scene.add
                  .text(0, 0, "+", {
                    color: "#92278F",
                    fontWeight: 700,
                    align: "center",
                    lineSpacing: 0,
                    fontSize: isMobile ? 70 : 30,
                    padding: 0,
                    fontFamily: "font1",
                  })
                  .setDepth(20)
                  .setInteractive({ cursor: "pointer" }),
              });
              dash.indexLoop = index;
              number.indexLoop = index;
              plus.indexLoop = index;

              scene.rexUI.add
                .click(plus.getElement("text"), { threshold: 10 })
                .on("click", () => {
                  scene.sound.play("click");
                  scene.selecciona_mas.pause();
                  escena.accionesItems.forEach((item: any, index: any) => {
                    escena[`tweensA${index}`].stop();
                    item.setAlpha(1);
                  });

                  escena[`tweensbotonFinal`] = escena.tweens.add({
                    targets: escena.btnGeneralComprar,
                    alpha: 0.3,
                    ease: "Cubic.easeOut",
                    duration: 300,
                    repeat: -1,
                    yoyo: true,
                  });

                  let tas = itemsPuerta.filter((item: any) => {
                    let numberItem = `number${item.indexLoop}`;
                    if (
                      item.name === numberItem &&
                      item.indexLoop === plus.indexLoop
                    ) {
                      return item;
                    }
                  });
                  let nextNumber = parseInt(tas[0].text) + 1;
                  let numberFinal;
                  if (parseInt(tas[0].text) < 9) {
                    numberFinal = `0${nextNumber}`;
                  } else {
                    numberFinal = nextNumber;
                  }

                  let getCarrito = escena[isTrabajando]
                    ? escena[addCola]
                    : escena[carrito];

                  const isExistData = getCarrito.filter(
                    (item: any, key: any) => {
                      if (item.key === plus.indexLoop) {
                        getCarrito.splice(key, 1);
                      }
                    }
                  );
                  const data = {
                    ...dataMateriales[plus.indexLoop],
                    cantidad: numberFinal,
                    key: plus.indexLoop,
                  };
                  getCarrito.push(data);
                  //
                  tas[0].setText(numberFinal);

                  if (escena.segundof) {
                    if (!escena.msjBtnCom) {
                      escena.msjBtnCom = true;
                      escena.segundof.destroy();
                      let isMob = escena.isMobile();
                      escena.segundof = escena
                        .createTextBox(
                          escena,
                          isMob ? 660 : 180,
                          410,
                          {
                            wrapWidth: 280,
                          },
                          escena.add.image(0, 0, "tutorialnube1"),
                          6,
                          null,
                          () => {
                            (escena as any).selecciona_comprar.play();
                          }
                        )
                        .start(
                          "Luego selecciona comprar para comenzar su fabricación.",
                          50
                        );
                      escena.segundof.setDepth(15);
                    }
                  }
                });

              // scene.rexUI.add
              //   .click(dash.getElement("text"), { threshold: 10 })
              //   .on("click", () => {
              //     scene.sound.play("click");
              //     escena.accionesItems.forEach((item: any, index: any) => {
              //       escena[`tweensA${index}`].stop();
              //       item.setAlpha(1);
              //     });
              //     let tas = itemsPuerta.filter((item: any) => {
              //       let numberItem = `number${item.indexLoop}`;
              //       if (
              //         item.name === numberItem &&
              //         item.indexLoop === plus.indexLoop
              //       ) {
              //         return item;
              //       }
              //     });
              //     let nextNumber;
              //     let numberFinal;
              //     if (parseInt(tas[0].text) === 0) {
              //       numberFinal = "00";
              //     } else {
              //       nextNumber = parseInt(tas[0].text) - 1;
              //       if (parseInt(tas[0].text) < 9) {
              //         numberFinal = `0${nextNumber}`;
              //       } else {
              //         numberFinal = nextNumber;
              //       }
              //     }
              //     tas[0].setText(numberFinal);

              //     let eliminadoCero = false;
              //     let getCarrito = escena[isTrabajando]
              //       ? escena[addCola]
              //       : escena[carrito];
              //     getCarrito.forEach((item: any, key: any) => {
              //       //  console.log(item.key, plus.indexLoop, key)
              //       if (item.key === plus.indexLoop) {
              //         getCarrito.splice(key, 1);
              //         if (parseInt(item.cantidad) - 1 === 0) {
              //           //getCarrito.splice(key, 1)
              //           eliminadoCero = true;
              //         }
              //         if (parseInt(item.cantidad) === 0) {
              //           eliminadoCero = true;
              //         }
              //       }
              //       // return item
              //     });

              //     if (parseInt(numberFinal as any) === 0) {
              //       eliminadoCero = true;
              //     }

              //     if (!eliminadoCero) {
              //       const data = {
              //         ...dataMateriales[dash.indexLoop],
              //         cantidad: numberFinal,
              //         key: dash.indexLoop,
              //       };
              //       getCarrito.push(data);
              //     }
              //   });

              itemsPuerta.push(dash);
              itemsPuerta.push(number);
              itemsPuerta.push(plus);
              scene.temporalItems.push(number);

              escena.accionesItems.push(dash);
              escena.accionesItems.push(plus);
              console.log(escena.accionesItems);
              cellContainer = scene.rexUI.add
                .gridSizer({
                  column: 3,
                  columnProportions: 0,
                  row: isMobile ? 1 : 2,
                  rowProportions: 0,
                  space: {
                    row: 0,
                  },
                  expand: false,
                  padding: 0,
                })
                .add(dash)
                .add(number)
                .add(plus)
                .add(scene.rexUI.add.label({ text: scene.add.text(0, 0, "") }))
                .setDepth(11)
                .add(
                  scene.rexUI.add.label({
                    text: scene.add
                      .text(0, 0, `$${priceObject}`, {
                        fontSize: isMobile ? 25 : 11,
                        color: "#808080",
                        fontFamily: "font1",
                      })
                      .setDepth(20),
                  })
                );
            }
            cellContainer.setMinSize(width, height);
          }
          return cellContainer;
        },
        items: this.CreateItems(dataMateriales.length),
      })
      .layout();

    itemsPuerta.forEach((label: any) => {
      if (!label) {
        return;
      }
    });
    return gridTable;
  }

  CreateItems(count: any) {
    var data = [];
    for (var i = 0; i < count; i++) {
      data.push({
        id: i,
        color: Random(0, 0xffffff),
      });
    }
    return data;
  }
  // footer
  GetFooterSizer(scene: any, orientation: any) {
    return scene.rexUI.add
      .sizer({
        orientation: 0,
        space: {
          top: 10,
        },
      })
      .add(this.CreateFooterButton(scene, "COMPRAR", orientation), {
        expand: false,
        proportion: 1,
      });
  }
  CreateFooterButton(scene: any, text: any, orientation: any) {
    // let backgroundColor = text === "COMPRAR" ? 0xe1a330 : 0xff0000;
    let backgrundF = this.add
      .image(0, 0, "boton1")
      .setDepth(9999)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.sound.play("click");
        this.selecciona_comprar.pause();
        (this as any)[`tweensbotonFinal`].stop();
        backgrundF.setAlpha(1);
        if (text === "COMPRAR") {
          this.zonasGeneral.forEach((element: any, x: any) => {
            element.forEach((el: any, elx: any) => {
              el.graphics.setDepth(11);
            });
          });
          btnComprar.setAlpha(1);
          scene.segundof.destroy();
          // scene.graphicCom1.destroy()
          scene.botonesEstacion.forEach((element: any) => {
            element.stop();
          });
          /// scene.thweensGrap.targets[0].setVisible(0)
          //  console.log(scene.thweensGrap)
          if (this.carrito.length > 0) {
            this.tableMono1.visible = true;
            this.add.sprite(190, 140, "relojmono", 0).setDepth(11);
            let tiempoTotal = 0;
            this.carrito.forEach((element: any) => {
              tiempoTotal = tiempoTotal + element.cantidad * element.time;
            });
            let count = 1;
            this.clickCountPuerta = 1;
            this.intervaloClock = setInterval(() => {
              this.relojMono = this.add.sprite(190, 140, "relojmono", count);
              this.relojMono.setDepth(11);
              count++;
              console.log(tiempoTotal, count);
            }, (tiempoTotal / 7) * 1000);
            this.crearPiezas();
            this.tableMono1.visible = false;
            this.temporalItems.forEach((element: any) => {
              element.setText("00");
            });
          }
        } else {
          if (this.tableMono1) {
            this.tableMono1.visible = false;
          }
        }
      });
    this.btnGeneralComprar = backgrundF;
    let btnComprar = scene.rexUI.add
      .label({
        //y:100,
        height: orientation === 0 ? 0 : undefined,
        width: orientation === 0 ? undefined : 40,
        orientation: orientation,
        background: backgrundF,
        text: scene.add.text(0, 0, text, {
          fontSize: scene.isMobile() ? 22 : 12,
          fontFamily: "font1",
          padding: {
            left: 5,
            right: 5,
            top: 5,
            bottom: 5,
          },
        }),
        // icon: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 10, COLOR_LIGHT),
        align: "center",
        space: {
          icon: 10,
        },
      })
      .setDepth(11);
    this.btnComprarPuerta = btnComprar;
    return btnComprar;
  }
  getRandomPosition(scena: any) {
    let posicion = Math.floor(Math.random() * scena.espacios.length);
    let respuesta = scena.espacios[posicion];
    //   scena.espacios.splice(posicion, 1)
    return respuesta;
  }
  createWindow(func: any, isNext: any) {
    var handle = "window" + generateUUID();

    var win = this.add.zone(0, 0, 0, 0).setOrigin(0).setDepth(11);

    var demo = new func(handle, win);

    //this.input.setDraggable(win);
    let pasarNivel = isNext ? isNext : false;
    return this.scene.add(handle, demo, true, {
      tipo: "Tutorial",
      pasarNivel: pasarNivel,
    });
  }
  crearPiezas() {
    this.music = this.sound.add("clock");
    this.music.play();
    this.music.setLoop(true);
    for (let index = 0; index < this.carrito.length; index++) {
      let cantidad = parseInt(this.carrito[index].cantidad);
      let nameObject = this.carrito[index].name;

      let timeForActual =
        this.carrito[index === 0 ? 0 : index - 1].time *
        this.carrito[index === 0 ? 0 : index - 1].cantidad *
        1000 *
        index;
      let nextTime;

      if (index === 0) {
        for (let indexChild = 1; indexChild <= cantidad; indexChild++) {
          nextTime = this.carrito[index].time * 1000 * indexChild;
          setTimeout(() => {
            const position: any = this.generarAleatorio(
              0,
              Posiciones.length - 1
            );
            let descuento = parseInt(this.carrito[index].precio);
            let dineroBase = parseInt(this.dineroBase.text);
            this.dineroBase.setText(dineroBase - descuento);
            console.log(position.x, position.y);
            this.piezasPuertas(position.x, position.y, nameObject);
            this.piezasStock.puerta[nameObject] =
              this.piezasStock.puerta[nameObject] + 1;
            localStorage.setItem(
              "piezasStock",
              JSON.stringify(this.piezasStock)
            );
            console.log(
              this.piezasStock.puerta,
              JSON.parse(localStorage.getItem("piezasStock") as any)
            );
            if (index === this.carrito.length - 1 && indexChild === cantidad) {
              this.relojMono = this.add.sprite(190, 140, "relojmono", 8);
              this.music.stop();
              this.relojMono.setDepth(11);
              if (this.countDrag === 0) {
                this.p2 = this.add
                  .image(200, 360, "p2")
                  .setDepth(11)
                  .setInteractive({ cursor: "pointer" });
                //this.input.setDraggable([this.p2])
                this.p2.myArray = [
                  [1, 1],
                  [1, null],
                ];
                this.p2.tipo = "puerta";
                this.p2.name = "p2";
                this.p2.posicionTipo = 1;
                this.p2.posicionAdecuada = [149, 181];
                //this.p1.setDepth(11)

                this.segundoh = this.createTextBox(
                  this,
                  30,
                  410,
                  {
                    wrapWidth: 250,
                  },
                  this.add.image(0, 0, "tutorialnube").setScale(1.5),
                  9,
                  "left",
                  () => {
                    this.cada_fabrica_tiene.play();
                  }
                ).start(
                  "Cada fábrica tiene su propio tiempo de producción. Cuando las piezas estén listas, caerán alrededor del tablero.",
                  50
                );
                this.segundoh.setDepth(11);

                this.pk1 = this.add
                  .image(437, 465, "p2")
                  .setDepth(11)
                  .setAlpha(0.7);
                this.pk2 = this.add
                  .image(437, 515, "p2")
                  .setDepth(11)
                  .setAlpha(0.7);
                this.pk3 = this.add
                  .image(449, 440, "p3")
                  .setDepth(11)
                  .setAlpha(0.7);
                this.pk4 = this.add
                  .image(462, 502, "p4")
                  .setDepth(11)
                  .setAlpha(0.7);

                setTimeout(() => {
                  this.segundoh.destroy();
                  this.segundoh = this.createTextBox(
                    this,
                    30,
                    410,
                    {
                      wrapWidth: 250,
                    },
                    this.add.image(0, 0, "tutorialnube"),
                    9,
                    "left"
                  ).start(
                    "Para armar la casa, arrastra las piezas hacia la mesa de trabajo.",
                    50
                  );
                  this.segundoh.setDepth(11);
                  setTimeout(() => {
                    this.tests = this.tweens.add({
                      targets: this.p2,
                      x: 437,
                      y: 465,
                      duration: 850,
                      ease: "Power0",
                    });
                    this.piezasCompradas.forEach((element: any) => {
                      this.tweensPiezas.push(
                        this.tweens.add({
                          targets: element,
                          alpha: 0.3,
                          ease: "Cubic.easeOut",
                          duration: 300,
                          repeat: -1,
                          yoyo: true,
                        })
                      );
                    });
                  }, 3000);
                  this.countDrag++;
                }, 7000);
              }
              clearInterval(this.intervaloClock);
              this.carrito = [];
            }
          }, this.carrito[index].time * 1000 * indexChild);
        }
      } else {
        nextTime =
          this.carrito[index - 1].time *
            1000 *
            this.carrito[index - 1].cantidad +
          1000;
        setTimeout(() => {
          for (
            let indexChildElse = 1;
            indexChildElse <= cantidad;
            indexChildElse++
          ) {
            if (indexChildElse === 1) {
              setTimeout(() => {
                const position: any = this.generarAleatorio(
                  0,
                  Posiciones.length - 1
                );
                /*                                 console.log(`Lanzado as ${nameObject}`) */
                console.log(position.x, position.y);
                this.piezasPuertas(
                  position.x,
                  position.y,
                  this.carrito[index].name
                );
                this.piezasStock.puerta[nameObject] =
                  this.piezasStock.puerta[nameObject] + 1;
                let descuento = parseInt(this.carrito[index].precio);
                let dineroBase = parseInt(this.dineroBase.text);
                localStorage.setItem(
                  "piezasStock",
                  JSON.stringify(this.piezasStock)
                );
                console.log(
                  this.piezasStock.puerta,
                  JSON.parse(localStorage.getItem("piezasStock") as any)
                );
                this.dineroBase.setText(dineroBase - descuento);

                if (
                  index === this.carrito.length - 1 &&
                  indexChildElse === cantidad
                ) {
                  this.relojMono = this.add.sprite(190, 140, "relojmono", 8);
                  this.relojMono.setDepth(11);
                  this.music.stop();
                  console.log("entre jeje");
                  if (this.countDrag === 0) {
                    this.p2 = this.add
                      .image(200, 360, "p2")
                      .setDepth(11)
                      .setInteractive({ cursor: "pointer" });
                    //this.input.setDraggable([this.p2])
                    this.p2.myArray = [
                      [1, 1],
                      [1, null],
                    ];
                    this.p2.tipo = "puerta";
                    this.p2.name = "p2";
                    this.p2.posicionTipo = 1;
                    this.p2.posicionAdecuada = [149, 181];
                    //this.p1.setDepth(11)

                    this.segundoh = this.createTextBox(
                      this,
                      30,
                      410,
                      {
                        wrapWidth: 250,
                      },
                      this.add.image(0, 0, "tutorialnube"),
                      9,
                      "left",
                      () => {
                        this.cada_fabrica_tiene.play();
                      }
                    ).start(
                      "Cada fábrica tiene su propio tiempo de producción. Cuando las piezas estén listas, caerán alrededor del tablero.",
                      50
                    );
                    this.segundoh.setDepth(11);

                    this.pk1 = this.add
                      .image(437, 465, "p2")
                      .setDepth(11)
                      .setAlpha(0.7);
                    this.pk2 = this.add
                      .image(437, 515, "p2")
                      .setDepth(11)
                      .setAlpha(0.7);
                    this.pk3 = this.add
                      .image(449, 440, "p3")
                      .setDepth(11)
                      .setAlpha(0.7);
                    this.pk4 = this.add
                      .image(462, 502, "p4")
                      .setDepth(11)
                      .setAlpha(0.7);

                    setTimeout(() => {
                      this.segundoh.destroy();
                      this.segundoh = this.createTextBox(
                        this,
                        30,
                        410,
                        {
                          wrapWidth: 250,
                        },
                        this.add.image(0, 0, "tutorialnube"),
                        9,
                        "left"
                      ).start(
                        "Para armar la casa, arrastra las piezas hacia la mesa de trabajo.",
                        50
                      );
                      this.segundoh.setDepth(11);
                      setTimeout(() => {
                        this.tests = this.tweens.add({
                          targets: this.p2,
                          x: 437,
                          y: 465,
                          duration: 850,
                          ease: "Power0",
                        });
                        this.piezasCompradas.forEach((element: any) => {
                          this.tweensPiezas.push(
                            this.tweens.add({
                              targets: element,
                              alpha: 0.3,
                              ease: "Cubic.easeOut",
                              duration: 300,
                              repeat: -1,
                              yoyo: true,
                            })
                          );
                        });
                      }, 3000);
                      this.countDrag++;
                    }, 7000);
                  }
                  clearInterval(this.intervaloClock);
                  this.carrito = [];
                }
              }, this.carrito[index].time * 1000);
            } else {
              setTimeout(() => {
                const position: any = this.generarAleatorio(
                  0,
                  Posiciones.length - 1
                );
                /*                                 console.log(`Lanzado asx ${nameObject}`) */
                console.log(position.x, position.y);
                this.piezasPuertas(
                  position.x,
                  position.y,
                  this.carrito[index].name
                );
                this.piezasStock.puerta[nameObject] =
                  this.piezasStock.puerta[nameObject] + 1;
                let descuento = parseInt(this.carrito[index].precio);
                let dineroBase = parseInt(this.dineroBase.text);
                localStorage.setItem(
                  "piezasStock",
                  JSON.stringify(this.piezasStock)
                );
                console.log(
                  this.piezasStock.puerta,
                  JSON.parse(localStorage.getItem("piezasStock") as any)
                );
                this.dineroBase.setText(dineroBase - descuento);

                if (
                  index === this.carrito.length - 1 &&
                  indexChildElse === cantidad
                ) {
                  this.relojMono = this.add.sprite(190, 140, "relojmono", 8);
                  this.relojMono.setDepth(11);
                  this.music.stop();
                  console.log("entre jeje");
                  if (this.countDrag === 0) {
                    this.p2 = this.add
                      .image(200, 360, "p2")
                      .setDepth(11)
                      .setInteractive({ cursor: "pointer" });
                    //this.input.setDraggable([this.p2])
                    this.p2.myArray = [
                      [1, 1],
                      [1, null],
                    ];
                    this.p2.tipo = "puerta";
                    this.p2.name = "p2";
                    this.p2.posicionTipo = 1;
                    this.p2.posicionAdecuada = [149, 181];
                    //this.p1.setDepth(11)

                    this.segundoh = this.createTextBox(
                      this,
                      30,
                      410,
                      {
                        wrapWidth: 250,
                      },
                      this.add.image(0, 0, "tutorialnube"),
                      9,
                      "left",
                      () => {
                        this.cada_fabrica_tiene.play();
                      }
                    ).start(
                      "Cada fábrica tiene su propio tiempo de producción. Cuando las piezas estén listas, caerán alrededor del tablero.",
                      50
                    );
                    this.segundoh.setDepth(11);

                    this.pk1 = this.add
                      .image(437, 465, "p2")
                      .setDepth(11)
                      .setAlpha(0.7);
                    this.pk2 = this.add
                      .image(437, 515, "p2")
                      .setDepth(11)
                      .setAlpha(0.7);
                    this.pk3 = this.add
                      .image(449, 440, "p3")
                      .setDepth(11)
                      .setAlpha(0.7);
                    this.pk4 = this.add
                      .image(462, 502, "p4")
                      .setDepth(11)
                      .setAlpha(0.7);

                    setTimeout(() => {
                      this.segundoh.destroy();
                      this.segundoh = this.createTextBox(
                        this,
                        30,
                        410,
                        {
                          wrapWidth: 250,
                        },
                        this.add.image(0, 0, "tutorialnube"),
                        9,
                        "left"
                      ).start(
                        "Para armar la casa, arrástralas hacia la mesa de trabajo.",
                        50
                      );
                      this.segundoh.setDepth(11);
                      setTimeout(() => {
                        this.tests = this.tweens.add({
                          targets: this.p2,
                          x: 437,
                          y: 465,
                          duration: 850,
                          ease: "Power0",
                        });
                        this.piezasCompradas.forEach((element: any) => {
                          this.tweensPiezas.push(
                            this.tweens.add({
                              targets: element,
                              alpha: 0.3,
                              ease: "Cubic.easeOut",
                              duration: 300,
                              repeat: -1,
                              yoyo: true,
                            })
                          );
                        });
                      }, 3000);
                      this.countDrag++;
                    }, 7000);
                  }
                  clearInterval(this.intervaloClock);
                  this.carrito = [];
                }
              }, this.carrito[index].time * 1000 * indexChildElse);
            }
          }
        }, timeForActual);
      }
    }
  }
  piezasPuertas(x: any, y: any, name: any) {
    console.log(name, "xd");
    switch (name) {
      case "p2":
        this.p1 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.p1.myArray = [
          [1, 1],
          [1, null],
        ];
        this.p1.tipo = "puerta";
        this.p1.name = "p2";
        this.p1.posicionTipo = 1;
        this.input.setDraggable([this.p1]);
        this.p1.posicionAdecuada = [149, 181];
        this.p1.setDepth(25);
        this.piezasCompradas.push(this.p1);
        break;
      case "p3":
        this.p3 = this.add
          .image(x, y, "p3")
          .setInteractive({ cursor: "pointer" });
        this.p3.myArray = [
          [1, 1, 1],
          [null, null, 1],
        ];
        this.p3.hideX = true;
        this.p3.tipo = "puerta";
        this.p3.posicionTipo = 0;
        this.p3.name = "p3";
        this.input.setDraggable([this.p3]);
        this.p3.posicionAdecuada = [133];
        this.p3.setDepth(25);
        this.piezasCompradas.push(this.p3);
        break;
      case "p4":
        this.p4 = this.add
          .image(x, y, "p4")
          .setInteractive({ cursor: "pointer" });
        this.p4.myArray = [
          [1, 1],
          [null, 1],
          [1, 1],
        ];
        this.p4.hideY = true;
        this.p4.tipo = "puerta";
        this.p4.name = "p4";
        this.p4.posicionTipo = 2;
        this.input.setDraggable([this.p4]);
        this.p4.posicionAdecuada = [166];
        this.p4.setDepth(25);
        this.piezasCompradas.push(this.p4);
        break;
    }
  }
  createTextBox(
    scene: any,
    x: any,
    y: any,
    config: any,
    fondo: any,
    altura: any,
    tipo: any,
    handleStart = () => {},
    handleFinish = () => {}
  ) {
    const GetValue = Phaser.Utils.Objects.GetValue;
    var wrapWidth = GetValue(config, "wrapWidth", 0);
    var fixedWidth = GetValue(config, "fixedWidth", 0);
    var fixedHeight = GetValue(config, "fixedHeight", 0);
    let spaceJson = {
      left: 70,
      right: 20,
      top: 20,
      bottom: 20,
      icon: 10,
      text: 10,
    };
    if (tipo === "left") {
      spaceJson = {
        left: 20,
        right: 70,
        top: 20,
        bottom: 20,
        icon: 10,
        text: 10,
      };
    }
    if (tipo === "center") {
      console.log("centrado");
      spaceJson = {
        left: 20,
        right: 20,
        top: 40,
        bottom: 20,
        icon: 10,
        text: 10,
      };
    }
    var textBox = scene.rexUI.add
      .textBox({
        x: x,
        y: y,
        background: fondo,
        // icon: scene.add.image(0, 0, 'nextPage').setTint(COLOR_LIGHT).setVisible(false),
        text: this.getBBcodeText(
          scene,
          wrapWidth,
          fixedWidth,
          fixedHeight,
          altura
        ),
        // action: scene.add.image(0, 0, 'nextPage').setTint(COLOR_LIGHT).setVisible(false),
        space: spaceJson,
      })
      .setOrigin(0)
      .layout();
    textBox
      .setInteractive({ cursor: "pointer" })
      .on("start", handleStart)
      .on("pageend", handleFinish)
      .on(
        "pointerdown",
        () => {
          // var icon = this.getElement('action').setVisible(false);
          //this.resetChildVisibleState(icon);
          if (this.isTyping) {
            //  this.stop(true);
          } else {
            // this.typeNextPage();
          }
        },
        textBox
      )
      .on(
        "pageend",
        () => {
          if (this.isLastPage) {
            if (this.mostrarOtro) {
              this.mostrarOtro = false;
              this.btnGrupo.setVisible(1);
              clearInterval(this.intervaloConversa);
              return;
            }
            //  this.scene.btnGrupo.setVisible(1)
          }
          /*                 var icon = this.getElement('action').setVisible(true);
                this.resetChildVisibleState(icon);
                icon.y -= 30;
                var tween = scene.tweens.add({
                    targets: icon,
                    y: '+=30',
                    ease: 'Bounce',
                    duration: 500,
                    repeat: 0,
                    yoyo: false
                }); */
        },
        textBox
      );

    this.intervaloConversa = setInterval(() => {
      textBox.emit("pointerdown");
    }, 800);
    (this.input.keyboard as any).on("keydown-ENTER", function (event: any) {
      textBox.emit("pointerdown");
    });
    return textBox;
  }
  getBuiltInText(
    scene: any,
    wrapWidth: any,
    fixedWidth: any,
    fixedHeight: any
  ) {
    return scene.add
      .text(0, 0, "", {
        fontSize: "20px",
        wordWrap: {
          width: wrapWidth,
        },
        maxLines: 3,
        color: "#0B2167",
      })
      .setFixedSize(fixedWidth, fixedHeight);
  }

  generarAleatorio(min: any, max: any) {
    let numero = Math.floor(Math.random() * (max - min)) + min;
    let temporal = Posiciones[numero];

    if (temporal.active === true) {
      let temporalNew: any = Posiciones.map((item, index) => {
        if (item.active === false) {
          return { ...item, posicion: index };
        }
      }).filter(Boolean);
      // console.log(temporalNew)
      Posiciones[temporalNew[0].posicion].active = true;
      return temporalNew[0];
    } else {
      Posiciones[numero].active = true;
      return temporal;
    }
  }

  getBBcodeText(
    scene: any,
    wrapWidth: any,
    fixedWidth: any,
    fixedHeight: any,
    altura: any
  ) {
    return scene.rexUI.add.BBCodeText(0, 0, "", {
      fixedWidth: fixedWidth,
      fixedHeight: fixedHeight,

      fontSize: "18px",
      wrap: {
        mode: "word",
        width: wrapWidth,
      },
      maxLines: altura ? altura : 4,
      color: "#000000",
    });
  }

  eventosDrag() {
    const eventos = Phaser.Input.Events;

    this.input.on(
      eventos.DRAG_START,
      (pointer: any, obj: any, dragX: any, dragY: any) => {
        this.sound.play("coge_pieza");
        if (obj.tipo === "puerta") {
          if (obj.myArray) {
            obj.setScale(0.9);
            obj.x = pointer.x + 12.5 * obj.myArray[0].length;
            obj.y = pointer.y + 12.5 * obj.myArray.length;
          }
        }
        if (obj.tipo === "techo") {
          obj.x = pointer.x + 10 * obj.myArray[0].length;
          obj.y = pointer.y + 10 * obj.myArray.length;
        }
        if (obj.tipo === "ventana") {
          obj.setScale(0.9);
          obj.x = pointer.x + 12.5 * obj.myArray[0].length;
          obj.y = pointer.y + 12.5 * obj.myArray.length;
        }
        if (obj.tipo === "pared") {
          obj.setScale(0.9);
          //console.log(obj)
          if (obj.especial) {
            obj.x = pointer.x - 2;
            obj.y = pointer.y + 12.5 * obj.myArray.length;
          } else {
            if (obj.triangulo) {
              obj.x = pointer.x - 2;
              obj.y = pointer.y + 12.5 * obj.myArray.length;
            } else {
              obj.x = pointer.x + 12.5 * obj.myArray[0].length;
              obj.y = pointer.y + 12.5 * obj.myArray.length;
            }
          }
        }
        this.tweensPiezas.forEach((element: any) => {
          element.stop();
        });
        this.piezasCompradas.forEach((element: any) => {
          element.setAlpha(1);
        });
      }
    );

    this.input.on(
      eventos.DRAG,
      (pointer: any, obj: any, dragX: any, dragY: any) => {
        obj.x = pointer.x + 12.5;
        obj.y = pointer.y + 12.5;
        if (obj.tipo === "puerta") {
          if (obj.myArray) {
            obj.x = pointer.x + 12.5 * obj.myArray[0].length;
            obj.y = pointer.y + 12.5 * obj.myArray.length;
          }
        }
        if (obj.tipo === "techo") {
          obj.x = pointer.x + 10 * obj.myArray[0].length;
          obj.y = pointer.y + 10 * obj.myArray.length;
        }
        if (obj.tipo === "ventana") {
          obj.x = pointer.x + 12.5 * obj.myArray[0].length;
          obj.y = pointer.y + 12.5 * obj.myArray.length;
        }
        if (obj.tipo === "pared") {
          if (obj.especial) {
            obj.x = pointer.x - 2;
            obj.y = pointer.y + 12.5 * obj.myArray.length;
          } else {
            if (obj.triangulo) {
              obj.x = pointer.x - 2;
              obj.y = pointer.y + 12.5 * obj.myArray.length;
            } else {
              obj.x = pointer.x + 12.5 * obj.myArray[0].length;
              obj.y = pointer.y + 12.5 * obj.myArray.length;
            }
          }
        }
      }
    );

    this.input.on(eventos.DRAG_END, (pointer: any, obj: any, dropzone: any) => {
      this.sound.play("soltar_pieza");
      if (obj.tipo !== "monos") {
        obj.setScale(1);
      }
    });

    this.input.on(
      eventos.DRAG_OVER,
      (pointer: any, gameObject: any, dropzone: any) => {
        if (gameObject.tipo === "puerta") {
          let positionInitial: any = dropzone.posicion;
          let countPosition: any = 0;
          let arrayPosiciones: any = [];
          const arrayObjeto = gameObject.myArray;
          for (var x = 0; x < arrayObjeto.length; x++) {
            arrayPosiciones[x] = [];
            for (var y = 0; y < arrayObjeto[x].length; y++) {
              if (x === 0 && y === 0) {
                arrayPosiciones[x][y] = positionInitial;
              } else if (x === 0) {
                arrayPosiciones[x][y] = positionInitial + y * 16;
              } else if (y === 0) {
                arrayPosiciones[x][y] = positionInitial + x;
              } else {
                arrayPosiciones[x][y] = positionInitial + y * 16 + x;
              }
              if (arrayObjeto[x][y] === null) {
                let indexPosicion4: any = parseInt(
                  (arrayPosiciones[x][y] / 16) as any
                );
                arrayPosiciones[x][y] = null;
              }
            }
          }
          this.arrayPosicionActualArray = arrayPosiciones;
        }
      }
    );

    this.input.on(
      eventos.DRAG_ENTER,
      (pointer: any, gameObject: any, dropzone: any) => {
        if (gameObject.tipo === "techo") {
          let positionInitial = dropzone.posicion;
          let countPosition = 0;
          let arrayPosiciones: any = [];
          const arrayObjeto = gameObject.myArray;
          for (var x = 0; x < arrayObjeto.length; x++) {
            arrayPosiciones[x] = [];
            //      console.log(arrayObjeto[x])
            for (var y = 0; y < arrayObjeto[x].length; y++) {
              if (x === 0 && y === 0) {
                arrayPosiciones[x][y] = positionInitial;
              } else if (x === 0) {
                arrayPosiciones[x][y] = positionInitial + y * 16;
              } else if (y === 0) {
                arrayPosiciones[x][y] = positionInitial + x;
              } else {
                arrayPosiciones[x][y] = positionInitial + y * 16 + x;
                if (arrayObjeto[x][y] === null) {
                  arrayPosiciones[x][y] = null;
                }
              }
            }
          }
        }
        if (gameObject.tipo === "ventana") {
          let positionInitial = dropzone.posicion;
          let countPosition = 0;
          let arrayPosiciones: any = [];
          const arrayObjeto = gameObject.myArray;
          for (var x = 0; x < arrayObjeto.length; x++) {
            arrayPosiciones[x] = [];
            for (var y = 0; y < arrayObjeto[x].length; y++) {
              if (x === 0 && y === 0) {
                arrayPosiciones[x][y] = positionInitial;
              } else if (x === 0) {
                arrayPosiciones[x][y] = positionInitial + y * 16;
              } else if (y === 0) {
                arrayPosiciones[x][y] = positionInitial + x;
              } else {
                arrayPosiciones[x][y] = positionInitial + y * 16 + x;
              }

              if (arrayObjeto[x][y] === null) {
                arrayPosiciones[x][y] = null;
              }
            }
          }
          this.arrayPosicionActualArray = arrayPosiciones;
        }
        if (gameObject.tipo === "pared") {
          let positionInitial = dropzone.posicion;
          let countPosition = 0;
          let arrayPosiciones: any = [];
          const arrayObjeto = gameObject.myArray;
          for (var x = 0; x < arrayObjeto.length; x++) {
            arrayPosiciones[x] = [];
            for (var y = 0; y < arrayObjeto[x].length; y++) {
              if (x === 0 && y === 0) {
                arrayPosiciones[x][y] = positionInitial;
              } else if (x === 0) {
                arrayPosiciones[x][y] = positionInitial + y * 16;
              } else if (y === 0) {
                arrayPosiciones[x][y] = positionInitial + x;
              } else {
                arrayPosiciones[x][y] = positionInitial + y * 16 + x;
              }

              if (arrayObjeto[x][y] === null) {
                arrayPosiciones[x][y] = null;
              }
            }
          }
          this.arrayPosicionActualArray = arrayPosiciones;
        }
      }
    );

    this.input.on(
      eventos.DRAG_LEAVE,
      (pointer: any, gameObject: any, dropzone: any) => {}
    );

    this.input.on(
      eventos.DROP,
      (pointer: any, gameObject: any, dropzone: any) => {
        if (gameObject.tipo === "puerta") {
          if (this.segundoh && this.countDrag === 1) {
            if (dropzone) {
              this.tweensPiezas.forEach((element: any) => {
                element.stop();
              });
              this.piezasCompradas.forEach((element: any) => {
                element.setAlpha(1);
              });
              this.segundoh.destroy();
              this.segundoh = this.createTextBox(
                this,
                30,
                410,
                {
                  wrapWidth: 250,
                },
                this.add.image(0, 0, "tutorialnube").setScale(1.2),
                9,
                "left",
                () => {
                  this.cada_fabrica_tiene.pause();
                  this.pon_cada_pieza.play();
                }
              ).start(
                "Pon cada pieza en su ubicación exacta. Si te equivocas en el armado, al final de la ronda, tu casa no se podrá utilizar. ",
                50
              );
              this.segundoh.setDepth(15);
              setTimeout(() => {
                this.segundoh.destroy();
                this.segundoh = this.createTextBox(
                  this,
                  30,
                  410,
                  {
                    wrapWidth: 250,
                  },
                  this.add.image(0, 0, "tutorialnube").setScale(1.2),
                  9,
                  "left",
                  () => {},
                  () => {
                    this.btnGrupo.setVisible(1);
                    this.paso = 6;
                  }
                ).start(
                  "Si la casa no se puede utilizar, se te devolverá un 70% de lo que te costó construirla.",
                  50
                );
                this.segundoh.setDepth(15);
                this.mostrarOtro = true;
              }, 7500);
              //
              this.countDrag++;
            }
          }

          if (gameObject.hideX) {
            gameObject.x = dropzone.x + 25;
          } else {
            gameObject.x = dropzone.x + 12.5;
          }
          if (gameObject.hideY) {
            gameObject.y = dropzone.y + 25;
          } else {
            gameObject.y = dropzone.y + 12.5;
          }
          // console.log(gameObject)
          if (gameObject.posicionAdecuada.includes(dropzone.name)) {
            if (gameObject.posicionAdecuada.length > 1) {
              if (!this.posicionAdecuadaJsonP[dropzone.name]) {
                this.posicionAdecuadaJsonP[dropzone.name] = true;
              } else {
                this.posicionesEstablecidas[gameObject.name] = false;
              }
              console.log(this.posicionAdecuadaJsonP);
              console.log(
                Object.values(this.posicionAdecuadaJsonP).includes(false)
              );
              if (!Object.values(this.posicionAdecuadaJsonP).includes(false)) {
                this.posicionesEstablecidas[gameObject.name] = true;
              }
            } else {
              this.posicionesEstablecidas[gameObject.name] = true;
            }
          } else {
            this.posicionesEstablecidas[gameObject.name] = false;
          }
          this.objetosDropzone.push(gameObject);
        }
        if (gameObject.tipo === "techo") {
          if (gameObject.hideX) {
            gameObject.x = dropzone.x + 25;
          } else {
            if (gameObject.especialX3 === true) {
              gameObject.x = dropzone.x + 25 + 12.5;
            } else {
              gameObject.x = dropzone.x + 12.5;
            }
          }
          if (gameObject.hideY) {
            gameObject.y = dropzone.y + 25;
          } else {
            if (gameObject.techoEspecial === true) {
              gameObject.y = dropzone.y + 25 + 12.5;
            } else {
              gameObject.y = dropzone.y + 12.5;
            }
          }

          if (gameObject.posicionAdecuada.includes(dropzone.name)) {
            if (gameObject.posicionAdecuada.length > 1) {
              if (!this.posicionAdecuadaJsonT[dropzone.name]) {
                this.posicionAdecuadaJsonT[dropzone.name] = true;
              } else {
                this.posicionesEstablecidas[gameObject.name] = false;
              }
              console.log(this.posicionAdecuadaJsonT);
              console.log(
                Object.values(this.posicionAdecuadaJsonT).includes(false)
              );
              if (!Object.values(this.posicionAdecuadaJsonT).includes(false)) {
                this.posicionesEstablecidas[gameObject.name] = true;
              }
            } else {
              this.posicionesEstablecidas[gameObject.name] = true;
            }
          } else {
            this.posicionesEstablecidas[gameObject.name] = false;
          }

          console.log(this.posicionesEstablecidas);
          this.objetosDropzone.push(gameObject);
        }
        if (gameObject.tipo === "ventana") {
          if (gameObject.hideX) {
            if (gameObject.especialX === true) {
              gameObject.x = dropzone.x;
            } else {
              gameObject.x = dropzone.x + 25;
            }
          } else {
            gameObject.x = dropzone.x + 12.5;
          }

          if (gameObject.hideY) {
            if (gameObject.especialY === true) {
              gameObject.y = dropzone.y;
            } else {
              gameObject.y = dropzone.y + 12.5;
            }
          } else {
            console.log("aquo");
            gameObject.y = dropzone.y + 25;
          }

          if (gameObject.posicionAdecuada.includes(dropzone.name)) {
            if (gameObject.posicionAdecuada.length > 1) {
              if (!this.posicionAdecuadaJsonV[dropzone.name]) {
                this.posicionAdecuadaJsonV[dropzone.name] = true;
              } else {
                this.posicionesEstablecidas[gameObject.name] = false;
              }
              console.log(this.posicionAdecuadaJsonV);
              console.log(
                Object.values(this.posicionAdecuadaJsonV).includes(false)
              );
              if (!Object.values(this.posicionAdecuadaJsonV).includes(false)) {
                this.posicionesEstablecidas[gameObject.name] = true;
              }
            } else {
              this.posicionesEstablecidas[gameObject.name] = true;
            }
          } else {
            this.posicionesEstablecidas[gameObject.name] = false;
          }
          this.objetosDropzone.push(gameObject);
        }
        if (gameObject.tipo === "pared") {
          if (gameObject.hideX) {
            if (gameObject.especialX === true) {
              gameObject.x = dropzone.x + 25 + 12.5;
            } else {
              gameObject.x = dropzone.x + 12.5;
            }
          } else {
            if (gameObject.limpio === true) {
              if (gameObject.menosx === true) {
                gameObject.x = dropzone.x + 25;
              } else {
                gameObject.x = dropzone.x;
              }
            } else {
              /*                        if(gameObject.triangulo){
                         gameObject.x = dropzone.x+ 25
                        }else{ */
              gameObject.x = dropzone.x + 12.5;
              // }
            }
          }
          if (gameObject.hideY) {
            if (gameObject.espHide) {
              gameObject.y = dropzone.y + 25;
            } else {
              gameObject.y = dropzone.y + 12.5;
            }
          } else {
            if (gameObject.limpio === true) {
              gameObject.y = dropzone.y;
            } else {
              gameObject.y = dropzone.y + 12.5;
            }
          }

          let nombre = `posicionAdecuadaJson${gameObject.name}`;
          if (gameObject.posicionAdecuada.includes(dropzone.name)) {
            if (gameObject.posicionAdecuada.length > 1) {
              if (!(this as any)[nombre][dropzone.name]) {
                (this as any)[nombre][dropzone.name] = true;
              } else {
                this.posicionesEstablecidas[gameObject.name] = false;
              }
              console.log((this as any)[nombre]);
              console.log(Object.values((this as any)[nombre]).includes(false));
              if (!Object.values((this as any)[nombre]).includes(false)) {
                this.posicionesEstablecidas[gameObject.name] = true;
              }
            } else {
              this.posicionesEstablecidas[gameObject.name] = true;
            }
          } else {
            this.posicionesEstablecidas[gameObject.name] = false;
          }
          this.objetosDropzone.push(gameObject);
        }
      }
    );

    ////////////////////////////////////////////////////////////////////////////////
  }
}

export default Tutorial;
