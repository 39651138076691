export const materialesPuerta = [
  {
    tipo: "object",
    objeto: "p22",
    name: "p2",
    time: 4,
    timeMejora: 7,
  },
  {
    tipo: "info",
    precio: "1",
    name: "p2",
    time: 4,
    timeMejora: 7,
  },
  {
    tipo: "object",
    objeto: "p33",
    name: "p3",
    time: 4,
    timeMejora: 7,
  },
  {
    tipo: "info",
    precio: "1",
    name: "p3",
    time: 4,
    timeMejora: 7,
  },
  {
    tipo: "object",
    objeto: "p44",
    name: "p4",
    time: 4,
    timeMejora: 7,
  },
  {
    tipo: "info",
    precio: "1",
    name: "p4",
    time: 4,
    timeMejora: 7,
  },
];

export const materialesPared = [
  {
    tipo: "object",
    objeto: "pa00",
    name: "pa0",
    indexLoop: 0,
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "info",
    precio: "1",
    name: "pa0",
    indexLoop: 0,
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "object",
    objeto: "pa22",
    indexLoop: 1,
    name: "pa1",
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "info",
    precio: "1",
    indexLoop: 1,
    name: "pa1",
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "object",
    objeto: "pa33",
    indexLoop: 2,
    name: "pa2",
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "info",
    precio: "1",
    indexLoop: 2,
    name: "pa2",
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "object",
    objeto: "pa44",
    indexLoop: 3,
    name: "pa3",
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "info",
    precio: "1",
    indexLoop: 3,
    name: "pa3",
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "object",
    objeto: "pa55",
    indexLoop: 4,
    name: "pa4",
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "info",
    precio: "1",
    name: "pa4",
    indexLoop: 4,
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "object",
    objeto: "pa66",
    name: "pa5",
    indexLoop: 5,
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "info",
    precio: "1",
    name: "pa5",
    indexLoop: 5,
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "object",
    objeto: "pa77",
    name: "pa6",
    indexLoop: 6,
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "info",
    precio: "1",
    name: "pa6",
    indexLoop: 6,
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "object",
    objeto: "pa88",
    name: "pa7",
    indexLoop: 7,
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "info",
    precio: "1",
    name: "pa7",
    indexLoop: 7,
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "object",
    objeto: "pa99",
    indexLoop: 8,
    name: "pa8",
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "info",
    indexLoop: 8,
    precio: "1",
    name: "pa8",
    time: 7,
    timeMejora: 3,
  },
];

export const materialesParedNew = [
  {
    tipo: "object",
    objeto: "pa22",
    indexLoop: 1,
    name: "pa1",
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "info",
    precio: "1",
    indexLoop: 1,
    name: "pa1",
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "object",
    objeto: "pa44",
    indexLoop: 3,
    name: "pa3",
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "info",
    precio: "1",
    indexLoop: 3,
    name: "pa3",
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "object",
    objeto: "pa2ne",
    indexLoop: 8,
    name: "pa2n",
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "info",
    indexLoop: 8,
    precio: "1",
    name: "pa2n",
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "object",
    objeto: "pa8ne",
    indexLoop: 8,
    name: "pa8n",
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "info",
    indexLoop: 8,
    precio: "1",
    name: "pa8n",
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "object",
    objeto: "pa88",
    name: "pa7",
    indexLoop: 7,
    time: 7,
    timeMejora: 3,
  },
  {
    tipo: "info",
    precio: "1",
    name: "pa7",
    indexLoop: 7,
    time: 7,
    timeMejora: 3,
  },
];

export const materialesTecho = [
  {
    tipo: "object",
    objeto: "t11",
    name: "t1",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "info",
    precio: "1",
    name: "t1",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "object",
    objeto: "t22",
    name: "t2",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "info",
    precio: "1",
    name: "t2",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "object",
    objeto: "t33",
    name: "t3",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "info",
    precio: "1",
    name: "t3",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "object",
    objeto: "t44",
    name: "t4",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "info",
    precio: "1",
    name: "t4",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "object",
    objeto: "t55",
    name: "t5",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "info",
    precio: "1",
    name: "t5",
    time: 3,
    timeMejora: 4,
  },
];

export const materialesTechoNew = [
  {
    tipo: "object",
    objeto: "t11",
    name: "t1",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "info",
    precio: "1",
    name: "t1",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "object",
    objeto: "t2ne",
    name: "t2n",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "info",
    precio: "1",
    name: "t2n",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "object",
    objeto: "t33",
    name: "t3",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "info",
    precio: "1",
    name: "t3",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "object",
    objeto: "t44",
    name: "t4",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "info",
    precio: "1",
    name: "t4",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "object",
    objeto: "t55",
    name: "t5",
    time: 3,
    timeMejora: 4,
  },
  {
    tipo: "info",
    precio: "1",
    name: "t5",
    time: 3,
    timeMejora: 4,
  },
];

export const materialesVentana = [
  {
    tipo: "object",
    objeto: "v11",
    name: "v1",
    time: 2,
    timeMejora: 5,
  },
  {
    tipo: "info",
    precio: "1",
    name: "v1",
    time: 2,
    timeMejora: 5,
  },
  {
    tipo: "object",
    objeto: "v22",
    name: "v2",
    time: 2,
    timeMejora: 5,
  },
  {
    tipo: "info",
    precio: "1",
    name: "v2",
    time: 2,
    timeMejora: 5,
  },
  {
    tipo: "object",
    objeto: "v33",
    name: "v3",
    time: 2,
    timeMejora: 5,
  },
  {
    tipo: "info",
    precio: "1",
    name: "v3",
    time: 2,
    timeMejora: 5,
  },
];

export const mejorasPrecios: any = {
  "11": "400",
  "12": "400",
  "13": "400",
  "14": "400",
  "15": "400",
  "16": "400",
  "17": "400",
  "18": "400",
  "19": "400",
};

export const puertaCompleta = [
  {
    tipo: "object",
    objeto: "puertamapa2",
    name: "p2",
    time: 4,
    timeMejora: 7,
  },
  {
    tipo: "info",
    precio: "1",
    name: "p2",
    time: 4,
    timeMejora: 7,
  },
];

export const muroCompleto = [
  {
    tipo: "object",
    objeto: "paredmapa2",
    name: "p2",
    time: 4,
    timeMejora: 7,
  },
  {
    tipo: "info",
    precio: "1",
    name: "p2",
    time: 4,
    timeMejora: 7,
  },
];

export const techoCompleto = [
  {
    tipo: "object",
    objeto: "techomapa2",
    name: "p2",
    time: 4,
    timeMejora: 7,
  },
  {
    tipo: "info",
    precio: "1",
    name: "p2",
    time: 4,
    timeMejora: 7,
  },
];

export const ventanaCompleta = [
  {
    tipo: "object",
    objeto: "ventanamapa2",
    name: "p2",
    time: 4,
    timeMejora: 7,
  },
  {
    tipo: "info",
    precio: "1",
    name: "p2",
    time: 4,
    timeMejora: 7,
  },
];

export const costoMejoras: any = {
  11: 400,
  12: 400,
  13: 300,
  14: 500,
  15: 350,
  16: 400,
  17: 450,
};

/* 
// techo 
[
  {
      "tipo": "info",
      "precio": "1",
      "name": "t1",
      "time": 3,
      "timeMejora": 4,
      "cantidad": "01",
      "key": 1
  },
  {
      "tipo": "info",
      "precio": "1",
      "name": "t2",
      "time": 3,
      "timeMejora": 4,
      "cantidad": "01",
      "key": 3
  },
  {
      "tipo": "info",
      "precio": "1",
      "name": "t4",
      "time": 3,
      "cantidad": "01",
      "key": 7
  },
  {
      "tipo": "info",
      "precio": "1",
      "name": "t3",
      "time": 3,
      "timeMejora": 4,
      "cantidad": "01",
      "key": 5
  },
  {
      "tipo": "info",
      "precio": "1",
      "name": "t5",
      "time": 3,
      "timeMejora": 4,
      "cantidad": "01",
      "key": 9
  }
]


// ventana
[
  {
      "tipo": "info",
      "precio": "1",
      "name": "v1",
      "time": 2,
      "timeMejora": 5,
      "cantidad": "01",
      "key": 1
  },
  {
      "tipo": "info",
      "precio": "1",
      "name": "v2",
      "time": 2,
      "timeMejora": 5,
      "cantidad": "01",
      "key": 3
  },
  {
      "tipo": "info",
      "precio": "1",
      "name": "v3",
      "time": 2,
      "timeMejora": 5,
      "cantidad": "01",
      "key": 5
  }
] */
