import { funcionActualizarDatosFB } from "../../common/utils";
import { costoMejoras } from "../../common/schema/Piezas";

const COLOR_BASE = "#0B2167";
const COLOR_MORADO = "#673f91";

class ModalEtapas extends Phaser.Scene {
  parent;
  keyScene;
  centroCanvas;
  extremoCanvas;
  title;
  mensaje;
  icon;
  monto;
  tipoEtapa;
  alturaT;
  alturaM;
  anchoT;
  anchoM;
  fontsizeT;
  fontsizeM;
  widWrapM;
  alturaMoneda;
  descuento;
  pregunta;
  anchoP;
  alturaP;
  fontsizeP;
  background;
  constructor(handle: any, parent: any) {
    super(handle);
    this.parent = parent;
    this.keyScene = handle;
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
    this.title = "";
    this.mensaje = "";
    this.icon = "";
    this.monto = "";
    this.tipoEtapa = "";
    this.alturaT = "";
    this.alturaM = "" as any;
    this.anchoT = "" as any;
    this.anchoM = "" as any;
    this.fontsizeT = "";
    this.fontsizeM = "";
    this.widWrapM = "";
    this.alturaMoneda = "";
    this.descuento = "" as any;
    this.pregunta = "";
    this.anchoP = "" as any;
    this.alturaP = "" as any;
    this.fontsizeP = "";
    this.background = "" as any;
  }

  init(data: any) {
    console.log("Se ha iniciado la escena Modal Etapas");

    this.centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    this.extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };

    this.title = data.title;
    this.mensaje = data.mensaje;
    this.icon = data.icon;
    this.monto = data.monto;
    this.tipoEtapa = data.tipo;
    this.alturaT = data.alturaT;
    this.alturaM = data.alturaM;
    this.anchoT = data.anchoT;
    this.anchoM = data.anchoM;
    this.fontsizeT = data.fontsizeT;
    this.fontsizeM = data.fontsizeM;
    this.widWrapM = data.widWrapM;
    this.alturaMoneda = data.alturaMoneda ? data.alturaMoneda : 25;
    this.descuento = data.monto as any;
    this.pregunta = data.pregunta;
    this.anchoP = data.anchoP as any;
    this.alturaP = data.alturaP as any;
    this.fontsizeP = data.fontsizeP;
  }

  preload() {}

  create() {
    this.background = this.add.image(
      this.centroCanvas.width,
      this.centroCanvas.height,
      "modal"
    );
    this.cameras.main.setBackgroundColor("rgba(29, 26, 71, 0.69)");
    this.cameras.main.setViewport(
      0,
      0,
      this.sys.game.config.width as number,
      this.sys.game.config.height as any
    );

    this.add.image(
      this.centroCanvas.width,
      this.centroCanvas.height - 155,
      this.icon
    );

    // this.add.text(this.centroCanvas.width-this.anchoT, this.centroCanvas.height - this.alturaT ,this.title ,  {fontFamily:'font1', fontSize: this.fontsizeT ? this.fontsizeT : 26, color:COLOR_BASE, wordWrap: { width: 450, useAdvancedWrap: true },align:'center'})

    this.add.text(
      this.centroCanvas.width - this.anchoM,
      this.centroCanvas.height - this.alturaM,
      this.mensaje,
      {
        fontFamily: "font2",
        fontSize: this.fontsizeM ? this.fontsizeM : 22,
        color: COLOR_BASE,
        wordWrap: {
          width: this.widWrapM ? this.widWrapM : (400 as any),
          useAdvancedWrap: true,
        },
        align: "center",
      }
    );

    this.add.text(
      this.centroCanvas.width - this.anchoP,
      this.centroCanvas.height - this.alturaP,
      this.pregunta,
      {
        fontFamily: "font1",
        fontSize: this.fontsizeP ? this.fontsizeP : 22,
        color: COLOR_BASE,
        wordWrap: {
          width: this.widWrapM ? this.widWrapM : (400 as any),
          useAdvancedWrap: true,
        },
        align: "center",
      }
    );

    this.add.text(
      this.centroCanvas.width - 40,
      (this.centroCanvas.height + this.alturaMoneda) as any,
      this.monto,
      {
        fontFamily: "font1",
        fontSize: 26,
        color: COLOR_MORADO,
        wordWrap: { width: 400, useAdvancedWrap: true },
        align: "center",
      }
    );

    //botones
    this.add
      .image(
        this.centroCanvas.width - 120,
        this.centroCanvas.height + 150,
        "boton1"
      )
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.scene.stop("Play");
        this.scene.start("Levels");
        this.scene.remove(this.keyScene);
        this.sound.stopAll();
      });
    this.add.text(
      this.centroCanvas.width - 168,
      this.centroCanvas.height + 135,
      "Ignorar",
      { fontFamily: "font1", fontSize: 24 }
    );

    /*       console.log(localStorage.getItem("mejora"), "mejora final") */

    this.add
      .image(
        this.centroCanvas.width + 120,
        this.centroCanvas.height + 150,
        "boton2"
      )
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.sound.stopAll();
        if (this.tipoEtapa === "escena") {
          const mejoras = JSON.parse(
            localStorage.getItem("mejorasRealizadas") as any
          );
          localStorage.setItem(
            "mejorasRealizadas",
            JSON.stringify({
              ...mejoras,
              [localStorage.getItem("mejora") as any]: true,
            })
          );
          this.scene.start("Play", {
            etapa: ((localStorage.getItem("etapa") as any) + 1) as any,
            tipo: "mejora",
            abrir: false,
          });
          this.scene.remove(this.keyScene);
          // localStorage.setItem("descuento",this.descuento)
          let dinero =
            parseInt(localStorage.getItem("dinero") as any) -
            parseInt(costoMejoras[localStorage.getItem("mejora") as any]);

          //  console.log(dinero, "dinerooooo");

          localStorage.setItem("dinero", dinero as any);

          funcionActualizarDatosFB({
            mejorasRealizadas: {
              ...mejoras,
              [localStorage.getItem("mejora") as any]: true,
            },
            dinero: dinero,
          });

          console.log(this.tipoEtapa, this.descuento, "dentro del if");
        } else {
          console.log(this.tipoEtapa, this.descuento, "dentro del else");
          const mejoras = JSON.parse(
            localStorage.getItem("mejorasRealizadas") as any
          );
          localStorage.setItem("mejorasRealizadas", {
            ...mejoras,
            [localStorage.getItem("mejora") as any]: true,
          });
          this.scene.start("Play", {
            etapa: parseInt(localStorage.getItem("etapa") as any) + 1,
            abrir: false,
          });
          this.scene.remove(this.keyScene);
          localStorage.setItem("descuento", this.descuento);
          let dinero = (parseInt(localStorage.getItem("dinero") as any) -
            costoMejoras[localStorage.getItem("mejora") as any]) as any;
          // console.log(dinero, "dinerooooo");
          localStorage.setItem("dinero", dinero as any);

          funcionActualizarDatosFB({
            mejorasRealizadas: {
              ...mejoras,
              [localStorage.getItem("mejora") as any]: true,
            },
            dinero: dinero,
            descuento: this.descuento,
          });
        }
      });
    this.add.text(
      this.centroCanvas.width + 70,
      this.centroCanvas.height + 135,
      "Aceptar",
      { fontFamily: "font1", fontSize: 24 }
    );
  }

  update() {}
}

export default ModalEtapas;
