import BBCodeText from "phaser3-rex-plugins/plugins/bbcodetext.js";
import { TextEdit, Edit } from "phaser3-rex-plugins/plugins/textedit.js";
import { funcionActualizarDatosFB } from "../common/utils";

class ValidarEtapas extends Phaser.Scene {
  parent;
  keyScene;
  centroCanvas;
  extremoCanvas;
  nivel;
  background;
  clave;
  error;
  modal;
  buttonImage;
  respuestaT;
  constructor(handle: any, parent: any) {
    super(handle);
    this.parent = parent;
    this.keyScene = handle;
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
    this.nivel = 0;
    this.clave = "";
    this.background = "" as any;
    this.error = "" as any;
    this.modal = "" as any;
    this.buttonImage = "" as any;
    this.respuestaT = "" as any;
  }

  init(data: any) {
    console.log("Se ha iniciado la escena ValidarEtapas");
    this.centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    this.extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };
    this.nivel = parseInt(localStorage.getItem("nivel") as any);
    this.clave = data.clave;
  }

  preload() {
    // var url;
    // url =
    //   "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexbbcodetextplugin.min.js";
    // this.load.plugin("rexbbcodetextplugin", url, true);
    // url =
    //   "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rextexteditplugin.min.js";
    // this.load.plugin("rextexteditplugin", url, true);
  }

  create() {
    this.background = this.add.image(
      this.centroCanvas.width,
      this.centroCanvas.height,
      "fondopop"
    ) as any;

    this.add
      .image(
        this.centroCanvas.width + 220,
        this.centroCanvas.height - 150,
        "close"
      )
      .setScale(0.1)
      .setDepth(15)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.scene.resume("Levels");
        this.scene.remove(this.keyScene);
      });

    //document.querySelector(".textoStarWar").style.display = "none";
    this.cameras.main.setBackgroundColor("rgba(29, 26, 71, 0.69)");
    this.cameras.main.setViewport(
      0,
      0,
      this.sys.game.config.width as number,
      this.sys.game.config.height as number
    );

    this.add
      .text(
        this.centroCanvas.width - 200,
        this.centroCanvas.height + -80,
        `Ingresa tu código secreto para pasar a la siguiente etapa`,
        {
          fontFamily: "font1",
          fontSize: 25,
          wordWrap: { width: 450, useAdvancedWrap: true },
          color: "#1C2A55",
        }
      )
      .setDepth(2);

    this.error = this.add
      .text(
        this.centroCanvas.width - 200,
        this.centroCanvas.height + 90,
        `Código incorrecto, vuelve a intentarlo`,
        {
          fontFamily: "font1",
          fontSize: 18,
          wordWrap: { width: 450, useAdvancedWrap: true },
          color: "red",
        }
      )
      .setDepth(2);
    this.error.setVisible(0);

    this.modal = this.add.image(
      this.centroCanvas.width,
      this.centroCanvas.height,
      "fondopop"
    );

    this.add
      .text(
        this.centroCanvas.width - 60,
        this.centroCanvas.height + 140,
        `JUGAR`,
        { fontFamily: "font1", fontSize: 32 }
      )
      .setDepth(2);

    this.buttonImage = this.add
      .image(
        this.centroCanvas.width,
        this.centroCanvas.height + 155,
        "buttonConfig"
      )
      .setInteractive({ cursor: "pointer" });

    this.buttonImage.on(Phaser.Input.Events.POINTER_DOWN, () => {
      this.sound.play("click");
      setTimeout(() => {
        //console.log(this.respuestaT)
        if (this.respuestaT === this.clave) {
          localStorage.setItem("pasanivel", "true");
          funcionActualizarDatosFB({ pasanivel: true });
          //localStorage.setItem("nivel", this.nivel)
          this.scene.start("Levels");
          this.scene.remove(this.keyScene);
        } else {
          this.error.setVisible(1);
          setTimeout(() => {
            this.error.setVisible(0);
          }, 2500);
        }
      }, 100);
    });

    this.respuestaT = "";
    var txt = new BBCodeText(
      this,
      this.centroCanvas.width - 10,
      this.centroCanvas.height + 40,
      "Ingresarlo aqui",
      {
        color: "#1C2A55",
        fontSize: "24px",
        fixedWidth: 300,
        fixedHeight: 60,
        backgroundColor: "rgb(255,255,255)",
        borderRadius: 10,
        valign: "center",
        fontFamily: "font2",
      } as any
    );
    this.add
      .existing(txt)
      .setOrigin(0.5)
      .setInteractive({ cursor: "pointer" })
      .setDepth(99)
      .on(
        "pointerdown",
        (e: any) => {
          var config = {
            onTextChanged: (textObject: any, text: any) => {
              textObject.text = text;
              this.actualizarTexto(text);
              //console.log(`Text: ${text}`, text);
            },
            selectAll: true,
          };
          Edit(txt, config);
          //this.plugins.get("rextexteditplugin").edit(printText, config);
        },
        this
      );
    // this.scene.add.existing(txt)
  }
  actualizarTexto(text: any) {
    // console.log(text)
    this.respuestaT = text;
  }
}

export default ValidarEtapas;
