import { funcionActualizarDatosFB } from "../../common/utils";

class ConfirmarEtapas extends Phaser.Scene {
  parent;
  keyScene;
  centroCanvas;
  extremoCanvas;
  etapa;
  buttonImage;
  background;
  respuestaT;
  modal;
  constructor(
    handle: any,
    parent: any,
    etapa: any,
    buttonImage: any,
    background: any,
    respuestaT: any,
    modal: any
  ) {
    super(handle);
    this.parent = parent;
    this.keyScene = handle;
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
    this.etapa = etapa;
    this.buttonImage = buttonImage;
    this.background = background;
    this.respuestaT = respuestaT;
    this.modal = modal;
  }

  init(data: any) {
    console.log("Se ha iniciado la escena ConfirmarEtapas");
    this.centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    this.extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };
    this.etapa = data.etapa;
  }

  preload() {}

  create() {
    this.background = this.add.image(
      this.centroCanvas.width,
      this.centroCanvas.height,
      "fondopop"
    );
    // const textStarWar: any = document.querySelector(".textoStarWar");
    // textStarWar.style.display = "none";
    this.cameras.main.setBackgroundColor("rgba(29, 26, 71, 0.69)");
    this.cameras.main.setViewport(
      0,
      0,
      this.sys.game.config.width as number,
      this.sys.game.config.height as number
    );

    this.add
      .text(
        this.centroCanvas.width - 175,
        this.centroCanvas.height + -45,
        `¿Estás listo para comenzar a jugar?`,
        {
          fontFamily: "font1",
          fontSize: 35,
          wordWrap: { width: 450, useAdvancedWrap: true },
          color: "#1C2A55",
          align: "center",
        }
      )
      .setDepth(2);

    this.modal = this.add.image(
      this.centroCanvas.width,
      this.centroCanvas.height,
      "fondopop"
    );

    this.add
      .text(
        this.centroCanvas.width - 50,
        this.centroCanvas.height + 140,
        `JUGAR`,
        { fontFamily: "font1", fontSize: 28 }
      )
      .setDepth(2);

    this.buttonImage = this.add
      .image(
        this.centroCanvas.width,
        this.centroCanvas.height + 155,
        "buttonConfig"
      )
      .setInteractive({ cursor: "pointer" });

    this.buttonImage.on(Phaser.Input.Events.POINTER_DOWN, () => {
      this.sound.play("click");
      //  setTimeout(()=>{
      console.log(this.respuestaT);
      localStorage.setItem("pasoEtapa", true as any);
      funcionActualizarDatosFB({ pasoEtapa: "true" });
      console.log(this.etapa, "etapa modal");
      this.scene.run("Play", {
        etapa: parseInt(this.etapa),
        abrir: false,
      });
      this.sound.stopAll();
      this.scene.remove(this.keyScene);
      //}, 100)
    });
  }
}

export default ConfirmarEtapas;
