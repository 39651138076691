export const Posiciones = [
    {x:100,y:120,active:false},
    {x:150,y:120,active:false},
    {x:200,y:120,active:false},
    {x:250,y:120,active:false},
    {x:300,y:120,active:false},
    {x:750,y:120,active:false},
    {x:800,y:120,active:false},
    {x:850,y:120,active:false},
    {x:900,y:120,active:false},
    {x:950,y:120,active:false},
    {x:240,y:170,active:false},
    {x:290,y:170,active:false},
    {x:340,y:170,active:false},
    {x:390,y:170,active:false},
    {x:440,y:170,active:false},
    {x:490,y:170,active:false},
    {x:540,y:170,active:false},
    {x:590,y:170,active:false},
    {x:640,y:170,active:false},
    {x:690,y:170,active:false},
    {x:740,y:170,active:false},
    {x:790,y:170,active:false},
    {x:240,y:195,active:false},
    {x:290,y:195,active:false},
    {x:340,y:195,active:false},
    {x:390,y:195,active:false},
    {x:440,y:195,active:false},
    {x:490,y:195,active:false},
    {x:540,y:195,active:false},
    {x:590,y:195,active:false},
    {x:640,y:195,active:false},
    {x:690,y:195,active:false},
    {x:740,y:195,active:false},
    {x:790,y:195,active:false},
    {x:220,y:220,active:false},
    {x:270,y:220,active:false},
    {x:750,y:220,active:false},
    {x:800,y:220,active:false},
    {x:220,y:250,active:false},
    {x:270,y:250,active:false},
    {x:750,y:250,active:false},
    {x:800,y:250,active:false},
    {x:85,y:280,active:false},
    {x:135,y:280,active:false},
    {x:185,y:280,active:false},
    {x:235,y:280,active:false},
    {x:285,y:280,active:false},
    {x:750,y:280,active:false},
    {x:800,y:280,active:false},
    {x:850,y:280,active:false},
    {x:900,y:280,active:false},
    {x:950,y:280,active:false},
    {x:85,y:310,active:false},
    {x:135,y:310,active:false},
    {x:185,y:310,active:false},
    {x:235,y:310,active:false},
    {x:285,y:310,active:false},
    {x:750,y:310,active:false},
    {x:800,y:310,active:false},
    {x:850,y:310,active:false},
    {x:900,y:310,active:false},
    {x:950,y:310,active:false},
    {x:85,y:340,active:false},
    {x:135,y:340,active:false},
    {x:185,y:340,active:false},
    {x:235,y:340,active:false},
    {x:285,y:340,active:false},
    {x:750,y:340,active:false},
    {x:800,y:340,active:false},
    {x:850,y:340,active:false},
    {x:900,y:340,active:false},
    {x:950,y:340,active:false},
    {x:85,y:370,active:false},
    {x:135,y:370,active:false},
    {x:185,y:370,active:false},
    {x:235,y:370,active:false},
    {x:285,y:370,active:false},
    {x:750,y:370,active:false},
    {x:800,y:370,active:false},
    {x:850,y:370,active:false},
    {x:900,y:370,active:false},
    {x:950,y:370,active:false},
    {x:85,y:400,active:false},
    {x:135,y:400,active:false},
    {x:185,y:400,active:false},
    {x:235,y:400,active:false},
    {x:285,y:400,active:false},
    {x:750,y:400,active:false},
    {x:800,y:400,active:false},
    {x:850,y:400,active:false},
    {x:900,y:400,active:false},
    {x:950,y:400,active:false},
    {x:85,y:430,active:false},
    {x:135,y:430,active:false},
    {x:185,y:430,active:false},
    {x:235,y:430,active:false},
    {x:285,y:430,active:false},
    {x:750,y:430,active:false},
    {x:800,y:430,active:false},
    {x:850,y:430,active:false},
    {x:900,y:430,active:false},
    {x:950,y:430,active:false},
    {x:85,y:460,active:false},
    {x:135,y:460,active:false},
    {x:185,y:460,active:false},
    {x:235,y:460,active:false},
    {x:285,y:460,active:false},
    {x:750,y:460,active:false},
    {x:800,y:460,active:false},
    {x:850,y:460,active:false},
    {x:900,y:460,active:false},
    {x:950,y:460,active:false},
    {x:85,y:490,active:false},
    {x:135,y:490,active:false},
    {x:185,y:490,active:false},
    {x:235,y:490,active:false},
    {x:285,y:490,active:false},
    {x:750,y:490,active:false},
    {x:800,y:490,active:false},
    {x:850,y:490,active:false},
    {x:900,y:490,active:false},
    {x:950,y:490,active:false},
    {x:85,y:520,active:false},
    {x:135,y:520,active:false},
    {x:185,y:520,active:false},
    {x:235,y:520,active:false},
    {x:285,y:520,active:false},
    {x:750,y:520,active:false},
    {x:800,y:520,active:false},
    {x:850,y:520,active:false},
    {x:900,y:520,active:false},
    {x:950,y:520,active:false},
    {x:85,y:550,active:false},
    {x:135,y:550,active:false},
    {x:185,y:550,active:false},
    {x:235,y:550,active:false},
    {x:285,y:550,active:false},
    {x:750,y:550,active:false},
    {x:800,y:550,active:false},
    {x:850,y:550,active:false},
    {x:900,y:550,active:false},
    {x:950,y:550,active:false},
    {x:220,y:600,active:false},
    {x:270,y:600,active:false},
    {x:750,y:600,active:false},
    {x:800,y:600,active:false},
    {x:220,y:630,active:false},
    {x:270,y:630,active:false},
    {x:750,y:630,active:false},
    {x:800,y:630,active:false},
    {x:220,y:650,active:false},
    {x:270,y:650,active:false},
    {x:320,y:650,active:false},
    {x:370,y:650,active:false},
    {x:420,y:650,active:false},
    {x:470,y:650,active:false},
    {x:520,y:650,active:false},
    {x:570,y:650,active:false},
    {x:620,y:650,active:false},
    {x:670,y:650,active:false},
    {x:720,y:650,active:false},
    {x:770,y:650,active:false},
    {x:320,y:680,active:false},
    {x:370,y:680,active:false},
    {x:420,y:680,active:false},
    {x:470,y:680,active:false},
    {x:520,y:680,active:false},
    {x:570,y:680,active:false},
    {x:620,y:680,active:false},
    {x:670,y:680,active:false},
    {x:720,y:680,active:false},
    {x:770,y:680,active:false},
    {x:85,y:700,active:false},
    {x:135,y:700,active:false},
    {x:185,y:700,active:false},
    {x:235,y:700,active:false},
    {x:285,y:700,active:false},
    {x:335,y:700,active:false},
    {x:385,y:700,active:false},
    {x:435,y:700,active:false},
    {x:485,y:700,active:false},
    {x:535,y:700,active:false},
    {x:585,y:700,active:false},
    {x:635,y:700,active:false},
    {x:685,y:700,active:false},
    {x:735,y:700,active:false},
    {x:785,y:700,active:false},
    {x:835,y:700,active:false},
    {x:885,y:700,active:false},
    {x:885,y:700,active:false},
]

export const PosicionPuerta = [
    {x:150,y:120,active:false},
    {x:200,y:120,active:false},
    {x:250,y:120,active:false},
    {x:300,y:120,active:false},
    {x:240,y:170,active:false},
    {x:290,y:170,active:false},
    {x:340,y:170,active:false},
    {x:390,y:170,active:false},
    {x:440,y:170,active:false},
    {x:490,y:170,active:false},
    {x:240,y:195,active:false},
    {x:290,y:195,active:false},
    {x:340,y:195,active:false},
    {x:390,y:195,active:false},
    {x:440,y:195,active:false},
    {x:490,y:195,active:false},
    {x:220,y:220,active:false},
    {x:270,y:220,active:false},
    {x:220,y:250,active:false},
    {x:270,y:250,active:false},
    {x:85,y:280,active:false},
    {x:135,y:280,active:false},
    {x:185,y:280,active:false},
    {x:235,y:280,active:false},
    {x:285,y:280,active:false},
    {x:85,y:310,active:false},
    {x:135,y:310,active:false},
    {x:185,y:310,active:false},
    {x:235,y:310,active:false},
    {x:285,y:310,active:false},
    {x:85,y:340,active:false},
    {x:135,y:340,active:false},
    {x:185,y:340,active:false},
    {x:235,y:340,active:false},
    {x:285,y:340,active:false},
    {x:85,y:370,active:false},
    {x:135,y:370,active:false},
    {x:185,y:370,active:false},
    {x:235,y:370,active:false},
    {x:285,y:370,active:false},
]

export const PosicionPared = [
    {x:800,y:120,active:false},
    {x:850,y:120,active:false},
    {x:900,y:120,active:false},
    {x:950,y:120,active:false},
    {x:540,y:170,active:false},
    {x:590,y:170,active:false},
    {x:640,y:170,active:false},
    {x:690,y:170,active:false},
    {x:740,y:170,active:false},
    {x:790,y:170,active:false},
    {x:540,y:195,active:false},
    {x:590,y:195,active:false},
    {x:640,y:195,active:false},
    {x:690,y:195,active:false},
    {x:740,y:195,active:false},
    {x:790,y:195,active:false},
    {x:750,y:220,active:false},
    {x:800,y:220,active:false},
    {x:750,y:250,active:false},
    {x:800,y:250,active:false},
    {x:750,y:280,active:false},
    {x:800,y:280,active:false},
    {x:850,y:280,active:false},
    {x:900,y:280,active:false},
    {x:950,y:280,active:false},
    {x:750,y:310,active:false},
    {x:800,y:310,active:false},
    {x:850,y:310,active:false},
    {x:900,y:310,active:false},
    {x:950,y:310,active:false},
    {x:750,y:340,active:false},
    {x:800,y:340,active:false},
    {x:850,y:340,active:false},
    {x:900,y:340,active:false},
    {x:950,y:340,active:false},
    {x:750,y:370,active:false},
    {x:800,y:370,active:false},
    {x:850,y:370,active:false},
    {x:900,y:370,active:false},
    {x:950,y:370,active:false},
]

export const PosicionTecho = [
    {x:135,y:460,active:false},
    {x:185,y:460,active:false},
    {x:235,y:460,active:false},
    {x:285,y:460,active:false},
    {x:85,y:490,active:false},
    {x:135,y:490,active:false},
    {x:185,y:490,active:false},
    {x:235,y:490,active:false},
    {x:285,y:490,active:false},
    {x:85,y:520,active:false},
    {x:135,y:520,active:false},
    {x:185,y:520,active:false},
    {x:235,y:520,active:false},
    {x:285,y:520,active:false},
    {x:85,y:550,active:false},
    {x:135,y:550,active:false},
    {x:185,y:550,active:false},
    {x:235,y:550,active:false},
    {x:285,y:550,active:false},
    {x:220,y:600,active:false},
    {x:270,y:600,active:false},
    {x:220,y:630,active:false},
    {x:270,y:630,active:false},
    {x:220,y:650,active:false},
    {x:270,y:650,active:false},
    {x:320,y:650,active:false},
    {x:370,y:650,active:false},
    {x:420,y:650,active:false},
    {x:470,y:650,active:false},
    {x:320,y:680,active:false},
    {x:370,y:680,active:false},
    {x:420,y:680,active:false},
    {x:470,y:680,active:false},
    {x:85,y:700,active:false},
    {x:135,y:700,active:false},
    {x:185,y:700,active:false},
    {x:235,y:700,active:false},
    {x:285,y:700,active:false},
    {x:335,y:700,active:false},
    {x:385,y:700,active:false},
    {x:435,y:700,active:false},
    {x:485,y:700,active:false},
]

export const PosicionVentana = [
    {x:750,y:460,active:false},
    {x:800,y:460,active:false},
    {x:850,y:460,active:false},
    {x:900,y:460,active:false},
    {x:950,y:460,active:false},
    {x:750,y:490,active:false},
    {x:800,y:490,active:false},
    {x:850,y:490,active:false},
    {x:900,y:490,active:false},
    {x:950,y:490,active:false},
    {x:750,y:520,active:false},
    {x:800,y:520,active:false},
    {x:850,y:520,active:false},
    {x:900,y:520,active:false},
    {x:950,y:520,active:false},
    {x:750,y:550,active:false},
    {x:800,y:550,active:false},
    {x:850,y:550,active:false},
    {x:900,y:550,active:false},
    {x:950,y:550,active:false},
    {x:750,y:600,active:false},
    {x:800,y:600,active:false},
    {x:750,y:630,active:false},
    {x:800,y:630,active:false},
    {x:520,y:650,active:false},
    {x:570,y:650,active:false},
    {x:620,y:650,active:false},
    {x:670,y:650,active:false},
    {x:720,y:650,active:false},
    {x:770,y:650,active:false},
    {x:520,y:680,active:false},
    {x:570,y:680,active:false},
    {x:620,y:680,active:false},
    {x:670,y:680,active:false},
    {x:720,y:680,active:false},
    {x:770,y:680,active:false},
    {x:535,y:700,active:false},
    {x:585,y:700,active:false},
    {x:635,y:700,active:false},
    {x:685,y:700,active:false},
    {x:735,y:700,active:false},
    {x:785,y:700,active:false},
    {x:835,y:700,active:false},
    {x:885,y:700,active:false},
    {x:885,y:700,active:false},
]

//Zona puerta
//Zona Pared
//Zona techo
//Zona ventana
