import { funcionActualizarDatosFB } from "../common/utils";
import BBCodeText from "phaser3-rex-plugins/plugins/bbcodetext.js";
import { TextEdit, Edit } from "phaser3-rex-plugins/plugins/textedit.js";

import ModalError from "./PlayScene/ModalError";
class Personaje extends Phaser.Scene {
  parent;
  keyScene;
  centroCanvas;
  extremoCanvas;
  dimensionLevel;
  nombreCurrent;
  constructor(
    handle: any,
    parent: any,
    nombreCurrent: any,
    dimensionLevel: any
  ) {
    super({ key: "Personaje" });
    this.parent = parent;
    this.keyScene = handle;
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
    this.nombreCurrent = nombreCurrent;
    this.dimensionLevel = dimensionLevel;
  }
  init() {
    console.log("Se ha iniciado la escena Personaje");
    this.centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    this.extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };

    this.dimensionLevel = {
      width: this.sys.game.config.width,
      height: 2224,
    };
    this.nombreCurrent = "";
  }

  preload() {
    this.load.plugin(
      "rexperspectiveimageplugin",
      "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexperspectiveimageplugin.min.js",
      true
    );

    var url =
      "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexbbcodetextplugin.min.js";
    this.load.plugin("rexbbcodetextplugin", url, true);

    url =
      "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rextexteditplugin.min.js";
    this.load.plugin("rextexteditplugin", url, true);

    this.load.setPath("assets/");

    this.load.atlas(
      "hombreatlas",
      "tierra/personajes/hombre/hombre.png",
      "tierra/personajes/hombre/hombre_atlas.json"
    );

    this.load.atlas(
      "mujeratlas",
      "tierra/personajes/mujer/mujer.png",
      "tierra/personajes/mujer/mujer_atlas.json"
    );
  }

  create() {
    /*         this.add.image(this.centroCanvas.width + 450, 70, "fulls").setScale(.2).setInteractive({cursor:'pointer'}).on("pointerdown", ()=>{
            if(this.scale.isFullscreen){
                this.scale.stopFullscreen();    
            }else{
                this.scale.startFullscreen();
            }
         }) */
    localStorage.setItem("escenaActual", "Personaje");
    localStorage.setItem("tipoPersonaje", "hombre");
    localStorage.setItem("namePersonaje", "personajea1");
    funcionActualizarDatosFB({
      escenaActual: "Personaje",
      tipoPersonaje: "hombre",
      namePersonaje: "personajea1",
    });

    (this as any).background = this.add
      .image(0, 0, "personajebg")
      .setOrigin(0, 0);
    (this as any).music = this.sound.add("music_comienzo", { volume: 0.1 });
    (this as any).music.setLoop(true);
    (this as any).music.play();

    this.add.text(this.centroCanvas.width - 410, 30, "Escoge tu personaje", {
      fontFamily: "font1",
      fontSize: 35,
    });

    // (this as any).btnHombre = this.add.image(this.centroCanvas.width, this.centroCanvas.height + 320, "boton1").setInteractive({cursor:'pointer'}).on("pointerdown", ()=>{
    //         this.sound.play('click');
    //       //  this.btnHombre.setScale(.9)
    //       (this as any).showHombre()
    //         (this as any).hideMujer()
    //         (this as any).btnMujer.setAlpha(.5)
    //         (this as any).btnHombre.setAlpha(1)
    //         localStorage.setItem("tipoPersonaje", "hombre")
    //         let elementDomActive = document.querySelector(".carrucelhombre .swiper-slide-active")
    //         const nombrePersonaje = elementDomActive.getAttribute("personaje")
    //         let tipoPe = localStorage.getItem("tipoPersonaje")
    //         if(tipoPe == 'hombre'){
    //             localStorage.setItem("namePersonaje", nombrePersonaje)
    //         }
    //         (this as any).swiperHombre.update()
    //     })

    //     this.add.text(this.centroCanvas.width - 65, this.centroCanvas.height + 305, "Masculino",{fontFamily:'font1', fontSize: 24} ) as any

    //     (this as any).btnMujer = this.add.image(this.centroCanvas.width + 200, this.centroCanvas.height + 320, "comprar_btn").setInteractive({cursor:'pointer'}).setScale(.75).on("pointerdown", ()=>{
    //         this.sound.play('click');
    //         //this.btnMujer.setScale(.65)
    //         this.sound.play('click');
    //         (this as any).hideHombre()
    //         (this as any).showMujer()
    //         (this as any).btnMujer.setAlpha(1)
    //         (this as any).btnHombre.setAlpha(.5)
    //         localStorage.setItem("tipoPersonaje", "mujer")
    //         let elementDomActive = document.querySelector(".carrucelmujer .swiper-slide-active")
    //         const nombrePersonaje = elementDomActive.getAttribute("personaje")
    //         let tipoPe = localStorage.getItem("tipoPersonaje")
    //         if(tipoPe == 'mujer'){
    //             localStorage.setItem("namePersonaje", nombrePersonaje)
    //         }
    //         (this as any).swiperMujer.update()
    //     })

    //     this.add.text(this.centroCanvas.width + 135, this.centroCanvas.height + 305, "Femenino",{fontFamily:'font1', fontSize: 24})

    this.getHombre();
    this.getMujer(true);
    // this.btnMujer.setAlpha(.5)
    setTimeout(() => {
      (this as any).swiperHombre.update();
    }, 500);

    this.add.image(160, this.centroCanvas.height + 200, "nombrebg");
    this.add.text(70, this.centroCanvas.height + 160, "ESCRIBE TU NOMBRE", {
      fontFamily: "font2",
      fontSize: 15,
      color: "#FFA900",
    });

    (this as any).nombrePersonaje = localStorage.getItem("nameP")
      ? localStorage.getItem("nameP")
      : "ESCRIBE AQUI";

    var txt = new BBCodeText(
      this,
      120,
      this.centroCanvas.height + 200,
      (this as any).nombrePersonaje,
      {
        color: "#ffff",
        fontSize: "24px",
        fixedWidth: 200,
        fixedHeight: 80,
        backgroundColor: "rgba(0,0,0,0)",
        valign: "center",
        fontFamily: "font1",
      } as any
    );
    this.add
      .existing(txt)
      .setOrigin(0.5)
      .setInteractive({ cursor: "pointer" })
      .on(
        "pointerdown",
        () => {
          var config = {
            onOpen: function (textObject: any) {
              //console.log('Open text editor');
            },
            onTextChanged: (textObject: any, text: any) => {
              textObject.text = text;
              localStorage.setItem("nombrePersonaje", text);
              funcionActualizarDatosFB({ nombrePersonaje: text });
              //console.log(`Text: ${text}`);
              this.nombreCurrent = text;
            },
            onClose: function (textObject: any) {
              // console.log('Close text editor');
            },
            selectAll: true,
          };
          Edit(txt, config);
        },
        this
      );

    (this as any).salirFinal = false;

    (this as any).click = false;
    (this as any).botonFin = this.add
      .image(900, 50, "comprar_btn")
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        if (this.nombreCurrent !== "") {
          if (!(this as any).click) {
            this.hideHombre();
            this.hideMujer();
            (this as any).click = true;
            console.log((this as any).click);
            (this as any).botonFin.setScale(0.9);
            (this as any).sound.play("click");
            (this as any).salirFinal = true;
            const callBack = () => {};
            this.salirEscena(callBack);
          }
        } else {
          this.hideHombre();
          this.hideMujer();
          this.scene.pause();
          (this as any).ModalError = this.createWindow(ModalError, {
            title: "Completa tu nombre",
            mensaje: "Debes escribir un nombre, para continuar",
          });
        }
      })
      .on("pointerup", () => {
        if (!(this as any).click) {
          (this as any).botonFin.setScale(1);
        }
      });
    (this as any).textoFin = this.add
      .text(this.centroCanvas.width + 320, 32, `ENTRAR`, {
        fontFamily: "font1",
        fontSize: 32,
      })
      .setDepth(2);
    (this as any).mascaraInicio("entrar", false);
  }

  getHombre() {
    (document.querySelector(".carrucelhombre") as any).style.display = "block";
    if ((this as any).swiperHombre) {
      (this as any).swiperHombre.update();
    }
    (this as any).swiperHombre = new (window as any).Swiper(
      ".carrucelhombre .swiper-container",
      {
        loop: true,
        slidesPerView: 1,
        simulateTouch: false,
        navigation: {
          nextEl: ".carrucelhombre .swiper-button-next",
          prevEl: ".carrucelhombre .swiper-button-prev",
        },
      }
    ) as any;

    (
      document.querySelector(".carrucelhombre .swiper-button-next") as any
    ).addEventListener("click", () => {
      this.sound.play("click");
    });
    (
      document.querySelector(".carrucelhombre .swiper-button-prev") as any
    ).addEventListener("click", () => {
      this.sound.play("click");
    });

    (this as any).swiperHombre.on("slideChangeTransitionEnd", (e: any) => {
      let elementDomActive: any = document.querySelector(
        ".carrucelhombre .swiper-slide-active"
      );
      const nombrePersonaje = elementDomActive.getAttribute("personaje");
      let tipoPe = localStorage.getItem("tipoPersonaje");
      if (tipoPe === "hombre") {
        localStorage.setItem("namePersonaje", nombrePersonaje);
        funcionActualizarDatosFB({ namePersonaje: nombrePersonaje });
      }
    });
  }
  showHombre() {
    (document.querySelector(".carrucelhombre") as any).style.display = "block";
  }
  hideHombre() {
    (document.querySelector(".carrucelhombre") as any).style.display = "none";
  }

  getMujer(disabledInitial: any) {
    (document.querySelector(".carrucelmujer") as any).style.display = "block";
    if ((this as any).swiperMujer) {
      (this as any).swiperMujer.update();
    }
    (this as any).swiperMujer = new (window as any).Swiper(
      ".carrucelmujer .swiper-container",
      {
        loop: true,
        slidesPerView: 1,
        simulateTouch: false,
        navigation: {
          nextEl: ".carrucelmujer .swiper-button-next",
          prevEl: ".carrucelmujer .swiper-button-prev",
        },
        on: {
          afterInit: function () {
            if (disabledInitial) {
              console.log("se inicializo");
              (
                document.querySelector(".carrucelmujer") as any
              ).style.opacity = 0;
              setTimeout(() => {
                (
                  document.querySelector(".carrucelmujer") as any
                ).style.display = "none";
                (
                  document.querySelector(".carrucelmujer") as any
                ).style.opacity = 1;
              }, 800);
            }
          },
        },
      }
    );

    (
      document.querySelector(".carrucelmujer .swiper-button-next") as any
    ).addEventListener("click", () => {
      this.sound.play("click");
    });
    (
      document.querySelector(".carrucelmujer .swiper-button-prev") as any
    ).addEventListener("click", () => {
      this.sound.play("click");
    });

    (this as any).swiperMujer.on("slideChangeTransitionEnd", function (e: any) {
      let elementDomActive: any = document.querySelector(
        ".carrucelmujer .swiper-slide-active"
      );
      const nombrePersonaje = elementDomActive.getAttribute("personaje");
      let tipoPe = localStorage.getItem("tipoPersonaje");
      if (tipoPe === "mujer") {
        localStorage.setItem("namePersonaje", nombrePersonaje);
        funcionActualizarDatosFB({ namePersonaje: nombrePersonaje });
      }
    });
  }
  showMujer() {
    (document.querySelector(".carrucelmujer") as any).style.display = "block";
  }
  hideMujer() {
    (document.querySelector(".carrucelmujer") as any).style.display = "none";
  }

  salirEscena(callback: any) {
    this.mascaraInicio("salir", false, true, callback);
  }

  updateTransition() {
    (this as any).imagenBG.setScale((this as any).transition.counter);
  }

  createWindow(func: any, data: any) {
    var handle = "window" + (this as any).count++;

    var win = this.add.zone(0, 0, 0, 0).setDepth(555);

    var demo = new func(handle, win);

    return this.scene.add(handle, demo, true, data);
  }

  mascaraInicio(tipo: any, activeScroll: any, salirScena: any, callback: any) {
    (this as any).imagenBG = this.add
      .image(this.centroCanvas.width, this.centroCanvas.height, "circlebg")
      .setVisible(false)
      .setDepth(999);

    (this as any).shape1 = this.make.graphics();
    var mask1 = (this as any).shape1.createBitmapMask((this as any).imagenBG);

    this.cameras.main.setMask(mask1);

    let counter = tipo === "entrar" ? 0 : 2;
    let countTwen = tipo === "entrar" ? 4.8 : 0;
    (this as any).transition = { counter: counter };

    // tween counter variable and set onUpdate function
    let tweenEffect = this.tweens.add({
      targets: (this as any).transition,
      counter: countTwen,
      callbackScope: this,
      onUpdate: this.updateTransition,
      ease: "Sine.easeInOut",
      yoyo: false,
      repeat: 0,
      onStart: () => {
        (this as any).music1 = this.sound.add("efecto_scene", { volume: 0.2 });
        (this as any).music1.play();
      },
      duration: 1500,
    });

    tweenEffect.on("complete", () => {
      if (activeScroll) {
        //this.activeScroll();
      }
      if (salirScena) {
        callback();
      }
      if ((this as any).salirFinal) {
        // console.log(this.scene.remove())
        // (this as any).printText.destroy();
        (this as any).music.destroy();
        /*                 this.arrowLHombre.destroy()
                this.arrowRHombre.destroy()
                this.carouselHombre.destroy()
                this.arrowLMujer.destroy()
                this.arrowRMujer.destroy()
                this.carouselMujer.destroy() */
        // this.finalText.destroy()
        /*                 this.btnHombre.destroy()
                this.btnMujer.destroy() */
        this.hideMujer();
        this.hideHombre();
        this.scene.stop("Personaje");
        this.scene.run("Tutorial");
      }
    });

    console.log("jeje");
  }
}

export default Personaje;
