import MapaTecho from "./PlayScene/MapaTecho";
import MapaPared from "./PlayScene/MapaPared";
import MapaPuerta from "./PlayScene/MapaPuerta";
import MapaVentana from "./PlayScene/MapaVentana";
import MapaCasa from "./PlayScene/MapaCasa";
import ModalEtapas from "./PlayScene/ModalEtapas";
import ModalConfirmEtapas from "./PlayScene/ModalConfirmEtapas";
import ConfirmarEtapas from "./PlayScene/ConfirmarEtapa";

import {
  Posiciones,
  PosicionPuerta,
  PosicionTecho,
  PosicionPared,
  PosicionVentana,
} from "../common/schema/Posiciones";

import {
  materialesPuerta,
  materialesPared,
  materialesTecho,
  materialesVentana,
  puertaCompleta,
  techoCompleto,
  ventanaCompleta,
  materialesParedNew,
  materialesTechoNew,
  //muroCompleto,
} from "../common/schema/Piezas";
import { generateUUID, funcionActualizarDatosFB } from "../common/utils";
import PausaGame from "./PlayScene/PauseGame";

const Random = Phaser.Math.Between;
const COLOR_BASE = "#0B2167";
const COLOR_YELLOW = "#FFA900";

class Play extends Phaser.Scene {
  arrayTotal;
  centroCanvas;
  extremoCanvas;
  zonasGeneral;
  objetos;
  carritoPuertoCompleta;
  carritoMuroCompleta;
  carritoTechoCompleta;
  carritoVentanaCompleta;
  carrito;
  carritoTecho;
  carritoPared;
  carritoVentana;
  arrayPosicionTemporal;
  incluidoPared;
  elementosPared;
  posicionesEstablecidas;
  objetosDropzone;
  posicionAdecuadaJsonP;
  posicionAdecuadaJsonT;
  posicionAdecuadaJsonV;
  posicionAdecuadaJsonpa8;
  posicionAdecuadaJsonpa4;
  posicionAdecuadaJsonpa3;
  posicionAdecuadaJsonpa1;
  casasRealizadas;
  etapaD;
  etapa;
  etapaEscena;
  tipoEs;
  piezasStock;
  molestosBorrados;
  tipoEscena;
  confirmInitial;
  titleMejora;
  abrir;
  objetosUsados;
  trabajandoPuerta;
  addColaPuerta;
  colaPuerta;
  trabajandoVentana;
  addColaVentana;
  colaVentana;
  trabajoPared;
  addColaPared;
  colaPared;
  trabajoTecho;
  addColaTecho;
  colaTecho;
  tiemposCarritoPuerta;
  tiemposCarritoVentana;
  tiemposCarritosTecho;
  tiemposCarritosPared;
  piezasCompradas;
  allDropzones;
  zonasPintadas;
  gastosPorEtapa;
  casasMalasCapturas;
  piezasShowImageError;
  allColisiones;
  allTimout;
  botonesComprar;
  dineroBodegaje;
  dineroDevolucion;
  piezasTotalUsadas;
  depthActual;
  tableMono1;
  tableMono2;
  tableMono3;
  tableMono4;
  setPositionGrid;
  arrayPosicionActualArray;
  temporalItems;
  espacios;
  music;
  background;
  grupo;
  mapaTechoModal;
  mapaParedModal;
  mapaPuertaModal;
  mapaVentanaModal;
  mapaCasaModal;
  toptitle;
  config;
  audioI;
  menu;
  intervalRegresivo;
  info;
  reloj;
  base_moneda;
  moneda;
  aream1;
  aream2;
  aream3;
  aream4;
  relojMono;
  relojTecho;
  relojPared;
  relojVentana;
  btnEnviar;
  btnMejoraAccept;
  modalConfirmEtapas;
  dineroBase;
  hourText;
  separadorHora;
  segundosText;
  textTotalCasa;
  primero;
  grupomono1;
  grupomono2;
  grupomono3;
  etapaModal;
  count;
  element;
  taladro1;
  taladro2;
  guincha;
  guincha2;
  martillo;
  martillo2;
  martillo3;
  perno;
  perno2;
  casco;
  casco2;
  audifono;
  audifono2;
  lampa;
  lampa2;
  cuadro;
  cuadro2;
  lapiz;
  lapiz2;
  balde;
  balde2;
  balde3;
  removeTaladro1;
  rmgrupo1;
  removeTaladro2;
  rmgrupo2;
  removeGuincha;
  rmgrupo3;
  removeGuincha2;
  rmgrupo4;
  removeMartillo;
  rmgrupo5;
  removeMartillo2;
  rmgrupo6;
  removeMartillo3;
  rmgrupo7;
  removePerno;
  rmgrupo8;
  removePerno2;
  rmgrupo9;
  removeCasco;
  rmgrupo10;
  removeCasco2;
  rmgrupo11;
  removeAudifono;
  rmgrupo12;
  removeAudifono2;
  rmgrupo13;
  removeLampa;
  rmgrupo14;
  removeLampa2;
  rmgrupo15;
  removeCuadro;
  rmgrupo16;
  removeCuadro2;
  rmgrupo17;
  removeLapiz;
  rmgrupo18;
  removeLapiz2;
  rmgrupo19;
  removeBalde;
  rmgrupo20;
  removeBalde2;
  rmgrupo21;
  removeBalde3;
  rmgrupo22;
  isTrabajando;
  clickCountTecho;
  intervaloClockTecho;
  tableMono31;
  tableMono21;
  tableMono22;
  clickCountPared;
  intervaloClockPared;
  clickCountVentana;
  intervaloClockVentana;
  relojMonoVentana;
  p1;
  p3;
  p4;
  t1;
  t2;
  t3;
  t4;
  t5;
  t2n;
  v1;
  v2;
  v3;
  b3;
  pa1;
  pa2;
  pa3;
  pa4;
  pa5;
  pa6;
  pa7;
  pa8;
  pa9;
  pa2n;
  pa8n;
  Scrent1;
  Scrent2;
  Scrent21;
  Scrent22;
  Scrent23;
  Scrent31;
  Scrent4;
  Scrent5;
  Screnpa1;
  Screnpa2;
  Screnpa21;
  Screnpa3;
  Screnpa31;
  Screnpa4;
  Screnpa5;
  Screnpa6;
  Screnpa7;
  Screnpa71;
  Screnpa8;
  Screnpa0;
  Screnpa01;
  Screnpa02;
  Screnpa03;
  Screnpa04;
  Screnpa222;
  Screnpa2221;
  Screnp3;
  Screnp4;
  Screnv1;
  Screnv11;
  Screnv2;
  Screnv3;
  errt1;
  errt5;
  errt3;
  errt2;
  errt8;
  errt6;
  errt7;
  errt4;
  errp1;
  errp2;
  errp3;
  errp4;
  errv1;
  errv4;
  errv3;
  errv2;
  errpa1;
  errpa10;
  errpa11;
  errpa12;
  errpa13;
  errpa2;
  errpa3;
  errpa14;
  errpa4;
  errpa15;
  errpa5;
  errpa6;
  errpa7;
  errpa8;
  errpa16;
  errpa9;
  imagensParaPantallazo;
  eliminarPiezasCompradas;
  poco_tiempo;
  imagenBG;
  shape1;
  transition;
  music2;
  intervaloClock;
  isTyping;
  isLastPage;
  mostrarOtro;
  intervaloConversa;
  timeRemaining;
  intervalRegresivoCuenta;
  minutos;
  segundos;
  constructor(
    arrayTotal: any,
    zonasGeneral: any,
    objetos: any,
    carritoMuroCompleta: any,
    carritoTechoCompleta: any,
    carritoPuertoCompleta: any,
    carritoVentanaCompleta: any,
    carrito: any,
    carritoTecho: any,
    carritoPared: any,
    carritoVentana: any,
    arrayPosicionTemporal: any,
    incluidoPared: any,
    elementosPared: any,
    posicionesEstablecidas: any,
    objetosDropzone: any,
    posicionAdecuadaJsonP: any,
    posicionAdecuadaJsonT: any,
    posicionAdecuadaJsonV: any,
    posicionAdecuadaJsonpa8: any,
    posicionAdecuadaJsonpa4: any,
    posicionAdecuadaJsonpa3: any,
    posicionAdecuadaJsonpa1: any,
    casasRealizadas: any,
    etapaD: any,
    etapa: any,
    etapaEscena: any,
    tipoEs: any,
    piezasStock: any,
    molestosBorrados: any,
    tipoEscena: any,
    confirmInitial: any,
    titleMejora: any,
    abrir: any,
    objetosUsados: any,
    trabajandoPuerta: any,
    addColaPuerta: any,
    colaPuerta: any,
    trabajandoVentana: any,
    addColaVentana: any,
    colaVentana: any,
    trabajoPared: any,
    addColaPared: any,
    colaPared: any,
    trabajoTecho: any,
    addColaTecho: any,
    colaTecho: any,
    tiemposCarritoPuerta: any,
    tiemposCarritoVentana: any,
    tiemposCarritosTecho: any,
    tiemposCarritosPared: any,
    piezasCompradas: any,
    allDropzones: any,
    zonasPintadas: any,
    gastosPorEtapa: any,
    casasMalasCapturas: any,
    piezasShowImageError: any,
    allColisiones: any,
    allTimout: any,
    botonesComprar: any,
    dineroBodegaje: any,
    dineroDevolucion: any,
    piezasTotalUsadas: any,
    depthActual: any,
    tableMono1: any,
    tableMono2: any,
    tableMono3: any,
    tableMono4: any,
    setPositionGrid: any,
    arrayPosicionActualArray: any,
    temporalItems: any,
    espacios: any,
    music: any,
    background: any,
    grupo: any,
    mapaTechoModal: any,
    mapaParedModal: any,
    mapaPuertaModal: any,
    mapaVentanaModal: any,
    mapaCasaModal: any,
    toptitle: any,
    config: any,
    audioI: any,
    menu: any,
    intervalRegresivo: any,
    info: any,
    reloj: any,
    base_moneda: any,
    moneda: any,
    aream1: any,
    aream2: any,
    aream3: any,
    aream4: any,
    relojMono: any,
    relojTecho: any,
    relojPared: any,
    relojVentana: any,
    btnEnviar: any,
    btnMejoraAccept: any,
    modalConfirmEtapas: any,
    dineroBase: any,
    hourText: any,
    separadorHora: any,
    segundosText: any,
    textTotalCasa: any,
    primero: any,
    grupomono1: any,
    grupomono2: any,
    grupomono3: any,
    etapaModal: any,
    count: any,
    element: any,
    taladro1: any,
    taladro2: any,
    guincha: any,
    guincha2: any,
    martillo: any,
    martillo2: any,
    martillo3: any,
    perno: any,
    perno2: any,
    casco: any,
    casco2: any,
    audifono: any,
    audifono2: any,
    lampa: any,
    lampa2: any,
    cuadro: any,
    cuadro2: any,
    lapiz: any,
    lapiz2: any,
    balde: any,
    balde2: any,
    balde3: any,
    removeTaladro1: any,
    rmgrupo1: any,
    removeTaladro2: any,
    rmgrupo2: any,
    removeGuincha: any,
    rmgrupo3: any,
    removeGuincha2: any,
    rmgrupo4: any,
    removeMartillo: any,
    rmgrupo5: any,
    removeMartillo2: any,
    rmgrupo6: any,
    removeMartillo3: any,
    rmgrupo7: any,
    removePerno: any,
    rmgrupo8: any,
    removePerno2: any,
    rmgrupo9: any,
    removeCasco: any,
    rmgrupo10: any,
    removeCasco2: any,
    rmgrupo11: any,
    removeAudifono: any,
    rmgrupo12: any,
    removeAudifono2: any,
    rmgrupo13: any,
    removeLampa: any,
    rmgrupo14: any,
    removeLampa2: any,
    rmgrupo15: any,
    removeCuadro: any,
    rmgrupo16: any,
    removeCuadro2: any,
    rmgrupo17: any,
    removeLapiz: any,
    rmgrupo18: any,
    removeLapiz2: any,
    rmgrupo19: any,
    removeBalde: any,
    rmgrupo20: any,
    removeBalde2: any,
    rmgrupo21: any,
    removeBalde3: any,
    rmgrupo22: any,
    isTrabajando: any,
    clickCountTecho: any,
    intervaloClockTecho: any,
    tableMono31: any,
    tableMono21: any,
    tableMono22: any,
    clickCountPared: any,
    intervaloClockPared: any,
    clickCountVentana: any,
    intervaloClockVentana: any,
    relojMonoVentana: any,
    p1: any,
    p3: any,
    p4: any,
    t1: any,
    t2: any,
    t3: any,
    t4: any,
    t5: any,
    t2n: any,
    v1: any,
    v2: any,
    v3: any,
    b3: any,
    pa1: any,
    pa2: any,
    pa3: any,
    pa4: any,
    pa5: any,
    pa6: any,
    pa7: any,
    pa8: any,
    pa9: any,
    pa2n: any,
    pa8n: any,
    Scrent1: any,
    Scrent2: any,
    Scrent21: any,
    Scrent22: any,
    Scrent23: any,
    Scrent31: any,
    Scrent4: any,
    Scrent5: any,
    Screnpa1: any,
    Screnpa2: any,
    Screnpa21: any,
    Screnpa3: any,
    Screnpa31: any,
    Screnpa4: any,
    Screnpa5: any,
    Screnpa6: any,
    Screnpa7: any,
    Screnpa71: any,
    Screnpa8: any,
    Screnpa0: any,
    Screnpa01: any,
    Screnpa02: any,
    Screnpa03: any,
    Screnpa04: any,
    Screnpa222: any,
    Screnpa2221: any,
    Screnp3: any,
    Screnp4: any,
    Screnv1: any,
    Screnv11: any,
    Screnv2: any,
    Screnv3: any,
    errt1: any,
    errt5: any,
    errt3: any,
    errt2: any,
    errt8: any,
    errt6: any,
    errt7: any,
    errt4: any,
    errp1: any,
    errp2: any,
    errp3: any,
    errp4: any,
    errv1: any,
    errv4: any,
    errv3: any,
    errv2: any,
    errpa1: any,
    errpa10: any,
    errpa11: any,
    errpa12: any,
    errpa13: any,
    errpa2: any,
    errpa3: any,
    errpa14: any,
    errpa4: any,
    errpa15: any,
    errpa5: any,
    errpa6: any,
    errpa7: any,
    errpa8: any,
    errpa16: any,
    errpa9: any,
    imagensParaPantallazo: any,
    eliminarPiezasCompradas: any,
    poco_tiempo: any,
    imagenBG: any,
    shape1: any,
    transition: any,
    music2: any,
    intervaloClock: any,
    isTyping: any,
    isLastPage: any,
    mostrarOtro: any,
    intervaloConversa: any,
    minutos: any,
    segundos: any
  ) {
    super({ key: "Play" });
    this.arrayTotal = arrayTotal;
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
    this.carritoPuertoCompleta = carritoPuertoCompleta;
    this.zonasGeneral = zonasGeneral;
    this.objetos = objetos;
    this.carritoMuroCompleta = carritoMuroCompleta;
    this.carritoTechoCompleta = carritoTechoCompleta;
    this.carritoVentanaCompleta = carritoTechoCompleta;
    this.carrito = carrito;
    this.carritoTecho = carritoTecho;
    this.carritoPared = carritoPared;
    this.carritoVentana = carritoVentana;
    this.arrayPosicionTemporal = arrayPosicionTemporal;
    this.incluidoPared = incluidoPared;
    this.elementosPared = elementosPared;
    this.posicionesEstablecidas = posicionesEstablecidas;
    this.objetosDropzone = objetosDropzone;
    this.posicionAdecuadaJsonP = posicionAdecuadaJsonP;
    this.posicionAdecuadaJsonT = posicionAdecuadaJsonT;
    this.posicionAdecuadaJsonV = posicionAdecuadaJsonV;
    this.posicionAdecuadaJsonpa8 = posicionAdecuadaJsonpa8;
    this.posicionAdecuadaJsonpa4 = posicionAdecuadaJsonpa4;
    this.posicionAdecuadaJsonpa3 = posicionAdecuadaJsonpa3;
    this.posicionAdecuadaJsonpa1 = posicionAdecuadaJsonpa1;
    this.casasRealizadas = casasRealizadas;
    this.etapaD = etapaD;
    this.etapa = etapa;
    this.etapaEscena = etapaEscena;
    this.tipoEs = tipoEs;
    this.piezasStock = piezasStock;
    this.molestosBorrados = molestosBorrados;
    this.tipoEscena = tipoEscena;
    this.confirmInitial = confirmInitial;
    this.titleMejora = titleMejora;
    this.abrir = abrir;
    this.objetosUsados = objetosUsados;
    this.trabajandoPuerta = trabajandoPuerta;
    this.addColaPuerta = addColaPuerta;
    this.colaPuerta = colaPuerta;
    this.trabajandoVentana = trabajandoVentana;
    this.addColaVentana = addColaVentana;
    this.colaVentana = colaVentana;
    this.trabajoPared = trabajoPared;
    this.addColaPared = addColaPared;
    this.colaPared = colaPared;
    this.trabajoTecho = trabajoTecho;
    this.addColaTecho = addColaTecho;
    this.colaTecho = colaTecho;
    this.tiemposCarritoPuerta = tiemposCarritoPuerta;
    this.tiemposCarritoVentana = tiemposCarritoVentana;
    this.tiemposCarritosTecho = tiemposCarritosTecho;
    this.tiemposCarritosPared = tiemposCarritosPared;
    this.piezasCompradas = piezasCompradas;
    this.allDropzones = allDropzones;
    this.zonasPintadas = zonasPintadas;
    this.gastosPorEtapa = gastosPorEtapa;
    this.casasMalasCapturas = casasMalasCapturas;
    this.piezasShowImageError = piezasShowImageError;
    this.allColisiones = allColisiones;
    this.allTimout = allTimout;
    this.botonesComprar = botonesComprar;
    this.dineroBodegaje = dineroBodegaje;
    this.dineroDevolucion = dineroDevolucion;
    this.piezasTotalUsadas = piezasTotalUsadas;
    this.depthActual = depthActual;
    this.tableMono1 = tableMono1;
    this.tableMono2 = tableMono2;
    this.tableMono3 = tableMono3;
    this.tableMono4 = tableMono4;
    this.setPositionGrid = setPositionGrid;
    this.arrayPosicionActualArray = arrayPosicionActualArray;
    this.temporalItems = temporalItems;
    this.espacios = espacios;
    this.music = music;
    this.background = background;
    this.grupo = grupo;
    this.mapaTechoModal = mapaTechoModal;
    this.mapaParedModal = mapaParedModal;
    this.mapaPuertaModal = mapaPuertaModal;
    this.mapaVentanaModal = mapaVentanaModal;
    this.mapaCasaModal = mapaCasaModal;
    this.toptitle = toptitle;
    this.config = config;
    this.audioI = audioI;
    this.menu = menu;
    this.intervalRegresivo = intervalRegresivo;
    this.info = info;
    this.reloj = reloj;
    this.base_moneda = base_moneda;
    this.moneda = moneda;
    this.aream1 = aream1;
    this.aream2 = aream2;
    this.aream3 = aream3;
    this.aream4 = aream4;
    this.relojMono = relojMono;
    this.relojTecho = relojTecho;
    this.relojPared = relojPared;
    this.relojVentana = relojVentana;
    this.btnEnviar = btnEnviar;
    this.btnMejoraAccept = btnMejoraAccept;
    this.modalConfirmEtapas = modalConfirmEtapas;
    this.dineroBase = dineroBase;
    this.hourText = hourText;
    this.separadorHora = separadorHora;
    this.segundosText = segundosText;
    this.textTotalCasa = textTotalCasa;
    this.primero = primero;
    this.grupomono1 = grupomono1;
    this.grupomono2 = grupomono2;
    this.grupomono3 = grupomono3;
    this.etapaModal = etapaModal;
    this.count = count;
    this.element = element;
    this.taladro1 = taladro1;
    this.taladro2 = taladro2;
    this.guincha = guincha;
    this.guincha2 = guincha2;
    this.martillo = martillo;
    this.martillo2 = martillo2;
    this.martillo3 = martillo3;
    this.perno = perno;
    this.perno2 = perno2;
    this.casco = casco;
    this.casco2 = casco2;
    this.audifono = audifono;
    this.audifono2 = audifono2;
    this.lampa = lampa;
    this.lampa2 = lampa2;
    this.cuadro = cuadro;
    this.cuadro2 = cuadro2;
    this.lapiz = lapiz;
    this.lapiz2 = lapiz2;
    this.balde = balde;
    this.balde2 = balde2;
    this.balde3 = balde3;
    this.removeTaladro1 = removeTaladro1;
    this.rmgrupo1 = rmgrupo1;
    this.removeTaladro2 = removeTaladro2;
    this.rmgrupo2 = rmgrupo2;
    this.removeGuincha = removeGuincha;
    this.rmgrupo3 = rmgrupo3;
    this.removeGuincha2 = removeGuincha2;
    this.rmgrupo4 = rmgrupo4;
    this.removeMartillo = removeMartillo;
    this.rmgrupo5 = rmgrupo5;
    this.removeMartillo2 = removeMartillo2;
    this.rmgrupo6 = rmgrupo6;
    this.removeMartillo3 = "" as any;
    this.rmgrupo7 = "" as any;
    this.removePerno = "" as any;
    this.rmgrupo8 = "" as any;
    this.removePerno2 = "" as any;
    this.rmgrupo9 = "" as any;
    this.removeCasco = "" as any;
    this.rmgrupo10 = "" as any;
    this.removeCasco2 = "" as any;
    this.rmgrupo11 = "" as any;
    this.removeAudifono = "" as any;
    this.rmgrupo12 = "" as any;
    this.removeAudifono2 = "" as any;
    this.rmgrupo13 = "" as any;
    this.removeLampa = "" as any;
    this.rmgrupo14 = "" as any;
    this.removeLampa2 = "" as any;
    this.rmgrupo15 = "" as any;
    this.removeCuadro = "" as any;
    this.rmgrupo16 = "" as any;
    this.removeCuadro2 = "" as any;
    this.rmgrupo17 = "" as any;
    this.removeLapiz = "" as any;
    this.rmgrupo18 = "" as any;
    this.removeLapiz2 = "" as any;
    this.rmgrupo19 = "" as any;
    this.removeBalde = "" as any;
    this.rmgrupo20 = "" as any;
    this.removeBalde2 = "" as any;
    this.rmgrupo21 = "" as any;
    this.removeBalde3 = "" as any;
    this.rmgrupo22 = "" as any;
    this.isTrabajando = "" as any;
    this.clickCountTecho = "" as any;
    this.intervaloClockTecho = "" as any;
    this.tableMono31 = "" as any;
    this.tableMono21 = "" as any;
    this.tableMono22 = "" as any;
    this.clickCountPared = "" as any;
    this.intervaloClockPared = "" as any;
    this.clickCountVentana = "" as any;
    this.intervaloClockVentana = "" as any;
    this.relojMonoVentana = "" as any;
    this.p1 = "" as any;
    this.p3 = "" as any;
    this.p4 = "" as any;
    this.t1 = "" as any;
    this.t2 = "" as any;
    this.t3 = "" as any;
    this.t4 = "" as any;
    this.t5 = "" as any;
    this.t2n = "" as any;
    this.v1 = "" as any;
    this.v2 = "" as any;
    this.v3 = "" as any;
    this.b3 = "" as any;
    this.pa1 = "" as any;
    this.pa2 = "" as any;
    this.pa3 = "" as any;
    this.pa4 = "" as any;
    this.pa5 = "" as any;
    this.pa6 = "" as any;
    this.pa7 = "" as any;
    this.pa8 = "" as any;
    this.pa9 = "" as any;
    this.pa2n = "" as any;
    this.pa8n = "" as any;
    this.Scrent1 = "" as any;
    this.Scrent2 = "" as any;
    this.Scrent21 = "" as any;
    this.Scrent22 = "" as any;
    this.Scrent23 = "" as any;
    this.Scrent31 = "" as any;
    this.Scrent4 = "" as any;
    this.Scrent5 = "" as any;
    this.Screnpa1 = "" as any;
    this.Screnpa2 = "" as any;
    this.Screnpa21 = "" as any;
    this.Screnpa3 = "" as any;
    this.Screnpa31 = "" as any;
    this.Screnpa4 = "" as any;
    this.Screnpa5 = "" as any;
    this.Screnpa6 = "" as any;
    this.Screnpa7 = "" as any;
    this.Screnpa71 = "" as any;
    this.Screnpa8 = "" as any;
    this.Screnpa0 = "" as any;
    this.Screnpa01 = "" as any;
    this.Screnpa02 = "" as any;
    this.Screnpa03 = "" as any;
    this.Screnpa04 = "" as any;
    this.Screnpa222 = "" as any;
    this.Screnpa2221 = "" as any;
    this.Screnp3 = "" as any;
    this.Screnp4 = "" as any;
    this.Screnv1 = "" as any;
    this.Screnv11 = "" as any;
    this.Screnv2 = "" as any;
    this.Screnv3 = "" as any;
    this.errt1 = "" as any;
    this.errt5 = "" as any;
    this.errt3 = "" as any;
    this.errt2 = "" as any;
    this.errt8 = "" as any;
    this.errt6 = "" as any;
    this.errt7 = "" as any;
    this.errt4 = "" as any;
    this.errp1 = "" as any;
    this.errp2 = "" as any;
    this.errp3 = "" as any;
    this.errp4 = "" as any;
    this.errv1 = "" as any;
    this.errv4 = "" as any;
    this.errv3 = "" as any;
    this.errv2 = "" as any;
    this.errpa1 = "" as any;
    this.errpa10 = "" as any;
    this.errpa11 = "" as any;
    this.errpa12 = "" as any;
    this.errpa13 = "" as any;
    this.errpa2 = "" as any;
    this.errpa3 = "" as any;
    this.errpa14 = "" as any;
    this.errpa4 = "" as any;
    this.errpa15 = "" as any;
    this.errpa5 = "" as any;
    this.errpa6 = "" as any;
    this.errpa7 = "" as any;
    this.errpa8 = "" as any;
    this.errpa16 = "" as any;
    this.errpa9 = "" as any;
    this.imagensParaPantallazo = "" as any;
    this.eliminarPiezasCompradas = "" as any;
    this.poco_tiempo = "" as any;
    this.imagenBG = "" as any;
    this.shape1 = "" as any;
    this.transition = "" as any;
    this.music2 = "" as any;
    this.intervaloClock = "" as any;
    this.isTyping = "" as any;
    this.isLastPage = "" as any;
    this.mostrarOtro = "" as any;
    this.intervaloConversa = "" as any;
    this.timeRemaining = 0; // En segundos
    this.intervalRegresivoCuenta = null as any;
    this.minutos = minutos;
    this.segundos = segundos;
  }

  init(data: any) {
    console.log("Se ha iniciado la escena Play", data.prueba);
    this.arrayTotal = [];
    this.centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    this.extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };
    this.zonasGeneral = [];
    this.objetos = {
      puerta: [
        [1, 1, 1],
        [1, 1, 1],
        [1, 1, 1],
        [1, 1, 1],
      ],
      temporalPuerta: {
        primero: {
          active: false,
          posicion: 0,
        },
        segundo: {
          active: false,
          posicion: 0,
        },
        tercer: {
          active: false,
          posicion: 0,
        },
        cuarto: {
          active: false,
          posicion: 0,
        },
        arrayPuerta: [
          [null, null, null],
          [null, null, null],
          [null, null, null],
          [null, null, null],
        ],
      },
    };

    this.carritoPuertoCompleta = [];
    this.carritoMuroCompleta = [];
    this.carritoTechoCompleta = [];
    this.carritoVentanaCompleta = [];

    this.carrito = [];
    this.carritoTecho = [];
    this.carritoPared = [];
    this.carritoVentana = [];
    this.arrayPosicionTemporal = [];
    this.incluidoPared = [];
    this.elementosPared = [];
    this.posicionesEstablecidas = {
      t1: false,
      t2: false,
      t3: false,
      t4: false,
      t5: false,
      t6: false,
      t7: false,
      t8: false,
      pa1: false,
      pa2: false,
      pa3: false,
      pa4: false,
      pa5: false,
      pa6: false,
      pa7: false,
      pa8: false,
      pa9: false,
      pa10: false,
      pa11: false,
      pa12: false,
      pa13: false,
      pa14: false,
      pa15: false,
      pa16: false,
      p1: false,
      p2: false,
      p3: false,
      p4: false,
      v1: false,
      v2: false,
      v3: false,
      v4: false,
    };
    this.objetosDropzone = {};
    /// objetos repetidos

    this.posicionAdecuadaJsonP = { 149: false, 181: false };
    this.posicionAdecuadaJsonT = { 53: false, 55: false, 57: false, 59: false };
    this.posicionAdecuadaJsonV = { 138: false, 140: false };
    this.posicionAdecuadaJsonpa8 = { 116: false, 121: false };
    this.posicionAdecuadaJsonpa4 = { 153: false, 141: false };
    this.posicionAdecuadaJsonpa3 = { 164: false, 120: false };
    this.posicionAdecuadaJsonpa1 = {
      148: false,
      168: false,
      186: false,
      173: false,
      125: false,
    };

    this.casasRealizadas = {
      buenas: 0,
      malas: 0,
    };

    this.etapaD = data.etapa;
    this.etapa = data.etapa ? data.etapa : localStorage.getItem("etapa");
    // data.tipo == "mejora"
    //   ? data.etapa
    //   : localStorage.getItem("etapa")
    //   ? localStorage.getItem("etapa")
    //   : 1;
    this.etapaEscena = data.etapa ? data.etapa : localStorage.getItem("etapa");
    // data.tipo == "mejora"
    //   ? data.etapa
    //   : localStorage.getItem("etapa")
    //   ? localStorage.getItem("etapa")
    //   : 1;
    this.tipoEs = data.tipo;
    (this as any).toggleTime = data.toggleTime;
    this.piezasStock = {
      puerta: { p2: 0, p3: 0, p4: 0 },
      techo: { t1: 0, t2: 0, t3: 0, t4: 0 },
      pared: {
        pa0: 0,
        pa1: 0,
        pa2: 0,
        pa3: 0,
        pa4: 0,
        pa5: 0,
        pa6: 0,
        pa7: 0,
        pa8: 0,
      },
      ventana: { v1: 0, v2: 0, v3: 0 },
    };
    localStorage.setItem("piezasStock", JSON.stringify(this.piezasStock));
    this.molestosBorrados = JSON.parse(
      localStorage.getItem("molestosBorrados") as any
    );
    this.tipoEscena = data.tipo ? data.tipo : "normal";
    this.confirmInitial = data.confirm;
    this.titleMejora = "";
    this.abrir = data.abrir;
    this.objetosUsados = {
      puerta: { p2: 0, p3: 0, p4: 0 },
      techo: { t1: 0, t2: 0, t3: 0, t4: 0 },
      pared: {
        pa0: 0,
        pa1: 0,
        pa2: 0,
        pa3: 0,
        pa4: 0,
        pa5: 0,
        pa6: 0,
        pa7: 0,
        pa8: 0,
      },
      ventana: { v1: 0, v2: 0, v3: 0 },
    };
    this.piezasTotalUsadas = 0;
    this.depthActual = 2;

    this.trabajandoPuerta = false;
    this.addColaPuerta = [];
    this.colaPuerta = [];

    this.trabajandoVentana = false;
    this.addColaVentana = [];
    this.colaVentana = [];

    this.trabajoPared = false;
    this.addColaPared = [];
    this.colaPared = [];

    this.trabajoTecho = false;
    this.addColaTecho = [];
    this.colaTecho = [];

    this.tiemposCarritoPuerta = [];
    this.tiemposCarritoVentana = [];
    this.tiemposCarritosTecho = [];
    this.tiemposCarritosPared = [];

    this.piezasCompradas = {};

    this.allDropzones = {};
    this.zonasPintadas = {};

    this.gastosPorEtapa = 0;

    this.casasMalasCapturas = [];
    this.piezasShowImageError = [];

    this.allColisiones = [];

    this.allTimout = [];

    this.botonesComprar = {};

    this.dineroBodegaje = 0;
    this.dineroDevolucion = 0;

    console.log(data.toggleTime);
  }
  preload() {
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexuiplugin.min.js",
      sceneKey: "rexUI",
    });
  }
  // crear una vez cargados
  create() {
    //validando paneles abiertos
    this.events.on("resume", () => {
      const isToggleResume =
        sessionStorage.getItem("toggleTime") === "true" ? true : false;
      if (isToggleResume) {
        this.togglePause();
        sessionStorage.setItem("toggleTime", "false");
      }
      //console.log("jeje")
    });
    console.log(this.etapaD);
    if (this.tableMono1) {
      this.tableMono1.visible = false;
    }
    if (this.tableMono2) {
      this.tableMono2.visible = false;
    }
    if (this.tableMono3) {
      this.tableMono3.visible = false;
    }
    if (this.tableMono4) {
      this.tableMono4.visible = false;
    }

    /*             if(!localStorage.getItem("objetosUsados")){
                localStorage.setItem("objetosUsados",  JSON.stringify(this.objetosUsados))
            }else{
                this.objetosUsados = JSON.parse(localStorage.getItem("objetosUsados"))
            } */

    if (!localStorage.getItem("piezasTotalUsadas")) {
      localStorage.setItem("piezasTotalUsadas", this.piezasTotalUsadas);
    }

    if (!localStorage.getItem("casasRealizadas")) {
      localStorage.setItem(
        "casasRealizadas",
        JSON.stringify(this.casasRealizadas)
      );
    } else {
      this.casasRealizadas = JSON.parse(
        localStorage.getItem("casasRealizadas") as any
      );
    }

    //

    // const textStarWar: any = document.querySelector(".textoStarWar");
    // textStarWar.style.display = "none";

    /// variables iniciales
    this.setPositionGrid = [];
    this.arrayPosicionActualArray = [];
    this.temporalItems = [];
    this.espacios = [
      {
        x: 763,
        y: 281,
      },
      {
        x: 848,
        y: 281,
      },
      { x: 933, y: 190 },
      {
        x: 980,
        y: 620,
      },
      {
        x: 763,
        y: 366,
      },
      {
        x: 848,
        y: 366,
      },
      { x: 933, y: 366 },
      {
        x: 980,
        y: 366,
      },
      {
        x: 763,
        y: 451,
      },
      {
        x: 848,
        y: 451,
      },
      { x: 933, y: 451 },
      {
        x: 980,
        y: 451,
      },
      {
        x: 763,
        y: 536,
      },
      {
        x: 848,
        y: 536,
      },
      { x: 933, y: 536 },
      {
        x: 980,
        y: 536,
      },
    ];
    ///funciones

    this.music = this.sound.add("sonid_mesa2", { volume: 0.02 });
    this.music.setLoop(true);
    this.music.play();
    /// elementos
    ///fondo
    this.background = this.add.image(0, 0, "background").setOrigin(0, 0);
    /// grilla de trabajo
    this.setZone(this, 256);
    ///botones mapa
    this.grupo = this.add.group({
      key: "circlemap",
      repeat: 4,
      setXY: {
        x: this.centroCanvas.width - 170,
        y: 110,
        stepX: 85,
      },
    });
    this.grupo.getChildren().forEach((element: any, index: any) => {
      element.name = `area${index}`;
      element.setInteractive({ cursor: "pointer" });
      element.setDepth(2);
      element.on(
        "pointerdown",
        (pointer: any, localX: any, localY: any, event: any) => {
          this.sound.play("click");

          if (element.name === "area0") {
            this.mapaTechoModal = this.createWindow(MapaTecho, "");
            this.scene.pause();
          }
          if (element.name === "area1") {
            this.mapaParedModal = this.createWindow(MapaPared, "");
            this.scene.pause();
          }
          if (element.name === "area2") {
            this.mapaPuertaModal = this.createWindow(MapaPuerta, "");
            this.scene.pause();
          }
          if (element.name === "area3") {
            this.mapaVentanaModal = this.createWindow(MapaVentana, "");
            this.scene.pause();
          }
          if (element.name === "area4") {
            this.mapaCasaModal = this.createWindow(MapaCasa, "");
            this.scene.pause();
          }
        }
      );
    });

    let stepInitialA = 3;
    let setpInitialB = this.sound.mute ? stepInitialA + 1 : stepInitialA - 1;
    this.toptitle = this.add.image(this.centroCanvas.width, 23, "toptitle");

    // sonidos de las mejoras
    (this as any).soundmejora1 = this.sound.add("mejora1", { volume: 0.3 });
    (this as any).soundmejora2 = this.sound.add("mejora2", { volume: 0.3 });
    (this as any).soundmejora3 = this.sound.add("mejora3", { volume: 0.3 });
    (this as any).soundmejora4 = this.sound.add("mejora4", { volume: 0.3 });
    (this as any).soundmejora5 = this.sound.add("mejora5", { volume: 0.3 });
    (this as any).soundmejora6 = this.sound.add("mejora6", { volume: 0.3 });
    (this as any).soundmejora7 = this.sound.add("mejora7", { volume: 0.3 });

    ///acciones izquierda header
    this.config = this.add
      .image(50, 50, "sinaudio2")
      .setInteractive({ cursor: "pointer" })
      .setScale(0.25)
      .setDepth(stepInitialA);

    this.audioI = this.add
      .image(50, 50, "audio2")
      .setInteractive({ cursor: "pointer" })
      .setScale(0.25)
      .setDepth(setpInitialB);
    this.menu = this.add
      .image(110, 50, "saliricon")
      .setScale(0.6)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.sound.play("click");
        this.sound.stopAll();
        this.cerrarEstaciones();
        this.scene.run("Levels");
        this.scene.stop("Play");
        if (this.intervalRegresivo) {
          clearInterval(this.intervalRegresivo);
        }

        this.stopFullScren();
      });

    // this.info = this.add
    //   .image(170, 50, "info")
    //   .setInteractive()
    //   .on("pointerdown", () => {
    //     this.enviarWhatsApp();
    //   });

    //230, 50 old cordenadas x,y pausa icon
    this.add
      .image(170, 50, "pausaicon")
      .setScale(0.6)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.scene.pause("Play");
        this.togglePause();
        this.createWindow(PausaGame, {});

        // if (this.scale.isFullscreen) {
        //   this.scale.stopFullscreen();
        // } else {
        //   this.scale.startFullscreen();
        // }
      });
    /// reloj

    this.reloj = this.add.image(this.extremoCanvas.width - 50, 50, "reloj");
    //moneda
    this.base_moneda = this.add.image(
      this.extremoCanvas.width - 160,
      50,
      "base_moneda"
    );
    this.moneda = this.add.sprite(
      this.extremoCanvas.width - 204,
      50,
      "moneda",
      0
    );
    this.anims.create({
      key: "moneda_anims",
      frames: this.anims.generateFrameNames("moneda", {
        start: 0,
        end: 10,
      }),
      repeat: -1,
      frameRate: 17,
    });
    this.anims.play("moneda_anims", this.moneda);
    // relojo mono
    this.config.on(Phaser.Input.Events.POINTER_DOWN, () => {
      this.sound.play("click");
      this.sound.mute = true;
      this.config.setDepth(2);
      this.audioI.setDepth(4);
    });
    this.audioI.on("pointerdown", () => {
      this.sound.play("click");
      this.sound.mute = false;
      this.config.setDepth(4);
      this.audioI.setDepth(2);
    });

    //areas
    this.aream1 = this.add.image(105, 190, "aream");
    this.aream2 = this.add.image(105, 620, "aream");
    this.aream3 = this.add.image(920, 190, "aream");
    this.aream4 = this.add.image(920, 620, "aream");
    // this.add.image(69,294, "t4")
    //monos
    ///relojes monos
    this.relojMono = this.add.sprite(190, 140, "relojmono", 0);
    this.relojTecho = this.add.sprite(190, 570, "relojmono", 0);
    this.relojPared = this.add.sprite(
      this.extremoCanvas.width - 170,
      140,
      "relojmono",
      0
    );
    this.relojVentana = this.add.sprite(
      this.extremoCanvas.width - 170,
      570,
      "relojmono",
      0
    );

    if (this.tipoEscena !== "mejora") {
      this.btnEnviar = this.add
        .image(620, 650, "boton1")
        .setInteractive({ cursor: "pointer" })
        .on("pointerdown", () => {
          this.sound.play("click");
          this.verificarCasaConstruida();
        });
    }
    //mejora texto finaliza r

    if (this.tipoEscena === "mejora") {
      this.add
        .text(545, 640, "APLICAR CAMBIOS", {
          fontFamily: "font1",
          fontSize: 15,
        })
        .setDepth(2);
      this.btnMejoraAccept = this.add
        .image(620, 650, "boton2")
        .setInteractive({ cursor: "pointer" })
        .on("pointerdown", () => {
          this.modalConfirmEtapas = this.createWindow(ModalConfirmEtapas, "");
          this.scene.pause();
          this.sound.play("click");
          this.sound.stopAll();
          this.cerrarEstaciones();
        });

      let graphics = this.add.graphics({
        x: 530,
        y: 628,
      });
      graphics.fillStyle(0xffff00, 0.75);
      graphics.setDepth(16);
      graphics.fillRoundedRect(
        0,
        0,
        this.btnMejoraAccept.width,
        this.btnMejoraAccept.height,
        20
      );
      this.tweens.add({
        targets: graphics,
        alpha: 0,
        ease: "Cubic.easeOut",
        duration: 600,
        repeat: -1,
        yoyo: true,
      });
    }

    /// funcion general click
    this.input.on(
      "pointerdown",
      (pointer: any, gameObject: any, localy: any, event: any) => {
        if (gameObject.length === 0) {
          if (this.tableMono1) {
            this.tableMono1.visible = false;
          }
          if (this.tableMono2) {
            this.tableMono2.visible = false;
          }
          if (this.tableMono3) {
            this.tableMono3.visible = false;
          }
          if (this.tableMono4) {
            this.tableMono4.visible = false;
          }

          if (this.mapaTechoModal) {
            this.scene.remove(this.mapaTechoModal);
            this.scene.run("");
          }
          if (this.mapaParedModal) {
            this.scene.remove(this.mapaParedModal);
            this.scene.run("");
          }
          if (this.mapaPuertaModal) {
            this.scene.remove(this.mapaPuertaModal);
            this.scene.run("");
          }
          if (this.mapaVentanaModal) {
            this.scene.remove(this.mapaVentanaModal);
            this.scene.run("");
          }
        }
      }
    );

    /// textos iniciales
    this.dineroBase = this.add.text(
      this.extremoCanvas.width - 180,
      42,
      localStorage.getItem("dinero") as any,
      { fontFamily: "font1" }
    );
    this.hourText = this.add.text(this.extremoCanvas.width - 74, 45, "10", {
      fontFamily: "font1",
      fontSize: 18,
      color: COLOR_BASE,
    });

    this.separadorHora = this.add.text(this.extremoCanvas.width - 60, 45, ":", {
      fontFamily: "font1",
      fontSize: 18,
      color: COLOR_BASE,
    });

    this.segundosText = this.add.text(this.extremoCanvas.width - 55, 45, "00", {
      fontFamily: "font1",
      fontSize: 18,
      color: COLOR_BASE,
    });

    if (this.tipoEscena !== "mejora") {
      this.add
        .text(this.centroCanvas.width - 50, 7, `ETAPA ${this.etapa}`, {
          fontFamily: "font1",
          fontSize: 22,
          color: COLOR_YELLOW,
        })
        .setDepth(2);
    } else {
      this.getTitleMejora();
    }

    this.eventosDrag();

    this.add.image(740, 50, "totalcasa").setDepth(12).setScale(0.9);
    this.textTotalCasa = this.add
      .text(740, 41, `0/5`, {
        fontFamily: "font1",
        fontSize: 18,
        color: "#FFFF",
      })
      .setDepth(12);

    this.iniciarEtapa(this.etapa, this);
    console.log(this.abrir);
    if (this.abrir === undefined) {
      this.mascaraInicio("entrar", false, null, null);
    }

    /*             this.mapaModalFin = this.createWindow(Modal, {title: "Etapa Finalizada", mensaje: "Tu tiempo se agoto, presiona en Salir, para volver al mapa y continuar el proceso", etapa:this.etapaEscena})
            this.scene.remove() */

    // if (!this.confirmInitial && this.tipoEs !== "mejora") {
    //   if (this.scale.isFullscreen) {
    //     this.scale.stopFullscreen();
    //     setTimeout(() => {
    //       this.scale.startFullscreen();
    //     }, 2500);
    //   } else {
    //     this.scale.startFullscreen();
    //   }
    // }

    console.log(this.etapaD, "etapa");
  }

  stopFullScren() {
    if (this.scale.isFullscreen) {
      this.scale.stopFullscreen();
    }
  }

  eliminarRestantesMejoraUltima() {
    if (localStorage.getItem("piezasCompradas")) {
      let ObjSobrantes = JSON.parse(
        localStorage.getItem("piezasCompradas") as any
      );
      if (Object.values(ObjSobrantes).length > 0) {
        localStorage.removeItem("piezasCompradas");
      }
    }
  }

  verificarELementosComprados() {
    if (localStorage.getItem("piezasCompradas")) {
      let ObjSobrantes = JSON.parse(
        localStorage.getItem("piezasCompradas") as any
      );
      let valoreSobrante = Object.values(ObjSobrantes);
      let precioCobrar = 0;
      if (valoreSobrante.length >= 0) {
        Object.values(ObjSobrantes).forEach((element: any, index: any) => {
          precioCobrar += parseInt(element.price) * 0.15;
          this.dineroBodegaje = precioCobrar;
          //  let keys = Object.keys(ObjSobrantes);
          switch (element.tipo) {
            case "puerta":
              this.piezasPuertas(
                element.x,
                element.y,
                element.name,
                true,
                element.key,
                element.price
              );
              break;
            case "techo":
              this.piezasTecho(
                element.x,
                element.y,
                element.name,
                true,
                element.key,
                element.price
              );
              break;
            case "pared":
              this.piezasPared(
                element.x,
                element.y,
                element.name,
                true,
                element.key,
                element.price
              );
              break;
            case "ventana":
              this.piezasVentana(
                element.x,
                element.y,
                element.name,
                true,
                element.key,
                element.price
              );
              break;
          }
        });
        let dineroActual = localStorage.getItem("dinero");
        let dineroFinal =
          parseInt(dineroActual as any) - parseFloat(precioCobrar as any);
        console.log(dineroFinal, "ESTE ES");
        localStorage.setItem("dinero", dineroFinal.toFixed());
        funcionActualizarDatosFB({ dinero: dineroFinal.toFixed() });
      }
    }
  }

  getTitleMejora() {
    switch (parseInt(this.etapa)) {
      case 21:
        this.add
          .text(this.centroCanvas.width - 70, 7, `Mejora 1: 5S`, {
            fontFamily: "font1",
            fontSize: 22,
            color: COLOR_YELLOW,
          })
          .setDepth(2);
        break;
      case 31:
        this.add
          .text(this.centroCanvas.width - 140, 7, `Mejora 2: Gestión visual`, {
            fontFamily: "font1",
            fontSize: 22,
            color: COLOR_YELLOW,
          })
          .setDepth(2);
        break;
      case 41:
        this.add
          .text(this.centroCanvas.width - 120, 7, `Mejora 3: Pokayoke`, {
            fontFamily: "font1",
            fontSize: 22,
            color: COLOR_YELLOW,
          })
          .setDepth(2);
        break;
      case 51:
        this.add
          .text(this.centroCanvas.width - 100, 7, `Mejora 4: Layout`, {
            fontFamily: "font1",
            fontSize: 22,
            color: COLOR_YELLOW,
          })
          .setDepth(2);
        break;
      case 61:
        this.add
          .text(
            this.centroCanvas.width - 145,
            7,
            `Mejora 5: Balance de carga`,
            { fontFamily: "font1", fontSize: 20, color: COLOR_YELLOW }
          )
          .setDepth(2);
        break;
      case 71:
        this.add
          .text(this.centroCanvas.width - 105, 7, `Mejora 6: Kanban`, {
            fontFamily: "font1",
            fontSize: 22,
            color: COLOR_YELLOW,
          })
          .setDepth(2);
        break;
      case 81:
        this.add
          .text(this.centroCanvas.width - 125, 7, `Mejora 7: Innovación`, {
            fontFamily: "font1",
            fontSize: 22,
            color: COLOR_YELLOW,
          })
          .setDepth(2);
        break;
      case 11:
        this.add
          .text(this.centroCanvas.width - 70, 7, `Mejora 1: 5S`, {
            fontFamily: "font1",
            fontSize: 22,
            color: COLOR_YELLOW,
          })
          .setDepth(2);
        break;
      case 12:
        this.add
          .text(this.centroCanvas.width - 140, 7, `Mejora 2: Gestión visual`, {
            fontFamily: "font1",
            fontSize: 22,
            color: COLOR_YELLOW,
          })
          .setDepth(2);
        break;
      case 13:
        this.add
          .text(this.centroCanvas.width - 120, 7, `Mejora 3: Pokayoke`, {
            fontFamily: "font1",
            fontSize: 22,
            color: COLOR_YELLOW,
          })
          .setDepth(2);
        break;
      case 14:
        this.add
          .text(this.centroCanvas.width - 100, 7, `Mejora 4: Layout`, {
            fontFamily: "font1",
            fontSize: 22,
            color: COLOR_YELLOW,
          })
          .setDepth(2);
        break;
      case 15:
        this.add
          .text(
            this.centroCanvas.width - 145,
            7,
            `Mejora 5: Balance de carga`,
            { fontFamily: "font1", fontSize: 20, color: COLOR_YELLOW }
          )
          .setDepth(2);
        break;
      case 16:
        this.add
          .text(this.centroCanvas.width - 105, 7, `Mejora 6: Kanban`, {
            fontFamily: "font1",
            fontSize: 22,
            color: COLOR_YELLOW,
          })
          .setDepth(2);
        break;
      case 17:
        this.add
          .text(this.centroCanvas.width - 125, 7, `Mejora 7: Innovación`, {
            fontFamily: "font1",
            fontSize: 22,
            color: COLOR_YELLOW,
          })
          .setDepth(2);
        break;
      default:
        this.add
          .text(this.centroCanvas.width + 100, 7, `Mejora`, {
            fontFamily: "font1",
            fontSize: 22,
            color: COLOR_YELLOW,
          })
          .setDepth(2);
        break;
    }
  }

  iniciarEtapa(etapa: any, scene: any) {
    console.log((this as any).toggleTime);
    switch (parseInt(etapa)) {
      case 1: {
        if (localStorage.getItem("pasoEtapa") === "true") {
          scene.monoInicial();
          //scene.addTextScene()
          scene.elementosMolestosInitial();
          scene.cuentaRegresiva();
          localStorage.setItem("pasoEtapa", false as any);
          scene.verificarELementosComprados();
        } else {
          if (this.confirmInitial) {
            this.createWindow(ConfirmarEtapas, { etapa: 1 });
          }
        }
        // scene.verificarELementosComprados()
        break;
      }
      case 2: {
        if (localStorage.getItem("pasoEtapa") === "true") {
          scene.monoInicial();
          scene.cuentaRegresiva();
          scene.elementosMolestosInitial();
          scene.verificarELementosComprados();
          let jsonMejoras = JSON.parse(
            localStorage.getItem("mejorasRealizadas") as any
          );
          if (jsonMejoras[12]) {
            scene.addTextScene();
            scene.graphicarZona();
          }
          if (jsonMejoras[13]) {
            scene.graphicarPiezas();
          }
          if (jsonMejoras[14]) {
            scene.separarZonas();
          }
          localStorage.setItem("pasoEtapa", false as any);
        } else {
          if (this.confirmInitial) {
            this.createWindow(ConfirmarEtapas, { etapa: 2 });
          }
        }
        break;
      }
      case 3: {
        if (localStorage.getItem("pasoEtapa") === "true") {
          let jsonMejoras = JSON.parse(
            localStorage.getItem("mejorasRealizadas") as any
          );
          scene.monoInicial();
          scene.verificarELementosComprados();
          if (jsonMejoras[12]) {
            scene.addTextScene();
            scene.graphicarZona();
          }
          if (jsonMejoras[13]) {
            scene.graphicarPiezas();
          }
          if (jsonMejoras[14]) {
            scene.separarZonas();
          }
          scene.elementosMolestosInitial();
          scene.cuentaRegresiva();
          localStorage.setItem("pasoEtapa", false as any);
        } else {
          if (this.confirmInitial) {
            this.createWindow(ConfirmarEtapas, { etapa: 3 });
          }
        }
        break;
      }
      case 4: {
        if (localStorage.getItem("pasoEtapa") === "true") {
          let jsonMejoras = JSON.parse(
            localStorage.getItem("mejorasRealizadas") as any
          );
          if (jsonMejoras[12]) {
            scene.addTextScene();
            scene.graphicarZona();
          }
          if (jsonMejoras[13]) {
            scene.graphicarPiezas();
          }
          if (jsonMejoras[14]) {
            scene.separarZonas();
          }
          scene.monoInicial();
          scene.elementosMolestosInitial();
          scene.cuentaRegresiva();
          scene.verificarELementosComprados();
          //scene.verificarELementosComprados()
          localStorage.setItem("pasoEtapa", false as any);
        } else {
          if (this.confirmInitial) {
            this.createWindow(ConfirmarEtapas, { etapa: 4 });
          }
        }
        // scene.verificarELementosComprados()
        break;
      }
      case 5: {
        if (localStorage.getItem("pasoEtapa") === "true") {
          let jsonMejoras = JSON.parse(
            localStorage.getItem("mejorasRealizadas") as any
          );
          if (jsonMejoras[13]) {
            scene.separarZonas();
          }
          if (jsonMejoras[12]) {
            scene.addTextScene();
          }
          if (jsonMejoras[14]) {
            scene.graphicarZona();
          }
          if (jsonMejoras[13]) {
            scene.graphicarPiezas();
          }
          scene.monoInicial();
          scene.elementosMolestosInitial();
          scene.cuentaRegresiva();
          scene.verificarELementosComprados();
          localStorage.setItem("pasoEtapa", false as any);
        } else {
          if (this.confirmInitial) {
            this.createWindow(ConfirmarEtapas, { etapa: 5 });
          }
        }
        //scene.verificarELementosComprados()
        break;
      }
      case 6: {
        if (localStorage.getItem("pasoEtapa") === "true") {
          let jsonMejoras = JSON.parse(
            localStorage.getItem("mejorasRealizadas") as any
          );
          if (jsonMejoras[13]) {
            scene.separarZonas();
          }
          if (jsonMejoras[12]) {
            scene.addTextScene();
          }
          if (jsonMejoras[14]) {
            scene.graphicarZona();
          }
          if (jsonMejoras[15]) {
            scene.graphicarPiezas();
          }
          scene.monoEspecial();
          scene.elementosMolestosInitial();
          scene.cuentaRegresiva();
          scene.verificarELementosComprados();
          localStorage.setItem("pasoEtapa", false as any);
        } else {
          if (this.confirmInitial) {
            this.createWindow(ConfirmarEtapas, { etapa: 6 });
          }
        }
        // scene.verificarELementosComprados()
        break;
      }
      case 7: {
        if (localStorage.getItem("pasoEtapa") === "true") {
          let jsonMejoras = JSON.parse(
            localStorage.getItem("mejorasRealizadas") as any
          );
          if (jsonMejoras[13]) {
            scene.separarZonas();
          }
          if (jsonMejoras[12]) {
            scene.addTextScene();
          }
          if (jsonMejoras[14]) {
            scene.graphicarZona();
          }
          if (jsonMejoras[15]) {
            scene.graphicarPiezas();
          }
          scene.monoEspecial();
          scene.elementosMolestosInitial();
          scene.cuentaRegresiva();
          scene.verificarELementosComprados();

          if (!this.tableMono1) {
            const callbackFunction = (
              escena: any,
              orientation: any,
              isTrabajando: any,
              addCola: any,
              cola: any
            ) => {
              return this.GetFooterSizer(
                escena,
                orientation,
                isTrabajando,
                addCola,
                cola,
                null,
                null
              );
            };
            this.tableMono1 = this.createPanelGeneral(
              this,
              materialesPuerta,
              105,
              350,
              "carrito",
              callbackFunction,
              null,
              null,
              "trabajandoPuerta",
              "addColaPuerta",
              "colaPuerta",
              350,
              350,
              null
            );
            this.tableMono1.setDepth(999999999999999999999999999999);
            if (this.tableMono2) {
              this.tableMono2.visible = false;
            }
            if (this.tableMono3) {
              this.tableMono3.visible = false;
            }
            if (this.tableMono4) {
              this.tableMono4.visible = false;
            }
          } else {
            this.tableMono1.visible = true;
            if (this.tableMono2) {
              this.tableMono2.visible = false;
            }
            if (this.tableMono3) {
              this.tableMono3.visible = false;
            }
            if (this.tableMono4) {
              this.tableMono4.visible = false;
            }
          }
          // scene.add.image(100, 145, "comprar_btn").setScale(.4).setInteractive({cursor:'pointer'}).on("pointerdown", ()=>{
          //     if(scene.tableMono1){
          //         scene['carritoPuertoCompleta'].push('01')
          //         scene.botonesComprar['puerta'].emit("pointerdown")
          //     }else{
          //         scene[`grupomono1`].getChildren().forEach((element)=>{
          //             element.emit("pointerdown")
          //          })
          //     }

          // })
          // scene.add.text(65,137, 'COMPLETO', {fontFamily:'font1', fontSize:12, color:'#fff'})

          localStorage.setItem("pasoEtapa", false as any);
        } else {
          if (this.confirmInitial) {
            this.createWindow(ConfirmarEtapas, { etapa: 7 });
          }
        }
        // scene.verificarELementosComprados()
        break;
      }
      case 8: {
        if (localStorage.getItem("pasoEtapa") === "true") {
          let jsonMejoras = JSON.parse(
            localStorage.getItem("mejorasRealizadas") as any
          );
          if (jsonMejoras[13]) {
            scene.separarZonas();
          }
          if (jsonMejoras[12]) {
            scene.addTextScene();
          }
          if (jsonMejoras[14]) {
            scene.graphicarZona();
          }
          if (jsonMejoras[15]) {
            scene.graphicarPiezas();
          }
          scene.monoEspecial();
          scene.cuentaRegresiva();
          scene.eliminarRestantesMejoraUltima();
          //scene.verificarELementosComprados()
          localStorage.setItem("pasoEtapa", false as any);
        } else {
          if (this.confirmInitial) {
            this.createWindow(ConfirmarEtapas, { etapa: 8 });
          }
        }
        // scene.verificarELementosComprados()
        break;
      }
      //mejoras
      case 11: {
        (this as any).soundmejora1.play();
        this.createWindow(ModalEtapas, {
          title:
            "El primer paso para aprender a estandarizar es implementar 5s",
          mensaje:
            "Te proponemos eliminar todos los elementos innecesarios que se encuentran en distintos lugares de tu fábrica y afectan negativamente tu desempeño.",
          monto: "",
          icon: "deletepieza",
          tipo: "escena",
          alturaM: 85,
          anchoM: 180,
          alturaT: 90,
          anchoT: 190,
          pregunta: "Esta mejora tiene un valor de $400",
          fontsizeP: 19,
          anchoP: 160,
          alturaP: -70,
        });
        break;
      }
      case 12: {
        (this as any).soundmejora2.play();
        this.createWindow(ModalEtapas, {
          title:
            "Aplicando gestión visual a tu fábrica puedes mejorar tu comprensión del espacio",
          mensaje:
            "Te propongo incluir las etiquetas en los distintos botones que forman parte de la fábrica",
          monto: "",
          icon: "etiquetapieza",
          tipo: "escena",
          alturaM: 80,
          anchoM: 180,
          alturaT: 90,
          anchoT: 220,
          fontsizeT: 20,
          fontsizeM: 18,
          pregunta: "Esta mejora tiene un valor de $400",
          fontsizeP: 19,
          anchoP: 195,
          alturaP: -40,
          alturaMoneda: -15,
        });
        break;
      }
      case 13: {
        (this as any).soundmejora3.play();
        this.createWindow(ModalEtapas, {
          title:
            "Tu error más recurrente es instalar piezas en lugares o posiciones equivocadas",
          mensaje:
            "Es una mejora que puede cambiar tu forma de trabajar y tus resultados con un impacto relevante",
          monto: "",
          icon: "armado",
          tipo: "escena",
          alturaM: 100,
          anchoM: 190,
          alturaT: 110,
          anchoT: 180,
          pregunta: "Esta mejora tiene un valor de $300",
          fontsizeP: 19,
          anchoP: 185,
          alturaP: -55,
          alturaMoneda: 10,
        });
        break;
      }
      case 14: {
        (this as any).soundmejora4.play();
        this.createWindow(ModalEtapas, {
          title:
            "El mejor layout es generar zonas de entrega para cada tipo de material",
          mensaje:
            "El tiempo de búsqueda de cada pieza y la distancia que debes recorrer para instalarla en el lugar preciso de la mesa de trabajo será menor",
          tipo: "escena",
          alturaM: 90,
          anchoM: 218,
          alturaT: 100,
          anchoT: 210,
          fontsizeT: 20,
          fontsizeM: 18,
          monto: "",
          icon: "etiquetapieza",
          widWrapM: 445,
          pregunta: "Esta mejora tiene un valor de $500",
          fontsizeP: 19,
          anchoP: 210,
          alturaP: -65,
          alturaMoneda: 10,
        });
        break;
      }
      case 15: {
        (this as any).soundmejora5.play();
        this.createWindow(ModalEtapas, {
          title:
            "Aplicando técnicas de balance, se pue de determinar el número óptimo de integrantes en cada equipo",
          mensaje:
            "Si aceptas reorganizar los equipos hasta su balance ideal, el proceso d e entrega de materiales será más eficiente para ti",
          tipo: "escena",
          alturaM: 90,
          anchoM: 210,
          alturaT: 100,
          anchoT: 220,
          fontsizeT: 20,
          fontsizeM: 18,
          monto: "",
          icon: "etiquetapieza",
          widWrapM: 445,
          pregunta: "Esta mejora tiene un valor de $350",
          fontsizeP: 19,
          anchoP: 210,
          alturaP: -65,
          alturaMoneda: 20,
        });
        break;
      }
      case 16: {
        (this as any).soundmejora6.play();
        this.createWindow(ModalEtapas, {
          title:
            "Aplicando Kanban como método de suministro al proceso de fabricación de casas, encontramos un mecanismo para hacer mucho más eficiente la provisión de materiales",
          mensaje:
            "Al comenzar la fabricación de manera automática y sin necesidad de hacer un pedido, tu tiempo de fabricación aumentará significativamente",
          tipo: "escena",
          alturaM: 90,
          anchoM: 205,
          alturaT: 105,
          anchoT: 200,
          fontsizeT: 19,
          fontsizeM: 17,
          monto: "",
          icon: "etiquetapieza",
          widWrapM: 445,
          pregunta: "Esta mejora tiene un valor de $400",
          fontsizeP: 19,
          anchoP: 210,
          alturaP: -55,
          alturaMoneda: 5,
        });
        break;
      }
      case 17: {
        (this as any).soundmejora7.play();
        this.createWindow(ModalEtapas, {
          title: "¿Qué pasaría si tuviésemos que mover menos piezas?",
          mensaje:
            "Las fábricas que logren mejores resultados serán parte del salón de la fama de Construye y aparecerán mencionados para los futuros jugadores.",
          monto: "",
          icon: "armado",
          tipo: "escena",
          alturaM: 100,
          fontsizeM: 18,
          anchoM: 200,
          alturaT: 110,
          anchoT: 170,
          pregunta: "Esta mejora tiene un valor de $450",
          fontsizeP: 19,
          anchoP: 175,
          alturaP: -55,
          alturaMoneda: 10,
        });
        break;
      }
      //fin mejoras
      case 21: {
        (this as any).soundmejora1.stop();
        scene.elementosMolestos();
        scene.removeElementosMolestos();

        if (this.primero) {
          this.primero.destroy();
          this.primero = false;
        }

        this.primero = this.createTextBox(
          this,
          430,
          390,
          {
            wrapWidth: 250,
          },
          this.add.image(0, 0, "tutorialnube"),
          4,
          "left"
        ).start("Borra todos los elementos que desees eliminar", 50);
        this.primero.setDepth(11);

        setTimeout(() => {
          this.primero.destroy();
        }, 5500);

        // let dinero = parseInt(localStorage.getItem("dinero")) - parseInt(localStorage.getItem("descuento").split("$")[1])
        // localStorage.setItem("dinero", dinero)
        //this.dineroBase.setText(dinero)
        break;
      }
      case 31: {
        (this as any).soundmejora2.stop();
        scene.elementosMolestosInitial();
        scene.monoInicial();
        scene.addTextScene();
        scene.graphicarZona();
        // let dinero = parseInt(localStorage.getItem("dinero")) - parseInt(localStorage.getItem("descuento").split("$")[1])
        // localStorage.setItem("dinero", dinero)
        //  let jsonMejoras  = JSON.parse(localStorage.getItem("mejorasRealizadas") as any);
        break;
      }
      case 41: {
        (this as any).soundmejora3.stop();
        let jsonMejoras = JSON.parse(
          localStorage.getItem("mejorasRealizadas") as any
        );
        if (jsonMejoras[12]) {
          scene.addTextScene();
          scene.graphicarZona();
        }
        scene.elementosMolestosInitial();
        scene.monoInicial();
        scene.graphicarPiezas();
        // let dinero = parseInt(localStorage.getItem("dinero")) - parseInt(localStorage.getItem("descuento").split("$")[1])
        // localStorage.setItem("dinero", dinero)
        break;
      }
      case 51: {
        (this as any).soundmejora4.stop();
        let jsonMejoras = JSON.parse(
          localStorage.getItem("mejorasRealizadas") as any
        );
        if (jsonMejoras[13]) {
          scene.separarZonas();
        }
        if (jsonMejoras[12]) {
          scene.addTextScene();
        }
        if (jsonMejoras[14]) {
          scene.graphicarZona();
        }
        if (jsonMejoras[15]) {
          scene.graphicarPiezas();
        }
        scene.elementosMolestosInitial();
        scene.monoInicial();
        // let dinero = parseInt(localStorage.getItem("dinero")) - parseInt(localStorage.getItem("descuento").split("$")[1])
        // localStorage.setItem("dinero", dinero)
        break;
      }
      case 61: {
        (this as any).soundmejora5.stop();
        let jsonMejoras = JSON.parse(
          localStorage.getItem("mejorasRealizadas") as any
        );
        if (jsonMejoras[13]) {
          scene.separarZonas();
        }
        if (jsonMejoras[12]) {
          scene.addTextScene();
        }
        if (jsonMejoras[14]) {
          scene.graphicarZona();
        }
        if (jsonMejoras[15]) {
          scene.graphicarPiezas();
        }
        scene.elementosMolestosInitial();
        scene.monoEspecial();
        scene.separarZonas();
        break;
      }
      case 71: {
        (this as any).soundmejora6.stop();
        let jsonMejoras = JSON.parse(
          localStorage.getItem("mejorasRealizadas") as any
        );
        if (jsonMejoras[12]) {
          scene.addTextScene();
          scene.graphicarZona();
        }
        if (jsonMejoras[13]) {
          scene.graphicarPiezas();
        }
        scene.elementosMolestosInitial();
        scene.monoEspecial();
        scene.separarZonas();

        this[`grupomono1`].getChildren().forEach((element: any) => {
          element.emit("pointerdown");
        });

        break;
      }
      case 81: {
        (this as any).soundmejora7.stop();
        let jsonMejoras = JSON.parse(
          localStorage.getItem("mejorasRealizadas") as any
        );
        if (jsonMejoras[12]) {
          scene.addTextScene();
          scene.graphicarZona();
        }
        if (jsonMejoras[13]) {
          scene.graphicarPiezas(true);
        }
        scene.elementosMolestosInitial();
        scene.monoEspecial();
        scene.separarZonas();
        scene.eliminarRestantesMejoraUltima();
        this[`grupomono2`].getChildren().forEach((element: any, index: any) => {
          if (index === 0) {
            element.emit("pointerdown");
          }
        });

        setTimeout(() => {
          this[`grupomono3`]
            .getChildren()
            .forEach((element: any, index: any) => {
              if (index === 0) {
                element.emit("pointerdown");
              }
            });
        }, 2500);
      }
    }
  }

  vistaModalEtapa() {
    this.etapaModal = this.createWindow(ModalEtapas, {
      title: "¿Quieres eliminar piezas?",
      mensaje: "Elimina piezas para ordenar, por un costo de",
      monto: "$400",
      icon: "deletepieza",
    });
  }

  addTextScene() {
    if (this.tipoEscena !== "mejora") {
      this.add
        .text(555, 640, "ENVIAR CASA", { fontFamily: "font1", fontSize: 18 })
        .setDepth(2);
    }
    this.add
      .text(70, 220, "PUERTA", {
        fontFamily: "font1",
        fontSize: 18,
        color: COLOR_BASE,
      })
      .setDepth(11);
    this.add
      .text(70, 648, "TECHO", {
        fontFamily: "font1",
        fontSize: 18,
        color: COLOR_BASE,
      })
      .setDepth(11);
    this.add
      .text(this.extremoCanvas.width - 140, 220, "MURO", {
        fontFamily: "font1",
        fontSize: 18,
        color: COLOR_BASE,
      })
      .setDepth(11);
    this.add
      .text(this.extremoCanvas.width - 155, 648, "VENTANA", {
        fontFamily: "font1",
        fontSize: 18,
        color: COLOR_BASE,
      })
      .setDepth(11);

    ///text planos
    this.add
      .text(this.centroCanvas.width - 185, 85, "01", {
        fontFamily: "font1",
        fontSize: 27,
        color: "#FFFFFF",
      })
      .setDepth(11);
    this.add
      .text(this.centroCanvas.width - 192, 115, "TECHO", {
        fontFamily: "font1",
        fontSize: 12,
        color: "#FFFFFF",
      })
      .setDepth(11);

    this.add
      .text(this.centroCanvas.width - 103, 85, "02", {
        fontFamily: "font1",
        fontSize: 27,
        color: "#FFFFFF",
      })
      .setDepth(11);
    this.add
      .text(this.centroCanvas.width - 105, 115, "MURO", {
        fontFamily: "font1",
        fontSize: 12,
        color: "#FFFFFF",
      })
      .setDepth(11);

    this.add
      .text(this.centroCanvas.width - 18, 85, "03", {
        fontFamily: "font1",
        fontSize: 27,
        color: "#FFFFFF",
      })
      .setDepth(11);
    this.add
      .text(this.centroCanvas.width - 26, 115, "PUERTA", {
        fontFamily: "font1",
        fontSize: 12,
        color: "#FFFFFF",
      })
      .setDepth(11);

    this.add
      .text(this.centroCanvas.width + 67, 85, "04", {
        fontFamily: "font1",
        fontSize: 27,
        color: "#FFFFFF",
      })
      .setDepth(11);
    this.add
      .text(this.centroCanvas.width + 52, 115, "VENTANA", {
        fontFamily: "font1",
        fontSize: 12,
        color: "#FFFFFF",
      })
      .setDepth(11);

    this.add
      .text(this.centroCanvas.width + 154, 85, "05", {
        fontFamily: "font1",
        fontSize: 27,
        color: "#FFFFFF",
      })
      .setDepth(11);
    this.add
      .text(this.centroCanvas.width + 148, 115, "PLANO", {
        fontFamily: "font1",
        fontSize: 12,
        color: "#FFFFFF",
      })
      .setDepth(11);
  }
  createWindow(func: any, data: any) {
    var x = this.centroCanvas.width;
    var y = this.centroCanvas.height;
    var handle = "window" + this.count++;
    var win = this.add.zone(0, 0, 0, 0).setDepth(555);

    var demo = new func(handle, win);
    return this.scene.add(handle, demo, true, data);
  }
  // funciones propias
  setZone(sceneName: any, num: any) {
    let zone;
    let ax = 25;
    let ay = 25;
    let count = 1;
    for (let i = 0; i < Math.sqrt(num); i++) {
      this.zonasGeneral[i] = [];
      for (let j = 0; j < Math.sqrt(num); j++) {
        zone = sceneName.add
          .zone(
            this.centroCanvas.width - 200 + i * ax + ax / 2,
            this.centroCanvas.height - 170 + j * ay + ay / 2,
            ax,
            ay
          )
          .setDropZone();
        zone.setName(j * Math.sqrt(num) + i + 1);
        zone.graphics = sceneName.add.graphics();

        zone.graphics.lineStyle(1.003, 0xfcdcea);
        zone.graphics.fillStyle(0x47427c, 0.7);
        zone.graphics.fillRect(
          zone.x - zone.input.hitArea.width / 2,
          zone.y - zone.input.hitArea.height / 2,
          zone.input.hitArea.width,
          zone.input.hitArea.height
        );
        zone.graphics.strokeRect(
          zone.x - zone.input.hitArea.width / 2,
          zone.y - zone.input.hitArea.height / 2,
          zone.input.hitArea.width,
          zone.input.hitArea.height
        );
        zone.graphics.setDepth(2);
        //sceneName.graphics.fillPath();

        zone.posicion = count;
        zone.piezasC = 0;
        zone.indexPJ = j;
        zone.countcolision = 0;
        zone.colisionando = false;
        zone.indexPI = i;
        this.allDropzones[zone.name] = zone;
        this.zonasGeneral[i][j] = zone;
        count++;
      }
    }
  }
  /// esta función es para las escenas de jueg
  elementosMolestosInitial() {
    let elementosMolestos = JSON.parse(
      localStorage.getItem("molestosBorrados") as any
    );

    Object.keys(elementosMolestos).forEach((element) => {
      if (!elementosMolestos[element]) {
        switch (element) {
          case "taladro1":
            this[element] = this.add
              .image(220, 250, "taladro")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "taladro2":
            this[element] = this.add
              .image(220, 400, "taladro")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "guincha":
            this[element] = this.add
              .image(150, 470, "guincha")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "guincha2":
            this[element] = this.add
              .image(250, 520, "guincha")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "martillo":
            this[element] = this.add
              .image(440, 180, "martillo")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "martillo2":
            this[element] = this.add
              .image(this.centroCanvas.width + 260, 300, "martillo")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "martillo3":
            this[element] = this.add
              .image(this.centroCanvas.width + 360, 450, "martillo")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "perno":
            this[element] = this.add
              .image(this.centroCanvas.width + 260, 180, "perno")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "perno2":
            this[element] = this.add
              .image(this.centroCanvas.width - 180, 180, "perno")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "casco":
            this[element] = this.add
              .image(this.centroCanvas.width + 280, 450, "casco")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "casco2":
            this[element] = this.add
              .image(this.centroCanvas.width + 230, 600, "casco")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "audifono":
            this[element] = this.add
              .image(this.centroCanvas.width + 350, 350, "audifono")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "audifono2":
            this[element] = this.add
              .image(this.centroCanvas.width - 70, 680, "audifono")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "lampa":
            this[element] = this.add
              .image(this.centroCanvas.width - 210, 675, "lampa")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "lampa2":
            this[element] = this.add
              .image(120, 350, "lampa")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "cuadro":
            this[element] = this.add
              .image(80, 490, "cuadro")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "cuadro2":
            this[element] = this.add
              .image(this.centroCanvas.width + 100, 715, "cuadro")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "lapiz":
            this[element] = this.add
              .image(this.centroCanvas.width + 20, 180, "lapiz")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "lapiz2":
            this[element] = this.add
              .image(this.centroCanvas.width + 420, 500, "lapiz")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "balde":
            this[element] = this.add
              .image(this.centroCanvas.width + 130, 180, "balde")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "balde2":
            this[element] = this.add
              .image(this.centroCanvas.width + 450, 320, "balde")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
          case "balde3":
            this[element] = this.add
              .image(this.centroCanvas.width + 260, 680, "balde")
              .setDepth(1)
              .setInteractive({ cursor: "pointer" });
            this.input.setDraggable(this[element]);
            break;
        }
      }
    });
  }
  /// esta función es para la mejora 5s, es usada por el contenedor para agruparlo con el boton eliminar
  elementosMolestos() {
    let elementosMolestos = JSON.parse(
      localStorage.getItem("molestosBorrados") as any
    );
    Object.keys(elementosMolestos).forEach((element) => {
      if (!elementosMolestos[element]) {
        switch (element) {
          case "taladro1":
            this[element] = this.add.image(0, 0, "taladro");
            break;
          case "taladro2":
            this[element] = this.add.image(0, 0, "taladro");
            break;
          case "guincha":
            this[element] = this.add.image(0, 0, "guincha");
            break;
          case "guincha2":
            this[element] = this.add.image(0, 0, "guincha");
            break;
          case "martillo":
            this[element] = this.add.image(0, 0, "martillo");
            break;
          case "martillo2":
            this[element] = this.add.image(0, 0, "martillo");
            break;
          case "martillo3":
            this[element] = this.add.image(0, 0, "martillo");
            break;
          case "perno":
            this[element] = this.add.image(0, 0, "perno");
            break;
          case "perno2":
            this[element] = this.add.image(0, 0, "perno");
            break;
          case "casco":
            this[element] = this.add.image(0, 0, "casco");
            break;
          case "casco2":
            this[element] = this.add.image(0, 0, "casco");
            break;
          case "audifono":
            this[element] = this.add.image(0, 0, "audifono");
            break;
          case "audifono2":
            this[element] = this.add.image(0, 0, "audifono");
            break;
          case "balde":
            this[element] = this.add.image(0, 0, "balde");
            break;
          case "balde2":
            this[element] = this.add.image(0, 0, "balde");
            break;
          case "balde3":
            this[element] = this.add.image(0, 0, "balde");
            break;
          case "lampa":
            this[element] = this.add.image(0, 0, "lampa");
            break;
          case "lampa2":
            this[element] = this.add.image(0, 0, "lampa");
            break;
          case "lapiz":
            this[element] = this.add.image(0, 0, "lapiz");
            break;
          case "lapiz2":
            this[element] = this.add.image(0, 0, "lapiz");
            break;
          case "cuadro":
            this[element] = this.add.image(0, 0, "cuadro");
            break;
          case "cuadro2":
            this[element] = this.add.image(0, 0, "cuadro");
            break;
        }
      }
    });
  }

  removeElementosMolestos() {
    let elementosMolestos = JSON.parse(
      localStorage.getItem("molestosBorrados") as any
    );
    Object.keys(elementosMolestos).forEach((element) => {
      if (!elementosMolestos[element]) {
        switch (element) {
          case "taladro1":
            this.removeTaladro1 = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setDepth(18)
              .setInteractive({ cursor: "pointer" })
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.taladro1.destroy();
                // this.removeTaladro1.destroy();
                this.rmgrupo1.destroy();
                this.molestosBorrados["taladro1"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo1",
              220,
              250,
              this.taladro1,
              this.removeTaladro1
            );
            break;
          case "taladro2":
            this.removeTaladro2 = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.taladro2.destroy();
                // this.removeTaladro2.destroy();
                this.rmgrupo2.destroy();
                this.molestosBorrados["taladro2"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo2",
              220,
              400,
              this.taladro2,
              this.removeTaladro2
            );
            break;
          case "guincha":
            this.removeGuincha = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.guincha.destroy();
                // this.removeGuincha.destroy();
                this.rmgrupo3.destroy();
                this.molestosBorrados["guincha"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo3",
              150,
              470,
              this.guincha,
              this.removeGuincha
            );
            break;
          case "guincha2":
            this.removeGuincha2 = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.guincha2.destroy();
                // this.removeGuincha2.destroy();
                this.rmgrupo4.destroy();
                this.molestosBorrados["guincha2"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo4",
              250,
              520,
              this.guincha2,
              this.removeGuincha2
            );
            break;
          case "martillo":
            this.removeMartillo = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.martillo.destroy();
                // this.removeMartillo.destroy();
                this.rmgrupo5.destroy();
                this.molestosBorrados["martillo"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo5",
              380,
              180,
              this.martillo,
              this.removeMartillo
            );
            break;
          case "martillo2":
            this.removeMartillo2 = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.martillo2.destroy();
                // this.removeMartillo2.destroy();
                this.rmgrupo6.destroy();
                this.molestosBorrados["martillo2"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo6",
              this.centroCanvas.width + 260,
              300,
              this.martillo2,
              this.removeMartillo2
            );
            break;
          case "martillo3":
            this.removeMartillo3 = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.martillo3.destroy();
                // this.removeMartillo3.destroy();
                this.rmgrupo7.destroy();
                this.molestosBorrados["martillo3"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo7",
              this.centroCanvas.width + 360,
              450,
              this.martillo3,
              this.removeMartillo3
            );
            break;
          case "perno":
            this.removePerno = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.perno.destroy();
                // this.removePerno.destroy();
                this.rmgrupo8.destroy();
                this.molestosBorrados["perno"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
              });
            this.addContainerObjects(
              "rmgrupo8",
              this.centroCanvas.width + 260,
              180,
              this.perno,
              this.removePerno
            );
            break;
          case "perno2":
            this.removePerno2 = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.perno2.destroy();
                // this.removePerno2.destroy();
                this.rmgrupo9.destroy();
                this.molestosBorrados["perno2"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
              });
            this.addContainerObjects(
              "rmgrupo9",
              this.centroCanvas.width - 180,
              180,
              this.perno2,
              this.removePerno2
            );
            break;
          case "casco":
            this.removeCasco = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.casco.destroy();
                // this.removeCasco.destroy();
                this.rmgrupo10.destroy();
                this.molestosBorrados["casco"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo10",
              this.centroCanvas.width + 280,
              450,
              this.casco,
              this.removeCasco
            );
            break;
          case "casco2":
            this.removeCasco2 = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.casco2.destroy();
                // this.removeCasco2.destroy();
                this.rmgrupo11.destroy();
                this.molestosBorrados["casco2"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo11",
              this.centroCanvas.width + 230,
              600,
              this.casco2,
              this.removeCasco2
            );
            break;
          case "audifono":
            this.removeAudifono = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.audifono.destroy();
                // this.removeAudifono.destroy();
                this.rmgrupo12.destroy();
                this.molestosBorrados["audifono"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo12",
              this.centroCanvas.width + 350,
              350,
              this.audifono,
              this.removeAudifono
            );
            break;
          case "audifono2":
            this.removeAudifono2 = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.audifono2.destroy();
                // this.removeAudifono2.destroy();
                this.rmgrupo13.destroy();
                this.molestosBorrados["audifono2"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo13",
              this.centroCanvas.width + 350,
              350,
              this.audifono2,
              this.removeAudifono2
            );
            break;
          case "lampa":
            this.removeLampa = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.lampa.destroy();
                // this.removeLampa.destroy();
                this.rmgrupo14.destroy();
                this.molestosBorrados["lampa"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo14",
              this.centroCanvas.width - 210,
              675,
              this.lampa,
              this.removeLampa
            );
            break;
          case "lampa2":
            this.removeLampa2 = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.lampa2.destroy();
                // this.removeLampa2.destroy();
                this.rmgrupo15.destroy();
                this.molestosBorrados["lampa2"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo15",
              120,
              350,
              this.lampa2,
              this.removeLampa2
            );
            break;
          case "cuadro":
            this.removeCuadro = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.cuadro.destroy();
                // this.removeCuadro.destroy();
                this.rmgrupo16.destroy();
                this.molestosBorrados["cuadro"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo16",
              80,
              490,
              this.cuadro,
              this.removeCuadro
            );
            break;
          case "cuadro2":
            this.removeCuadro2 = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.cuadro2.destroy();
                // this.removeCuadro2.destroy();
                this.rmgrupo17.destroy();
                this.molestosBorrados["cuadro2"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo17",
              this.centroCanvas.width + 100,
              715,
              this.cuadro2,
              this.removeCuadro2
            );
            break;
          case "lapiz":
            this.removeLapiz = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.lapiz.destroy();
                // this.removeLapiz.destroy();
                this.rmgrupo18.destroy();
                this.molestosBorrados["lapiz"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo18",
              this.centroCanvas.width + 20,
              180,
              this.lapiz,
              this.removeLapiz
            );
            break;
          case "lapiz2":
            this.removeLapiz2 = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.lapiz2.destroy();
                // this.removeLapiz2.destroy();
                this.rmgrupo19.destroy();
                this.molestosBorrados["lapiz2"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo19",
              this.centroCanvas.width + 420,
              500,
              this.lapiz2,
              this.removeLapiz2
            );
            break;
          case "balde":
            this.removeBalde = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.balde.destroy();
                // this.removeBalde.destroy();
                this.rmgrupo20.destroy();
                this.molestosBorrados["balde"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo20",
              this.centroCanvas.width + 130,
              180,
              this.balde,
              this.removeBalde
            );
            break;
          case "balde2":
            this.removeBalde2 = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.balde2.destroy();
                // this.removeBalde2.destroy();
                this.rmgrupo21.destroy();
                this.molestosBorrados["balde2"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
              });
            this.addContainerObjects(
              "rmgrupo21",
              this.centroCanvas.width + 450,
              320,
              this.balde2,
              this.removeBalde2
            );
            break;
          case "balde3":
            this.removeBalde3 = this.add
              .image(25, -20, "close")
              .setScale(0.075)
              .setInteractive({ cursor: "pointer" })
              .setDepth(18)
              .on("pointerdown", () => {
                this.sound.play("click");
                // this.balde3.destroy();
                // this.removeBalde3.destroy();
                this.rmgrupo22.destroy();
                this.molestosBorrados["balde3"] = true;
                localStorage.setItem(
                  "molestosBorrados",
                  JSON.stringify(this.molestosBorrados)
                );
                funcionActualizarDatosFB({
                  molestosBorrados: this.molestosBorrados,
                });
              });
            this.addContainerObjects(
              "rmgrupo22",
              this.centroCanvas.width + 260,
              680,
              this.balde3,
              this.removeBalde3
            );
            break;
        }
      }
    });
  }

  addContainerObjects(name: string, x: any, y: any, obj1: any, obj2: any) {
    (this as any)[name] = this.add.container(x, y, [obj1, obj2]);
    (this as any)[name].setSize(obj1.width, obj2.height);
    (this as any)[name].setDepth(11);
    (this as any)[name].setInteractive({ cursor: "pointer" });
    this.input.setDraggable((this as any)[name]);
  }

  separarZonas() {
    /// separar zonas
    let graphicsLine = this.add.graphics();
    let line = new Phaser.Geom.Line(
      this.centroCanvas.width,
      100,
      this.centroCanvas.width,
      725
    );
    graphicsLine.lineStyle(4, 0x5df8fd, 1);
    graphicsLine.strokeLineShape(line);
    graphicsLine.setDepth(1);

    let graphicsLine2 = this.add.graphics();
    let line2 = new Phaser.Geom.Line(
      50,
      415,
      this.extremoCanvas.width - 50,
      415
    );
    graphicsLine2.lineStyle(4, 0x5df8fd, 1);
    graphicsLine2.strokeLineShape(line2);
    graphicsLine2.setDepth(1);
  }

  graphicarZona() {
    let COLOR_GRAPHIC = 0xff00ff;
    // graficar techo
    let graphics = this.add.graphics();
    //estilos del grafico
    graphics.lineStyle(2, COLOR_GRAPHIC);
    graphics.fillStyle(COLOR_GRAPHIC, 0.4);

    //iniciando dibujo
    graphics.beginPath();
    graphics.moveTo(714, 215);
    graphics.lineTo(312, 215);
    graphics.lineTo(312, 391);
    graphics.lineTo(361, 391);
    graphics.lineTo(387, 291);
    graphics.lineTo(637, 291);
    graphics.lineTo(664, 391);
    graphics.lineTo(714, 391);
    //cerrando el dibujo
    graphics.closePath();
    //estableciendo bordes, fondo y posicionamiento
    //graphics.strokePath();
    graphics.fillPath();
    graphics.setDepth(2);

    //graficar pared
    let graphics1 = this.add.graphics();
    //estilos del dibujo
    graphics1.lineStyle(2, COLOR_GRAPHIC);
    graphics1.fillStyle(COLOR_GRAPHIC, 0.4);
    //iniciando dibujo
    graphics1.beginPath();
    graphics1.moveTo(714, 616);
    graphics1.lineTo(312, 616);
    graphics1.lineTo(312, 390);
    graphics1.lineTo(387, 390);
    graphics1.lineTo(387, 540);
    graphics1.lineTo(638, 540);
    graphics1.lineTo(638, 390);
    graphics1.lineTo(714, 390);
    //cerrando el dibujo
    graphics1.closePath();
    //estableciendo bordes, fondo y posicionamiento
    //graphics1.strokePath();
    graphics1.fillPath();
    graphics1.setDepth(2);
  }

  graphicarPiezas(active: any) {
    if (
      JSON.parse(localStorage.getItem("mejorasRealizadas") as any)[17] ||
      active
    ) {
      //graficar piezas
      this.add.image(387, 340, "t1l").setDepth(2).setAlpha(1);

      // this.add.image(437,315,"t2l").setDepth(2).setAlpha(1)
      // this.add.image(487,315,"t2l").setDepth(2).setAlpha(1)
      // this.add.image(537,315,"t2l").setDepth(2).setAlpha(1)
      // this.add.image(587,315,"t2l").setDepth(2).setAlpha(1)

      this.add.image(462, 365, "t3l").setDepth(2).setAlpha(1);
      this.add.image(563, 365, "t4l").setDepth(2).setAlpha(1);
      this.add.image(637, 340, "t5l").setDepth(2).setAlpha(1);
      this.add.image(463, 315, "tddd6l").setDepth(2).setAlpha(1);
      this.add.image(563, 315, "tddd6l").setDepth(2).setAlpha(1);

      this.add.image(512, 365, "papunta").setDepth(2).setAlpha(1);

      // this.add.image(400,503,"pa2l").setDepth(2).setAlpha(1)

      this.add.image(500, 428, "pa2l").setDepth(2).setAlpha(1);

      this.add.image(526, 465, "pa3l").setDepth(2).setAlpha(1);

      //this.add.image(625,439,"pa3l").setDepth(2).setAlpha(1)

      //this.add.image(550,528,"pa4l").setDepth(2).setAlpha(1)

      //this.add.image(587,502,"pa5l").setDepth(2).setAlpha(1)

      //this.add.image(512,515,"pa6l").setDepth(2).setAlpha(1)

      this.add.image(437, 416, "pa7l").setDepth(2).setAlpha(1);
      this.add.image(563, 416, "pa7l").setDepth(2).setAlpha(1);

      // this.add.image(612,515,"pa8l").setDepth(2).setAlpha(1)

      //this.add.image(625,478,"pa0l").setDepth(2).setAlpha(1)
      //this.add.image(625,403,"pa0l").setDepth(2).setAlpha(1)

      //this.add.image(550,502,"pa0l").setDepth(2).setAlpha(1)
      this.add.image(500, 478, "pa0l").setDepth(2).setAlpha(1);

      // this.add.image(400,452,"pa0l").setDepth(2).setAlpha(1)
      this.add.image(400, 491, "paddd11l").setDepth(2).setAlpha(1);
      this.add.image(625, 440, "paddd11l").setDepth(2).setAlpha(1);
      this.add.image(525, 515, "paddd12l").setDepth(2).setAlpha(1);
      this.add.image(600, 515, "paddd12l").setDepth(2).setAlpha(1);

      this.add.image(437, 465, "p2l").setDepth(2).setAlpha(1);
      this.add.image(437, 515, "p2l").setDepth(2).setAlpha(1);
      this.add.image(449, 440, "p3l").setDepth(2).setAlpha(1);
      this.add.image(462, 502, "p4l").setDepth(2).setAlpha(1);

      this.add.image(550, 452, "v1l").setDepth(2).setAlpha(1);
      this.add.image(600, 452, "v1l").setDepth(2).setAlpha(1);
      this.add.image(575, 427, "v2l").setDepth(2).setAlpha(1);
      this.add.image(575, 465, "v3l").setDepth(2).setAlpha(1);
    } else {
      //graficar piezas
      this.add.image(387, 340, "t1l").setDepth(2).setAlpha(0.7);
      this.add.image(437, 315, "t2l").setDepth(2).setAlpha(0.7);
      this.add.image(487, 315, "t2l").setDepth(2).setAlpha(0.7);
      this.add.image(537, 315, "t2l").setDepth(2).setAlpha(0.7);
      this.add.image(587, 315, "t2l").setDepth(2).setAlpha(0.7);
      this.add.image(462, 365, "t3l").setDepth(2).setAlpha(0.7);
      this.add.image(563, 365, "t4l").setDepth(2).setAlpha(0.7);
      this.add.image(637, 340, "t5l").setDepth(2).setAlpha(0.7);

      this.add.image(512, 365, "pa1l").setDepth(2).setAlpha(0.7);

      this.add.image(400, 503, "pa2l").setDepth(2).setAlpha(0.7);

      this.add.image(500, 428, "pa2l").setDepth(2).setAlpha(0.7);

      this.add.image(526, 465, "pa3l").setDepth(2).setAlpha(0.7);
      this.add.image(625, 439, "pa3l").setDepth(2).setAlpha(0.7);

      this.add.image(550, 528, "pa4l").setDepth(2).setAlpha(0.7);

      this.add.image(587, 502, "pa5l").setDepth(2).setAlpha(0.7);

      this.add.image(512, 515, "pa6l").setDepth(2).setAlpha(0.7);

      this.add.image(437, 416, "pa7l").setDepth(2).setAlpha(0.7);
      this.add.image(563, 416, "pa7l").setDepth(2).setAlpha(0.7);

      this.add.image(612, 515, "pa8l").setDepth(2).setAlpha(0.7);

      this.add.image(625, 478, "pa0l").setDepth(2).setAlpha(0.7);
      this.add.image(625, 403, "pa0l").setDepth(2).setAlpha(0.7);
      this.add.image(550, 502, "pa0l").setDepth(2).setAlpha(0.7);
      this.add.image(500, 478, "pa0l").setDepth(2).setAlpha(0.7);

      this.add.image(400, 452, "pa0l").setDepth(2).setAlpha(0.7);

      this.add.image(437, 465, "p2l").setDepth(2).setAlpha(0.7);
      this.add.image(437, 515, "p2l").setDepth(2).setAlpha(0.7);
      this.add.image(449, 440, "p3l").setDepth(2).setAlpha(0.7);
      this.add.image(462, 502, "p4l").setDepth(2).setAlpha(0.7);

      this.add.image(550, 452, "v1l").setDepth(2).setAlpha(0.7);
      this.add.image(600, 452, "v1l").setDepth(2).setAlpha(0.7);
      this.add.image(575, 427, "v2l").setDepth(2).setAlpha(0.7);
      this.add.image(575, 465, "v3l").setDepth(2).setAlpha(0.7);
    }
  }

  monoInicial() {
    this.createMono("mono1", "salto", 2, 55, 190, 45, 0.9, 180, 190);
    this.createMono(
      "mono2",
      "salto2",
      2,
      this.extremoCanvas.width - 157,
      190,
      50,
      0.9,
      180,
      180
    );
    this.createMono("mono3", "salto3", 2, 55, 620, 50, 0.9, 610, 610);
    this.createMono(
      "mono4",
      "salto4",
      2,
      this.extremoCanvas.width - 157,
      620,
      50,
      0.9,
      610,
      610
    );
  }

  monoEspecial() {
    this.createMonoEspecial(
      "mono1",
      "salto",
      0,
      105,
      190,
      35,
      0.9,
      180,
      190,
      null
    );
    this.createMonoEspecial(
      "mono2",
      "salto2",
      7,
      this.extremoCanvas.width - 140,
      165,
      25,
      0.5,
      180,
      180,
      true
    );
    this.createMonoEspecial(
      "mono3",
      "salto3",
      2,
      80,
      620,
      50,
      0.9,
      610,
      610,
      null
    );
    this.createMonoEspecial(
      "mono4",
      "salto4",
      0,
      this.extremoCanvas.width - 105,
      620,
      37,
      0.9,
      610,
      610,
      null
    );
  }

  createMono(
    key: any,
    nameAnimation: any,
    repeat: any,
    positionX: any,
    positionY: any,
    stepX: any,
    scale: any,
    overPositionY: any,
    outOverPositionY: any
  ) {
    let count = 0;
    (this as any)[`grupo${key}`] = this.add.group({
      key: key,
      repeat: repeat,
      setXY: {
        x: positionX,
        y: positionY,
        stepX: stepX,
      },
    });
    (this as any)[`grupo${key}`].getChildren().forEach((element: any) => {
      element.posicionElement = count;
      count++;
      element.setDepth(10);
      element.setScale(scale);
      element
        .setInteractive({ cursor: "pointer" })
        .on(
          "pointerover",
          (pointer: any, localX: any, localY: any, event: any) => {
            element.anims.play(nameAnimation);
            element.setY(overPositionY);
            setTimeout(() => {
              element.setY(positionY);
            }, 500);
            event.stopPropagation();
          }
        )
        .on("pointerdown", () => {
          if (this.tipoEscena !== "mejora") {
            this.sound.play("click");
            if (key === "mono1") {
              if (!this.tableMono1) {
                const callbackFunction = (
                  escena: any,
                  orientation: any,
                  isTrabajando: any,
                  addCola: any,
                  cola: any
                ) => {
                  return this.GetFooterSizer(
                    escena,
                    orientation,
                    isTrabajando,
                    addCola,
                    cola,
                    null,
                    null
                  );
                };
                this.tableMono1 = this.createPanelGeneral(
                  this,
                  materialesPuerta,
                  105,
                  350,
                  "carrito",
                  callbackFunction,
                  null,
                  null,
                  "trabajandoPuerta",
                  "addColaPuerta",
                  "colaPuerta",
                  350,
                  350,
                  null
                );
                this.tableMono1.setDepth(999999999999999999999999999999);
                if (this.tableMono2) {
                  this.tableMono2.visible = false;
                }
                if (this.tableMono3) {
                  this.tableMono3.visible = false;
                }
                if (this.tableMono4) {
                  this.tableMono4.visible = false;
                }
              } else {
                this.tableMono1.visible = true;
                if (this.tableMono2) {
                  this.tableMono2.visible = false;
                }
                if (this.tableMono3) {
                  this.tableMono3.visible = false;
                }
                if (this.tableMono4) {
                  this.tableMono4.visible = false;
                }
              }
            }
            if (key === "mono2") {
              if (!this.tableMono2) {
                const callbackFunction = (
                  escena: any,
                  orientation: any,
                  isTrabajando: any,
                  addCola: any,
                  cola: any
                ) => {
                  return this.GetFooterPared(
                    escena,
                    0,
                    isTrabajando,
                    addCola,
                    cola
                  );
                };
                this.tableMono2 = this.createPanelTemporal(
                  this,
                  materialesPared,
                  this.extremoCanvas.width - 170,
                  400,
                  "carritoPared",
                  callbackFunction,
                  1,
                  360,
                  null,
                  1,
                  "trabajoPared",
                  "addColaPared",
                  "colaPared",
                  580,
                  750,
                  1.7
                );
                this.tableMono2.setDepth(999999999999999999999999999999);
                if (this.tableMono1) {
                  this.tableMono1.visible = false;
                }
                if (this.tableMono3) {
                  this.tableMono3.visible = false;
                }
                if (this.tableMono4) {
                  this.tableMono4.visible = false;
                }
              } else {
                this.tableMono2.visible = true;
                if (this.tableMono1) {
                  this.tableMono1.visible = false;
                }
                if (this.tableMono3) {
                  this.tableMono3.visible = false;
                }
                if (this.tableMono4) {
                  this.tableMono4.visible = false;
                }
              }
            }
            if (key === "mono3") {
              if (!this.tableMono3) {
                const callbackFunction = (
                  escena: any,
                  orientation: any,
                  isTrabajando: any,
                  addCola: any,
                  cola: any
                ) => {
                  return this.GetFooterTecho(
                    escena,
                    orientation,
                    isTrabajando,
                    addCola,
                    cola
                  );
                };

                this.tableMono3 = this.createPanelTemporal(
                  this,
                  materialesTecho,
                  160,
                  457,
                  "carritoTecho",
                  callbackFunction,
                  null,
                  250,
                  300,
                  0.4,
                  "trabajoTecho",
                  "addColaTecho",
                  "colaTecho",
                  400,
                  750,
                  null
                );
                this.tableMono3.setDepth(999999999999999999999999999999);
                if (this.tableMono1) {
                  this.tableMono1.visible = false;
                }
                if (this.tableMono2) {
                  this.tableMono2.visible = false;
                }
                if (this.tableMono4) {
                  this.tableMono4.visible = false;
                }
              } else {
                this.tableMono3.visible = true;
                if (this.tableMono1) {
                  this.tableMono1.visible = false;
                }
                if (this.tableMono2) {
                  this.tableMono2.visible = false;
                }
                if (this.tableMono4) {
                  this.tableMono4.visible = false;
                }
              }
            }
            if (key === "mono4") {
              if (!this.tableMono4) {
                const callbackFunction = (
                  escena: any,
                  orientation: any,
                  isTrabajando: any,
                  addCola: any,
                  cola: any
                ) => {
                  return this.GetFooterVentana(
                    escena,
                    orientation,
                    isTrabajando,
                    addCola,
                    cola
                  );
                };
                this.tableMono4 = this.createPanelGeneral(
                  this,
                  materialesVentana,
                  this.extremoCanvas.width - 105,
                  458,
                  "carritoVentana",
                  callbackFunction,
                  null,
                  1,
                  "trabajandoVentana",
                  "addColaVentana",
                  "colaVentana",
                  350,
                  350,
                  1.5
                );
                this.tableMono4.setDepth(999999999999999999999999999999);
                if (this.tableMono1) {
                  this.tableMono1.visible = false;
                }
                if (this.tableMono2) {
                  this.tableMono2.visible = false;
                }
                if (this.tableMono3) {
                  this.tableMono3.visible = false;
                }
              } else {
                this.tableMono4.visible = true;
                if (this.tableMono1) {
                  this.tableMono1.visible = false;
                }
                if (this.tableMono2) {
                  this.tableMono2.visible = false;
                }
                if (this.tableMono3) {
                  this.tableMono3.visible = false;
                }
              }
            }
          }
        });
    });
  }

  createMonoEspecial(
    key: any,
    nameAnimation: any,
    repeat: any,
    positionX: any,
    positionY: any,
    stepX: any,
    scale: any,
    overPositionY: any,
    outOverPositionY: any,
    double: any
  ) {
    let count = 0;
    let repeatPoint = double ? (repeat + 1) / 2 : repeat;
    (this as any)[`grupo${key}`] = this.add.group({
      key: key,
      repeat: repeatPoint - 1,
      setXY: {
        x: positionX,
        y: positionY,
        stepX: stepX,
      },
    });
    (this as any)[`grupo${key}`].getChildren().forEach((element: any) => {
      element.posicionElement = count;
      count++;
      element.setDepth(10);
      element.setScale(scale);
      element.nameAnim = nameAnimation;
      element.tipo = "monos";
      element
        .setInteractive({ cursor: "pointer" })
        .on(
          "pointerover",
          (pointer: any, localX: any, localY: any, event: any) => {
            element.anims.play(nameAnimation);
            event.stopPropagation();
          }
        )
        .on("pointerdown", () => {
          if (
            [81, 71].includes(parseInt(this.etapaD)) ||
            this.tipoEscena !== "mejora"
          ) {
            this.sound.play("click");
            if (key === "mono1") {
              if (!this.tableMono1) {
                const callbackFunction = (
                  escena: any,
                  orientation: any,
                  isTrabajando: any,
                  addCola: any,
                  cola: any
                ) => {
                  return this.GetFooterSizer(
                    escena,
                    orientation,
                    isTrabajando,
                    addCola,
                    cola,
                    null,
                    null
                  );
                };

                let materialesEscogido = JSON.parse(
                  localStorage.getItem("mejorasRealizadas") as any
                )[16]
                  ? puertaCompleta
                  : materialesPuerta;

                if (
                  JSON.parse(
                    localStorage.getItem("mejorasRealizadas") as any
                  )[16]
                ) {
                  // this.tableMono1 = this.createPanelPiezasCompletas(this,materialesEscogido,105, 350,'carritoPuertoCompleta', callbackFunction, null, .3, "trabajandoPuerta", "addColaPuerta", "colaPuerta", 12, null, null, null, 12 )
                  this.tableMono1 = this.createPanelGeneral(
                    this,
                    materialesPuerta,
                    105,
                    350,
                    "carrito",
                    callbackFunction,
                    null,
                    null,
                    "trabajandoPuerta",
                    "addColaPuerta",
                    "colaPuerta",
                    350,
                    350,
                    null
                  );
                } else {
                  this.tableMono1 = this.createPanelGeneral(
                    this,
                    materialesPuerta,
                    105,
                    350,
                    "carrito",
                    callbackFunction,
                    null,
                    null,
                    "trabajandoPuerta",
                    "addColaPuerta",
                    "colaPuerta",
                    350,
                    350,
                    null
                  );
                }

                this.tableMono1.setDepth(99999);
                if (this.tableMono2) {
                  this.tableMono2.visible = false;
                }
                if (this.tableMono3) {
                  this.tableMono3.visible = false;
                }
                if (this.tableMono4) {
                  this.tableMono4.visible = false;
                }
              } else {
                this.tableMono1.visible = true;
                this.tableMono1.setDepth(15);
                if (this.tableMono2) {
                  this.tableMono2.visible = false;
                }
                if (this.tableMono3) {
                  this.tableMono3.visible = false;
                }
                if (this.tableMono4) {
                  this.tableMono4.visible = false;
                }
              }
            }
            if (key === "mono2") {
              if (!this.tableMono2) {
                const callbackFunction = (
                  escena: any,
                  orientation: any,
                  isTrabajando: any,
                  addCola: any,
                  cola: any
                ) => {
                  return this.GetFooterPared(
                    escena,
                    0,
                    isTrabajando,
                    addCola,
                    cola
                  );
                };

                let materialesEscogido = JSON.parse(
                  localStorage.getItem("mejorasRealizadas") as any
                )[17]
                  ? materialesParedNew
                  : materialesPared;

                if (
                  JSON.parse(
                    localStorage.getItem("mejorasRealizadas") as any
                  )[16]
                ) {
                  // this.tableMono2 = this.createPanelPiezasCompletas(this,materialesEscogido,this.extremoCanvas.width - 110, 360,'carritoMuroCompleta',callbackFunction, 1,.37,'trabajoPared', 'addColaPared', "colaPared", 0, null, null, null, 12)
                  this.tableMono2 = this.createPanelTemporal(
                    this,
                    materialesEscogido,
                    this.extremoCanvas.width - 170,
                    400,
                    "carritoPared",
                    callbackFunction,
                    1,
                    null,
                    null,
                    1,
                    "trabajoPared",
                    "addColaPared",
                    "colaPared",
                    580,
                    750,
                    1.7
                  );
                } else {
                  this.tableMono2 = this.createPanelTemporal(
                    this,
                    materialesEscogido,
                    this.extremoCanvas.width - 170,
                    400,
                    "carritoPared",
                    callbackFunction,
                    1,
                    null,
                    null,
                    1,
                    "trabajoPared",
                    "addColaPared",
                    "colaPared",
                    580,
                    750,
                    1.7
                  );
                }

                this.tableMono2.setDepth(99999);
                if (this.tableMono1) {
                  this.tableMono1.visible = false;
                }
                if (this.tableMono3) {
                  this.tableMono3.visible = false;
                }
                if (this.tableMono4) {
                  this.tableMono4.visible = false;
                }
              } else {
                this.tableMono2.visible = true;
                if (this.tableMono1) {
                  this.tableMono1.visible = false;
                }
                if (this.tableMono3) {
                  this.tableMono3.visible = false;
                }
                if (this.tableMono4) {
                  this.tableMono4.visible = false;
                }
              }
            }
            if (key === "mono3") {
              if (!this.tableMono3) {
                const callbackFunction = (
                  escena: any,
                  orientation: any,
                  isTrabajando: any,
                  addCola: any,
                  cola: any
                ) => {
                  return this.GetFooterTecho(
                    escena,
                    orientation,
                    isTrabajando,
                    addCola,
                    cola
                  );
                };

                let materialesEscogido = JSON.parse(
                  localStorage.getItem("mejorasRealizadas") as any
                )[17]
                  ? materialesTechoNew
                  : materialesTecho;
                // this.carritoTechoCompleta
                if (
                  JSON.parse(
                    localStorage.getItem("mejorasRealizadas") as any
                  )[16]
                ) {
                  //console.log(materialesEscogido)
                  // this.tableMono3 = this.createPanelPiezasCompletas(this,materialesEscogido,118, 457,'carritoTechoCompleta',callbackFunction, null, .32, "trabajoTecho", "addColaTecho", "colaTecho", 0, null, null, null, 0)
                  this.tableMono3 = this.createPanelTemporal(
                    this,
                    materialesEscogido,
                    160,
                    457,
                    "carritoTecho",
                    callbackFunction,
                    null,
                    250,
                    300,
                    0.4,
                    "trabajoTecho",
                    "addColaTecho",
                    "colaTecho",
                    400,
                    750,
                    null
                  );
                } else {
                  this.tableMono3 = this.createPanelTemporal(
                    this,
                    materialesEscogido,
                    160,
                    457,
                    "carritoTecho",
                    callbackFunction,
                    null,
                    250,
                    300,
                    0.4,
                    "trabajoTecho",
                    "addColaTecho",
                    "colaTecho",
                    400,
                    750,
                    null
                  );

                  console.log("aca sxx", this.tableMono3);
                }

                this.tableMono3.setDepth(99999);

                if (this.tableMono1) {
                  this.tableMono1.visible = false;
                }
                if (this.tableMono2) {
                  this.tableMono2.visible = false;
                }
                if (this.tableMono4) {
                  this.tableMono4.visible = false;
                }
              } else {
                this.tableMono3.visible = true;
                if (this.tableMono1) {
                  this.tableMono1.visible = false;
                }
                if (this.tableMono2) {
                  this.tableMono2.visible = false;
                }
                if (this.tableMono4) {
                  this.tableMono4.visible = false;
                }
              }
            }
            if (key === "mono4") {
              if (!this.tableMono4) {
                const callbackFunction = (
                  escena: any,
                  orientation: any,
                  isTrabajando: any,
                  addCola: any,
                  cola: any
                ) => {
                  return this.GetFooterVentana(
                    escena,
                    orientation,
                    isTrabajando,
                    addCola,
                    cola
                  );
                };

                let materialesEscogido = JSON.parse(
                  localStorage.getItem("mejorasRealizadas") as any
                )[16]
                  ? ventanaCompleta
                  : materialesVentana;
                // this.carritoTechoCompleta
                if (
                  JSON.parse(
                    localStorage.getItem("mejorasRealizadas") as any
                  )[16]
                ) {
                  //console.log(materialesEscogido)
                  //this.carritoVentanaCompleta
                  //  this.tableMono4 = this.createPanelPiezasCompletas(this,materialesEscogido,this.extremoCanvas.width-105, 458,'carritoVentanaCompleta',callbackFunction, null, .4, "trabajandoVentana", "addColaVentana", "colaVentana", 0, null, null, null, 12)
                  this.tableMono4 = this.createPanelGeneral(
                    this,
                    materialesVentana,
                    this.extremoCanvas.width - 105,
                    458,
                    "carritoVentana",
                    callbackFunction,
                    null,
                    1,
                    "trabajandoVentana",
                    "addColaVentana",
                    "colaVentana",
                    350,
                    350,
                    1.5
                  );
                } else {
                  this.tableMono4 = this.createPanelGeneral(
                    this,
                    materialesVentana,
                    this.extremoCanvas.width - 105,
                    458,
                    "carritoVentana",
                    callbackFunction,
                    null,
                    1,
                    "trabajandoVentana",
                    "addColaVentana",
                    "colaVentana",
                    350,
                    350,
                    1.5
                  );
                }
                this.tableMono4.setDepth(99999);
                if (this.tableMono1) {
                  this.tableMono1.visible = false;
                }
                if (this.tableMono2) {
                  this.tableMono2.visible = false;
                }
                if (this.tableMono3) {
                  this.tableMono3.visible = false;
                }
              } else {
                this.tableMono4.visible = true;
                if (this.tableMono1) {
                  this.tableMono1.visible = false;
                }
                if (this.tableMono2) {
                  this.tableMono2.visible = false;
                }
                if (this.tableMono3) {
                  this.tableMono3.visible = false;
                }
              }
            }
          }
        });
    });
    if (double) {
      (this as any)[`grupo${key}`] = this.add.group({
        key: key,
        repeat: repeatPoint - 1,
        setXY: {
          x: positionX,
          y: positionY + 38,
          stepX: stepX,
        },
      });
      (this as any)[`grupo${key}`].getChildren().forEach((element: any) => {
        element.posicionElement = count;
        count++;
        element.setDepth(10);
        element.setScale(scale);
        element.nameAnim = nameAnimation;
        element.tipo = "monos";
        element
          .setInteractive({ cursor: "pointer" })
          .on(
            "pointerover",
            (pointer: any, localX: any, localY: any, event: any) => {
              element.anims.play(nameAnimation);

              event.stopPropagation();
            }
          )
          .on("pointerdown", () => {
            if (
              [81, 71].includes(parseInt(this.etapaD)) ||
              this.tipoEscena !== "mejora"
            ) {
              this.sound.play("click");
              if (key === "mono1") {
                if (!this.tableMono1) {
                  const callbackFunction = (
                    escena: any,
                    orientation: any,
                    isTrabajando: any,
                    addCola: any,
                    cola: any
                  ) => {
                    return this.GetFooterSizer(
                      escena,
                      orientation,
                      isTrabajando,
                      addCola,
                      cola,
                      null,
                      null
                    );
                  };

                  let materialesEscogido = JSON.parse(
                    localStorage.getItem("mejorasRealizadas") as any
                  )[16]
                    ? puertaCompleta
                    : materialesPuerta;

                  if (
                    JSON.parse(
                      localStorage.getItem("mejorasRealizadas") as any
                    )[16]
                  ) {
                    this.tableMono1 = this.createPanelPiezasCompletas(
                      this,
                      materialesEscogido,
                      105,
                      350,
                      "carritoPuertoCompleta",
                      callbackFunction,
                      null,
                      0.3,
                      "trabajandoPuerta",
                      "addColaPuerta",
                      "colaPuerta",
                      12,
                      null,
                      null,
                      null,
                      12
                    );
                  } else {
                    this.tableMono1 = this.createPanelGeneral(
                      this,
                      materialesEscogido,
                      105,
                      350,
                      "carrito",
                      callbackFunction,
                      null,
                      null,
                      "trabajandoPuerta",
                      "addColaPuerta",
                      "colaPuerta",
                      null,
                      null,
                      null
                    );
                  }

                  this.tableMono1.setDepth(99999);
                  if (this.tableMono2) {
                    this.tableMono2.visible = false;
                  }
                  if (this.tableMono3) {
                    this.tableMono3.visible = false;
                  }
                  if (this.tableMono4) {
                    this.tableMono4.visible = false;
                  }
                } else {
                  this.tableMono1.visible = true;
                  this.tableMono1.setDepth(15);
                  if (this.tableMono2) {
                    this.tableMono2.visible = false;
                  }
                  if (this.tableMono3) {
                    this.tableMono3.visible = false;
                  }
                  if (this.tableMono4) {
                    this.tableMono4.visible = false;
                  }
                }
              }
              if (key === "mono2") {
                if (!this.tableMono2) {
                  const callbackFunction = (
                    escena: any,
                    orientation: any,
                    isTrabajando: any,
                    addCola: any,
                    cola: any
                  ) => {
                    return this.GetFooterPared(
                      escena,
                      0,
                      isTrabajando,
                      addCola,
                      cola
                    );
                  };

                  let materialesEscogido = JSON.parse(
                    localStorage.getItem("mejorasRealizadas") as any
                  )[17]
                    ? materialesParedNew
                    : materialesPared;

                  let mejoraRealizada = JSON.parse(
                    localStorage.getItem("mejorasRealizadas") as any
                  )[17];

                  if (
                    JSON.parse(
                      localStorage.getItem("mejorasRealizadas") as any
                    )[16]
                  ) {
                    // this.tableMono2 = this.createPanelPiezasCompletas(this,materialesEscogido,this.extremoCanvas.width - 110, 360,'carritoMuroCompleta',callbackFunction, 1,.37,'trabajoPared', 'addColaPared', "colaPared", 0, null, null, null, 12)

                    this.tableMono2 = this.createPanelTemporal(
                      this,
                      materialesEscogido,
                      this.extremoCanvas.width - 170,
                      mejoraRealizada ? 355 : 400,
                      "carritoPared",
                      callbackFunction,
                      1,
                      mejoraRealizada ? 250 : null,
                      null,
                      1,
                      "trabajoPared",
                      "addColaPared",
                      "colaPared",
                      580,
                      750,
                      1.7
                    );
                  } else {
                    this.tableMono2 = this.createPanelTemporal(
                      this,
                      materialesEscogido,
                      this.extremoCanvas.width - 170,
                      mejoraRealizada ? 355 : 400,
                      "carritoPared",
                      callbackFunction,
                      1,
                      mejoraRealizada ? 250 : null,
                      null,
                      1,
                      "trabajoPared",
                      "addColaPared",
                      "colaPared",
                      580,
                      750,
                      1.7
                    );
                  }

                  this.tableMono2.setDepth(99999);
                  if (this.tableMono1) {
                    this.tableMono1.visible = false;
                  }
                  if (this.tableMono3) {
                    this.tableMono3.visible = false;
                  }
                  if (this.tableMono4) {
                    this.tableMono4.visible = false;
                  }
                } else {
                  this.tableMono2.visible = true;
                  if (this.tableMono1) {
                    this.tableMono1.visible = false;
                  }
                  if (this.tableMono3) {
                    this.tableMono3.visible = false;
                  }
                  if (this.tableMono4) {
                    this.tableMono4.visible = false;
                  }
                }
              }
              if (key === "mono3") {
                if (!this.tableMono3) {
                  const callbackFunction = (
                    escena: any,
                    orientation: any,
                    isTrabajando: any,
                    addCola: any,
                    cola: any
                  ) => {
                    return this.GetFooterTecho(
                      escena,
                      orientation,
                      isTrabajando,
                      addCola,
                      cola
                    );
                  };

                  let materialesEscogido = JSON.parse(
                    localStorage.getItem("mejorasRealizadas") as any
                  )[16]
                    ? techoCompleto
                    : materialesTecho;
                  // this.carritoTechoCompleta
                  if (
                    JSON.parse(
                      localStorage.getItem("mejorasRealizadas") as any
                    )[16]
                  ) {
                    //console.log(materialesEscogido)
                    this.tableMono3 = this.createPanelPiezasCompletas(
                      this,
                      materialesEscogido,
                      118,
                      457,
                      "carritoTechoCompleta",
                      callbackFunction,
                      null,
                      0.32,
                      "trabajoTecho",
                      "addColaTecho",
                      "colaTecho",
                      0,
                      null,
                      null,
                      null,
                      0
                    );
                  } else {
                    this.tableMono3 = this.createPanelTemporal(
                      this,
                      materialesTecho,
                      160,
                      457,
                      "carritoTecho",
                      callbackFunction,
                      null,
                      250,
                      300,
                      0.4,
                      "trabajoTecho",
                      "addColaTecho",
                      "colaTecho",
                      400,
                      750,
                      null
                    );
                  }

                  this.tableMono3.setDepth(99999);

                  if (this.tableMono1) {
                    this.tableMono1.visible = false;
                  }
                  if (this.tableMono2) {
                    this.tableMono2.visible = false;
                  }
                  if (this.tableMono4) {
                    this.tableMono4.visible = false;
                  }
                } else {
                  this.tableMono3.visible = true;
                  if (this.tableMono1) {
                    this.tableMono1.visible = false;
                  }
                  if (this.tableMono2) {
                    this.tableMono2.visible = false;
                  }
                  if (this.tableMono4) {
                    this.tableMono4.visible = false;
                  }
                }
              }
              if (key === "mono4") {
                if (!this.tableMono4) {
                  const callbackFunction = (
                    escena: any,
                    orientation: any,
                    isTrabajando: any,
                    addCola: any,
                    cola: any
                  ) => {
                    return this.GetFooterVentana(
                      escena,
                      orientation,
                      isTrabajando,
                      addCola,
                      cola
                    );
                  };

                  let materialesEscogido = JSON.parse(
                    localStorage.getItem("mejorasRealizadas") as any
                  )[16]
                    ? ventanaCompleta
                    : materialesVentana;
                  // this.carritoTechoCompleta
                  if (
                    JSON.parse(
                      localStorage.getItem("mejorasRealizadas") as any
                    )[16]
                  ) {
                    //console.log(materialesEscogido)
                    //this.carritoVentanaCompleta
                    this.tableMono4 = this.createPanelPiezasCompletas(
                      this,
                      materialesEscogido,
                      this.extremoCanvas.width - 105,
                      458,
                      "carritoVentanaCompleta",
                      callbackFunction,
                      null,
                      0.4,
                      "trabajandoVentana",
                      "addColaVentana",
                      "colaVentana",
                      0,
                      null,
                      null,
                      null,
                      12
                    );
                  } else {
                    this.tableMono4 = this.createPanelGeneral(
                      this,
                      materialesVentana,
                      this.extremoCanvas.width - 105,
                      458,
                      "carritoVentana",
                      callbackFunction,
                      null,
                      1,
                      "trabajandoVentana",
                      "addColaVentana",
                      "colaVentana",
                      350,
                      350,
                      1.5
                    );
                  }

                  this.tableMono4.setDepth(99999);
                  if (this.tableMono1) {
                    this.tableMono1.visible = false;
                  }
                  if (this.tableMono2) {
                    this.tableMono2.visible = false;
                  }
                  if (this.tableMono3) {
                    this.tableMono3.visible = false;
                  }
                } else {
                  this.tableMono4.visible = true;
                  if (this.tableMono1) {
                    this.tableMono1.visible = false;
                  }
                  if (this.tableMono2) {
                    this.tableMono2.visible = false;
                  }
                  if (this.tableMono3) {
                    this.tableMono3.visible = false;
                  }
                }
              }
            }
          });
      });
    }
  }

  isMobile() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  }

  createPanelGeneral(
    escena: any,
    materiales: any,
    posicionTableX: any,
    posicionTableY: any,
    carrito: any,
    getFooter: any,
    orientacion: any,
    scaleObject: any,
    isTrabajando: any,
    addCola: any,
    cola: any,
    alturaMobile: any,
    anchoMobile: any,
    scaleExtra: any
  ) {
    let isMobile = escena.isMobile();
    var scrollMode = 0;
    let dataMateriales = materiales;
    let itemsPuerta: any = [];
    var gridTable = escena.rexUI.add
      .gridTable({
        x: isMobile ? escena.centroCanvas.width : posicionTableX,
        y: isMobile ? escena.centroCanvas.height + 15 : posicionTableY,
        width: isMobile ? anchoMobile : 190,
        height: isMobile ? alturaMobile : 250,
        scrollMode: scrollMode,
        background: this.add
          .image(0, 0, "fondo_panel")
          .setDisplaySize(
            isMobile ? anchoMobile : 190,
            isMobile ? alturaMobile : 250
          ),
        slider: null,
        table: {
          cellWidth: undefined,
          cellHeight: isMobile ? 75 : 50,
          columns: 2,
          mask: {
            padding: 0,
          },
          interactive: true,
          reuseCellContainer: true,
        },
        header: (this as any).rexUI.add
          .label({
            width: scrollMode === 0 ? 60 : 30,
            height: scrollMode === 0 ? undefined : 30,
            align: "right",
            space: {
              right: 5,
            },
            orientation: scrollMode,
            text: this.add
              .image(0, 0, "close")
              .setScale(isMobile ? 0.075 : 0.045)
              .setInteractive({ cursor: "pointer" })
              .on("pointerdown", function () {
                escena.sound.play("click");
                if (escena.tableMono1) {
                  escena.tableMono1.visible = false;
                }
                if (escena.tableMono2) {
                  escena.tableMono2.visible = false;
                }
                if (escena.tableMono3) {
                  escena.tableMono3.visible = false;
                }
                if (escena.tableMono4) {
                  escena.tableMono4.visible = false;
                }
              }),
          })
          .setDepth(25),
        footer: getFooter(this, 0, isTrabajando, addCola, cola, null, true),
        space: {
          left: 15,
          right: 15,
          top: 20,
          bottom: 14,
          table: { bottom: 0, left: 20, right: 20 },
          header: 0,
          footer: 0,
        },
        expand: {
          header: true,
          footer: false,
        },
        createCellContainerCallback: function (cell: any, cellContainer: any) {
          if (cellContainer == null) {
            var scene = cell.scene,
              width = cell.width,
              height = cell.height,
              item = cell.item,
              index = cell.index;
            const posicion = dataMateriales[cell.index].indexLoop;
            const priceObject =
              dataMateriales[cell.index] && dataMateriales[cell.index].precio;
            if (cellContainer === null && index % 2 === 0) {
              const nameObject =
                dataMateriales[cell.index] && dataMateriales[cell.index].objeto;
              cellContainer = scene.rexUI.add
                .label({
                  width: width,
                  height: height,
                  orientation: scrollMode,
                  icon: scene.add
                    .image(0, 0, nameObject)
                    .setScale(
                      scaleObject
                        ? isMobile
                          ? scaleExtra
                            ? scaleExtra
                            : 1
                          : scaleObject
                        : isMobile
                        ? scaleExtra
                          ? scaleExtra
                          : 1
                        : 0.6
                    )
                    .setDepth(99999999999999999999),
                  space: {
                    icon: 10,
                    left: scrollMode === 0 ? 0 : 0,
                    top: scrollMode === 0 ? 0 : 15,
                  },
                })
                .setDepth(11);
            } else if (cellContainer === null && index % 2 === 1) {
              let dash = scene.rexUI.add.label({
                name: `dash${index}`,
                text: scene.add
                  .text(0, 0, "-", {
                    color: "#92278F",
                    fontWeight: 700,
                    fontSize: isMobile ? 50 : 30,
                    align: "center",
                    padding: 0,
                    fontFamily: "font1",
                  })
                  .setDepth(20)
                  .setInteractive({ cursor: "pointer" }),
              });
              let number = scene.rexUI.add
                .label({
                  name: `number${index}`,
                  text: scene.add
                    .text(0, 0, "00", {
                      color: "#0B2167",
                      fontWeight: 700,
                      fontSize: isMobile ? 35 : 20,
                      align: "center",
                      padding: 0,
                      fontFamily: "font1",
                    })
                    .setDepth(20)
                    .setInteractive({ cursor: "pointer" }),
                })
                .setDepth(20);
              let plus = scene.rexUI.add.label({
                name: `plus${index}`,
                text: scene.add
                  .text(0, 0, "+", {
                    color: "#92278F",
                    fontWeight: 700,
                    align: "center",
                    lineSpacing: 0,
                    fontSize: isMobile ? 50 : 30,
                    padding: 0,
                    fontFamily: "font1",
                  })
                  .setDepth(20)
                  .setInteractive({ cursor: "pointer" }),
              });
              dash.indexLoop = index;
              number.indexLoop = index;
              plus.indexLoop = index;

              scene.rexUI.add
                .click(plus.getElement("text"), { threshold: 10 })
                .on("click", () => {
                  console.log(escena[carrito], "click");
                  scene.sound.play("click");
                  let tas = itemsPuerta.filter((item: any) => {
                    let numberItem = `number${item.indexLoop}`;
                    if (
                      item.name === numberItem &&
                      item.indexLoop === plus.indexLoop
                    ) {
                      return item;
                    }
                  });
                  let nextNumber = parseInt(tas[0].text) + 1;
                  let numberFinal;
                  if (parseInt(tas[0].text) < 9) {
                    numberFinal = `0${nextNumber}`;
                  } else {
                    numberFinal = nextNumber;
                  }

                  let getCarrito = escena[isTrabajando]
                    ? escena[addCola]
                    : escena[carrito];
                  console.log(
                    getCarrito,
                    escena[isTrabajando],
                    escena[carrito],
                    carrito,
                    escena
                  );
                  const isExistData = getCarrito.filter(
                    (item: any, key: any) => {
                      if (item.key == plus.indexLoop) {
                        getCarrito.splice(key, 1);
                      }
                    }
                  );
                  const data = {
                    ...dataMateriales[plus.indexLoop],
                    cantidad: numberFinal,
                    key: plus.indexLoop,
                  };
                  getCarrito.push(data);
                  //
                  tas[0].setText(numberFinal);
                });

              scene.rexUI.add
                .click(dash.getElement("text"), { threshold: 10 })
                .on("click", () => {
                  scene.sound.play("click");
                  let tas = itemsPuerta.filter((item: any) => {
                    let numberItem = `number${item.indexLoop}`;
                    if (
                      item.name === numberItem &&
                      item.indexLoop === plus.indexLoop
                    ) {
                      return item;
                    }
                  });
                  let nextNumber;
                  let numberFinal;
                  if (parseInt(tas[0].text) === 0) {
                    numberFinal = "00";
                  } else {
                    nextNumber = parseInt(tas[0].text) - 1;
                    if (parseInt(tas[0].text) < 9) {
                      numberFinal = `0${nextNumber}`;
                    } else {
                      numberFinal = nextNumber;
                    }
                  }
                  tas[0].setText(numberFinal);

                  let eliminadoCero = false;
                  let getCarrito = escena[isTrabajando]
                    ? escena[addCola]
                    : escena[carrito];
                  getCarrito.forEach((item: any, key: any) => {
                    //  console.log(item.key, plus.indexLoop, key)
                    if (item.key == plus.indexLoop) {
                      getCarrito.splice(key, 1);
                      if (parseInt(item.cantidad) - 1 === 0) {
                        //getCarrito.splice(key, 1)
                        eliminadoCero = true;
                      }
                      if (parseInt(item.cantidad) === 0) {
                        eliminadoCero = true;
                      }
                    }
                    // return item
                  });

                  if (parseInt(numberFinal as any) === 0) {
                    eliminadoCero = true;
                  }

                  if (!eliminadoCero) {
                    const data = {
                      ...dataMateriales[dash.indexLoop],
                      cantidad: numberFinal,
                      key: dash.indexLoop,
                    };
                    getCarrito.push(data);
                  }
                });

              itemsPuerta.push(dash);
              itemsPuerta.push(number);
              itemsPuerta.push(plus);
              escena.temporalItems.push(number);

              cellContainer = scene.rexUI.add
                .gridSizer({
                  column: 3,
                  columnProportions: 0,
                  row: isMobile ? 2 : 2,
                  rowProportions: 0,
                  space: {
                    row: 0,
                  },
                  expand: false,
                  padding: 0,
                })
                .add(dash)
                .add(number)
                .add(plus)
                .add(scene.rexUI.add.label({ text: scene.add.text(0, 0, "") }))
                .setDepth(11)
                .add(
                  scene.rexUI.add
                    .label({
                      text: scene.add
                        .text(0, 0, `$${priceObject}`, {
                          fontSize: isMobile ? 25 : 11,
                          color: "#808080",
                          fontFamily: "font1",
                        })
                        .setDepth(20),
                    })
                    .setY(-180)
                );
            }
            cellContainer.setMinSize(width, height);
          }
          return cellContainer;
        },
        items: this.CreateItems(dataMateriales.length),
      })
      .layout();

    itemsPuerta.forEach((label: any) => {
      if (!label) {
        return;
      }
    });
    return gridTable;
  }

  createPanelTemporal(
    escena: any,
    materiales: any,
    posicionTableX: any,
    posicionTableY: any,
    carrito: any,
    getFooter: any,
    orientacion: any,
    altura: any,
    ancho: any,
    scaleObject: any,
    isTrabajando: any,
    addCola: any,
    cola: any,
    alturaMobile: any,
    anchoMobile: any,
    scaleExtra: any
  ) {
    let isMobile = escena.isMobile();
    var scrollMode = 0;
    let dataMateriales = materiales;
    let itemsPuerta: any = [];
    let anchoF = ancho ? ancho : 320;
    let alturaF = altura ? altura : 360;
    var gridTable = (this as any).rexUI.add
      .gridTable({
        x: isMobile ? escena.centroCanvas.width : posicionTableX,
        y: isMobile ? escena.centroCanvas.height + 15 : posicionTableY,
        width: isMobile ? anchoMobile : anchoF,
        height: isMobile ? alturaMobile : alturaF,
        scrollMode: scrollMode,
        background: this.add
          .image(0, 0, "fondo_panel")
          .setDepth(10)
          .setDisplaySize(
            isMobile ? anchoMobile : anchoF,
            isMobile ? alturaMobile : alturaF
          ),
        slider: false,
        table: {
          cellWidth: scrollMode === 0 ? undefined : 30,
          cellHeight: isMobile ? 95 : 50,
          columns: 4,
          mask: {
            padding: 0,
          },
          interactive: true,
          reuseCellContainer: true,
        },
        header: (this as any).rexUI.add
          .label({
            width: scrollMode === 0 ? 60 : 30,
            height: scrollMode === 0 ? 30 : undefined,
            align: "right",
            space: {
              right: 30,
            },
            orientation: scrollMode,
            text: this.add
              .image(0, 0, "close")
              .setScale(isMobile ? 0.075 : 0.055)
              .setInteractive({ cursor: "pointer" })
              .on("pointerdown", function () {
                escena.sound.play("click");
                if (escena.tableMono1) {
                  escena.tableMono1.visible = false;
                }
                if (escena.tableMono2) {
                  escena.tableMono2.visible = false;
                }
                if (escena.tableMono3) {
                  escena.tableMono3.visible = false;
                }
                if (escena.tableMono4) {
                  escena.tableMono4.visible = false;
                }
              }),
          })
          .setDepth(25),
        footer: getFooter(this, 0, isTrabajando, addCola, cola),
        space: {
          left: 0,
          right: 0,
          top: 20,
          bottom: 10,

          table: { bottom: 30 },
          header: 0,
          footer: 0,
        },
        expand: {
          header: true,
          footer: false,
        },
        createCellContainerCallback: function (cell: any, cellContainer: any) {
          if (cellContainer == null) {
            var scene = cell.scene,
              width = cell.width,
              height = cell.height,
              item = cell.item,
              index = cell.index;
            const posicion = dataMateriales[cell.index].indexLoop;
            const priceObject =
              dataMateriales[cell.index] && dataMateriales[cell.index].precio;
            if (cellContainer === null && index % 2 === 0) {
              const nameObject =
                dataMateriales[cell.index] && dataMateriales[cell.index].objeto;
              cellContainer = scene.rexUI.add
                .label({
                  width: width,
                  height: height,
                  orientation: scrollMode,
                  icon: scene.add
                    .image(0, 0, nameObject)
                    .setScale(
                      scaleObject
                        ? isMobile
                          ? scaleExtra
                            ? scaleExtra
                            : 1
                          : scaleObject
                        : isMobile
                        ? scaleExtra
                          ? scaleExtra
                          : 1
                        : 1
                    ),
                  //.setTint(0x000000)
                  space: {
                    icon: 10,
                    left: 30,
                    top: scrollMode === 0 ? 0 : 15,
                    right: 0,
                  },
                })
                .setDepth(11);
            } else if (cellContainer === null && index % 2 === 1) {
              let dash = scene.rexUI.add
                .label({
                  name: `dash${index}`,
                  text: scene.add.text(0, 0, "-", {
                    color: "#92278F",
                    fontWeight: 700,
                    fontSize: isMobile ? 50 : 30,
                    align: "center",
                    padding: 0,
                    fontFamily: "font1",
                    //marginLeft: 40
                  }),
                })
                .setDepth(11);
              let number = scene.rexUI.add
                .label({
                  name: `number${index}`,
                  text: scene.add.text(0, 0, "00", {
                    color: "#0B2167",
                    fontWeight: 700,
                    fontSize: isMobile ? 35 : 20,
                    align: "center",
                    padding: 0,
                    fontFamily: "font1",
                  }),
                })
                .setDepth(11);
              let plus = scene.rexUI.add
                .label({
                  name: `plus${index}`,
                  text: scene.add.text(0, 0, "+", {
                    color: "#92278F",
                    fontWeight: 700,
                    align: "center",
                    lineSpacing: 0,
                    padding: 0,
                    fontFamily: "font1",
                    fontSize: isMobile ? 50 : 30,
                    //marginRight: 50
                  }),
                })
                .setDepth(11);
              dash.indexLoop = index;
              number.indexLoop = index;
              plus.indexLoop = index;

              scene.rexUI.add
                .click(plus.getElement("text"), { threshold: 10 })
                .on("click", () => {
                  scene.sound.play("click");
                  let tas = itemsPuerta.filter((item: any) => {
                    let numberItem = `number${item.indexLoop}`;
                    if (
                      item.name == numberItem &&
                      item.indexLoop == plus.indexLoop
                    ) {
                      return item;
                    }
                  });
                  let nextNumber = parseInt(tas[0].text) + 1;
                  let numberFinal;
                  if (parseInt(tas[0].text) < 9) {
                    numberFinal = `0${nextNumber}`;
                  } else {
                    numberFinal = nextNumber;
                  }

                  let getCarrito = escena[isTrabajando]
                    ? escena[addCola]
                    : escena[carrito];

                  const isExistData = getCarrito.filter(
                    (item: any, key: any) => {
                      if (item.key === plus.indexLoop) {
                        getCarrito.splice(key, 1);
                      }
                    }
                  );
                  const data = {
                    ...dataMateriales[plus.indexLoop],
                    cantidad: numberFinal,
                    key: plus.indexLoop,
                  };
                  getCarrito.push(data);
                  //
                  console.log(getCarrito);
                  console.log(scene.carrito);
                  tas[0].setText(numberFinal);
                });

              scene.rexUI.add
                .click(dash.getElement("text"), { threshold: 10 })
                .on("click", () => {
                  scene.sound.play("click");
                  let tas = itemsPuerta.filter((item: any) => {
                    let numberItem = `number${item.indexLoop}`;
                    if (
                      item.name === numberItem &&
                      item.indexLoop === dash.indexLoop
                    ) {
                      return item;
                    }
                  });
                  let nextNumber;
                  let numberFinal;
                  if (parseInt(tas[0].text) === 0) {
                    numberFinal = "00";
                  } else {
                    nextNumber = parseInt(tas[0].text) - 1;
                    if (parseInt(tas[0].text) < 9) {
                      numberFinal = `0${nextNumber}`;
                    } else {
                      numberFinal = nextNumber;
                    }
                  }
                  tas[0].setText(numberFinal);

                  let eliminadoCero = false;
                  let getCarrito = escena[isTrabajando]
                    ? escena[addCola]
                    : escena[carrito];

                  getCarrito.forEach((item: any, key: any) => {
                    console.log(item.key, dash.indexLoop, key);
                    if (item.key === dash.indexLoop) {
                      getCarrito.splice(key, 1);
                      if (parseInt(item.cantidad) - 1 === 0) {
                        //getCarrito.splice(key, 1)
                        eliminadoCero = true;
                      }
                      if (parseInt(item.cantidad) === 0) {
                        eliminadoCero = true;
                      }
                    }
                    // return item
                  });
                  if (parseInt(numberFinal as any) === 0) {
                    eliminadoCero = true;
                  }
                  if (!eliminadoCero) {
                    const data = {
                      ...dataMateriales[dash.indexLoop],
                      cantidad: numberFinal,
                      key: dash.indexLoop,
                    };

                    getCarrito.push(data);
                  }
                  console.log(getCarrito);
                  console.log(scene.carrito);
                });

              itemsPuerta.push(dash);
              itemsPuerta.push(number);
              escena.temporalItems.push(number);
              itemsPuerta.push(plus);
              cellContainer = scene.rexUI.add
                .gridSizer({
                  column: 3,
                  columnProportions: 0,
                  row: isMobile ? 1 : 2,
                  rowProportions: 0,
                  space: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                  },
                })
                .add(dash)
                .add(number)
                .add(plus)
                .add(scene.rexUI.add.label({ text: scene.add.text(0, 0, "") }))
                .setDepth(11)
                .add(
                  scene.rexUI.add.label({
                    text: scene.add.text(0, 0, `$${priceObject}`, {
                      fontSize: isMobile ? 25 : 11,
                      color: "#808080",
                      fontFamily: "font1",
                    }),
                  })
                )
                .setDepth(1000);
            }
            cellContainer.setMinSize(width, height);
          }
          return cellContainer;
        },
        items: this.CreateItems(dataMateriales.length),
      })
      .layout();

    itemsPuerta.forEach((label: any) => {
      if (!label) {
        return;
      }
    });

    return gridTable;
  }

  createPanelPiezasCompletas(
    escena: any,
    materiales: any,
    posicionTableX: any,
    posicionTableY: any,
    carrito: any,
    getFooter: any,
    orientacion: any,
    scaleObject: any,
    isTrabajando: any,
    addCola: any,
    cola: any,
    spaceLeft: any,
    alturaMobile: any,
    anchoMobile: any,
    scaleExtra: any,
    spaceLeftM: any
  ) {
    var scrollMode = 0;
    let dataMateriales = materiales;
    let itemsPuerta: any = [];
    var container = escena.add.container();
    let isMobile = escena.isMobile();
    var gridTable = escena.rexUI.add
      .gridTable({
        x: isMobile ? escena.centroCanvas.width : posicionTableX,
        y: isMobile ? escena.centroCanvas.height : posicionTableY,
        width: isMobile ? 220 : 180,
        height: isMobile ? 350 : 260,
        scrollMode: scrollMode,
        background: escena.add.image(0, 0, "fondo_panel").setDepth(9),
        slider: null,
        table: {
          cellWidth: undefined,
          cellHeight: isMobile ? 160 : 105,
          columns: 1,
          mask: {
            padding: 0,
          },
          interactive: true,
          reuseCellContainer: true,
        },
        header: (this as any).rexUI.add
          .label({
            width: scrollMode === 0 ? 60 : 30,
            height: scrollMode === 0 ? 30 : undefined,
            align: "right",
            space: {
              right: isMobile ? 10 : 30,
            },
            orientation: scrollMode,
            text: this.add
              .image(0, 0, "close")
              .setScale(isMobile ? 0.075 : 0.055)
              .setInteractive({ cursor: "pointer" })
              .on("pointerdown", function () {
                escena.sound.play("click");
                if (escena.tableMono1) {
                  escena.tableMono1.visible = false;
                }
                if (escena.tableMono2) {
                  escena.tableMono2.visible = false;
                }
                if (escena.tableMono3) {
                  escena.tableMono3.visible = false;
                }
                if (escena.tableMono4) {
                  escena.tableMono4.visible = false;
                }
              }),
          })
          .setDepth(25),
        footer: getFooter(escena, 0, isTrabajando, addCola, cola, true),
        space: {
          left: 15,
          right: 15,
          top: 20,
          bottom: 14,
          table: { bottom: 0, left: 20, right: 20 },
          header: 0,
          footer: 0,
        },
        expand: {
          header: true,
          footer: false,
        },
        createCellContainerCallback: function (cell: any, cellContainer: any) {
          if (cellContainer == null) {
            var scene = cell.scene,
              width = cell.width,
              height = cell.height,
              item = cell.item,
              index = cell.index;
            const posicion = dataMateriales[cell.index].indexLoop;
            const priceObject =
              dataMateriales[cell.index] && dataMateriales[cell.index].precio;
            if (cellContainer === null && index % 2 === 0) {
              const nameObject =
                dataMateriales[cell.index] && dataMateriales[cell.index].objeto;
              cellContainer = scene.rexUI.add.label({
                width: width,
                height: height,
                orientation: scrollMode,
                icon: scene.add
                  .image(0, 0, nameObject)
                  .setScale(
                    scaleObject
                      ? isMobile
                        ? 0.45
                        : scaleObject
                      : isMobile
                      ? 0.7
                      : 0.6
                  )
                  .setDepth(99),
                space: {
                  icon: 10,
                  left: spaceLeft
                    ? isMobile
                      ? spaceLeftM
                      : spaceLeft
                    : isMobile
                    ? spaceLeftM
                    : 0,
                  top: scrollMode === 0 ? 0 : 15,
                },
              });
            } else if (cellContainer === null && index % 2 === 1) {
              let dash = scene.rexUI.add.label({
                name: `dash${index}`,
                text: scene.add
                  .text(0, 0, "-", {
                    color: "#92278F",
                    fontWeight: 700,
                    fontSize: isMobile ? 35 : 55,
                    align: "center",
                    padding: 0,
                    fontFamily: "font1",
                  })
                  .setDepth(20)
                  .setInteractive({ cursor: "pointer" }),
              });
              let number = scene.rexUI.add.label({
                name: `number${index}`,
                text: scene.add
                  .text(0, 0, "00", {
                    color: "#0B2167",
                    fontWeight: 700,
                    fontSize: isMobile ? 50 : 30,
                    align: "center",
                    padding: 0,
                    fontFamily: "font1",
                  })
                  .setDepth(20)
                  .setInteractive({ cursor: "pointer" }),
              });
              let plus = scene.rexUI.add.label({
                name: `plus${index}`,
                text: scene.add
                  .text(0, 0, "+", {
                    color: "#92278F",
                    fontWeight: 700,
                    align: "center",
                    fontSize: isMobile ? 50 : 40,
                    lineSpacing: 0,
                    padding: 0,
                    fontFamily: "font1",
                  })
                  .setDepth(20)
                  .setInteractive({ cursor: "pointer" }),
              });
              dash.indexLoop = index;
              number.indexLoop = index;
              plus.indexLoop = index;

              scene.rexUI.add
                .click(plus.getElement("text"), { threshold: 10 })
                .on("click", () => {
                  console.log(escena[carrito]);
                  scene.sound.play("click");
                  let tas = itemsPuerta.filter((item: any) => {
                    let numberItem = `number${item.indexLoop}`;
                    if (
                      item.name === numberItem &&
                      item.indexLoop === plus.indexLoop
                    ) {
                      return item;
                    }
                  });
                  let nextNumber = parseInt(tas[0].text) + 1;
                  let numberFinal;
                  if (parseInt(tas[0].text) < 9) {
                    numberFinal = `0${nextNumber}`;
                  } else {
                    numberFinal = nextNumber;
                  }

                  let getCarrito = escena[isTrabajando]
                    ? escena[addCola]
                    : escena[carrito];

                  getCarrito.push(numberFinal);
                  //
                  tas[0].setText(numberFinal);
                });

              scene.rexUI.add
                .click(dash.getElement("text"), { threshold: 10 })
                .on("click", () => {
                  scene.sound.play("click");
                  let tas = itemsPuerta.filter((item: any) => {
                    let numberItem = `number${item.indexLoop}`;
                    if (
                      item.name === numberItem &&
                      item.indexLoop === plus.indexLoop
                    ) {
                      return item;
                    }
                  });
                  let nextNumber;
                  let numberFinal;
                  if (parseInt(tas[0].text) === 0) {
                    numberFinal = "00";
                  } else {
                    nextNumber = parseInt(tas[0].text) - 1;
                    if (parseInt(tas[0].text) < 9) {
                      numberFinal = `0${nextNumber}`;
                    } else {
                      numberFinal = nextNumber;
                    }
                  }
                  tas[0].setText(numberFinal);

                  let eliminadoCero = false;
                  let getCarrito = escena[isTrabajando]
                    ? escena[addCola]
                    : escena[carrito];

                  getCarrito.splice(getCarrito.length - 1, 1);
                });

              itemsPuerta.push(dash);
              itemsPuerta.push(number);
              itemsPuerta.push(plus);
              escena.temporalItems.push(number);
              cellContainer = scene.rexUI.add
                .gridSizer({
                  column: 3,
                  columnProportions: 0,
                  row: 1,
                  rowProportions: 0,
                  space: {
                    left: 12,
                  },
                })
                .add(dash)
                .add(number)
                .add(plus);
            }
            cellContainer.setMinSize(width, height);
          }
          return cellContainer;
        },
        items: escena.CreateItems(dataMateriales.length),
      })
      .layout();

    itemsPuerta.forEach((label: any) => {
      if (!label) {
        return;
      }
    });
    return gridTable;
  }

  CreateItems(count: any) {
    var data = [];
    for (var i = 0; i < count; i++) {
      data.push({
        id: i,
        color: Random(0, 0xffffff),
      });
    }
    return data;
  }

  // footer
  GetFooterSizer(
    scene: any,
    orientation: any,
    isTrabajando: any,
    addCola: any,
    cola: any,
    completo: any,
    extra: any
  ) {
    if (this.tipoEscena !== "mejora") {
      let passed = JSON.parse(
        localStorage.getItem("mejorasRealizadas") as any
      )[16];
      let label = this.add
        .image(0, 0, "boton1")
        .setVisible(0 as any)
        .setScale(0);
      if (passed) {
        let backgroundF = scene.add
          .image(0, 0, "comprar_btn")
          .setScale(0.5)
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", () => {
            (this as any)["carritoPuertoCompleta"].push("01");
            this.botonesComprar["puerta"].emit("pointerdown");
          });
        label = scene.rexUI.add.label({
          //y:100,
          height: undefined,
          width: 40,
          orientation: orientation,
          background: backgroundF,
          text: scene.add
            .text(0, 0, "COMPLETO", {
              fontSize: scene.isMobile() ? 20 : 12,
              fontFamily: "font1",
              padding: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5,
              },
            })
            .setDepth(9999999),
          //icon: scene.add.image(0,0,"boton1").setDepth(99999).setScale(.5),
          align: "center",
          space: {
            icon: 10,
          },
        });
      }
      return scene.rexUI.add
        .sizer({
          orientation: 0,
          space: {
            top: 10,
          },
        })
        .add(
          this.CreateFooterButton(
            scene,
            "COMPRAR",
            orientation,
            isTrabajando,
            addCola,
            cola,
            completo
          ),
          {
            expand: false,
            proportion: 1,
          }
        )
        .add(label);
    } else {
      let passed = JSON.parse(
        localStorage.getItem("mejorasRealizadas") as any
      )[16];
      let label = this.add
        .image(0, 0, "boton1")
        .setVisible(0 as any)
        .setScale(0);
      if (passed) {
        let backgroundF = scene.add
          .image(0, 0, "comprar_btn")
          .setScale(0.5)
          .setInteractive({ cursor: "pointer" });
        label = scene.rexUI.add.label({
          //y:100,
          height: undefined,
          width: 40,
          orientation: orientation,
          background: backgroundF,
          text: scene.add
            .text(0, 0, "COMPLETO", {
              fontSize: scene.isMobile() ? 22 : 15,
              fontFamily: "font1",
              padding: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5,
              },
            })
            .setDepth(9999999),
          //icon: scene.add.image(0,0,"boton1").setDepth(99999).setScale(.5),
          align: "center",
          space: {
            icon: 10,
          },
        });
      }
      return scene.rexUI.add
        .sizer({
          orientation: 0,
          space: {
            top: 10,
          },
        })
        .add(
          this.CreateFooterButton(
            scene,
            "COMPRAR",
            orientation,
            isTrabajando,
            addCola,
            cola,
            completo
          ),
          {
            expand: false,
            proportion: 1,
          }
        )
        .add(label);
    }
  }

  CreateFooterButton(
    scene: any,
    text: any,
    orientation: any,
    isTrabajando: any,
    addCola: any,
    cola: any,
    completo: any
  ) {
    let backgroundColor = text === "COMPRAR" ? 0xe1a330 : 0xff0000;
    let backgroundF = scene.add
      .image(0, 0, "boton1")
      .setScale(0.5)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        // console.log(isTrabajando,addCola, cola)
        this.sound.play("click");
        console.log(this.carritoPuertoCompleta);
        if (this.tipoEs !== "mejora") {
          if ((this as any).carritoPuertoCompleta.length > 0) {
            if ((this as any)[isTrabajando]) {
              (this as any)[addCola] = [
                {
                  tipo: "info",
                  precio: "1",
                  name: "p2",
                  time: 4,
                  timeMejora: 7,
                  cantidad: `0${
                    ((this as any).carritoPuertoCompleta as any).length
                  }`,
                  key: 1,
                },
                {
                  tipo: "info",
                  precio: "1",
                  name: "p2",
                  time: 4,
                  timeMejora: 7,
                  cantidad: `0${
                    ((this as any).carritoPuertoCompleta as any).length
                  }`,
                  key: 1,
                },
                {
                  tipo: "info",
                  precio: "1",
                  name: "p3",
                  time: 4,
                  timeMejora: 7,
                  cantidad: `0${
                    ((this as any).carritoPuertoCompleta as any).length
                  }`,
                  key: 3,
                },
                {
                  tipo: "info",
                  precio: "1",
                  name: "p4",
                  time: 4,
                  timeMejora: 7,
                  cantidad: `0${
                    ((this as any).carritoPuertoCompleta as any).length
                  }`,
                  key: 5,
                },
              ];
            } else {
              this.carrito = [
                {
                  tipo: "info",
                  precio: "1",
                  name: "p2",
                  time: 4,
                  timeMejora: 7,
                  cantidad: `0${
                    ((this as any).carritoPuertoCompleta as any).length
                  }`,
                  key: 1,
                },
                {
                  tipo: "info",
                  precio: "1",
                  name: "p2",
                  time: 4,
                  timeMejora: 7,
                  cantidad: `0${
                    ((this as any).carritoPuertoCompleta as any).length
                  }`,
                  key: 1,
                },
                {
                  tipo: "info",
                  precio: "1",
                  name: "p3",
                  time: 4,
                  timeMejora: 7,
                  cantidad: `0${
                    ((this as any).carritoPuertoCompleta as any).length
                  }`,
                  key: 3,
                },
                {
                  tipo: "info",
                  precio: "1",
                  name: "p4",
                  time: 4,
                  timeMejora: 7,
                  cantidad: `0${
                    ((this as any).carritoPuertoCompleta as any).length
                  }`,
                  key: 5,
                },
              ];
            }
            (this as any).carritoPuertoCompleta = [];
          }
          // console.log(
          //   this.carrito,
          //   "jeje, ghhhhh",
          //   this.carritoPuertoCompleta,
          //   this[isTrabajando]
          // );
          if (text === "COMPRAR") {
            if (!(this as any)[isTrabajando]) {
              if (this.carrito.length > 0) {
                (this as any)[isTrabajando] = true;
                this.tableMono1.visible = true;
                this.add.sprite(190, 140, "relojmono", 0);
                let tiempoTotal = 0;
                let timeTipo = JSON.parse(
                  localStorage.getItem("mejorasRealizadas") as any
                )[15]
                  ? "timeMejora"
                  : "time";
                this.carrito.forEach((element: any) => {
                  tiempoTotal =
                    tiempoTotal + element.cantidad * element[timeTipo];
                });
                let count = 1;
                (this as any).clickCountPuerta = 1;
                (this as any).intervaloClock = setInterval(() => {
                  this.relojMono = this.add.sprite(
                    190,
                    140,
                    "relojmono",
                    count
                  );
                  count++;
                }, (tiempoTotal / 7) * 1000);
                this.crearPiezas(label);
                this.tableMono1.visible = false;
                this.temporalItems.forEach((element: any) => {
                  element.setText("00");
                });
              }
            } else {
              //  let mejoraSeis = JSON.parse(localStorage.getItem("mejorasRealizadas"))[16]
              //  if(this.carrito.length > 0){
              //      if(this[addCola].length > 0){
              //          let cantidadInicial = this[addCola].length
              //          this[addCola]=[]
              //          this[addCola] = [
              //              {
              //                  "tipo": "info",
              //                  "precio": "1",
              //                  "name": "p2",
              //                  "time": 4,
              //                  "timeMejora": 7,
              //                  "cantidad": `0${cantidadInicial}`,
              //                  "key": 1
              //              },
              //              {
              //                  "tipo": "info",
              //                  "precio": "1",
              //                  "name": "p2",
              //                  "time": 4,
              //                  "timeMejora": 7,
              //                  "cantidad": `0${cantidadInicial}`,
              //                  "key": 1
              //              },
              //              {
              //                  "tipo": "info",
              //                  "precio": "1",
              //                  "name": "p3",
              //                  "time": 4,
              //                  "timeMejora": 7,
              //                  "cantidad": `0${cantidadInicial}`,
              //                  "key": 3
              //              },
              //              {
              //                  "tipo": "info",
              //                  "precio": "1",
              //                  "name": "p4",
              //                  "time": 4,
              //                  "timeMejora": 7,
              //                  "cantidad": `0${cantidadInicial}`,
              //                  "key": 5
              //              }
              //          ]
              //          this.carritoPuertoCompleta  = []
              //          this.tableMono1.visible=false
              //          this.temporalItems.forEach(element=>{
              //            element.setText("00")
              //           })
              //          this[cola].push(this[addCola])
              //          this[addCola] = []
              //      }
              //  }else{

              //  }
              this.tableMono1.visible = false;
              this.temporalItems.forEach((element: any) => {
                element.setText("00");
              });
              (this as any)[cola].push((this as any)[addCola]);
              (this as any)[addCola] = [];
            }
          } else {
            if (this.tableMono1) {
              this.tableMono1.visible = false;
            }
          }
        }
      });
    scene.botonesComprar["puerta"] = backgroundF;
    let label = scene.rexUI.add.label({
      //y:100,
      height: orientation === 0 ? 0 : undefined,
      width: orientation === 0 ? undefined : 40,
      orientation: orientation,
      background: backgroundF,
      text: scene.add
        .text(0, 0, text, {
          fontSize: scene.isMobile() ? 20 : 12,
          fontFamily: "font1",
          padding: {
            left: 5,
            right: 5,
            top: 5,
            bottom: 5,
          },
        })
        .setDepth(9999999),
      //icon: scene.add.image(0,0,"boton1").setDepth(99999).setScale(.5),
      align: "center",
      space: {
        icon: 10,
      },
    });
    return label;
  }

  //techo footer
  GetFooterTecho(
    scene: any,
    orientation: any,
    isTrabajando: any,
    addCola: any,
    cola: any
  ) {
    if (this.tipoEscena !== "mejora") {
      let passed = JSON.parse(
        localStorage.getItem("mejorasRealizadas") as any
      )[16];
      let label = this.add
        .image(0, 0, "boton1")
        .setVisible(0 as any)
        .setScale(0.5);
      if (passed) {
        let backgroundF = scene.add
          .image(0, 0, "comprar_btn")
          .setScale(0.5)
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", () => {
            this["carritoTechoCompleta"].push("01");
            this.botonesComprar["techo"].emit("pointerdown");
          });
        label = scene.rexUI.add.label({
          //y:100,
          height: undefined,
          width: 40,
          orientation: orientation,
          background: backgroundF,
          text: scene.add
            .text(0, 0, "COMPLETO", {
              fontSize: scene.isMobile() ? 22 : 15,
              fontFamily: "font1",
              padding: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5,
              },
            })
            .setDepth(9999999),
          //icon: scene.add.image(0,0,"boton1").setDepth(99999).setScale(.5),
          align: "center",
          space: {
            icon: 10,
          },
        });
      }
      return scene.rexUI.add
        .sizer({
          orientation: 0,
          space: {
            top: 10,
          },
        })
        .add(
          this.CreateFooterTecho(
            scene,
            "COMPRAR",
            orientation,
            isTrabajando,
            addCola,
            cola
          ),
          {
            expand: false,
            proportion: 1,
          }
        )
        .add(label);
    } else {
      let passed = JSON.parse(
        localStorage.getItem("mejorasRealizadas") as any
      )[16];
      let label = this.add
        .image(0, 0, "boton1")
        .setVisible(0 as any)
        .setScale(0.5);
      if (passed) {
        let backgroundF = scene.add
          .image(0, 0, "comprar_btn")
          .setScale(0.5)
          .setInteractive({ cursor: "pointer" });
        label = scene.rexUI.add.label({
          //y:100,
          height: undefined,
          width: 40,
          orientation: orientation,
          background: backgroundF,
          text: scene.add
            .text(0, 0, "COMPLETO", {
              fontSize: scene.isMobile() ? 22 : 15,
              fontFamily: "font1",
              padding: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5,
              },
            })
            .setDepth(9999999),
          //icon: scene.add.image(0,0,"boton1").setDepth(99999).setScale(.5),
          align: "center",
          space: {
            icon: 10,
          },
        });
      }
      return scene.rexUI.add
        .sizer({
          orientation: 0,
          space: {
            top: 10,
          },
        })
        .add(
          this.CreateFooterTecho(
            scene,
            "COMPRAR",
            orientation,
            isTrabajando,
            addCola,
            cola
          ),
          {
            expand: false,
            proportion: 1,
          }
        )
        .add(label);
    }
  }

  CreateFooterTecho(
    scene: any,
    text: any,
    orientation: any,
    isTrabajando: any,
    addCola: any,
    cola: any
  ) {
    let backgroundColor = text === "COMPRAR" ? 0xe1a330 : 0xff0000;
    let trabajando = (this as any)[isTrabajando];
    let backgroundF = scene.add
      .image(0, 0, "boton1")
      .setScale(0.5)
      .setDepth(9999)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.sound.play("click");
        if (this.tipoEs !== "mejora") {
          if (this.carritoTechoCompleta.length > 0) {
            if (
              JSON.parse(localStorage.getItem("mejorasRealizadas") as any)[17]
            ) {
              if ((this as any)[isTrabajando]) {
                (this as any)[addCola] = [
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t1",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "2",
                    name: "t2n",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 3,
                  },
                  {
                    tipo: "info",
                    precio: "2",
                    name: "t2n",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 3,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t4",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 7,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t3",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 5,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t5",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 9,
                  },
                ];
              } else {
                this.carritoTecho = [
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t1",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "2",
                    name: "t2n",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 3,
                  },
                  {
                    tipo: "info",
                    precio: "2",
                    name: "t2n",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 3,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t4",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 7,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t3",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 5,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t5",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 9,
                  },
                ];
              }
            } else {
              if ((this as any)[isTrabajando]) {
                (this as any)[addCola] = [
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t1",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t2",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 3,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t2",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 3,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t2",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 3,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t2",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 3,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t4",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 7,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t3",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 5,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t5",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 9,
                  },
                ];
              } else {
                this.carritoTecho = [
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t1",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t2",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 3,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t2",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 3,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t2",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 3,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t2",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 3,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t4",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 7,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t3",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 5,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "t5",
                    time: 3,
                    timeMejora: 4,
                    cantidad: `0${this.carritoTechoCompleta.length}`,
                    key: 9,
                  },
                ];
              }
            }
            this.carritoTechoCompleta = [];
          }

          if (text === "COMPRAR") {
            if (!(this as any)[isTrabajando]) {
              if (this.carritoTecho.length > 0) {
                (this as any)[isTrabajando] = true;
                this.tableMono3.visible = true;
                this.add.sprite(190, 570, "relojmono", 0);
                let tiempoTotal = 0;
                let timeTipo = JSON.parse(
                  localStorage.getItem("mejorasRealizadas") as any
                )[15]
                  ? "timeMejora"
                  : "time";
                this.carritoTecho.forEach((element: any) => {
                  tiempoTotal =
                    tiempoTotal + element.cantidad * element[timeTipo];
                });
                let count = 1;
                this.clickCountTecho = 1;
                this.intervaloClockTecho = setInterval(() => {
                  this.relojTecho = this.add.sprite(
                    190,
                    570,
                    "relojmono",
                    count
                  );
                  count++;
                }, (tiempoTotal / 7) * 1000);
                this.crearPiezasTecho(label);
                if (this.tableMono3) {
                  this.tableMono3.visible = false;
                }
                if (this.tableMono31) {
                  this.tableMono31.visible = false;
                }
                this.temporalItems.forEach((element: any) => {
                  element.setText("00");
                });
              }
            } else {
              (this as any)[cola].push((this as any)[addCola]);
              (this as any)[addCola] = [];
              if (this.tableMono3) {
                this.tableMono3.visible = false;
              }
              if (this.tableMono31) {
                this.tableMono31.visible = false;
              }
              this.temporalItems.forEach((element: any) => {
                element.setText("00");
              });
            }
          } else {
            if (this.tableMono3) {
              this.tableMono3.visible = false;
            }
            if (this.tableMono31) {
              this.tableMono31.visible = false;
            }
          }
        }
      });
    scene.botonesComprar["techo"] = backgroundF;
    let label = scene.rexUI.add.label({
      //y:100,
      orientation: orientation,
      background: backgroundF,
      text: scene.add
        .text(0, 0, text, {
          fontSize: scene.isMobile() ? 22 : 15,
          fontFamily: "font1",
          padding: {
            left: 5,
            right: 5,
            top: 5,
            bottom: 5,
          },
          fontWeight: 700, //width:30
        })
        .setDepth(9999),
      // icon: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 10, COLOR_LIGHT),
      align: "center",
      space: {
        icon: 10,
      },
    });
    return label;
  }

  /// pared footer
  GetFooterPared(
    scene: any,
    orientation: any,
    isTrabajando: any,
    addCola: any,
    cola: any
  ) {
    if (this.tipoEscena !== "mejora") {
      let passed = JSON.parse(
        localStorage.getItem("mejorasRealizadas") as any
      )[16];
      let label = this.add
        .image(0, 0, "boton1")
        .setVisible(0 as any)
        .setScale(0);
      if (passed) {
        let backgroundF = scene.add
          .image(0, 0, "comprar_btn")
          .setScale(0.5)
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", () => {
            this["carritoMuroCompleta"].push("01");
            this.botonesComprar["pared"].emit("pointerdown");
          });
        label = scene.rexUI.add.label({
          //y:100,
          height: undefined,
          width: 40,
          orientation: orientation,
          background: backgroundF,
          text: scene.add
            .text(0, 0, "COMPLETO", {
              fontSize: scene.isMobile() ? 22 : 15,
              fontFamily: "font1",
              padding: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5,
              },
            })
            .setDepth(9999999),
          //icon: scene.add.image(0,0,"boton1").setDepth(99999).setScale(.5),
          align: "center",
          space: {
            icon: 10,
          },
        });
      }
      return scene.rexUI.add
        .sizer({
          orientation: 0,
          space: {
            top: 10,
          },
        })
        .add(
          this.CreateFooterPared(
            scene,
            "COMPRAR",
            orientation,
            isTrabajando,
            addCola,
            cola
          ),
          {
            expand: false,
            proportion: 1,
          }
        )
        .add(label);
    } else {
      let passed = JSON.parse(
        localStorage.getItem("mejorasRealizadas") as any
      )[16];
      let label = this.add
        .image(0, 0, "boton1")
        .setVisible(0 as any)
        .setScale(0);
      if (passed) {
        let backgroundF = scene.add
          .image(0, 0, "comprar_btn")
          .setScale(0.5)
          .setInteractive({ cursor: "pointer" });
        label = scene.rexUI.add.label({
          //y:100,
          height: undefined,
          width: 40,
          orientation: orientation,
          background: backgroundF,
          text: scene.add
            .text(0, 0, "COMPLETO", {
              fontSize: scene.isMobile() ? 22 : 15,
              fontFamily: "font1",
              padding: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5,
              },
            })
            .setDepth(9999999),
          //icon: scene.add.image(0,0,"boton1").setDepth(99999).setScale(.5),
          align: "center",
          space: {
            icon: 10,
          },
        });
      }
      return scene.rexUI.add
        .sizer({
          orientation: 0,
          space: {
            top: 10,
          },
        })
        .add(
          this.CreateFooterPared(
            scene,
            "COMPRAR",
            orientation,
            isTrabajando,
            addCola,
            cola
          ),
          {
            expand: false,
            proportion: 1,
          }
        )
        .add(label);
    }
  }

  CreateFooterPared(
    scene: any,
    text: any,
    orientation: any,
    isTrabajando: any,
    addCola: any,
    cola: any
  ) {
    let backgroundColor = text === "Comprar" ? 0xe1a330 : 0xff0000;
    let backgroundF = this.add
      .image(0, 0, "boton1")
      .setScale(0.5)
      .setDepth(9999)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.sound.play("click");
        if (this.tipoEs !== "mejora") {
          if (this.carritoMuroCompleta.length > 0) {
            if (
              JSON.parse(localStorage.getItem("mejorasRealizadas") as any)[17]
            ) {
              if ((this as any)[isTrabajando]) {
                (this as any)[addCola] = [
                  {
                    tipo: "info",
                    precio: "2",
                    name: "pa2n",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "2",
                    name: "pa2n",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "2",
                    name: "pa2n",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "3",
                    name: "pa8n",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "3",
                    name: "pa8n",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    indexLoop: 1,
                    name: "pa1",
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 3,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa7",
                    indexLoop: 7,
                    timeMejora: 3,
                    time: 7,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 15,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa7",
                    indexLoop: 7,
                    timeMejora: 3,
                    time: 7,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 15,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    indexLoop: 3,
                    name: "pa3",
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 7,
                  },
                ];
              } else {
                this.carritoPared = [
                  {
                    tipo: "info",
                    precio: "2",
                    name: "pa2n",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "2",
                    name: "pa2n",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "2",
                    name: "pa2n",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "3",
                    name: "pa8n",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "3",
                    name: "pa8n",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    indexLoop: 1,
                    name: "pa1",
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 3,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa7",
                    indexLoop: 7,
                    timeMejora: 3,
                    time: 7,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 15,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa7",
                    indexLoop: 7,
                    timeMejora: 3,
                    time: 7,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 15,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    indexLoop: 3,
                    name: "pa3",
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 7,
                  },
                ];
              }
            } else {
              if ((this as any)[isTrabajando]) {
                (this as any)[addCola] = [
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa0",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa0",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa0",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa0",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa0",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    indexLoop: 1,
                    name: "pa1",
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 3,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    indexLoop: 2,
                    name: "pa2",
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 5,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    indexLoop: 2,
                    name: "pa2",
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 5,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    indexLoop: 3,
                    name: "pa3",
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 7,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    indexLoop: 3,
                    name: "pa3",
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 7,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa4",
                    indexLoop: 4,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 9,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa5",
                    indexLoop: 5,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 11,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa6",
                    indexLoop: 6,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 13,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa7",
                    indexLoop: 7,
                    timeMejora: 3,
                    time: 7,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 15,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa7",
                    indexLoop: 7,
                    timeMejora: 3,
                    time: 7,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 15,
                  },
                  {
                    tipo: "info",
                    indexLoop: 8,
                    precio: "1",
                    name: "pa8",
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 17,
                  },
                ];
              } else {
                this.carritoPared = [
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa0",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa0",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa0",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa0",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa0",
                    indexLoop: 0,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 1,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    indexLoop: 1,
                    name: "pa1",
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 3,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    indexLoop: 2,
                    name: "pa2",
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 5,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    indexLoop: 2,
                    name: "pa2",
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 5,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    indexLoop: 3,
                    name: "pa3",
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 7,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    indexLoop: 3,
                    name: "pa3",
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 7,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa4",
                    indexLoop: 4,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 9,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa5",
                    indexLoop: 5,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 11,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa6",
                    indexLoop: 6,
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 13,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa7",
                    indexLoop: 7,
                    timeMejora: 3,
                    time: 7,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 15,
                  },
                  {
                    tipo: "info",
                    precio: "1",
                    name: "pa7",
                    indexLoop: 7,
                    timeMejora: 3,
                    time: 7,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 15,
                  },
                  {
                    tipo: "info",
                    indexLoop: 8,
                    precio: "1",
                    name: "pa8",
                    time: 7,
                    timeMejora: 3,
                    cantidad: `0${this.carritoMuroCompleta.length}`,
                    key: 17,
                  },
                ];
              }
            }
            this.carritoMuroCompleta = [];
          }

          if (text === "COMPRAR") {
            if (!(this as any)[isTrabajando]) {
              if (this.carritoPared.length > 0) {
                (this as any)[isTrabajando] = true;
                if (this.tableMono2) {
                  this.tableMono2.visible = false;
                }
                if (this.tableMono21) {
                  this.tableMono21.visible = false;
                }
                if (this.tableMono22) {
                  this.tableMono22.visible = false;
                }
                this.add.sprite(
                  this.extremoCanvas.width - 170,
                  140,
                  "relojmono",
                  0
                );
                let tiempoTotal = 0;
                let timeTipo = JSON.parse(
                  localStorage.getItem("mejorasRealizadas") as any
                )[15]
                  ? "timeMejora"
                  : "time";
                this.carritoPared.forEach((element: any) => {
                  tiempoTotal =
                    tiempoTotal + element.cantidad * element[timeTipo];
                });
                let count = 1;
                this.clickCountPared = 1;
                this.intervaloClockPared = setInterval(() => {
                  this.relojPared = this.add.sprite(
                    this.extremoCanvas.width - 170,
                    140,
                    "relojmono",
                    count
                  );
                  count++;
                }, (tiempoTotal / 7) * 1000);
                this.crearPiezasPared(label);
                if (this.tableMono2) {
                  this.tableMono2.visible = false;
                }
                if (this.tableMono21) {
                  this.tableMono21.visible = false;
                }
                if (this.tableMono22) {
                  this.tableMono22.visible = false;
                }

                this.temporalItems.forEach((element: any) => {
                  element.setText("00");
                });
              }
            } else {
              this.temporalItems.forEach((element: any) => {
                element.setText("00");
              });
              if (this.tableMono2) {
                this.tableMono2.visible = false;
              }
              if (this.tableMono21) {
                this.tableMono21.visible = false;
              }
              if (this.tableMono22) {
                this.tableMono22.visible = false;
              }
              (this as any)[cola].push((this as any)[addCola]);
              (this as any)[addCola] = [];
            }
          }
        }
      });
    scene.botonesComprar["pared"] = backgroundF;
    let label = scene.rexUI.add
      .label({
        //y:100,
        orientation: orientation,
        background: backgroundF,
        text: scene.add.text(0, 0, text, {
          fontSize: scene.isMobile() ? 22 : 15,
          fontFamily: "font1",
          padding: {
            left: 5,
            right: 5,
            top: 5,
            bottom: 5,
          },
          fontWeight: 700, //width:30
        }),
        // icon: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 10, COLOR_LIGHT),
        align: "center",
        space: {
          icon: 10,
        },
      })
      .setDepth(1000);

    return label;
  }

  // ventana foooter
  GetFooterVentana(
    scene: any,
    orientation: any,
    isTrabajando: any,
    addCola: any,
    cola: any
  ) {
    if (this.tipoEscena !== "mejora") {
      let passed = JSON.parse(
        localStorage.getItem("mejorasRealizadas") as any
      )[16];
      let label = this.add
        .image(0, 0, "boton1")
        .setVisible(0 as any)
        .setScale(0);
      if (passed) {
        let backgroundF = scene.add
          .image(0, 0, "comprar_btn")
          .setScale(0.5)
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", () => {
            this["carritoVentanaCompleta"].push("01");
            this.botonesComprar["ventana"].emit("pointerdown");
          });
        label = scene.rexUI.add.label({
          //y:100,
          height: undefined,
          width: 40,
          orientation: orientation,
          background: backgroundF,
          text: scene.add
            .text(0, 0, "COMPLETO", {
              fontSize: scene.isMobile() ? 20 : 12,
              fontFamily: "font1",
              padding: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5,
              },
            })
            .setDepth(9999999),
          //icon: scene.add.image(0,0,"boton1").setDepth(99999).setScale(.5),
          align: "center",
          space: {
            icon: 10,
          },
        });
      }

      return scene.rexUI.add
        .sizer({
          orientation: 0,
          space: {
            top: 10,
          },
        })
        .add(
          this.CreateFooterVentana(
            scene,
            "COMPRAR",
            orientation,
            isTrabajando,
            addCola,
            cola
          ),
          {
            expand: false,
            proportion: 1,
          }
        )
        .add(label);
    } else {
      let passed = JSON.parse(
        localStorage.getItem("mejorasRealizadas") as any
      )[16];
      let label = this.add
        .image(0, 0, "boton1")
        .setVisible(0 as any)
        .setScale(0);
      if (passed) {
        let backgroundF = scene.add
          .image(0, 0, "comprar_btn")
          .setScale(0.5)
          .setInteractive({ cursor: "pointer" });
        label = scene.rexUI.add.label({
          //y:100,
          height: undefined,
          width: 40,
          orientation: orientation,
          background: backgroundF,
          text: scene.add
            .text(0, 0, "COMPLETO", {
              fontSize: scene.isMobile() ? 22 : 15,
              fontFamily: "font1",
              padding: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5,
              },
            })
            .setDepth(9999999),
          //icon: scene.add.image(0,0,"boton1").setDepth(99999).setScale(.5),
          align: "center",
          space: {
            icon: 10,
          },
        });
      }
      return scene.rexUI.add
        .sizer({
          orientation: 0,
          space: {
            top: 10,
          },
        })
        .add(
          this.CreateFooterVentana(
            scene,
            "COMPRAR",
            orientation,
            isTrabajando,
            addCola,
            cola
          ),
          {
            expand: false,
            proportion: 1,
          }
        )
        .add(label);
    }
  }

  CreateFooterVentana(
    scene: any,
    text: any,
    orientation: any,
    isTrabajando: any,
    addCola: any,
    cola: any
  ) {
    let backgroundColor = text === "COMPRAR" ? 0xe1a330 : 0xff0000;
    let backgroundF = this.add
      .image(0, 0, "boton1")
      .setScale(0.5)
      .setDepth(99999)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        console.log(this.tipoEs);
        if (this.tipoEs !== "mejora") {
          this.sound.play("click");
          if (this.carritoVentanaCompleta.length > 0) {
            if (!(this as any)[isTrabajando]) {
              this.carritoVentana = [
                {
                  tipo: "info",
                  precio: "1",
                  name: "v1",
                  time: 2,
                  timeMejora: 5,
                  cantidad: `0${this.carritoVentanaCompleta.length}`,
                  key: 1,
                },
                {
                  tipo: "info",
                  precio: "1",
                  name: "v1",
                  time: 2,
                  timeMejora: 5,
                  cantidad: `0${this.carritoVentanaCompleta.length}`,
                  key: 1,
                },
                {
                  tipo: "info",
                  precio: "1",
                  name: "v2",
                  time: 2,
                  timeMejora: 5,
                  cantidad: `0${this.carritoVentanaCompleta.length}`,
                  key: 3,
                },
                {
                  tipo: "info",
                  precio: "1",
                  name: "v3",
                  time: 2,
                  timeMejora: 5,
                  cantidad: `0${this.carritoVentanaCompleta.length}`,
                  key: 5,
                },
              ];
              this.carritoVentanaCompleta = [];
            } else {
              (this as any)[addCola] = [
                {
                  tipo: "info",
                  precio: "1",
                  name: "v1",
                  time: 2,
                  timeMejora: 5,
                  cantidad: `0${this.carritoVentanaCompleta.length}`,
                  key: 1,
                },
                {
                  tipo: "info",
                  precio: "1",
                  name: "v1",
                  time: 2,
                  timeMejora: 5,
                  cantidad: `0${this.carritoVentanaCompleta.length}`,
                  key: 1,
                },
                {
                  tipo: "info",
                  precio: "1",
                  name: "v2",
                  time: 2,
                  timeMejora: 5,
                  cantidad: `0${this.carritoVentanaCompleta.length}`,
                  key: 3,
                },
                {
                  tipo: "info",
                  precio: "1",
                  name: "v3",
                  time: 2,
                  timeMejora: 5,
                  cantidad: `0${this.carritoVentanaCompleta.length}`,
                  key: 5,
                },
              ];
              this.carritoVentanaCompleta = [];
            }
          }
          if (text === "COMPRAR") {
            // console.log(this[isTrabajando], addCola, cola);
            if (!(this as any)[isTrabajando]) {
              //clearInterval(this.intervaloClockVentana)
              if (this.carritoVentana.length > 0) {
                (this as any)[isTrabajando] = true;
                //this.tableMono4.visible=true
                this.add.sprite(
                  this.extremoCanvas.width - 170,
                  570,
                  "relojmono",
                  0
                );
                let tiempoTotal = 0;
                let timeTipo = JSON.parse(
                  localStorage.getItem("mejorasRealizadas") as any
                )[15]
                  ? "timeMejora"
                  : "time";
                this.carritoVentana.forEach((element: any) => {
                  tiempoTotal =
                    tiempoTotal + element.cantidad * element[timeTipo];
                });
                let count = 1;
                this.clickCountVentana = 1;
                this.intervaloClockVentana = setInterval(() => {
                  this.relojMonoVentana = this.add.sprite(
                    this.extremoCanvas.width - 170,
                    570,
                    "relojmono",
                    count
                  );
                  count++;
                }, (tiempoTotal / 7) * 1000);
                this.crearPiezasVentana(label);
                this.tableMono4.visible = false;
                this.temporalItems.forEach((element: any) => {
                  element.setText("00");
                });
              }
            } else {
              this.tableMono4.visible = false;
              this.temporalItems.forEach((element: any) => {
                element.setText("00");
              });
              (this as any)[cola].push((this as any)[addCola]);
              console.log((this as any)[cola]);
              (this as any)[addCola] = [];
            }
          } else {
            if (this.tableMono4) {
              this.tableMono4.visible = false;
            }
          }
        }
      });

    scene.botonesComprar["ventana"] = backgroundF;

    let label = scene.rexUI.add
      .label({
        //y:100,
        height: orientation === 0 ? 0 : undefined,
        width: orientation === 0 ? undefined : 40,
        orientation: orientation,
        background: backgroundF,
        text: scene.add.text(0, 0, text, {
          fontSize: scene.isMobile() ? 20 : 12,
          fontFamily: "font1",
          padding: {
            left: 5,
            right: 5,
            top: 5,
            bottom: 5,
          },
        }),
        // icon: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 10, COLOR_LIGHT),
        align: "center",
        space: {
          icon: 10,
        },
      })
      .setDepth(11);
    return label;
  }

  funcionCrearPiezasGeneral(
    musica: any,
    carrito: any,
    pizasObject: any,
    tipo: any,
    tipoStock: any,
    relojName: any,
    relojX: any,
    relojY: any,
    trabajoCart: any,
    intervalObject: any,
    tiemposCarro: any,
    colaCarro: any,
    label: any,
    posicionMejora: any,
    carritoCompleto: any
  ) {
    // console.log(this.carritoTecho)
    (this as any)[musica] = this.sound.add("clock");
    (this as any)[musica].play();
    (this as any)[musica].setLoop(true);
    let timeTipo = JSON.parse(
      localStorage.getItem("mejorasRealizadas") as any
    )[15]
      ? "timeMejora"
      : "time";
    for (let index = 0; index < (this as any)[carrito].length; index++) {
      //console.log(this[carrito][index])
      let nameObject = (this as any)[carrito][index].name;
      let count = 1;
      let timeForActual =
        (this as any)[carrito][index === 0 ? 0 : index - 1][timeTipo] *
        (this as any)[carrito][index === 0 ? 0 : index - 1].cantidad *
        1000 *
        index;
      let nextTime;
      let cantidad = parseInt((this as any)[carrito][index].cantidad);
      console.log(index, "index cart");
      if (index === 0) {
        //console.log("entrejejej")
        this.allTimout.push(
          setTimeout(() => {
            for (let indexChild = 1; indexChild <= cantidad; indexChild++) {
              let tiempoTimeout =
                indexChild === 1
                  ? 100
                  : (this as any)[carrito][index][timeTipo] *
                    1000 *
                    (indexChild - 1);
              this.allTimout.push(
                setTimeout(() => {
                  let mejoraPosicion = JSON.parse(
                    localStorage.getItem("mejorasRealizadas") as any
                  )[14];
                  let posicionJson = mejoraPosicion
                    ? posicionMejora
                    : Posiciones;
                  //console.log(posicionJson)

                  const position = this.generarAleatorio(
                    0,
                    posicionJson.length - 1,
                    posicionJson
                  );
                  let descuento = parseInt(
                    (this as any)[carrito][index].precio
                  );
                  let dineroBase = parseFloat(
                    localStorage.getItem("dinero") as any
                  );
                  let totalDescuento: any = dineroBase - descuento;
                  this.dineroBase.setText(totalDescuento);
                  localStorage.setItem("dinero", totalDescuento);

                  (this as any)[pizasObject](
                    position.x,
                    position.y,
                    nameObject,
                    false,
                    null,
                    (this as any)[carrito][index].precio
                  );

                  //  let objetosUsadosLocal = JSON.parse(localStorage.getItem("objetosUsados"))
                  this.objetosUsados[tipo][nameObject] =
                    parseInt(this.objetosUsados[tipo][nameObject]) + 1;
                  localStorage.setItem(
                    "objetosUsados",
                    JSON.stringify(this.objetosUsados)
                  );

                  localStorage.setItem(
                    "piezasTotalUsadas",
                    (parseInt(
                      localStorage.getItem("piezasTotalUsadas") as any
                    ) + 1) as any
                  );

                  this.piezasTotalUsadas = this.piezasTotalUsadas + 1;
                  this.gastosPorEtapa =
                    this.gastosPorEtapa +
                    parseInt((this as any)[carrito][index].cantidad);

                  this.piezasStock[tipoStock][nameObject] =
                    this.piezasStock[tipoStock][nameObject] + 1;
                  localStorage.setItem(
                    "piezasStock",
                    JSON.stringify(this.piezasStock)
                  );

                  const infoPiezasPorNivel = {
                    objetosUsados: JSON.stringify(this.objetosUsados),
                    piezasTotalUsadas: (parseInt(
                      localStorage.getItem("piezasTotalUsadas") as any
                    ) + 1) as any,
                    piezasStock: JSON.stringify(this.piezasStock),
                  };
                  funcionActualizarDatosFB({
                    dinero: totalDescuento,
                    ["infoPiezasPorNivel" + this.etapaD]: infoPiezasPorNivel,
                  });

                  if (
                    index === (this as any)[carrito].length - 1 &&
                    indexChild === cantidad
                  ) {
                    (this as any)[relojName] = this.add.sprite(
                      relojX,
                      relojY,
                      "relojmono",
                      0
                    );
                    (this as any)[musica].stop();
                    (this as any)[trabajoCart] = false;
                    clearInterval((this as any)[intervalObject]);
                    (this as any)[carrito] = [];
                    (this as any)[carritoCompleto] = [];
                    this.limpiarPosicione();
                    (this as any)[tiemposCarro] = [];
                    if ((this as any)[colaCarro].length > 0) {
                      (this as any)[carrito] = (this as any)[colaCarro][0];
                      (this as any)[colaCarro].splice(0, 1);
                      console.log((this as any)[carrito], "cart");
                      (this as any).botonesComprar[label].emit("pointerdown");
                    }
                  }
                }, tiempoTimeout)
              );
            }
          }, (this as any)[carrito][index][timeTipo] * 1000)
        );
      } else {
        nextTime =
          (this as any)[carrito][index - 1][timeTipo] *
          1000 *
          parseInt((this as any)[carrito][index - 1].cantidad);
        (this as any)[tiemposCarro].push(nextTime);
        let nextTimeFinal = (this as any)[tiemposCarro].reduce(
          (a: any, b: any) => a + b,
          0
        );
        this.allTimout.push(
          setTimeout(() => {
            for (
              let indexChildElse = 1;
              indexChildElse <= cantidad;
              indexChildElse++
            ) {
              if (indexChildElse === 1) {
                this.allTimout.push(
                  setTimeout(() => {
                    let mejoraPosicion = JSON.parse(
                      localStorage.getItem("mejorasRealizadas") as any
                    )[14];
                    let posicionJson = mejoraPosicion
                      ? posicionMejora
                      : Posiciones;
                    const position = this.generarAleatorio(
                      0,
                      posicionJson.length - 1,
                      posicionJson
                    );

                    let descuento = parseInt(
                      (this as any)[carrito][index].precio
                    );
                    let dineroBase = parseFloat(
                      localStorage.getItem("dinero") as any
                    );
                    let totalDescuento: any = dineroBase - descuento;
                    this.dineroBase.setText(totalDescuento);
                    localStorage.setItem("dinero", totalDescuento);

                    (this as any)[pizasObject](
                      position.x,
                      position.y,
                      (this as any)[carrito][index].name as any,
                      false,
                      null,
                      (this as any)[carrito][index].precio as any
                    );

                    this.objetosUsados[tipo][nameObject] =
                      parseInt(this.objetosUsados[tipo][nameObject]) + 1;
                    localStorage.setItem(
                      "objetosUsados",
                      JSON.stringify(this.objetosUsados)
                    );

                    localStorage.setItem(
                      "piezasTotalUsadas",
                      (parseInt(
                        localStorage.getItem("piezasTotalUsadas") as any
                      ) + 1) as any
                    );

                    this.piezasTotalUsadas = this.piezasTotalUsadas + 1;
                    this.gastosPorEtapa =
                      this.gastosPorEtapa +
                      parseInt((this as any)[carrito][index].cantidad);

                    this.piezasStock[tipoStock][nameObject] =
                      this.piezasStock[tipoStock][nameObject] + 1;
                    localStorage.setItem(
                      "piezasStock",
                      JSON.stringify(this.piezasStock)
                    );

                    const infoPiezasPorNivel = {
                      objetosUsados: JSON.stringify(this.objetosUsados),
                      piezasTotalUsadas: (parseInt(
                        localStorage.getItem("piezasTotalUsadas") as any
                      ) + 1) as any,
                      piezasStock: JSON.stringify(this.piezasStock),
                    };
                    funcionActualizarDatosFB({
                      dinero: totalDescuento,
                      ["infoPiezasPorNivel" + this.etapaD]: infoPiezasPorNivel,
                    });

                    if (
                      index === (this as any)[carrito].length - 1 &&
                      indexChildElse === cantidad
                    ) {
                      (this as any)[relojName] = this.add.sprite(
                        relojX,
                        relojY,
                        "relojmono",
                        0
                      );
                      (this as any)[musica].stop();
                      (this as any)[trabajoCart] = false;
                      clearInterval((this as any)[intervalObject]);
                      (this as any)[carrito] = [];
                      (this as any)[carritoCompleto] = [];
                      (this as any).limpiarPosicione();
                      (this as any)[tiemposCarro] = [];
                      if ((this as any)[colaCarro].length > 0) {
                        clearInterval((this as any)[intervalObject]);
                        (this as any)[carrito] = (this as any)[colaCarro][0];
                        (this as any)[colaCarro].splice(0, 1);
                        console.log((this as any)[carrito], "cart");
                        (this as any).botonesComprar[label].emit("pointerdown");
                      }
                    }
                  }, (this as any)[carrito][index][timeTipo] * 1000)
                );
              } else {
                let tiempoTimeout =
                  (this as any)[carrito][index][timeTipo] *
                  1000 *
                  indexChildElse;
                this.allTimout.push(
                  setTimeout(() => {
                    let mejoraPosicion = JSON.parse(
                      localStorage.getItem("mejorasRealizadas") as any
                    )[14];
                    let posicionJson = mejoraPosicion
                      ? posicionMejora
                      : Posiciones;
                    console.log(posicionJson);
                    const position = this.generarAleatorio(
                      0,
                      posicionJson.length - 1,
                      posicionJson
                    );

                    let descuento = parseInt(
                      (this as any)[carrito][index].precio
                    );
                    let dineroBase = parseFloat(
                      localStorage.getItem("dinero") as any
                    );
                    let totalDescuento: any = dineroBase - descuento;
                    this.dineroBase.setText(totalDescuento);
                    localStorage.setItem("dinero", totalDescuento);
                    funcionActualizarDatosFB({ dinero: totalDescuento });

                    (this as any)[pizasObject](
                      position.x,
                      position.y,
                      (this as any)[carrito][index].name,
                      false,
                      null,
                      (this as any)[carrito][index].precio
                    );

                    this.objetosUsados[tipo][nameObject] =
                      parseInt(this.objetosUsados[tipo][nameObject]) + 1;
                    localStorage.setItem(
                      "objetosUsados",
                      JSON.stringify(this.objetosUsados)
                    );

                    localStorage.setItem(
                      "piezasTotalUsadas",
                      (parseInt(
                        localStorage.getItem("piezasTotalUsadas") as any
                      ) + 1) as any
                    );

                    this.piezasTotalUsadas = this.piezasTotalUsadas + 1;
                    this.gastosPorEtapa =
                      this.gastosPorEtapa +
                      parseInt((this as any)[carrito][index].cantidad);
                    this.piezasStock[tipoStock][nameObject] =
                      this.piezasStock[tipoStock][nameObject] + 1;
                    localStorage.setItem(
                      "piezasStock",
                      JSON.stringify(this.piezasStock)
                    );

                    const infoPiezasPorNivel = {
                      objetosUsados: JSON.stringify(this.objetosUsados),
                      piezasTotalUsadas: (parseInt(
                        localStorage.getItem("piezasTotalUsadas") as any
                      ) + 1) as any,
                      piezasStock: JSON.stringify(this.piezasStock),
                    };
                    funcionActualizarDatosFB({
                      dinero: totalDescuento,
                      ["infoPiezasPorNivel" + this.etapaD]: infoPiezasPorNivel,
                    });

                    if (
                      index === (this as any)[carrito].length - 1 &&
                      indexChildElse === cantidad
                    ) {
                      (this as any)[relojName] = this.add.sprite(
                        relojX,
                        relojY,
                        "relojmono",
                        0
                      );
                      (this as any)[musica].stop();
                      (this as any)[trabajoCart] = false;
                      clearInterval((this as any)[intervalObject]);
                      (this as any)[carrito] = [];
                      (this as any)[carritoCompleto] = [];
                      this.limpiarPosicione();
                      (this as any)[tiemposCarro] = [];
                      if ((this as any)[colaCarro].length > 0) {
                        (this as any)[carrito] = (this as any)[colaCarro][0];
                        (this as any)[colaCarro].splice(0, 1);
                        console.log((this as any)[carrito], "cart");
                        (this as any).botonesComprar[label].emit("pointerdown");
                      }
                    }
                  }, tiempoTimeout)
                );
              }
            }
          }, nextTimeFinal)
        );
      }
    }
  }

  //pieza
  crearPiezas(label: any) {
    this.funcionCrearPiezasGeneral(
      "musicaP",
      "carrito",
      "piezasPuertas",
      "puerta",
      "puerta",
      "relojMono",
      190,
      140,
      "trabajandoPuerta",
      "intervaloClock",
      "tiemposCarritoPuerta",
      "colaPuerta",
      "puerta",
      PosicionPuerta,
      "carritoPuertoCompleta"
    );
  }
  piezasPuertas(
    x: any,
    y: any,
    name: any,
    reactive: any,
    key: any,
    price: any
  ) {
    let code: string;
    console.log(name, "jeje");
    switch (name) {
      case "p2":
        this.p1 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.p1.myArray = [
          [1, 1],
          [1, null],
        ];
        this.p1.tipo = "puerta";
        this.p1.name = "p2";
        this.p1.price = price;
        this.p1.posicionTipo = 1;
        this.input.setDraggable([this.p1]);
        this.p1.posicionAdecuada = [149, 181];
        this.p1.setDepth(3);
        code = generateUUID();
        console.log(code);
        this.p1.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "p2",
            tipo: "puerta",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "p3":
        this.p3 = this.add
          .image(x, y, "p3")
          .setInteractive({ cursor: "pointer" });
        this.p3.myArray = [
          [1, 1, 1],
          [null, null, 1],
        ];
        this.p3.hideX = true;
        this.p3.tipo = "puerta";
        this.p3.price = price;
        this.p3.posicionTipo = 0;
        this.p3.name = "p3";
        this.input.setDraggable([this.p3]);
        this.p3.posicionAdecuada = [133];
        this.p3.setDepth(3);
        code = generateUUID();
        console.log(code);
        this.p3.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "p3",
            tipo: "puerta",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "p4":
        this.p4 = this.add
          .image(x, y, "p4")
          .setInteractive({ cursor: "pointer" });
        this.p4.myArray = [
          [1, 1],
          [null, 1],
          [1, 1],
        ];
        this.p4.hideY = true;
        this.p4.tipo = "puerta";
        this.p4.price = price;
        this.p4.name = "p4";
        this.p4.posicionTipo = 2;
        this.input.setDraggable([this.p4]);
        this.p4.posicionAdecuada = [166];
        this.p4.setDepth(3);
        code = generateUUID();
        console.log(code);
        this.p4.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "p4",
            tipo: "puerta",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
    }
  }
  // techo
  crearPiezasTecho(label: any) {
    this.funcionCrearPiezasGeneral(
      "musicT",
      "carritoTecho",
      "piezasTecho",
      "techo",
      "techo",
      "relojTecho",
      190,
      570,
      "trabajoTecho",
      "intervaloClockTecho",
      "tiemposCarritosTecho",
      "colaTecho",
      "techo",
      PosicionTecho,
      "carritoMuroCompleta"
    );
  }

  piezasTecho(x: any, y: any, name: any, reactive: any, key: any, price: any) {
    switch (name) {
      case "t1":
        this.t1 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.t1.myArray = [
          [null, 1],
          [1, 1],
          [1, 1],
          [1, 1],
        ];
        this.t1.tipo = "techo";
        this.t1.hideX = false;
        this.t1.hideY = false;
        this.t1.especial = true;
        this.t1.price = price;
        this.t1.techoEspecial = true;
        this.t1.posicionTipo = 1;
        this.t1.name = "t1";
        this.t1.posicionAdecuada = [51];
        this.input.setDraggable([this.t1]);
        this.t1.setDepth(3);
        var code = generateUUID();
        this.t1.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "t1",
            tipo: "techo",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "t2":
        this.t2 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.t2.myArray = [
          [1, 1],
          [1, 1],
        ];
        this.t2.hideX = false;
        this.t2.hideY = false;
        this.t2.price = price;
        this.t2.tipo = "techo";
        this.t2.name = "t2";
        this.t2.posicionAdecuada = [53, 55, 57, 59];
        this.t2.posicionTipo = 0;
        this.input.setDraggable([this.t2]);
        this.t2.setDepth(3);
        var code = generateUUID();
        this.t2.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "t2",
            tipo: "techo",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "t3":
        this.t3 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.t3.myArray = [
          [1, 1, 1, 1],
          [1, 1, 1, null],
        ];
        this.t3.hideY = false;
        this.t3.hideX = false;
        this.t3.price = price;
        this.t3.especialX3 = true;
        this.t3.tipo = "techo";
        this.t3.posicionAdecuada = [85];
        this.t3.posicionTipo = 2;
        this.t3.name = "t3";
        this.input.setDraggable([this.t3]);
        this.t3.setDepth(3);
        var code = generateUUID();
        this.t3.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "t3",
            tipo: "techo",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "t4":
        this.t4 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.t4.myArray = [
          [1, 1, 1, 1],
          [null, 1, 1, 1],
        ];
        this.t4.hideY = false;
        this.t4.hideX = false;
        this.t4.price = price;
        this.t4.especialX3 = true;
        this.t4.tipo = "techo";
        this.t4.posicionAdecuada = [89];
        this.t4.posicionTipo = 3;
        this.t4.name = "t4";
        this.input.setDraggable([this.t4]);
        this.t4.setDepth(3);
        var code = generateUUID();
        this.t4.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "t4",
            tipo: "techo",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "t5":
        this.t5 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.t5.myArray = [
          [1, 1],
          [1, 1],
          [1, 1],
          [1, 1],
        ];
        this.t5.tipo = "techo";
        this.t5.hideX = false;
        this.t5.price = price;
        this.t5.hideY = false;
        this.t5.techoEspecial = true;
        this.t5.posicionAdecuada = [61];
        this.t5.posicionTipo = 4;
        this.t5.name = "t5";
        this.input.setDraggable([this.t5]);
        this.t5.setDepth(3);
        var code = generateUUID();
        this.t5.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "t5",
            tipo: "techo",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "t2n":
        this.t2n = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.t2n.myArray = [
          [1, 1, 1, 1],
          [1, 1, 1, 1],
        ];
        this.t2n.hideX = false;
        this.t2n.hideY = false;
        this.t2n.price = price;
        this.t2n.tipo = "techo";
        this.t2n.name = "t2n";
        this.t2n.posicionAdecuada = [53, 57];
        this.t2n.posicionTipo = 0;
        this.input.setDraggable([this.t2n]);
        this.t2n.setDepth(3);
        var code = generateUUID();
        this.t2n.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "t2n",
            tipo: "techo",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
    }
  }
  //ventana
  crearPiezasVentana(label: any) {
    this.funcionCrearPiezasGeneral(
      "musicV",
      "carritoVentana",
      "piezasVentana",
      "ventana",
      "ventana",
      "relojVentana",
      this.extremoCanvas.width - 170,
      570,
      "trabajandoVentana",
      "intervaloClockVentana",
      "tiemposCarritoVentana",
      "colaVentana",
      "ventana",
      PosicionVentana,
      "carritoVentanaCompleta"
    );
  }
  piezasVentana(
    x: any,
    y: any,
    name: any,
    reactive: any,
    key: any,
    price: any
  ) {
    switch (name) {
      case "v1":
        this.v1 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.v1.myArray = [[1], [1], [1]];
        this.v1.tipo = "ventana";
        this.v1.hideY = false;
        this.v1.hideX = true;
        this.v1.price = price;
        this.v1.especialX = true;
        this.v1.posicionTipo = 0;
        this.v1.name = "v1";
        this.v1.posicionAdecuada = [138, 140];
        this.v1.setDepth(3);
        this.input.setDraggable([this.v1]);
        var code = generateUUID();
        this.v1.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "v1",
            tipo: "ventana",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "v2":
        this.v2 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.v2.myArray = [[1]];
        this.v2.hideY = true;
        this.v2.hideX = true;
        this.v2.price = price;
        this.v2.tipo = "ventana";
        this.v2.especialX = true;
        this.v2.especialY = true;
        this.v2.name = "v2";
        this.v2.posicionAdecuada = [139];
        this.v2.posicionTipo = 0;
        this.input.setDraggable([this.v2]);
        this.v2.setDepth(3);
        var code = generateUUID();
        this.v2.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "v2",
            tipo: "ventana",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "v3":
        this.v3 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.v3.myArray = [[1], [1]];
        this.v3.hideY = true;
        this.v3.price = price;
        this.v3.hideX = true;
        this.v3.especialX = true;
        this.v3.tipo = "ventana";
        this.v3.name = "v3";
        this.v3.posicionAdecuada = [155];
        this.v3.posicionTipo = 2;
        this.v3.setDepth(3);
        this.input.setDraggable([this.v3]);
        var code = generateUUID();
        this.v3.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "v3",
            tipo: "ventana",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
    }
  }
  //pared
  crearPiezasPared(label: any) {
    this.funcionCrearPiezasGeneral(
      "musicPa",
      "carritoPared",
      "piezasPared",
      "pared",
      "pared",
      "relojPared",
      this.extremoCanvas.width - 170,
      140,
      "trabajoPared",
      "intervaloClockPared",
      "tiemposCarritosPared",
      "colaPared",
      "pared",
      PosicionPared,
      "carritoMuroCompleta"
    );
  }
  piezasPared(x: any, y: any, name: any, reactive: any, key: any, price: any) {
    switch (name) {
      case "pa0":
        this.pa1 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.pa1.myArray = [[1]];
        this.pa1.tipo = "pared";
        this.pa1.hideX = false;
        this.pa1.limpio = true;
        this.pa1.hideY = false;
        this.pa1.price = price;
        this.pa1.name = "pa1";
        this.pa1.posicionAdecuada = [148, 168, 186, 173, 125];
        this.pa1.posicionTipo = 0;
        this.input.setDraggable([this.pa1]);
        this.pa1.setDepth(3);
        var code = generateUUID();
        this.pa1.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "pa0",
            tipo: "pared",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "pa1":
        this.pa2 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.pa2.myArray = [
          [null, 1, 1, null],
          [1, 1, 1, 1],
        ];
        this.pa2.hideX = false;
        this.pa2.price = price;
        this.pa2.especial = false;
        this.pa2.triangulo = true;
        this.pa2.posicionTipo = 1;
        this.pa2.name = "pa2";
        this.pa2.posicionAdecuada = [89];
        this.pa2.tipo = "pared";
        this.pa2.setDepth(3);
        this.input.setDraggable([this.pa2]);
        var code = generateUUID();
        this.pa2.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "pa1",
            tipo: "pared",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "pa2":
        this.pa3 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.pa3.myArray = [[1], [1], [1]];
        this.pa3.hideY = true;
        this.pa3.espHide = true;
        this.pa3.price = price;
        this.pa3.tipo = "pared";
        this.pa3.hideX = false;
        this.pa3.limpio = true;
        this.pa3.name = "pa3";
        this.pa3.setDepth(3);
        this.pa3.posicionAdecuada = [164, 120];
        this.pa3.posicionTipo = 2;
        this.input.setDraggable([this.pa3]);
        var code = generateUUID();
        this.pa3.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "pa2",
            tipo: "pared",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "pa3":
        this.pa4 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.pa4.myArray = [[1], [1]];
        this.pa4.hideY = true;
        this.pa4.hideX = false;
        this.pa4.price = price;
        this.pa4.setDepth(3);
        this.pa4.limpio = true;
        this.pa4.tipo = "pared";
        this.pa4.name = "pa4";
        this.pa4.posicionAdecuada = [153, 141];
        this.pa4.posicionTipo = 3;
        this.input.setDraggable([this.pa4]);
        var code = generateUUID();
        this.pa4.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "pa3",
            tipo: "pared",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "pa4":
        this.pa5 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.pa5.myArray = [[1, 1, 1]];
        this.pa5.hideY = false;
        this.pa5.hideX = false;
        this.pa5.limpio = true;
        this.pa5.setDepth(3);
        this.pa5.price = price;
        this.pa5.menosx = true;

        this.pa5.name = "pa5";
        this.pa5.posicionAdecuada = [201];
        this.pa5.tipo = "pared";
        this.pa5.posicionTipo = 4;
        this.input.setDraggable([this.pa5]);
        var code = generateUUID();
        this.pa5.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "pa4",
            tipo: "pared",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "pa5":
        this.pa6 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.pa6.myArray = [[1, 1]];
        this.pa6.hideY = false;
        this.pa6.hideX = true;
        this.pa6.menosx = true;
        this.pa6.price = price;
        this.pa6.limpio = true;
        this.pa6.name = "pa6";
        this.pa6.posicionAdecuada = [187];
        this.pa6.tipo = "pared";
        this.pa6.setDepth(3);
        this.pa6.posicionTipo = 5;
        this.input.setDraggable([this.pa6]);
        var code = generateUUID();
        this.pa6.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "pa5",
            tipo: "pared",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "pa6":
        this.pa7 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.pa7.myArray = [
          [1, 1],
          [1, null],
        ];
        this.pa7.hideY = true;
        this.pa7.tipo = "pared";
        this.pa7.price = price;
        this.pa7.name = "pa7";
        this.pa7.setDepth(3);
        this.pa7.posicionAdecuada = [184];
        this.pa7.posicionTipo = 6;
        this.input.setDraggable([this.pa7]);
        var code = generateUUID();
        this.pa7.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "pa6",
            tipo: "pared",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "pa7":
        this.pa8 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.pa8.myArray = [
          [1, 1, 1, 1],
          [1, null, null, null],
        ];
        this.pa8.hideY = true;
        this.pa8.hideX = true;
        this.pa8.price = price;
        this.pa8.especialX = true;
        this.pa8.tipo = "pared";
        this.pa8.setDepth(3);
        this.pa8.name = "pa8";
        this.pa8.posicionAdecuada = [116, 121];
        this.pa8.posicionTipo = 7;
        this.input.setDraggable([this.pa8]);
        var code = generateUUID();
        this.pa8.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "pa7",
            tipo: "pared",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "pa8":
        this.pa9 = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.pa9.myArray = [
          [null, 1],
          [1, 1],
        ];
        this.pa9.hideY = true;
        this.pa9.price = price;
        this.pa9.tipo = "pared";
        this.pa9.setDepth(3);
        this.pa9.name = "pa9";
        this.pa9.posicionAdecuada = [188];
        this.pa9.posicionTipo = 8;
        this.input.setDraggable([this.pa9]);
        var code = generateUUID();
        this.pa9.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "pa8",
            tipo: "pared",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "pa2n":
        this.pa2n = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.pa2n.myArray = [[1], [1], [1], [1]];
        this.pa2n.hideY = true;
        this.pa2n.espHide = true;
        this.pa2n.price = price;
        this.pa2n.tipo = "pared";
        this.pa2n.hideX = false;
        this.pa2n.limpio = true;
        this.pa2n.name = "pa2n";
        this.pa2n.setDepth(3);
        this.pa2n.posicionAdecuada = [148, 120, 125];
        this.pa2n.posicionTipo = 2;
        this.input.setDraggable([this.pa2n]);
        var code = generateUUID();
        this.pa2n.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "pa2n",
            tipo: "pared",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
      case "pa8n":
        this.pa8n = this.add
          .image(x, y, name)
          .setInteractive({ cursor: "pointer" });
        this.pa8n.myArray = [
          [1, 1, 1],
          [1, 1, 1],
        ];
        this.pa8n.hideY = true;
        this.pa8n.espHide = true;
        this.pa8n.price = price;
        this.pa8n.tipo = "pared";
        this.pa8n.hideX = false;
        this.pa8n.limpio = true;
        this.pa8n.name = "pa8n";
        this.pa8n.setDepth(3);
        this.pa8n.posicionAdecuada = [184, 187];
        this.pa8n.posicionTipo = 2;
        this.input.setDraggable([this.pa8n]);
        var code = generateUUID();
        this.pa8n.uniqueKey = reactive ? key : code;
        if (reactive !== true) {
          this.piezasCompradas[code] = {
            x,
            y,
            name: "pa8n",
            tipo: "pared",
            key: code,
            dropzone: false,
            price: price,
          };
          let piezasCompradasLocal = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify({ ...piezasCompradasLocal, ...this.piezasCompradas })
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify({
              ...piezasCompradasLocal,
              ...this.piezasCompradas,
            }),
          });
        }
        break;
    }
  }

  verificarPiezasFaltantes(index: any) {
    this.Scrent1 = this.add.image(387, 340, "t1").setDepth(5).setAlpha(0.7);
    this.Scrent2 = this.add.image(437, 315, "t2").setDepth(5).setAlpha(0.7);
    this.Scrent21 = this.add.image(487, 315, "t2").setDepth(5).setAlpha(0.7);
    this.Scrent22 = this.add.image(537, 315, "t2").setDepth(5).setAlpha(0.7);
    this.Scrent23 = this.add.image(587, 315, "t2").setDepth(5).setAlpha(0.7);
    this.Scrent31 = this.add.image(462, 365, "t3").setDepth(5).setAlpha(0.7);
    this.Scrent4 = this.add.image(563, 365, "t4").setDepth(5).setAlpha(0.7);
    this.Scrent5 = this.add.image(637, 340, "t5").setDepth(5).setAlpha(0.7);

    this.Screnpa1 = this.add.image(512, 365, "pa1").setDepth(5).setAlpha(0.7);

    this.Screnpa2 = this.add.image(400, 503, "pa2").setDepth(5).setAlpha(0.7);
    this.Screnpa21 = this.add.image(500, 428, "pa2").setDepth(5).setAlpha(0.7);

    this.Screnpa3 = this.add.image(526, 465, "pa3").setDepth(5).setAlpha(0.7);
    this.Screnpa31 = this.add.image(625, 439, "pa3").setDepth(5).setAlpha(0.7);

    this.Screnpa4 = this.add.image(550, 528, "pa4").setDepth(5).setAlpha(0.7);

    this.Screnpa5 = this.add.image(587, 502, "pa5").setDepth(5).setAlpha(0.7);

    this.Screnpa6 = this.add.image(512, 515, "pa6").setDepth(5).setAlpha(0.7);

    this.Screnpa7 = this.add.image(437, 416, "pa7").setDepth(5).setAlpha(0.7);
    this.Screnpa71 = this.add.image(563, 416, "pa7").setDepth(5).setAlpha(0.7);

    this.Screnpa8 = this.add.image(612, 515, "pa8").setDepth(5).setAlpha(0.7);

    this.Screnpa0 = this.add.image(625, 478, "pa0").setDepth(5).setAlpha(0.7);
    this.Screnpa01 = this.add.image(625, 403, "pa0").setDepth(5).setAlpha(0.7);
    this.Screnpa02 = this.add.image(550, 502, "pa0").setDepth(5).setAlpha(0.7);
    this.Screnpa03 = this.add.image(500, 478, "pa0").setDepth(5).setAlpha(0.7);
    this.Screnpa04 = this.add.image(400, 452, "pa0").setDepth(5).setAlpha(0.7);

    this.Screnpa222 = this.add.image(437, 465, "p2").setDepth(5).setAlpha(0.7);
    this.Screnpa2221 = this.add.image(437, 515, "p2").setDepth(5).setAlpha(0.7);
    this.Screnp3 = this.add.image(449, 440, "p3").setDepth(5).setAlpha(0.7);
    this.Screnp4 = this.add.image(462, 502, "p4").setDepth(5).setAlpha(0.7);

    this.Screnv1 = this.add.image(550, 452, "v1").setDepth(5).setAlpha(0.7);
    this.Screnv11 = this.add.image(600, 452, "v1").setDepth(5).setAlpha(0.7);
    this.Screnv2 = this.add.image(575, 427, "v2").setDepth(5).setAlpha(0.7);
    this.Screnv3 = this.add.image(575, 465, "v3").setDepth(5).setAlpha(0.7);

    this.piezasShowImageError.push(
      this.Scrent1,
      this.Scrent2,
      this.Scrent21,
      this.Scrent22,
      this.Scrent23,
      this.Scrent31,
      this.Scrent4,
      this.Scrent5,
      this.Screnpa1,
      this.Screnpa2,
      this.Screnpa21,
      this.Screnpa3,
      this.Screnpa31,
      this.Screnpa4,
      this.Screnpa5,
      this.Screnpa6,
      this.Screnpa7,
      this.Screnpa71,
      this.Screnpa8,
      this.Screnpa0,
      this.Screnpa01,
      this.Screnpa02,
      this.Screnpa03,
      this.Screnpa04,
      this.Screnpa222,
      this.Screnpa2221,
      this.Screnp3,
      this.Screnp4,
      this.Screnv1,
      this.Screnv11,
      this.Screnv2,
      this.Screnv3
    );

    //////////// TECHO ////////////////////////
    if (Object.keys(this.posicionesEstablecidas)[index] === "t1") {
      if (!this.errt1) {
        this.errt1 = this.add
          .image(
            this.allDropzones[51].x + 12.5,
            this.allDropzones[51].y + 25 + 12.5,
            "t1"
          )
          .setTint(0x0000ff)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.7);
        this.piezasShowImageError.push(this.errt1);
      }
    }

    if (Object.keys(this.posicionesEstablecidas)[index] === "t5") {
      if (!this.errt5) {
        this.errt5 = this.add
          .image(
            this.allDropzones[61].x + 12.5,
            this.allDropzones[61].y + 25 + 12.5,
            "t5"
          )
          .setTint(0x0000ff)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.7);
        this.piezasShowImageError.push(this.errt5);
      }
    }

    if (Object.keys(this.posicionesEstablecidas)[index] === "t3") {
      if (!this.errt3) {
        this.errt3 = this.add
          .image(
            this.allDropzones[85].x + 25 + 12.5,
            this.allDropzones[85].y + 12.5,
            "t3"
          )
          .setTint(0x0000ff)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.7);
        this.piezasShowImageError.push(this.errt3);
      }
    }

    if (Object.keys(this.posicionesEstablecidas)[index] === "t2") {
      if (!this.errt2) {
        this.errt2 = this.add
          .image(
            this.allDropzones[53].x + 12.5,
            this.allDropzones[53].y + 12.5,
            "t2"
          )
          .setTint(0x0000ff)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.7);
        this.piezasShowImageError.push(this.errt2);
      }
    }

    if (Object.keys(this.posicionesEstablecidas)[index] === "t8") {
      if (!this.errt8) {
        this.errt8 = this.add
          .image(
            this.allDropzones[59].x + 12.5,
            this.allDropzones[59].y + 12.5,
            "t2"
          )
          .setTint(0x0000ff)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.7);
        this.piezasShowImageError.push(this.errt8);
      }
    }
    //53 55 57 59
    if (Object.keys(this.posicionesEstablecidas)[index] === "t6") {
      if (!this.errt6) {
        this.errt6 = this.add
          .image(
            this.allDropzones[55].x + 12.5,
            this.allDropzones[55].y + 12.5,
            "t2"
          )
          .setTint(0x0000ff)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.7);
        this.piezasShowImageError.push(this.errt6);
      }
    }

    if (Object.keys(this.posicionesEstablecidas)[index] === "t7") {
      if (!this.errt7) {
        this.errt7 = this.add
          .image(
            this.allDropzones[57].x + 12.5,
            this.allDropzones[57].y + 12.5,
            "t2"
          )
          .setTint(0x0000ff)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.7);
        this.piezasShowImageError.push(this.errt7);
      }
    }

    if (Object.keys(this.posicionesEstablecidas)[index] === "t4") {
      if (!this.errt4) {
        this.errt4 = this.add
          .image(
            this.allDropzones[89].x + 25 + 12.5,
            this.allDropzones[89].y + 12.5,
            "t4"
          )
          .setTint(0x0000ff)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.7);
        this.piezasShowImageError.push(this.errt4);
      }
    }

    /////////////////// PUERTA ///////////////////////////
    if (Object.keys(this.posicionesEstablecidas)[index] === "p1") {
      if (!this.errp1) {
        this.errp1 = this.add
          .image(
            this.allDropzones[149].x + 12.5,
            this.allDropzones[149].y + 12.5,
            "p2"
          )
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errp1);
      }
    }
    if (Object.keys(this.posicionesEstablecidas)[index] === "p2") {
      if (!this.errp2) {
        this.errp2 = this.add
          .image(
            this.allDropzones[181].x + 12.5,
            this.allDropzones[181].y + 12.5,
            "p2"
          )
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errp2);
      }
    }
    if (Object.keys(this.posicionesEstablecidas)[index] === "p3") {
      if (!this.errp3) {
        this.errp3 = this.add
          .image(
            this.allDropzones[133].x + 25,
            this.allDropzones[133].y + 12.5,
            "p3"
          )
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errp3);
      }
    }
    if (Object.keys(this.posicionesEstablecidas)[index] === "p4") {
      if (!this.errp4) {
        this.errp4 = this.add
          .image(
            this.allDropzones[166].x + 12.5,
            this.allDropzones[166].y + 25,
            "p4"
          )
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errp4);
      }
    }
    ////////////////// VENTANAA ////////////////////////
    if (Object.keys(this.posicionesEstablecidas)[index] === "v1") {
      if (!this.errv1) {
        this.errv1 = this.add
          .image(this.allDropzones[138].x, this.allDropzones[138].y + 25, "v1")
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errv1);
      }
    }

    if (Object.keys(this.posicionesEstablecidas)[index] == "v4") {
      if (!this.errv4) {
        this.errv4 = this.add
          .image(this.allDropzones[140].x, this.allDropzones[140].y + 25, "v1")
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errv4);
      }
    }

    if (Object.keys(this.posicionesEstablecidas)[index] === "v3") {
      if (!this.errv3) {
        this.errv3 = this.add
          .image(
            this.allDropzones[155].x,
            this.allDropzones[155].y + 12.5,
            "v3"
          )
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errv3);
      }
    }

    if (Object.keys(this.posicionesEstablecidas)[index] === "v2") {
      if (!this.errv2) {
        this.errv2 = this.add
          .image(this.allDropzones[139].x, this.allDropzones[139].y, "v2")
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errv2);
      }
    }

    ////////////////////// PARED //////////////////////////
    // 148 168 186 173 125
    // pa1 pa10 pa11 pa12 pa13
    if (Object.keys(this.posicionesEstablecidas)[index] === "pa1") {
      if (!this.errpa1) {
        this.errpa1 = this.add
          .image(this.allDropzones[148].x, this.allDropzones[148].y, "pa0")
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errpa1);
      }
    }
    if (Object.keys(this.posicionesEstablecidas)[index] === "pa10") {
      if (!this.errpa10) {
        this.errpa10 = this.add
          .image(this.allDropzones[168].x, this.allDropzones[168].y, "pa0")
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errpa10);
      }
    }
    if (Object.keys(this.posicionesEstablecidas)[index] === "pa11") {
      if (!this.errpa11) {
        this.errpa11 = this.add
          .image(this.allDropzones[186].x, this.allDropzones[186].y, "pa0")
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errpa11);
      }
    }
    if (Object.keys(this.posicionesEstablecidas)[index] === "pa12") {
      if (!this.errpa12) {
        this.errpa12 = this.add
          .image(this.allDropzones[173].x, this.allDropzones[173].y, "pa0")
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errpa12);
      }
    }
    if (Object.keys(this.posicionesEstablecidas)[index] === "pa13") {
      if (!this.errpa13) {
        this.errpa13 = this.add
          .image(this.allDropzones[125].x, this.allDropzones[125].y, "pa0")
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errpa13);
      }
    }

    // triangulo
    if (Object.keys(this.posicionesEstablecidas)[index] == "pa2") {
      if (!this.errpa2) {
        this.errpa2 = this.add
          .image(512, 365, "pa1")
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errpa2);
      }
    }
    // 164 120
    if (Object.keys(this.posicionesEstablecidas)[index] == "pa3") {
      if (!this.errpa3) {
        this.errpa3 = this.add
          .image(this.allDropzones[164].x, this.allDropzones[164].y + 25, "pa2")
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errpa3);
      }
    }
    if (Object.keys(this.posicionesEstablecidas)[index] == "pa14") {
      if (!this.errpa14) {
        this.errpa14 = this.add
          .image(this.allDropzones[120].x, this.allDropzones[120].y + 25, "pa2")
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errpa14);
      }
    }
    // 153 141
    if (Object.keys(this.posicionesEstablecidas)[index] === "pa4") {
      if (!this.errpa4) {
        this.errpa4 = this.add
          .image(
            this.allDropzones[153].x,
            this.allDropzones[153].y + 12.5,
            "pa3"
          )
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errpa4);
      }
    }
    if (Object.keys(this.posicionesEstablecidas)[index] === "pa15") {
      if (!this.errpa15) {
        this.errpa15 = this.add
          .image(
            this.allDropzones[141].x,
            this.allDropzones[141].y + 12.5,
            "pa3"
          )
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errpa15);
      }
    }
    if (Object.keys(this.posicionesEstablecidas)[index] === "pa5") {
      if (!this.errpa5) {
        this.errpa5 = this.add
          .image(this.allDropzones[201].x + 25, this.allDropzones[201].y, "pa4")
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errpa5);
      }
    }
    if (Object.keys(this.posicionesEstablecidas)[index] === "pa6") {
      if (!this.errpa6) {
        this.errpa6 = this.add
          .image(
            this.allDropzones[187].x + 12.5,
            this.allDropzones[187].y,
            "pa5"
          )
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errpa6);
      }
    }
    if (Object.keys(this.posicionesEstablecidas)[index] === "pa7") {
      if (!this.errpa7) {
        this.errpa7 = this.add
          .image(
            this.allDropzones[184].x + 12.5,
            this.allDropzones[184].y + 12.5,
            "pa6"
          )
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errpa7);
      }
    }
    // 116 121
    if (Object.keys(this.posicionesEstablecidas)[index] === "pa8") {
      if (!this.errpa8) {
        this.errpa8 = this.add
          .image(
            this.allDropzones[116].x + 25 + 12.5,
            this.allDropzones[116].y + 12.5,
            "pa7"
          )
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errpa8);
      }
    }
    if (Object.keys(this.posicionesEstablecidas)[index] === "pa16") {
      if (!this.errpa16) {
        this.errpa16 = this.add
          .image(
            this.allDropzones[121].x + 25 + 12.5,
            this.allDropzones[121].y + 12.5,
            "pa7"
          )
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errpa16);
      }
    }
    if (Object.keys(this.posicionesEstablecidas)[index] === "pa9") {
      if (!this.errpa9) {
        this.errpa9 = this.add
          .image(
            this.allDropzones[188].x + 12.5,
            this.allDropzones[188].y + 12.5,
            "pa8"
          )
          .setTint(0xff0000)
          .setDepth(this.depthActual + 10)
          .setAlpha(0.5);
        this.piezasShowImageError.push(this.errpa9);
      }
    }
  }

  verificarCasaConstruida() {
    if (this.tableMono1) {
      this.tableMono1.visible = false;
    }
    if (this.tableMono2) {
      this.tableMono2.visible = false;
    }
    if (this.tableMono3) {
      this.tableMono3.visible = false;
    }
    if (this.tableMono4) {
      this.tableMono4.visible = false;
    }

    Object.values(this.allDropzones).forEach((element: any) => {
      element.graphics.setDepth(1).setAlpha(1);
    });
    let casasRealizadasFin = this.casasRealizadas;
    if (Object.values(this.posicionesEstablecidas).includes(false)) {
      this.casasRealizadas.malas = this.casasRealizadas.malas + 1;
      //funcionActualizarDatosFB({['casasRealizadas'+this.etapaD]: this.casasRealizadas})
      //this.textTotalCasa.setText(`${this.casasRealizadas.malas}/5`)
      var textureManager = this.textures;
      Object.values(this.posicionesEstablecidas).forEach((element, index) => {
        if (!element) {
          this.imagensParaPantallazo = this.verificarPiezasFaltantes(index);
        }
      });

      Object.values(this.objetosDropzone).forEach(
        (element: any, index: any) => {
          let precio = parseInt(element.price);
          let devolución: any = precio * 0.7;
          this.dineroDevolucion = this.dineroDevolucion + devolución;

          this.dineroBase.setText(
            `${(
              parseFloat(localStorage.getItem("dinero") as any) +
              parseFloat(devolución as any)
            ).toFixed()}`
          );
          localStorage.setItem(
            "dinero",
            (
              parseFloat(localStorage.getItem("dinero") as any) +
              parseFloat(devolución as any)
            ).toFixed()
          );
          if (Object.values(this.objetosDropzone).length - 1 === index) {
            funcionActualizarDatosFB({
              dinero: parseInt(localStorage.getItem("dinero") as any),
            });
          }
        }
      );

      this.game.renderer.snapshotArea(
        this.centroCanvas.width - 200,
        this.centroCanvas.height - 170,
        400,
        400,
        (image) => {
          let code = generateUUID();
          textureManager.addImage(code, image as any);
          this.casasMalasCapturas.push(code);
          this.piezasShowImageError.forEach((element: any) => {
            element.destroy();
          });
          this.errp1 = false;
          this.errp2 = false;
          this.errp3 = false;
          this.errp4 = false;
          this.errt1 = false;
          this.errt2 = false;
          this.errt3 = false;
          this.errt4 = false;
          this.errt5 = false;
          this.errt6 = false;
          this.errt7 = false;
          this.errt8 = false;
          this.errv1 = false;
          this.errv2 = false;
          this.errv3 = false;
          this.errv4 = false;
          this.errpa1 = false;
          this.errpa2 = false;
          this.errpa3 = false;
          this.errpa4 = false;
          this.errpa5 = false;
          this.errpa6 = false;
          this.errpa7 = false;
          this.errpa8 = false;
          this.errpa9 = false;
          this.errpa10 = false;
          this.errpa11 = false;
          this.errpa12 = false;
          this.errpa13 = false;
          this.errpa14 = false;
          this.errpa15 = false;
          this.errpa16 = false;

          this.Scrent1 = false;
          this.Scrent2 = false;
          this.Scrent21 = false;
          this.Scrent22 = false;
          this.Scrent23 = false;
          this.Scrent31 = false;
          this.Scrent4 = false;
          this.Scrent5 = false;
          this.Screnpa1 = false;
          this.Screnpa2 = false;
          this.Screnpa21 = false;
          this.Screnpa3 = false;
          this.Screnpa31 = false;
          this.Screnpa4 = false;
          this.Screnpa5 = false;
          this.Screnpa6 = false;
          this.Screnpa7 = false;
          this.Screnpa71 = false;
          this.Screnpa8 = false;
          this.Screnpa0 = false;
          this.Screnpa01 = false;
          this.Screnpa02 = false;
          this.Screnpa03 = false;
          this.Screnpa04 = false;
          this.Screnpa222 = false;
          this.Screnpa2221 = false;
          this.Screnp3 = false;
          this.Screnp4 = false;
          this.Screnv1 = false;
          this.Screnv11 = false;
          this.Screnv2 = false;
          this.Screnv3 = false;

          //this.add.image(300,200,code).setDepth(25)

          this.posicionesEstablecidas = {
            t1: false,
            t2: false,
            t3: false,
            t4: false,
            t5: false,
            t6: false,
            t7: false,
            t8: false,
            pa1: false,
            pa2: false,
            pa3: false,
            pa4: false,
            pa5: false,
            pa6: false,
            pa7: false,
            pa8: false,
            pa9: false,
            pa10: false,
            pa11: false,
            pa12: false,
            pa13: false,
            pa14: false,
            pa15: false,
            pa16: false,
            p1: false,
            p2: false,
            p3: false,
            p4: false,
            v1: false,
            v2: false,
            v3: false,
            v4: false,
          };
          //console.log(this.objetosDropzone)

          this.eliminarPiezasCompradas = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          Object.values(this.objetosDropzone).forEach((element: any) => {
            element.destroy();
            let uniqueKey = element.uniqueKey;
            if (this.eliminarPiezasCompradas[uniqueKey]) {
              console.log(this.eliminarPiezasCompradas[uniqueKey], "asd");
              delete this.eliminarPiezasCompradas[uniqueKey];
              delete this.objetosDropzone[element.uniqueKey];
              delete this.piezasCompradas[element.uniqueKey];
            }
          });
          // console.log(
          //   Object.values(this.objetosDropzone).length,
          //   "objetos dropzone"
          // );
          // console.log(
          //   Object.values(this.eliminarPiezasCompradas).length,
          //   "comprados localstorage"
          // );
          localStorage.setItem(
            "piezasCompradas",
            JSON.stringify(this.eliminarPiezasCompradas)
          );
          funcionActualizarDatosFB({
            piezasCompradas: JSON.stringify(this.eliminarPiezasCompradas),
          });
        }
      );
    } else {
      this.casasRealizadas.buenas = this.casasRealizadas.buenas + 1;
      this.textTotalCasa.setText(`${this.casasRealizadas.buenas}/5`);
      this.dineroBase.setText(
        `${Math.round(parseFloat(localStorage.getItem("dinero") as any)) + 50}`
      );
      localStorage.setItem(
        "dinero",
        (Math.round(parseFloat(localStorage.getItem("dinero") as any)) +
          50) as any
      );
      funcionActualizarDatosFB({
        dinero:
          Math.round(parseFloat(localStorage.getItem("dinero") as any)) + 50,
      });
      //this.buenatext.setText(this.casasRealizadas[0].buenas)
      // funcionActualizarDatosFB({['casasRealizadas'+this.etapaD]: this.casasRealizadas})

      this.posicionesEstablecidas = {
        t1: false,
        t2: false,
        t3: false,
        t4: false,
        t5: false,
        t6: false,
        t7: false,
        t8: false,
        pa1: false,
        pa2: false,
        pa3: false,
        pa4: false,
        pa5: false,
        pa6: false,
        pa7: false,
        pa8: false,
        pa9: false,
        pa10: false,
        pa11: false,
        pa12: false,
        pa13: false,
        pa14: false,
        pa15: false,
        pa16: false,
        p1: false,
        p2: false,
        p3: false,
        p4: false,
        v1: false,
        v2: false,
        v3: false,
        v4: false,
      };
      //console.log(this.objetosDropzone)

      this.eliminarPiezasCompradas = JSON.parse(
        localStorage.getItem("piezasCompradas") as any
      );
      Object.values(this.objetosDropzone).forEach((element: any) => {
        element.destroy();
        let uniqueKey = element.uniqueKey;
        if (this.eliminarPiezasCompradas[uniqueKey]) {
          console.log(this.eliminarPiezasCompradas[uniqueKey], "asd");
          delete this.eliminarPiezasCompradas[uniqueKey];
          delete this.objetosDropzone[element.uniqueKey];
          delete this.piezasCompradas[element.uniqueKey];
        }
      });
      console.log(
        Object.values(this.objetosDropzone).length,
        "objetos dropzone"
      );
      console.log(
        Object.values(this.eliminarPiezasCompradas).length,
        "comprados localstorage"
      );
      localStorage.setItem(
        "piezasCompradas",
        JSON.stringify(this.eliminarPiezasCompradas)
      );
      funcionActualizarDatosFB({
        piezasCompradas: JSON.stringify(this.eliminarPiezasCompradas),
      });
    }
  }

  getRandomPosition(scena: any) {
    let posicion = Math.floor(Math.random() * scena.espacios.length);
    let respuesta = scena.espacios[posicion];
    //   scena.espacios.splice(posicion, 1)
    return respuesta;
  }

  //////////////// UPDATE /////////////////////

  update() {
    this.dineroBase.setText(localStorage.getItem("dinero"));
    // console.log((this as any).toggleTime);
  }

  ///////////////// UPDATE FIN ///////////////////////

  eventosDrag() {
    const eventos = Phaser.Input.Events;

    this.input.on(
      eventos.DRAG_START,
      (pointer: any, obj: any, dragX: any, dragY: any) => {
        console.log(obj.name);
        this.sound.play("coge_pieza");
        if (obj.tipo === "puerta") {
          if (obj.myArray) {
            obj.setScale(0.9);
            obj.x = pointer.x + 12.5 * obj.myArray[0].length;
            obj.y = pointer.y + 12.5 * obj.myArray.length;
          }
        }
        if (obj.tipo === "techo") {
          obj.x = pointer.x + 10 * obj.myArray[0].length;
          obj.y = pointer.y + 10 * obj.myArray.length;
        }
        if (obj.tipo === "ventana") {
          obj.setScale(0.9);
          obj.x = pointer.x + 12.5 * obj.myArray[0].length;
          obj.y = pointer.y + 12.5 * obj.myArray.length;
        }
        if (obj.tipo === "pared") {
          // console.log(obj)
          obj.setScale(0.9);
          if (obj.especial) {
            obj.x = pointer.x - 2;
            obj.y = pointer.y + 12.5 * obj.myArray.length;
          } else {
            if (obj.triangulo) {
              obj.x = pointer.x - 2;
              obj.y = pointer.y + 12.5 * obj.myArray.length;
            } else {
              obj.x = pointer.x + 12.5 * obj.myArray[0].length;
              obj.y = pointer.y + 12.5 * obj.myArray.length;
            }
          }
        }

        console.log(obj, "holaa");

        if (obj.dropzones) {
          obj.anterior = obj.dropzones;
          obj.dropzones.forEach((element: any) => {
            if (this.allDropzones[element]) {
              if (parseInt(this.allDropzones[element].countcolision) > 0) {
                this.allDropzones[element].countcolision =
                  this.allDropzones[element].countcolision - 1;
              }
              //console.log(this.allDropzones[element].countcolision, "restado")
              if (parseInt(this.allDropzones[element].countcolision) < 1) {
                this.allDropzones[element].graphics.setDepth(1).setAlpha(1);
                this.allDropzones[element].countcolision = 0;
                //console.log("limpiando")
              }
              // console.log(this.allDropzones[element].countcolision, this.allDropzones[element].name, "final")
            }
          });
        }
      }
    );

    this.input.on(
      eventos.DRAG,
      (pointer: any, obj: any, dragX: any, dragY: any) => {
        obj.x = pointer.x + 12.5;
        obj.y = pointer.y + 12.5;
        if (obj.tipo === "puerta") {
          if (obj.myArray) {
            obj.x = pointer.x + 8.5 * obj.myArray[0].length;
            obj.y = pointer.y + 8.5 * obj.myArray.length;
          }
        }
        if (obj.tipo === "techo") {
          obj.x = pointer.x + 8 * obj.myArray[0].length;
          obj.y = pointer.y + 8 * obj.myArray.length;
        }
        if (obj.tipo === "ventana") {
          obj.x = pointer.x + 8.5 * obj.myArray[0].length;
          obj.y = pointer.y + 8.5 * obj.myArray.length;
        }
        if (obj.tipo === "pared") {
          if (obj.especial) {
            obj.x = pointer.x - 2;
            obj.y = pointer.y + 8.5 * obj.myArray.length;
          } else {
            if (obj.triangulo) {
              obj.x = pointer.x - 2;
              obj.y = pointer.y + 8.5 * obj.myArray.length;
            } else {
              obj.x = pointer.x + 8.5 * obj.myArray[0].length;
              obj.y = pointer.y + 8.5 * obj.myArray.length;
            }
          }
        }
      }
    );

    this.input.on(eventos.DRAG_END, (pointer: any, obj: any, dropzone: any) => {
      console.log(this.posicionesEstablecidas);
      this.sound.play("soltar_pieza");
      if (obj.tipo !== "monos") {
        obj.setScale(1);
      }
      if (dropzone) {
        /*                 let detphActual =  this.depthActual + 1 
                this.depthActual = detphActual */
        /*                if(obj.name == 'pa2' && obj.depth !== 99 ){
                obj.setDepth(99)
               }else{
                this.depthActual = this.depthActual + 1 
                obj.setDepth(this.depthActual)
               } */

        this.depthActual = this.depthActual + 1;
        obj.setDepth(this.depthActual);

        if (obj.tipo) {
          let count = 0;
          let arrayTemporal: any = [];
          Object.values(this.objetosDropzone).forEach(
            (elementG: any, indexG: any) => {
              count++;
              let countI = 0;
              if (elementG.uniqueKey !== obj.uniqueKey) {
                let dropzonesEquals = obj.dropzones.filter((item: any) =>
                  elementG.dropzones.includes(item)
                );
                if (dropzonesEquals.length > 0) {
                  console.log(dropzonesEquals.length, dropzonesEquals);
                  dropzonesEquals.forEach((element: any, index: any) => {
                    countI++;
                    if (this.allDropzones[element]) {
                      /*                                         console.log(this.allDropzones[element].colisionando)
                                        if(!this.allDropzones[element].colisionando){
                                            this.allDropzones[element].countcolision = parseInt(this.allDropzones[element].countcolision) +1
                                            this.allDropzones[element].colisionando = true
                                        } */
                      /*                                         console.log(element, dropzonesEquals[index-1], "repetido")
                                        console.log(this.allDropzones[element].countcolision, this.allDropzones[element].name, index, indexG)
                                        console.log(this.objetosDropzone[elementG], elementG.name, element)

                                        console.log(this.allDropzones[element].countcolision, this.allDropzones[element].name, index, indexG) 
                                       
                                            if(obj.name == "pa2"){
                                                if(elementG.name == "t3"){
                                                    if(![elementG.dropzones[3],elementG.dropzones[6]].includes(element))
                                                    {
                                                            let depth = obj.depth == 99 ? obj.depth : 999
                                                            this.allDropzones[element].graphics.setDepth(this.depthActual + 99 ).setAlpha(.5) 
                                                            this.allDropzones[element].piezasC = this.allDropzones[element].piezasC + 1
                                                            //obj["colision"] = true
                                                        if(!this.zonasPintadas[element]){
                                                            this.zonasPintadas[element] = element
                                                        }
                                                    }
                                                }
                                                else if(elementG.name == "t4"){
                                                    if(![elementG.dropzones[0], elementG.dropzones[4]].includes(element))
                                                    {
                                                            let depth = obj.depth == 99 ? obj.depth : 999
                                                            this.allDropzones[element].graphics.setDepth(this.depthActual + 99 ).setAlpha(.5) 
                                                            this.allDropzones[element].piezasC = this.allDropzones[element].piezasC + 1
                                                           //obj["colision"] = true
                                                        if(!this.zonasPintadas[element]){
                                                            this.zonasPintadas[element] = element
                                                        }
                                                    }
                                                }
                                                else{
                                                            let depth = obj.depth == 99 ? obj.depth : 999
                                                            this.allDropzones[element].graphics.setDepth(this.depthActual + 99 ).setAlpha(.5) 
                                                            this.allDropzones[element].piezasC = this.allDropzones[element].piezasC + 1
                                                            //obj["colision"] = true
                                                        if(!this.zonasPintadas[element]){
                                                            this.zonasPintadas[element] = element
                                                        }
                                                }
                                            }
                                            else if(obj.name == "t3"){
                                                if(elementG.name == "pa2"){
                                                    if(![elementG.dropzones[0],elementG.dropzones[2]].includes(element))
                                                    {
                                                            let depth = obj.depth == 99 ? obj.depth : 999
                                                            this.allDropzones[element].graphics.setDepth(this.depthActual + 99 ).setAlpha(.5) 
                                                            this.allDropzones[element].piezasC = this.allDropzones[element].piezasC + 1
                                                           // obj["colision"] = true
                                                        if(!this.zonasPintadas[element]){
                                                            this.zonasPintadas[element] = element
                                                        }
                                                    }
                                                }else{
                                                            let depth = obj.depth == 99 ? obj.depth : 999
                                                            this.allDropzones[element].graphics.setDepth(this.depthActual + 99 ).setAlpha(.5) 
                                                            this.allDropzones[element].piezasC = this.allDropzones[element].piezasC + 1
                                                           // obj["colision"] = true
                                                        if(!this.zonasPintadas[element]){
                                                            this.zonasPintadas[element] = element
                                                        }                                            
                                                }
                                            }
                                            else if(obj.name == "t4"){
                                                if(elementG.name == "pa2"){
                                                    if(![elementG.dropzones[1],elementG.dropzones[5]].includes(element))
                                                    {
                                                            let depth = obj.depth == 99 ? obj.depth : 999
                                                            this.allDropzones[element].graphics.setDepth(this.depthActual + 99 ).setAlpha(.5) 
                                                            this.allDropzones[element].piezasC = this.allDropzones[element].piezasC + 1
                                                            //obj["colision"] = true
                                                        if(!this.zonasPintadas[element]){
                                                            this.zonasPintadas[element] = element
                                                        }
                                                    }
                                                }else{
                                                    let depth = obj.depth == 99 ? obj.depth : 999
                                                    this.allDropzones[element].graphics.setDepth(this.depthActual + 99 ).setAlpha(.5) 
                                                    this.allDropzones[element].piezasC = this.allDropzones[element].piezasC + 1
                                                    obj["colision"] = true
                                                    if(!this.zonasPintadas[element]){
                                                        this.zonasPintadas[element] = element
                                                    }
                                                }
                                            }
                                            else{
                                                console.log("entre")
                                                        let depth = obj.depth == 99 ? obj.depth : 999
                                                        this.allDropzones[element].graphics.setDepth(this.depthActual + 99 ).setAlpha(.5) 
                                                        this.allDropzones[element].piezasC = this.allDropzones[element].piezasC + 1
                                                        //obj["colision"] = true
                                                        if(!this.zonasPintadas[element]){
                                                        this.zonasPintadas[element] = element
                                                        }
                                            } */
                    }

                    arrayTemporal.push(this.allDropzones[element].name);
                    // console.log(count, Object.values(this.objetosDropzone).length)
                    if (
                      countI === dropzonesEquals.length &&
                      count === Object.values(this.objetosDropzone).length
                    ) {
                      ///console.log("finalizo Loop", arrayTemporal, count)
                    }
                  });
                } else {
                  /*                             if(obj.anterior){
                                let dropzonesEquals = obj.anterior.filter(item=>  elementG.dropzones.includes(item))
                                if(dropzonesEquals.length > 0){
                                    console.log(dropzonesEquals,  count)
                                }                              
                            } */
                }

                //console.log("recorrio el primer loop")
              }
              if (count === Object.values(this.objetosDropzone).length) {
                let unicos = this.unicos(arrayTemporal);
                // console.log(unicos, "array unicos")
                unicos.forEach((element) => {
                  if (this.allDropzones[element]) {
                    //this.allDropzones[element].countcolision = parseInt(this.allDropzones[element].countcolision) +1
                    arrayTemporal.push(this.allDropzones[element].name);
                    if (obj.name === "pa2") {
                      let elementT3: any = Object.values(
                        this.objetosDropzone
                      ).filter((item: any) => item.name === "t3")[0];
                      let elementT4: any = Object.values(
                        this.objetosDropzone
                      ).filter((item: any) => item.name === "t4")[0];
                      let arrayElementTemporal = [];
                      if (elementT3) {
                        arrayElementTemporal.push(
                          elementT3.dropzones[3],
                          elementT3.dropzones[6]
                        );
                      }
                      if (elementT4) {
                        arrayElementTemporal.push(
                          elementT4.dropzones[0],
                          elementT4.dropzones[4]
                        );
                      }

                      if (!arrayElementTemporal.includes(element)) {
                        this.allDropzones[element].graphics
                          .setDepth(this.depthActual + 99)
                          .setAlpha(0.5);
                        this.allDropzones[element].countcolision =
                          parseInt(this.allDropzones[element].countcolision) +
                          1;
                      }
                    } else if (obj.name === "t3") {
                      console.log(this.objetosDropzone, "objetos");
                      let elementPa2: any = Object.values(
                        this.objetosDropzone
                      ).filter((item: any) => item.name === "pa2")[0];
                      let elementT3: any = Object.values(
                        this.objetosDropzone
                      ).filter((item: any) => item.name === "t3")[0];
                      //if(elementPa2.name == "pa2")
                      if (elementPa2) {
                        if (
                          ![
                            elementPa2.dropzones[0],
                            elementPa2.dropzones[2],
                          ].includes(element)
                        ) {
                          this.allDropzones[element].graphics
                            .setDepth(this.depthActual + 99)
                            .setAlpha(0.5);
                          this.allDropzones[element].countcolision =
                            parseInt(this.allDropzones[element].countcolision) +
                            1;
                        } else if (
                          ![
                            elementT3.dropzones[3],
                            elementT3.dropzones[6],
                          ].includes(element)
                        ) {
                          this.allDropzones[element].graphics
                            .setDepth(this.depthActual + 99)
                            .setAlpha(0.5);
                          this.allDropzones[element].countcolision =
                            parseInt(this.allDropzones[element].countcolision) +
                            1;
                        }
                      } else {
                        this.allDropzones[element].graphics
                          .setDepth(this.depthActual + 99)
                          .setAlpha(0.5);
                        this.allDropzones[element].countcolision =
                          parseInt(this.allDropzones[element].countcolision) +
                          1;
                      }
                    } else if (obj.name === "t4") {
                      let elementPa2: any = Object.values(
                        this.objetosDropzone
                      ).filter((item: any) => item.name === "pa2")[0];
                      let elementT4: any = Object.values(
                        this.objetosDropzone
                      ).filter((item: any) => item.name === "t4")[0];
                      // if(elementPa2.name == "pa2"){
                      if (elementPa2) {
                        if (
                          ![
                            elementPa2.dropzones[1],
                            elementPa2.dropzones[5],
                          ].includes(element)
                        ) {
                          this.allDropzones[element].graphics
                            .setDepth(this.depthActual + 99)
                            .setAlpha(0.5);
                          this.allDropzones[element].countcolision =
                            parseInt(this.allDropzones[element].countcolision) +
                            1;
                        } else if (
                          ![
                            elementT4.dropzones[0],
                            elementT4.dropzones[4],
                          ].includes(element)
                        ) {
                          this.allDropzones[element].graphics
                            .setDepth(this.depthActual + 99)
                            .setAlpha(0.5);
                          this.allDropzones[element].countcolision =
                            parseInt(this.allDropzones[element].countcolision) +
                            1;
                        }
                      } else {
                        this.allDropzones[element].graphics
                          .setDepth(this.depthActual + 99)
                          .setAlpha(0.5);
                        this.allDropzones[element].countcolision =
                          parseInt(this.allDropzones[element].countcolision) +
                          1;
                      }
                    } else {
                      this.allDropzones[element].graphics
                        .setDepth(this.depthActual + 99)
                        .setAlpha(0.5);
                      this.allDropzones[element].countcolision =
                        parseInt(this.allDropzones[element].countcolision) + 1;
                    }
                  }
                });
              }
            }
          );
        }
      } else {
        if (obj.tipo) {
          if (this.objetosDropzone[obj.uniqueKey]) {
            delete this.objetosDropzone[obj.uniqueKey];
          }
        }
        obj.setDepth(11);
      }
    });

    this.input.on(
      eventos.DRAG_OVER,
      (pointer: any, gameObject: any, dropzone: any) => {
        if (gameObject.tipo === "puerta") {
          let positionInitial = dropzone.posicion;
          let countPosition = 0;
          let arrayPosiciones: any = [];
          const arrayObjeto = gameObject.myArray;
          for (var x = 0; x < arrayObjeto.length; x++) {
            arrayPosiciones[x] = [];
            for (var y = 0; y < arrayObjeto[x].length; y++) {
              if (x === 0 && y === 0) {
                arrayPosiciones[x][y] = positionInitial;
              } else if (x === 0) {
                arrayPosiciones[x][y] = positionInitial + y * 16;
              } else if (y === 0) {
                arrayPosiciones[x][y] = positionInitial + x;
              } else {
                arrayPosiciones[x][y] = positionInitial + y * 16 + x;
              }
              if (arrayObjeto[x][y] === null) {
                let indexPosicion4 = parseInt(
                  (arrayPosiciones[x][y] / 16) as any
                );
                arrayPosiciones[x][y] = null;
              }
            }
          }
          this.arrayPosicionActualArray = arrayPosiciones;
        }
      }
    );

    this.input.on(
      eventos.DRAG_ENTER,
      (pointer: any, gameObject: any, dropzone: any) => {
        if (gameObject.tipo === "techo") {
          let positionInitial = dropzone.posicion;
          let countPosition = 0;
          let arrayPosiciones: any = [];
          const arrayObjeto = gameObject.myArray;
          for (var x = 0; x < arrayObjeto.length; x++) {
            arrayPosiciones[x] = [];
            for (var y = 0; y < arrayObjeto[x].length; y++) {
              if (x === 0 && y === 0) {
                arrayPosiciones[x][y] = positionInitial;
              } else if (x === 0) {
                arrayPosiciones[x][y] = positionInitial + y * 16;
              } else if (y === 0) {
                arrayPosiciones[x][y] = positionInitial + x;
              } else {
                arrayPosiciones[x][y] = positionInitial + y * 16 + x;
                if (arrayObjeto[x][y] === null) {
                  arrayPosiciones[x][y] = null;
                }
              }
            }
          }
        }
        if (gameObject.tipo === "ventana") {
          let positionInitial = dropzone.posicion;
          let countPosition = 0;
          let arrayPosiciones: any = [];
          const arrayObjeto = gameObject.myArray;
          for (let x = 0; x < arrayObjeto.length; x++) {
            arrayPosiciones[x] = [];
            for (let y = 0; y < arrayObjeto[x].length; y++) {
              if (x === 0 && y === 0) {
                arrayPosiciones[x][y] = positionInitial;
              } else if (x === 0) {
                arrayPosiciones[x][y] = positionInitial + y * 16;
              } else if (y === 0) {
                arrayPosiciones[x][y] = positionInitial + x;
              } else {
                arrayPosiciones[x][y] = positionInitial + y * 16 + x;
              }

              if (arrayObjeto[x][y] === null) {
                arrayPosiciones[x][y] = null;
              }
            }
          }
          this.arrayPosicionActualArray = arrayPosiciones;
        }
        if (gameObject.tipo === "pared") {
          let positionInitial = dropzone.posicion;
          let countPosition = 0;
          let arrayPosiciones: any = [];
          const arrayObjeto = gameObject.myArray;
          for (var x = 0; x < arrayObjeto.length; x++) {
            arrayPosiciones[x] = [];
            for (var y = 0; y < arrayObjeto[x].length; y++) {
              if (x === 0 && y === 0) {
                arrayPosiciones[x][y] = positionInitial;
              } else if (x === 0) {
                arrayPosiciones[x][y] = positionInitial + y * 16;
              } else if (y === 0) {
                arrayPosiciones[x][y] = positionInitial + x;
              } else {
                arrayPosiciones[x][y] = positionInitial + y * 16 + x;
              }

              if (arrayObjeto[x][y] == null) {
                arrayPosiciones[x][y] = null;
              }
            }
          }
          this.arrayPosicionActualArray = arrayPosiciones;
        }
      }
    );

    this.input.on(
      eventos.DRAG_LEAVE,
      (pointer: any, gameObject: any, dropzone: any) => {}
    );

    this.input.on(
      eventos.DROP,
      (pointer: any, gameObject: any, dropzone: any) => {
        let constanteX = 1;
        let constanteY = 16;
        let dropzZonesName: any = [];

        if (gameObject.tipo) {
          // console.info(gameObject)
          gameObject.myArray.forEach((element: any, index: any) => {
            element.forEach((elementX: any, indexX: any) => {
              let siguienteX;
              if (gameObject.name === "pa2") {
                siguienteX =
                  parseInt(dropzone.name) -
                  2 +
                  indexX * constanteX +
                  constanteY * index;
              } else {
                siguienteX =
                  parseInt(dropzone.name) +
                  indexX * constanteX +
                  constanteY * index;
              }

              if (elementX === 1) {
                dropzZonesName.push(siguienteX);
              }
            });
          });

          gameObject["dropzones"] = dropzZonesName;
          //     console.log(dropzZonesName)
          this.piezasCompradas = JSON.parse(
            localStorage.getItem("piezasCompradas") as any
          );
          //   if (this.piezasCompradas[gameObject.uniqueKey]) {
          //     // this.piezasCompradas[gameObject.uniqueKey]['dropzone'] = true
          //   }
        }

        //  console.log(this.piezasCompradas[gameObject.uniqueKey]['dropzone'])
        //  console.log(this.piezasCompradas);
        //dropzone.objeto = gameObject.name

        if (gameObject.tipo === "puerta") {
          if (gameObject.hideX) {
            gameObject.x = dropzone.x + 25;
          } else {
            gameObject.x = dropzone.x + 12.5;
          }
          if (gameObject.hideY) {
            gameObject.y = dropzone.y + 25;
          } else {
            gameObject.y = dropzone.y + 12.5;
          }
          console.log(
            gameObject.posicionAdecuada,
            dropzone.name,
            gameObject.name
          );
          if (gameObject.posicionAdecuada.includes(dropzone.name)) {
            if (gameObject.posicionAdecuada.length > 1) {
              if (dropzone.name === 149) {
                this.posicionesEstablecidas["p1"] = true;
                dropzone.objeto = "p1";
                gameObject.objeto = "p1";
              }
              if (dropzone.name === 181) {
                this.posicionesEstablecidas["p2"] = true;
                dropzone.objeto = "p2";
                gameObject.objeto = "p2";
              }
            } else {
              this.posicionesEstablecidas[gameObject.name] = true;
            }
          } else {
            if (gameObject.posicionAdecuada.length <= 1) {
              this.posicionesEstablecidas[gameObject.name] = false;
            }
          }

          this.objetosDropzone[gameObject.uniqueKey] = gameObject;

          if (gameObject.objeto === "p2") {
            if (dropzone.name !== 181) {
              this.posicionesEstablecidas["p2"] = false;
              gameObject.objeto = "";
            }
          }

          if (gameObject.objeto === "p1") {
            if (dropzone.name !== 149) {
              this.posicionesEstablecidas["p1"] = false;
              gameObject.objeto = "";
            }
          }
        }

        // console.log(this.posicionesEstablecidas[gameObject.name], "piezasx")
        if (gameObject.tipo === "techo") {
          if (gameObject.hideX) {
            gameObject.x = dropzone.x + 25;
          } else {
            if (gameObject.especialX3 === true) {
              gameObject.x = dropzone.x + 25 + 12.5;
            } else {
              gameObject.x = dropzone.x + 12.5;
            }
          }
          if (gameObject.hideY) {
            gameObject.y = dropzone.y + 25;
          } else {
            if (gameObject.techoEspecial === true) {
              gameObject.y = dropzone.y + 25 + 12.5;
            } else {
              gameObject.y = dropzone.y + 12.5;
            }
          }

          if (gameObject.name === "t2n") {
            gameObject.x = dropzone.x + 37.5;
          }

          if (gameObject.posicionAdecuada.includes(dropzone.name)) {
            if (gameObject.posicionAdecuada.length > 1) {
              if (dropzone.name === 53) {
                this.posicionesEstablecidas["t2"] = true;
                dropzone.objeto = "t2";
                gameObject.objeto = "t2";
              }
              if (dropzone.name === 55) {
                this.posicionesEstablecidas["t6"] = true;
                dropzone.objeto = "t6";
                gameObject.objeto = "t6";
              }
              if (dropzone.name === 57) {
                this.posicionesEstablecidas["t7"] = true;
                dropzone.objeto = "t7";
                gameObject.objeto = "t7";
              }
              if (dropzone.name === 59) {
                this.posicionesEstablecidas["t8"] = true;
                dropzone.objeto = "t8";
                gameObject.objeto = "t8";
              }
              if (gameObject.name === "t2n") {
                if (dropzone.name === 53) {
                  this.posicionesEstablecidas["t2"] = true;
                  this.posicionesEstablecidas["t6"] = true;
                  dropzone.objeto = "t2n";
                  gameObject.objeto = "t2n";
                }
                if (dropzone.name === 57) {
                  this.posicionesEstablecidas["t7"] = true;
                  this.posicionesEstablecidas["t8"] = true;
                  dropzone.objeto = "t2n1";
                  gameObject.objeto = "t2n1";
                }
              }
            } else {
              this.posicionesEstablecidas[gameObject.name] = true;
            }
          } else {
            if (gameObject.posicionAdecuada.length <= 1) {
              this.posicionesEstablecidas[gameObject.name] = false;
            }
          }

          console.log(
            this.posicionesEstablecidas[gameObject.name],
            dropzone.name
          );

          this.objetosDropzone[gameObject.uniqueKey] = gameObject;

          if (gameObject.objeto == "t2") {
            if (dropzone.name !== 53) {
              this.posicionesEstablecidas["t2"] = false;
              gameObject.objeto = "";
            }
          }

          if (gameObject.objeto === "t6") {
            if (dropzone.name !== 55) {
              this.posicionesEstablecidas["t6"] = false;
              gameObject.objeto = "";
            }
          }

          if (gameObject.objeto === "t7") {
            if (dropzone.name !== 57) {
              this.posicionesEstablecidas["t7"] = false;
              gameObject.objeto = "";
            }
          }

          if (gameObject.objeto === "t8") {
            if (dropzone.name !== 59) {
              this.posicionesEstablecidas["t8"] = false;
              gameObject.objeto = "";
            }
          }

          if (gameObject.objeto === "t2n") {
            if (dropzone.name !== 53) {
              this.posicionesEstablecidas["t2"] = false;
              this.posicionesEstablecidas["t6"] = false;
              gameObject.objeto = "";
            }
          }

          if (gameObject.objeto === "t2n1") {
            if (dropzone.name !== 57) {
              this.posicionesEstablecidas["t7"] = false;
              this.posicionesEstablecidas["t8"] = false;
              dropzone.objeto = "";
            }
          }
        }
        if (gameObject.tipo === "ventana") {
          if (gameObject.hideX) {
            if (gameObject.especialX == true) {
              gameObject.x = dropzone.x;
            } else {
              gameObject.x = dropzone.x + 25;
            }
          } else {
            gameObject.x = dropzone.x + 12.5;
          }

          if (gameObject.hideY) {
            if (gameObject.especialY === true) {
              gameObject.y = dropzone.y;
            } else {
              gameObject.y = dropzone.y + 12.5;
            }
          } else {
            gameObject.y = dropzone.y + 25;
          }

          if (gameObject.posicionAdecuada.includes(dropzone.name)) {
            if (gameObject.posicionAdecuada.length > 1) {
              if (dropzone.name === 138) {
                this.posicionesEstablecidas["v1"] = true;
                dropzone.objeto = "v1";
                gameObject.objeto = "v1";
              }
              if (dropzone.name === 139) {
                this.posicionesEstablecidas["v2"] = true;
                dropzone.objeto = "v2";
                gameObject.objeto = "v2";
              }
              if (dropzone.name == 140) {
                this.posicionesEstablecidas["v4"] = true;
                dropzone.objeto = "v4";
                gameObject.objeto = "v4";
              }
              if (dropzone.name == 155) {
                this.posicionesEstablecidas["v3"] = true;
                dropzone.objeto = "v3";
                gameObject.objeto = "v3";
              }
            } else {
              this.posicionesEstablecidas[gameObject.name] = true;
            }
          } else {
            if (gameObject.posicionAdecuada.length <= 1) {
              this.posicionesEstablecidas[gameObject.name] = false;
            }
          }
          this.objetosDropzone[gameObject.uniqueKey] = gameObject;

          //138 139 v4 = 140   v3 = 155
          if (gameObject.objeto === "v1") {
            if (dropzone.name !== 138) {
              this.posicionesEstablecidas["v1"] = false;
              gameObject.objeto = "";
            }
          }

          if (gameObject.objeto == "v2") {
            if (dropzone.name !== 139) {
              this.posicionesEstablecidas["v2"] = false;
              gameObject.objeto = "";
            }
          }

          if (gameObject.objeto == "v3") {
            if (dropzone.name !== 155) {
              this.posicionesEstablecidas["v3"] = false;
              gameObject.objeto = "";
            }
          }

          if (gameObject.objeto === "v4") {
            if (dropzone.name !== 140) {
              this.posicionesEstablecidas["v4"] = false;
              gameObject.objeto = "";
            }
          }
        }
        if (gameObject.tipo == "pared") {
          if (gameObject.hideX) {
            if (gameObject.especialX === true) {
              gameObject.x = dropzone.x + 25 + 12.5;
            } else {
              gameObject.x = dropzone.x + 12.5;
            }
          } else {
            if (gameObject.limpio === true) {
              if (gameObject.menosx === true) {
                gameObject.x = dropzone.x + 25;
              } else {
                gameObject.x = dropzone.x;
              }
            } else {
              /*                        if(gameObject.triangulo){
                         gameObject.x = dropzone.x+ 25
                        }else{ */
              if (gameObject.triangulo) {
                gameObject.x = dropzone.x - 12.5;
              } else {
                gameObject.x = dropzone.x + 12.5;
              }

              // }
            }
          }
          if (gameObject.hideY) {
            if (gameObject.espHide) {
              gameObject.y = dropzone.y + 25;
            } else {
              gameObject.y = dropzone.y + 12.5;
            }
          } else {
            if (gameObject.limpio === true) {
              gameObject.y = dropzone.y;
            } else {
              gameObject.y = dropzone.y + 12.5;
            }
          }
          if (gameObject.triangulo) {
          }

          if (gameObject.name === "pa8n") {
            gameObject.x = dropzone.x + 25;
            gameObject.y = dropzone.y + 12.5;
          }

          if (gameObject.name === "pa2n") {
            gameObject.y = dropzone.y + 37.5;
          }

          let nombre = `posicionAdecuadaJson${gameObject.name}`;
          console.log(dropzone.name);
          if (gameObject.posicionAdecuada.includes(dropzone.name)) {
            if (gameObject.posicionAdecuada.length > 1) {
              if (dropzone.name === 148) {
                this.posicionesEstablecidas["pa1"] = true;
                dropzone.objeto = "pa1";
                gameObject.objeto = "pa1";
              }
              if (dropzone.name === 168) {
                this.posicionesEstablecidas["pa10"] = true;
                dropzone.objeto = "pa10";
                gameObject.objeto = "pa10";
              }
              if (dropzone.name === 186) {
                this.posicionesEstablecidas["pa11"] = true;
                dropzone.objeto = "pa11";
                gameObject.objeto = "pa11";
              }

              if (dropzone.name === 173) {
                this.posicionesEstablecidas["pa12"] = true;
                dropzone.objeto = "pa12";
                gameObject.objeto = "pa12";
              }
              if (dropzone.name === 125) {
                this.posicionesEstablecidas["pa13"] = true;
                dropzone.objeto = "pa13";
                gameObject.objeto = "pa13";
              }

              if (dropzone.name === 116) {
                this.posicionesEstablecidas["pa8"] = true;
                dropzone.objeto = "pa8";
                gameObject.objeto = "pa8";
              }
              if (dropzone.name === 121) {
                this.posicionesEstablecidas["pa16"] = true;
                dropzone.objeto = "pa16";
                gameObject.objeto = "pa16";
              }
              if (dropzone.name === 153) {
                this.posicionesEstablecidas["pa4"] = true;
                dropzone.objeto = "pa4";
                gameObject.objeto = "pa4";
              }
              if (dropzone.name === 141) {
                this.posicionesEstablecidas["pa15"] = true;
                dropzone.objeto = "pa15";
                gameObject.objeto = "pa15";
              }
              if (dropzone.name === 164) {
                this.posicionesEstablecidas["pa3"] = true;
                dropzone.objeto = "pa3";
                gameObject.objeto = "pa3";
              }
              if (dropzone.name === 120) {
                this.posicionesEstablecidas["pa14"] = true;
                dropzone.objeto = "pa14";
                gameObject.objeto = "pa14";
              }
              if (gameObject.name === "pa8n") {
                if (dropzone.name === 184) {
                  this.posicionesEstablecidas["pa7"] = true;
                  this.posicionesEstablecidas["pa11"] = true;

                  if (
                    this.posicionesEstablecidas["pa6"] &&
                    this.posicionesEstablecidas["pa9"]
                  ) {
                    this.posicionesEstablecidas["pa5"] = true;
                  }

                  dropzone.objeto = "pa8n";
                  gameObject.objeto = "pa8n";
                }
                if (dropzone.name === 187) {
                  this.posicionesEstablecidas["pa6"] = true;
                  this.posicionesEstablecidas["pa9"] = true;

                  if (
                    this.posicionesEstablecidas["pa7"] &&
                    this.posicionesEstablecidas["pa11"]
                  ) {
                    this.posicionesEstablecidas["pa5"] = true;
                  }
                  dropzone.objeto = "pa8n1";
                  gameObject.objeto = "pa8n1";
                }
              }

              if (gameObject.name === "pa2n") {
                if (dropzone.name === 148) {
                  this.posicionesEstablecidas["pa1"] = true;
                  this.posicionesEstablecidas["pa3"] = true;
                  dropzone.objeto = "pa2n";
                  gameObject.objeto = "pa2n";
                }
                if (dropzone.name === 120) {
                  this.posicionesEstablecidas["pa10"] = true;
                  this.posicionesEstablecidas["pa14"] = true;
                  dropzone.objeto = "pa2n1";
                  gameObject.objeto = "pa2n1";
                }
                if (dropzone.name === 125) {
                  this.posicionesEstablecidas["pa13"] = true;
                  this.posicionesEstablecidas["pa12"] = true;
                  this.posicionesEstablecidas["pa15"] = true;
                  dropzone.objeto = "pa2n2";
                  gameObject.objeto = "pa2n2";
                }
              }
            } else {
              this.posicionesEstablecidas[gameObject.name] = true;
            }
          } else {
            if (gameObject.posicionAdecuada.length <= 1) {
              this.posicionesEstablecidas[gameObject.name] = false;
            }
          }
          this.objetosDropzone[gameObject.uniqueKey] = gameObject;

          if (gameObject.objeto === "pa1") {
            if (dropzone.name !== 148) {
              this.posicionesEstablecidas["pa1"] = false;
              gameObject.objeto = "";
            }
          }
          if (gameObject.objeto === "pa10") {
            if (dropzone.name !== 168) {
              this.posicionesEstablecidas["pa10"] = false;
              gameObject.objeto = "";
            }
          }
          if (gameObject.objeto === "pa11") {
            if (dropzone.name !== 186) {
              this.posicionesEstablecidas["pa11"] = false;
              gameObject.objeto = "";
            }
          }
          if (gameObject.objeto === "pa12") {
            if (dropzone.name !== 173) {
              this.posicionesEstablecidas["pa12"] = false;
              gameObject.objeto = "";
            }
          }
          if (gameObject.objeto === "pa11") {
            if (dropzone.name !== 186) {
              this.posicionesEstablecidas["pa11"] = false;
              gameObject.objeto = "";
            }
          }
          if (gameObject.objeto === "pa13") {
            if (dropzone.name !== 125) {
              this.posicionesEstablecidas["pa13"] = false;
              gameObject.objeto = "";
            }
          }
          if (gameObject.objeto === "pa8") {
            if (dropzone.name !== 116) {
              this.posicionesEstablecidas["pa8"] = false;
              gameObject.objeto = "";
            }
          }
          if (gameObject.objeto === "pa16") {
            if (dropzone.name !== 121) {
              this.posicionesEstablecidas["pa16"] = false;
              gameObject.objeto = "";
            }
          }
          if (gameObject.objeto === "pa4") {
            if (dropzone.name !== 153) {
              this.posicionesEstablecidas["pa4"] = false;
              gameObject.objeto = "";
            }
          }
          if (gameObject.objeto === "pa15") {
            if (dropzone.name !== 141) {
              this.posicionesEstablecidas["pa15"] = false;
              gameObject.objeto = "";
            }
          }
          if (gameObject.objeto === "pa3") {
            if (dropzone.name !== 164) {
              this.posicionesEstablecidas["pa3"] = false;
              gameObject.objeto = "";
            }
          }
          if (gameObject.objeto === "pa14") {
            if (dropzone.name !== 120) {
              this.posicionesEstablecidas["pa14"] = false;
              gameObject.objeto = "";
            }
          }

          if (gameObject.objeto === "pa8n") {
            console.log("entre aqui ");
            if (dropzone.name !== 184) {
              console.log("entre aqui 2");
              this.posicionesEstablecidas["pa7"] = false;
              this.posicionesEstablecidas["pa11"] = false;
              this.posicionesEstablecidas["pa5"] = false;

              dropzone.objeto = "";
            }
          }

          if (gameObject.objeto === "pa8n1") {
            if (dropzone.name !== 187 || !dropzone) {
              this.posicionesEstablecidas["pa9"] = false;
              this.posicionesEstablecidas["pa6"] = false;
              this.posicionesEstablecidas["pa5"] = false;

              dropzone.objeto = "";
            }
          }

          if (gameObject.objeto === "pa2n") {
            if (dropzone.name !== 148) {
              this.posicionesEstablecidas["pa1"] = false;
              this.posicionesEstablecidas["pa3"] = false;
              gameObject.objeto = "";
            }
          }
          if (gameObject.objeto === "pa2n1") {
            if (dropzone.name !== 120) {
              this.posicionesEstablecidas["pa10"] = false;
              this.posicionesEstablecidas["pa14"] = false;
              gameObject.objeto = "";
            }
          }
          if (gameObject.objeto === "pa2n2") {
            if (dropzone.name !== 125) {
              this.posicionesEstablecidas["pa13"] = false;
              this.posicionesEstablecidas["pa12"] = false;
              this.posicionesEstablecidas["pa15"] = false;
              gameObject.objeto = "";
            }
          }
        }
      }
    );

    ////////////////////////////////////////////////////////////////////////////////
  }

  unicos(array: any) {
    const unicos = [];
    for (let i = 0; i < array.length; i++) {
      const valor = array[i];

      if (unicos.indexOf(valor) < 0) {
        unicos.push(valor);
      }
    }
    return unicos;
  }

  generarAleatorio(min: any, max: any, posicionJson: any) {
    let numero = Math.floor(Math.random() * (max - min)) + min;
    let temporal = posicionJson[numero];

    if (temporal.active === true) {
      let temporalNew = posicionJson
        .map((item: any, index: any) => {
          if (item.active === false) {
            return { ...item, posicion: index };
          }
        })
        .filter(Boolean);
      posicionJson[temporalNew[0].posicion].active = true;
      return temporalNew[0];
    } else {
      posicionJson[numero].active = true;
      return temporal;
    }
  }

  /*     create() {
        // ...
        var screenshotKey = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.S);
        //screenshotKey.isDown  
    
    } */

  limpiarPosicione() {
    Posiciones.forEach((element) => {
      element.active = false;
    });
    PosicionPared.forEach((element) => {
      element.active = false;
    });
    PosicionPuerta.forEach((element) => {
      element.active = false;
    });
    PosicionTecho.forEach((element) => {
      element.active = false;
    });
    PosicionVentana.forEach((element) => {
      element.active = false;
    });
    //console.log(Posiciones)
  }

  cerrarEstaciones() {
    if (this.tableMono1) {
      this.tableMono1 = undefined;
    }
    if (this.tableMono2) {
      this.tableMono2 = undefined;
    }
    if (this.tableMono3) {
      this.tableMono3 = undefined;
    }
    if (this.tableMono4) {
      this.tableMono4 = undefined;
    }
  }

  ///CUENTA REGRESIVA
  cuentaRegresiva(minutesInitial = "10", secondsInitial = "00") {
    console.log(minutesInitial, secondsInitial);

    let objectCuentaRegresiva: any = localStorage.getItem("cuentaRegresiva");
    console.log(objectCuentaRegresiva);
    objectCuentaRegresiva =
      objectCuentaRegresiva !== "undefined"
        ? JSON.parse(objectCuentaRegresiva)
        : {};
    let cuentaRegresivaActual = objectCuentaRegresiva[this.etapa];
    minutesInitial = cuentaRegresivaActual
      ? cuentaRegresivaActual.minuto
      : minutesInitial;
    secondsInitial = cuentaRegresivaActual
      ? cuentaRegresivaActual.segundos
      : secondsInitial;

    console.log(minutesInitial, secondsInitial);

    let date = new Date(
      `2021-05-10T09:${String(minutesInitial)}:${String(secondsInitial)}.989Z`
    );
    // Función para rellenar con ceros
    var padLeft = (n: any) => "00".substring(0, "00".length - n.length) + n;
    // Asignar el intervalo a una variable para poder eliminar el intervale cuando llegue al limite
    this.intervalRegresivo = setInterval(() => {
      // Asignar el valor de minutos
      var minutes = padLeft(date.getMinutes());
      // Asignar el valor de segundos
      var seconds = padLeft(date.getSeconds() + "");
      this.minutos = minutes;
      this.segundos = seconds;
      this.hourText.setText(minutes);
      this.segundosText.setText(seconds);

      ///guardar hora
      let objectCuentaRegresiva: any = localStorage.getItem("cuentaRegresiva");
      objectCuentaRegresiva =
        objectCuentaRegresiva !== "undefined"
          ? JSON.parse(objectCuentaRegresiva)
          : {};
      objectCuentaRegresiva[this.etapa] = {
        minuto: Number(minutes) < 10 ? `0${minutes}` : minutes,
        segundos: seconds,
      };
      // console.log(this.etapa);
      funcionActualizarDatosFB({ cuentaRegresiva: objectCuentaRegresiva });
      localStorage.setItem(
        "cuentaRegresiva",
        JSON.stringify(objectCuentaRegresiva)
      );
      // Restarle a la fecha actual 1000 milisegundos
      date = new Date(date.getTime() - 1000);

      // Si llega al tiempo estimado, eliminar el intervalo
      if (minutes === "0" && seconds === "20") {
        this.poco_tiempo = this.sound.add("poco_tiempo", { volume: 0.1 });
        this.poco_tiempo.setLoop(true);
        this.poco_tiempo.play();
      }
      if (minutes === "0" && seconds === "00") {
        // this.poco_tiempo.destroy()
        this.stopFullScren();
        this.sound.play("alarma_terminada");
        clearInterval(this.intervalRegresivo);
        // (this as any).mapaModalFin = this.createWindow(Modal, {
        //   title: "Etapa Finalizada",
        //   mensaje:
        //     "Tu tiempo se agoto, presiona en Salir, para volver al mapa y continuar el proceso",
        //   etapa: this.etapaEscena,
        //   imagenes: [(this as any).imagenTest, (this as any).sourceImage],
        // });
        this.allClearIntervalos();

        this.scene.stop();
        this.sound.stopAll();
        this.scene.start("Final", {
          casasRealizadas: this.casasRealizadas,
          piezasUsadas: this.piezasTotalUsadas,
          gastosPorEtapa: this.gastosPorEtapa,
          objetosUsados: this.objetosUsados,
          etapa: this.etapa,
          casasMalasCapturas: this.casasMalasCapturas,
          devolucion: this.dineroDevolucion,
          bodega: this.dineroBodegaje,
        });
      }
    }, 1000);
  }

  togglePause() {
    console.log(this.intervalRegresivo);
    if (this.intervalRegresivo !== null) {
      // Si el intervalo no es nulo (en ejecución), lo detenemos y lo marcamos como pausado
      clearInterval(this.intervalRegresivo);
      this.intervalRegresivo = null;
      this.music.pause();
    } else {
      console.log(this.minutos, this.segundos);
      this.music.play();
      // Si el intervalo es nulo (pausado), lo reiniciamos
      this.cuentaRegresiva(`0${this.minutos}`, this.segundos);
    }
  }

  mascaraInicio(tipo: any, activeScroll: any, salirScena: any, callback: any) {
    this.imagenBG = this.add
      .image(this.centroCanvas.width, this.centroCanvas.height, "circlebg")
      .setVisible(false)
      .setDepth(999);

    this.shape1 = this.make.graphics();
    var mask1 = this.shape1.createBitmapMask(this.imagenBG);
    this.cameras.main.setMask(mask1);
    let counter = tipo === "entrar" ? 0 : 2;
    let countTwen = tipo === "entrar" ? 4.8 : 0;
    this.transition = { counter: counter };

    // tween counter variable and set onUpdate function
    let tweenEffect = this.tweens.add({
      targets: this.transition,
      counter: countTwen,
      callbackScope: this,
      onUpdate: this.updateTransition,
      onStart: () => {
        this.music2 = this.sound.add("efecto_scene", { volume: 0.2 });
        this.music2.play();
      },
      ease: "Sine.easeInOut",
      yoyo: false,
      repeat: 0,
      duration: 1500,
    });

    tweenEffect.on("complete", () => {
      //   if (activeScroll) {
      //     this.activeScroll();
      //   }
      if (salirScena) {
        callback();
        this.sound.stopAll();
      }
    });
  }

  makeid(length: any) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  updateTransition() {
    this.imagenBG.setScale(this.transition.counter);
  }

  salirEscena(callback: any) {
    this.mascaraInicio("salir", false, true, callback);
  }

  allClearIntervalos() {
    if (this["intervaloClock"]) {
      clearInterval(this["intervaloClock"]);
    }
    if (this["intervaloClockTecho"]) {
      clearInterval(this["intervaloClockTecho"]);
    }
    if (this["intervaloClockVentana"]) {
      clearInterval(this["intervaloClockVentana"]);
    }
    if (this["intervaloClockPared"]) {
      console.log(this["intervaloClockPared"]);
      clearInterval(this["intervaloClockPared"]);
    }

    console.log(this.allTimout);

    this.allTimout.forEach((element: any) => {
      console.log(element);
      console.log(clearTimeout(element));
    });

    this.allTimout = [];

    //console.log(this['intervaloClockPared'], "interval pared")

    /*         clearInterval(this['intervaloClock'])
        clearInterval(this['intervaloClockTecho'])
        clearInterval(this['intervaloClockVentana'])
        clearInterval(this['intervaloClockPared']) */
  }

  createTextBox(
    scene: any,
    x: any,
    y: any,
    config: any,
    fondo: any,
    altura: any,
    tipo: any
  ) {
    const GetValue = Phaser.Utils.Objects.GetValue;
    var wrapWidth = GetValue(config, "wrapWidth", 0);
    var fixedWidth = GetValue(config, "fixedWidth", 0);
    var fixedHeight = GetValue(config, "fixedHeight", 0);
    let spaceJson = {
      left: 70,
      right: 20,
      top: 20,
      bottom: 20,
      icon: 10,
      text: 10,
    };
    if (tipo === "left") {
      spaceJson = {
        left: 20,
        right: 70,
        top: 20,
        bottom: 20,
        icon: 10,
        text: 10,
      };
    }
    if (tipo === "center") {
      console.log("centrado");
      spaceJson = {
        left: 20,
        right: 20,
        top: 40,
        bottom: 20,
        icon: 10,
        text: 10,
      };
    }
    var textBox = scene.rexUI.add
      .textBox({
        x: x,
        y: y,
        background: fondo,
        // icon: scene.add.image(0, 0, 'nextPage').setTint(COLOR_LIGHT).setVisible(false),
        text: this.getBBcodeText(
          scene,
          wrapWidth,
          fixedWidth,
          fixedHeight,
          altura
        ),
        // action: scene.add.image(0, 0, 'nextPage').setTint(COLOR_LIGHT).setVisible(false),
        space: spaceJson,
      })
      .setOrigin(0)
      .layout();
    textBox
      .setInteractive({ cursor: "pointer" })
      .on(
        "pointerdown",
        function () {
          // var icon = this.getElement('action').setVisible(false);
          //this.resetChildVisibleState(icon);
          if (scene.isTyping) {
            //  this.stop(true);
          } else {
            //scene.typeNextPage();
          }
        },
        textBox
      )
      .on(
        "pageend",
        function () {
          if (scene.isLastPage) {
            if (scene.scene.mostrarOtro) {
              scene.scene.mostrarOtro = false;
              scene.scene.btnGrupo.setVisible(1);
              clearInterval(scene.intervaloConversa);
              return;
            }
            //  this.scene.btnGrupo.setVisible(1)
          }
          /*                 var icon = this.getElement('action').setVisible(true);
                this.resetChildVisibleState(icon);
                icon.y -= 30;
                var tween = scene.tweens.add({
                    targets: icon,
                    y: '+=30',
                    ease: 'Bounce',
                    duration: 500,
                    repeat: 0,
                    yoyo: false
                }); */
        },
        textBox
      );

    scene.intervaloConversa = setInterval(() => {
      textBox.emit("pointerdown");
    }, 800);
    scene.input.keyboard.on("keydown-ENTER", function (event: any) {
      textBox.emit("pointerdown");
    });
    return textBox;
  }
  getBuiltInText(scene: any, wrapWidth: any, fixedWidth: any, fixedHeigh: any) {
    return scene.add
      .text(0, 0, "", {
        fontSize: "20px",
        wordWrap: {
          width: wrapWidth,
        },
        maxLines: 3,
        color: "#0B2167",
      })
      .setFixedSize(fixedWidth, fixedHeigh);
  }
  getBBcodeText(
    scene: any,
    wrapWidth: any,
    fixedWidth: any,
    fixedHeight: any,
    altura: any
  ) {
    return scene.rexUI.add.BBCodeText(0, 0, "", {
      fixedWidth: fixedWidth,
      fixedHeight: fixedHeight,

      fontSize: "18px",
      wrap: {
        mode: "word",
        width: wrapWidth,
      },
      maxLines: altura ? altura : 4,
      color: "#000000",
    });
  }

  enviarWhatsApp() {
    const mobileDetect =
      /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    if (mobileDetect) {
      var baseUrl = "https://wa.me/+56973070924?text=";
      var textEncode = encodeURI(
        `¡Hola, soy *${
          JSON.parse(sessionStorage.getItem("datosUser") as any).nombre
        } ${
          JSON.parse(sessionStorage.getItem("datosUser") as any).apellido
        }*!,\nNecesito ayuda con Construye.\nEstoy en la ronda *${
          this.etapaD
        }*.`
      );
      window.open(baseUrl + textEncode, "_blank");
    } else {
      var baseUrl = "https://web.whatsapp.com/send?phone=+56973070924&text=";
      var textEncode = encodeURI(
        `¡Hola, soy *${
          JSON.parse(sessionStorage.getItem("datosUser") as any).nombre
        } ${
          JSON.parse(sessionStorage.getItem("datosUser") as any).apellido
        }*!,\nNecesito ayuda con Construye.\nEstoy en la ronda *${
          this.etapaD
        }*.`
      );
      window.open(baseUrl + textEncode, "_blank");
    }
  }
}

export default Play;
