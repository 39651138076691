"use client";
import React, { useEffect, useState } from "react";

import "./App.css";

import GameContainer from "./components/GameContainer";
import Loaded from "./components/Loaded";
import { clearSesionUser, fetchRequestKampus, isMobile } from "./common/utils";

function App() {
  const [logged, setLogged] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isMobile()) {
      if (!window.matchMedia("(orientation: landscape)").matches) {
        (window as any).rotatePantalla.style.display = "flex";
      } else {
        (window as any).rotatePantalla.style.display = "none";
        //  inicializarJuego();
      }
    } else {
      (window as any).rotatePantalla.style.display = "none";
      // inicializarJuego();
    }

    window.addEventListener(
      "orientationchange",
      function () {
        if ((window as any).orientation === 0) {
          (window as any).rotatePantalla.style.display = "flex";
        } else {
          (window as any).rotatePantalla.style.display = "none";
          // inicializarJuego();
        }
      },
      false
    );

    inicializarJuego();
  }, []);

  const inicializarJuego = () => {
    if (sessionStorage.getItem("init") == null) {
      const PATHURL = new URLSearchParams(window.location.search.substr(1));
      if (PATHURL.get("param") && PATHURL.get("course")) {
        //console.log(PATHURL.get("param"), PATHURL.get("course"));
        fetchRequestKampus(
          PATHURL.get("param"),
          PATHURL.get("course"),
          setLogged,
          setLoading
        );
      } else {
        (window as any).noregistrado.style.display = "block";
        sessionStorage.setItem("invitado", "true");
        setLogged(false);
        setLoading(false);
        //console.log("no existe path");
        clearSesionUser();
      }
    }
  };

  return (
    <div>
      {loading && <Loaded />}
      {logged && <GameContainer />}
    </div>
  );
}

export default App;
