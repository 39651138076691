import { funcionActualizarDatosFB } from "../../common/utils";

const COLOR_BASE = "#0B2167";

class ModalConfirmEtapas extends Phaser.Scene {
  parent;
  keyScene;
  centroCanvas;
  extremoCanvas;
  title;
  mensaje;
  tipoEtapa;
  imagenes;
  background;
  intervalRegresivo;
  constructor(
    handle: any,
    parent: any,
    title: any,
    mensaje: any,
    tipoEtapa: any,
    imagenes: any,
    background: any,
    intervalRegresivo: any
  ) {
    super(handle);
    this.parent = parent;
    this.keyScene = handle;
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
    this.title = title;
    this.mensaje = mensaje;
    this.tipoEtapa = tipoEtapa;
    this.imagenes = imagenes;
    this.background = background;
    this.intervalRegresivo = intervalRegresivo;
  }

  init(data: any) {
    console.log("Se ha iniciado la escena Modal Confirm Etapas");

    this.centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    this.extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };

    this.title = "Mejora realizada";
    this.mensaje = "Veamos los resultados de esto";
    this.tipoEtapa = data.tipo;
  }

  preload() {}

  create() {
    this.background = this.add.image(
      this.centroCanvas.width,
      this.centroCanvas.height,
      "modal"
    );
    this.cameras.main.setBackgroundColor("rgba(29, 26, 71, 0.69)");
    this.cameras.main.setViewport(
      0,
      0,
      this.sys.game.config.width as number,
      this.sys.game.config.height as number
    );

    /*         this.add.image(this.centroCanvas.width, this.centroCanvas.height - 155, this.icon) */

    this.add.text(
      this.centroCanvas.width - 110,
      this.centroCanvas.height - 50,
      this.title,
      {
        fontFamily: "font1",
        fontSize: 26,
        color: COLOR_BASE,
        wordWrap: { width: 450, useAdvancedWrap: true },
        align: "center",
      }
    );

    this.add.text(
      this.centroCanvas.width - 150,
      this.centroCanvas.height,
      this.mensaje,
      {
        fontFamily: "font2",
        fontSize: 22,
        color: COLOR_BASE,
        wordWrap: { width: 400, useAdvancedWrap: true },
        align: "center",
      }
    );

    this.add
      .image(this.centroCanvas.width, this.centroCanvas.height + 150, "boton2")
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        localStorage.setItem(
          "nivelLevel",
          localStorage.getItem("mejora") as any
        );
        funcionActualizarDatosFB({
          nivelLevel: localStorage.getItem("mejora"),
        });
        this.scene.stop("Play");
        this.scene.start("Levels");
        this.scene.remove(this.keyScene);
      });
    this.add.text(
      this.centroCanvas.width - 50,
      this.centroCanvas.height + 135,
      "Aceptar",
      { fontFamily: "font1", fontSize: 24 }
    );
  }

  update() {}
}

export default ModalConfirmEtapas;
