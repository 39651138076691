class FinalF extends Phaser.Scene {
  centroCanvas;
  extremoCanvas;
  background;
  personajeF1;
  holograma;
  music;
  frodyM;
  linaM;
  intervaloConversa;
  imagenBG;
  shape1;
  transition;
  constructor(
    handle: any,
    parent: any,
    background: any,
    personajeF1: any,
    holograma: any,
    music: any,
    frodyM: any,
    linaM: any,
    intervaloConversa: any,
    imagenBG: any,
    shape1: any,
    transition: any
  ) {
    super({ key: "FinalF" });
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
    this.background = background;
    this.personajeF1 = personajeF1;
    this.holograma = holograma;
    this.music = music;
    this.frodyM = frodyM;
    this.linaM = linaM;
    this.intervaloConversa = intervaloConversa;
    this.imagenBG = imagenBG;
    this.shape1 = shape1;
    this.transition = transition;
  }

  init(data: any) {
    console.log("Se ha iniciado la escena Final");
    this.centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    this.extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };
  }

  preload() {
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexuiplugin.min.js",
      sceneKey: "rexUI",
    });
  }

  create() {
    this.background = this.add.image(0, 0, "finalbg").setOrigin(0, 0);

    this.mascaraInicio("entrar", false, null, null);

    this.personajeF1 = this.add.image(
      this.centroCanvas.width + 100,
      580,
      "personajef1"
    );

    this.holograma = this.add
      .sprite(this.centroCanvas.width - 100, 580, "holomu")
      .setScale(0.5);
    this.holograma.anims.play("playholomu");
    this.music = this.sound.add("music_comienzo", { volume: 0.1 });
    this.music.setLoop(true);
    this.music.play();

    this.frodyM = this.createTextBox(
      this,
      680,
      480,
      {
        wrapWidth: 250,
      },
      this.add.image(0, 0, "tutorialnube1"),
      null,
      null
    ).start(
      "Hemos completado exitosamente esta misión de construcción interestelar.",
      50
    );

    setTimeout(() => {
      this.frodyM.destroy();
      this.linaM = this.createTextBox(
        this,
        40,
        480,
        {
          wrapWidth: 250,
        },
        this.add.image(0, 0, "tutorialnube"),
        6,
        "left"
      ).start(
        "¡Lo logramos! No sabíamos si iba a ser posible. Sentíamos que lo estábamos arriesgando todo… ¡pero valió la pena!.",
        50
      );

      setTimeout(() => {
        this.linaM.destroy();
        this.frodyM = this.createTextBox(
          this,
          680,
          480,
          {
            wrapWidth: 250,
          },
          this.add.image(0, 0, "tutorialnube1"),
          9,
          null
        ).start(
          "Tu trabajo y tu capacidad de tomar decisiones para mejorar el proceso permitieron salvar la vida de muchísimas personas. ¡Gracias por no rendirte!.",
          50
        );
        setTimeout(() => {
          this.frodyM.destroy();
          this.linaM = this.createTextBox(
            this,
            40,
            480,
            {
              wrapWidth: 250,
            },
            this.add.image(0, 0, "tutorialnube"),
            6,
            "left"
          ).start(
            "Ya es momento de dar aviso. Estamos en la fecha límite para partir.",
            50
          );
          setTimeout(() => {
            this.sound.stopAll();
            this.scene.start("TierraBegli");
          }, 5500);
        }, 8500);
      }, 6500);
    }, 5500);
  }

  update() {}

  createTextBox(
    scene: any,
    x: any,
    y: any,
    config: any,
    fondo: any,
    altura: any,
    tipo: any
  ) {
    const GetValue = Phaser.Utils.Objects.GetValue;
    var wrapWidth = GetValue(config, "wrapWidth", 0);
    var fixedWidth = GetValue(config, "fixedWidth", 0);
    var fixedHeight = GetValue(config, "fixedHeight", 0);
    let spaceJson = {
      left: 70,
      right: 20,
      top: 20,
      bottom: 20,
      icon: 10,
      text: 10,
    };
    if (tipo === "left") {
      spaceJson = {
        left: 20,
        right: 70,
        top: 20,
        bottom: 20,
        icon: 10,
        text: 10,
      };
    }
    if (tipo === "center") {
      console.log("centrado");
      spaceJson = {
        left: 20,
        right: 20,
        top: 40,
        bottom: 20,
        icon: 10,
        text: 10,
      };
    }
    var textBox = scene.rexUI.add
      .textBox({
        x: x,
        y: y,
        background: fondo,
        text: this.getBBcodeText(
          scene,
          wrapWidth,
          fixedWidth,
          fixedHeight,
          altura
        ),
        space: spaceJson,
      })
      .setOrigin(0)
      .layout();
    textBox
      .setInteractive({ cursor: "pointer" })
      .on(
        "pointerdown",
        function () {
          if (scene.isTyping) {
          } else {
            // scene.typeNextPage();
          }
        },
        textBox
      )
      .on(
        "pageend",
        function () {
          if (scene.isLastPage) {
            if (scene.scene.mostrarOtro) {
              scene.scene.mostrarOtro = false;
              scene.scene.btnGrupo.setVisible(1);
              clearInterval(scene.intervaloConversa);
              return;
            }
          }
        },
        textBox
      );

    scene.intervaloConversa = setInterval(() => {
      textBox.emit("pointerdown");
    }, 800);
    scene.input.keyboard.on("keydown-ENTER", function (event: any) {
      textBox.emit("pointerdown");
    });
    return textBox;
  }

  getBuiltInText(
    scene: any,
    wrapWidth: any,
    fixedWidth: any,
    fixedHeight: any
  ) {
    return scene.add
      .text(0, 0, "", {
        fontSize: "20px",
        wordWrap: {
          width: wrapWidth,
        },
        maxLines: 3,
        color: "#0B2167",
      })
      .setFixedSize(fixedWidth, fixedHeight);
  }

  getBBcodeText(
    scene: any,
    wrapWidth: any,
    fixedWidth: any,
    fixedHeight: any,
    altura: any
  ) {
    return scene.rexUI.add.BBCodeText(0, 0, "", {
      fixedWidth: fixedWidth,
      fixedHeight: fixedHeight,
      fontSize: "18px",
      wrap: {
        mode: "word",
        width: wrapWidth,
      },
      maxLines: altura ? altura : 5,
      color: "#000000",
    });
  }

  salirEscena(callback: any) {
    this.mascaraInicio("salir", false, true, callback);
  }

  updateTransition() {
    this.imagenBG.setScale(this.transition.counter);
  }

  mascaraInicio(tipo: any, activeScroll: any, salirScena: any, callback: any) {
    this.imagenBG = this.add
      .image(this.centroCanvas.width, this.centroCanvas.height, "circlebg")
      .setVisible(false)
      .setDepth(999);

    this.shape1 = this.make.graphics();
    var mask1 = this.shape1.createBitmapMask(this.imagenBG);

    this.cameras.main.setMask(mask1);

    let counter = tipo === "entrar" ? 0 : 2;
    let countTwen = tipo === "entrar" ? 4.8 : 0;
    this.transition = { counter: counter };

    // tween counter variable and set onUpdate function
    let tweenEffect = this.tweens.add({
      targets: this.transition,
      counter: countTwen,
      callbackScope: this,
      onUpdate: this.updateTransition,
      ease: "Sine.easeInOut",
      yoyo: false,
      repeat: 0,
      duration: 1500,
    });

    tweenEffect.on("complete", () => {
      if (activeScroll) {
        ///this.activeScroll();
      }
      if (salirScena) {
        callback();
      }
    });

    //  console.log("jeje")
  }
}

export default FinalF;
