import { funcionActualizarDatosFB } from "../common/utils";
import IframeModal from "./PlayScene/IframeModal";
import ValidarEtapas from "./ValidarEtapas";
import ScrollingCamera from "phaser3-scrolling-camera";

class Levels extends Phaser.Scene {
  parent;
  keyScene;
  centroCanvas;
  extremoCanvas;
  nivel;
  background;
  clave;
  error;
  modal;
  buttonImage;
  respuestaT;
  dimensionLevel;
  etapasLocal;
  mejorasLocal;
  etapaLocal;
  mejoraLocal;
  etapasObjectName;
  opacidadMedalla;
  pasanivel;
  claves;
  urlZoom;
  music;
  audioI;
  configImg;
  tutorialLevel;
  configModal;
  holograma1;
  holograma2;
  holograma3;
  holograma4;
  holograma5;
  holograma6;
  holograma7;
  holograma8;
  personajeLevel;
  imagenBG;
  shape1;
  transition;
  music2;
  myCamera;
  count;
  constructor(handle: any, parent: any) {
    super({ key: "Levels" });
    this.parent = parent;
    this.keyScene = handle;
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
    this.nivel = 0;
    this.clave = "";
    this.background = "" as any;
    this.error = "" as any;
    this.modal = "" as any;
    this.buttonImage = "" as any;
    this.respuestaT = "" as any;
    this.dimensionLevel = "" as any;
    this.etapasLocal = "" as any;
    this.mejorasLocal = "" as any;
    this.etapaLocal = "" as any;
    this.mejoraLocal = "" as any;
    this.etapasObjectName = "" as any;
    this.opacidadMedalla = "" as any;
    this.pasanivel = "" as any;
    this.claves = "" as any;
    this.urlZoom = "" as any;
    this.music = "" as any;
    this.audioI = "" as any;
    this.configImg = "" as any;
    this.tutorialLevel = "" as any;
    this.configModal = "" as any;
    this.holograma1 = "" as any;
    this.holograma2 = "" as any;
    this.holograma3 = "" as any;
    this.holograma4 = "" as any;
    this.holograma5 = "" as any;
    this.holograma6 = "" as any;
    this.holograma7 = "" as any;
    this.holograma8 = "" as any;
    this.personajeLevel = "" as any;
    this.imagenBG = "" as any;
    this.shape1 = "" as any;
    this.transition = "" as any;
    this.music2 = "" as any;
    this.myCamera = "" as any;
    this.count = "" as any;
  }

  init(data: any) {
    console.log("Se ha iniciado la escena Levels");
    this.centroCanvas = {
      width:
        ((this.cameras.main.worldView.x + this.cameras.main.width) as number) /
        2,
      height:
        ((this.cameras.main.worldView.y + this.cameras.main.height) as number) /
        2,
    };
    this.extremoCanvas = {
      width: (this.cameras.main.worldView.x +
        this.cameras.main.width) as number,
      height: (this.cameras.main.worldView.y +
        this.cameras.main.height) as number,
    };

    this.dimensionLevel = {
      width: (this.cameras.main.worldView.x +
        this.cameras.main.width) as number,
      height: 2224,
    };
    // try {
    //     this.etapasLocal = JSON.parse(localStorage.getItem("etapasRealizadas"))
    // } catch (error) {
    //     this.etapasLocal = JSON.stringify(localStorage.getItem("etapasRealizadas"))
    // }
    this.etapasLocal = JSON.parse(
      localStorage.getItem("etapasRealizadas") as any
    );
    this.mejorasLocal = JSON.parse(
      localStorage.getItem("mejorasRealizadas") as any
    );
    //console.log(etapasLocal)
    this.etapaLocal = localStorage.getItem("etapa");
    this.mejoraLocal = localStorage.getItem("mejora");
    this.etapasObjectName = {
      1: this.etapasLocal[1] ? "mor1" : "num1",
      2: this.etapasLocal[2] ? "mor2" : "num2",
      3: this.etapasLocal[3] ? "mor3" : "num3",
      4: this.etapasLocal[4] ? "mor4" : "num4",
      5: this.etapasLocal[5] ? "mor5" : "num5",
      6: this.etapasLocal[6] ? "mor6" : "num6",
      7: this.etapasLocal[7] ? "mor7" : "num7",
      8: this.etapasLocal[8] ? "mor8" : "num8",
      9: this.etapasLocal[9] ? "mor9" : "num9",
    };

    this.opacidadMedalla = {
      1: this.mejorasLocal[11] ? 1 : 0.5,
      2: this.mejorasLocal[12] ? 1 : 0.5,
      3: this.mejorasLocal[13] ? 1 : 0.5,
      4: this.mejorasLocal[14] ? 1 : 0.5,
      5: this.mejorasLocal[15] ? 1 : 0.5,
      6: this.mejorasLocal[16] ? 1 : 0.5,
      7: this.mejorasLocal[17] ? 1 : 0.5,
      8: this.mejorasLocal[18] ? 1 : 0.5,
      9: this.mejorasLocal[19] ? 1 : 0.5,
    };

    data.nivel = 1;
    data.pasanivel = false;
    this.pasanivel = data.pasanivel;
    this.nivel = data.nivel;
    if (!localStorage.getItem("scrollY")) {
      localStorage.setItem("scrollY", 1800 as any);
      funcionActualizarDatosFB({ scrollY: 1800 });
    }

    this.claves = {
      2: "5srcks",
      3: "gvrls2",
      4: "pkayk3",
      5: "golyt4",
      6: "blncc5",
      7: "knbnt6",
      8: "innvt7",
    };

    this.urlZoom =
      "https://zoom.us/j/97112486557?pwd=SjlXVUVWNmNiOWFGZUNRL0FWL2QyZz09";
  }

  preload() {}

  create() {
    console.log(this.mejoraLocal, "actual mejora");
    localStorage.setItem("escenaActual", "Levels");
    funcionActualizarDatosFB({ escenaActual: "Levels" });
    this.background = this.add.image(0, 0, "fondolevel").setOrigin(0, 0);
    // document.querySelector(".textoStarWar").style.display = "none";
    this.music = this.sound.add("music_comienzo", { volume: 0.1 });
    this.music.setLoop(true);
    this.music.play();

    // this.camera = this.cameras.main.setBounds(0,0,1000,2224).setName("main")
    /*       this.minimap = this.cameras.add(0,-100,400,1000).setZoom(0.2).setName("mini") */

    /*         this.cursors = this.input.keyboard.createCursorKeys()
        var controlConfig = {
            camera: this.cameras.main,
            left: this.cursors.left,
            right: this.cursors.right,
            up: this.cursors.up,
            down: this.cursors.down,
            dragY:this.input.pointer1.isDown,
            zoomIn: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.Q),
            zoomOut: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.E),
            acceleration: 0.08,
            drag:0.0005,
            maxSpeed: 1.0
        };
        this.controls = new Phaser.Cameras.Controls.SmoothedKeyControl(controlConfig); */

    /// estableciendo la camara al principio
    // this.camera.pan(0,2224, 5000, 'Power2');
    //  this.cameras.main.setScroll(0, 2224)

    /// inicializando objetos
    //  this.cameras.main.setScroll(0, 2224)
    /*       this.camera = this.cameras.main.setBounds(0,0,1000,2224).setName("main")
      this.cameras.main.setScroll(0, 2224) */
    let stepInitialA = 3;
    let setpInitialB = this.sound.mute ? stepInitialA + 1 : stepInitialA - 1;

    this.audioI = this.add
      .image(70, this.dimensionLevel.height - 70, "sinaudio")
      .setInteractive({ cursor: "pointer" })
      .setScale(0.5)
      .setDepth(setpInitialB);

    this.configImg = this.add
      .image(70, this.dimensionLevel.height - 70, "audio")
      .setInteractive({ cursor: "pointer" })
      .setScale(0.5)
      .on(
        "pointerdown",
        (pointer: any, gameObject: any, localy: any, event: any) => {
          this.sound.play("click");
          this.sound.mute = true;
          this.configImg.setDepth(2);
          this.audioI.setDepth(4);
        }
      )
      .setDepth(stepInitialA);

    this.audioI.on("pointerdown", () => {
      this.sound.play("click");
      this.sound.mute = false;
      this.configImg.setDepth(4);
      this.audioI.setDepth(2);
    });

    this.tutorialLevel = this.add
      .image(190, this.dimensionLevel.height - 70, "tutorialevel")
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.sound.play("click");
        setTimeout(() => {
          this.sound.stopAll();
          this.scene.start("Tutorial", { renew: true });
        }, 200);
      });

    this.tutorialLevel.buttonE = true;

    // primera base
    this.add.image(260, this.dimensionLevel.height - 180, "basel");
    this.add
      .image(260, this.dimensionLevel.height - 240, this.etapasObjectName[1])
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.sound.stopAll();
        //console.log(this.etapasObjectName[1])
        if (this.etapasLocal[1] === false) {
          this.sound.play("click");
          const callBack = () => {
            this.scene.start("Play", { etapa: 1, confirm: true });
            localStorage.setItem("etapa", 1 as any);
            funcionActualizarDatosFB({ etapa: 1 });
          };
          this.salirEscena(callBack);
        }
      });

    // primera mejora
    this.add.image(370, this.dimensionLevel.height - 290, "baseprof");
    this.add.image(500, this.dimensionLevel.height - 300, "basel");

    this.add
      .image(500, this.dimensionLevel.height - 350, "5s")
      .setInteractive({ cursor: "pointer" })
      .setAlpha(this.opacidadMedalla[1])
      .on("pointerdown", () => {
        console.log(this.mejorasLocal, this.etapasLocal);
        if (this.etapasLocal[1] === true && this.mejorasLocal[11] === false) {
          this.sound.stopAll();
          this.sound.play("click");
          this.scene.start("Play", { etapa: 11, tipo: "mejora" });
          localStorage.setItem("mejora", 11 as any);
          localStorage.setItem("etapa", 2 as any);
          funcionActualizarDatosFB({ etapa: 2, mejora: 11 });
        }
      });

    // segunda etapa
    this.add.image(620, this.dimensionLevel.height - 400, "baselazul");
    this.add
      .image(620, this.dimensionLevel.height - 460, this.etapasObjectName[2])
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        if (
          this.etapasLocal[2] === false &&
          this.mejorasLocal[11] === true &&
          this.etapasLocal[1] === true
        ) {
          this.configModal = this.createWindow(ValidarEtapas, {
            nivel: 2,
            clave: this.claves[2],
          });
          this.scene.pause();
        }
      });

    /// mejora segunda
    this.add.image(760, this.dimensionLevel.height - 440, "baseprof");
    this.add.image(860, this.dimensionLevel.height - 480, "baselazul");
    this.add
      .image(860, this.dimensionLevel.height - 530, "eti")
      .setInteractive({ cursor: "pointer" })
      .setAlpha(this.opacidadMedalla[2])
      .on("pointerdown", () => {
        if (this.etapasLocal[2] === true && this.mejorasLocal[12] === false) {
          this.sound.stopAll();
          this.sound.play("click");
          this.scene.start("Play", { etapa: 12, tipo: "mejora" });
          localStorage.setItem("mejora", 12 as any);
          localStorage.setItem("etapa", 3 as any);
          funcionActualizarDatosFB({ etapa: 3, mejora: 12 });
        }
      });

    /// tercera etapa
    this.add.image(680, this.dimensionLevel.height - 615, "baselazul");
    this.add
      .image(680, this.dimensionLevel.height - 675, this.etapasObjectName[3])
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        if (
          this.etapasLocal[3] === false &&
          this.mejorasLocal[12] === true &&
          this.etapasLocal[2] === true
        ) {
          this.configModal = this.createWindow(ValidarEtapas, {
            nivel: 3,
            clave: this.claves[3],
          });
          this.scene.pause();
        }
      });

    // tercera mejora
    this.add.image(555, this.dimensionLevel.height - 620, "baseprof");
    this.add.image(450, this.dimensionLevel.height - 650, "baselazul");
    this.add
      .image(450, this.dimensionLevel.height - 700, "marcar")
      .setInteractive({ cursor: "pointer" })
      .setAlpha(this.opacidadMedalla[3])
      .on("pointerdown", () => {
        if (this.etapasLocal[3] === true && this.mejorasLocal[13] === false) {
          this.sound.stopAll();
          this.sound.play("click");
          this.scene.start("Play", { etapa: 13, tipo: "mejora" });
          localStorage.setItem("mejora", 13 as any);
          localStorage.setItem("etapa", 4 as any);
          funcionActualizarDatosFB({ etapa: 4, mejora: 13 });
        }
      });

    // cuarta etapa
    this.add.image(380, this.dimensionLevel.height - 800, "baseprof");
    this.add.image(520, this.dimensionLevel.height - 800, "baselazul");
    this.add
      .image(520, this.dimensionLevel.height - 860, this.etapasObjectName[4])
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        if (
          this.etapasLocal[4] === false &&
          this.mejorasLocal[13] === true &&
          this.etapasLocal[3] === true
        ) {
          this.configModal = this.createWindow(ValidarEtapas, {
            nivel: 4,
            clave: this.claves[4],
          });
          this.scene.pause();
        }
      });

    // cuarta mejora
    this.add.image(260, this.dimensionLevel.height - 820, "baselazul");
    this.add
      .image(260, this.dimensionLevel.height - 870, "marcaare")
      .setInteractive({ cursor: "pointer" })
      .setAlpha(this.opacidadMedalla[4])
      .on("pointerdown", () => {
        if (this.etapasLocal[4] === true && this.mejorasLocal[14] === false) {
          this.sound.stopAll();
          this.sound.play("click");
          this.scene.start("Play", { etapa: 14, tipo: "mejora" });
          localStorage.setItem("mejora", 14 as any);
          localStorage.setItem("etapa", 5 as any);
          funcionActualizarDatosFB({ etapa: 5, mejora: 14 });
        }
      });

    // quinta etapa
    this.add.image(380, this.dimensionLevel.height - 960, "baselazul");
    this.add
      .image(380, this.dimensionLevel.height - 1020, this.etapasObjectName[5])
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        if (
          this.etapasLocal[5] === false &&
          this.mejorasLocal[14] === true &&
          this.etapasLocal[4] === true
        ) {
          this.sound.play("click");
          this.configModal = this.createWindow(ValidarEtapas, {
            nivel: 5,
            clave: this.claves[5],
          });
          this.scene.pause();
        }
      });

    // quinta mejora
    this.add.image(475, this.dimensionLevel.height - 990, "baseprof");
    this.add.image(580, this.dimensionLevel.height - 990, "baselazul");
    this.add
      .image(580, this.dimensionLevel.height - 1040, "balance")
      .setInteractive({ cursor: "pointer" })
      .setAlpha(this.opacidadMedalla[5])
      .on("pointerdown", () => {
        if (this.etapasLocal[5] === true && this.mejorasLocal[15] === false) {
          this.sound.stopAll();
          this.sound.play("click");
          this.scene.start("Play", { etapa: 15, tipo: "mejora" });
          localStorage.setItem("mejora", 15 as any);
          localStorage.setItem("etapa", 6 as any);
          funcionActualizarDatosFB({ etapa: 6, mejora: 15 });
        }
      });

    // sexta etapa
    this.add.image(670, this.dimensionLevel.height - 1090, "baselazul");
    this.add
      .image(670, this.dimensionLevel.height - 1150, this.etapasObjectName[6])
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        /*             this.configModal = this.createWindow(ValidarEtapas, {nivel: 6})
            this.scene.pause() */
        if (
          this.etapasLocal[6] === false &&
          this.mejorasLocal[15] === true &&
          this.etapasLocal[5] === true
        ) {
          this.sound.play("click");
          this.configModal = this.createWindow(ValidarEtapas, {
            nivel: 6,
            clave: this.claves[6],
          });
          this.scene.pause();
        }
      });

    // sexta mejora
    this.add.image(550, this.dimensionLevel.height - 1120, "baseprof");
    this.add.image(440, this.dimensionLevel.height - 1150, "baselazul");
    this.add
      .image(440, this.dimensionLevel.height - 1200, "auto")
      .setInteractive({ cursor: "pointer" })
      .setAlpha(this.opacidadMedalla[6])
      .on("pointerdown", () => {
        if (this.etapasLocal[6] === true && this.mejorasLocal[16] === false) {
          this.sound.stopAll();
          this.sound.play("click");
          this.scene.start("Play", { etapa: 16, tipo: "mejora" });
          localStorage.setItem("mejora", 16 as any);
          localStorage.setItem("etapa", 7 as any);
          funcionActualizarDatosFB({ etapa: 7, mejora: 16 });
        }
      });

    // septima etapa
    this.add.image(670, this.dimensionLevel.height - 1300, "baselazul");
    this.add
      .image(670, this.dimensionLevel.height - 1360, this.etapasObjectName[7])
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        /*             this.configModal = this.createWindow(ValidarEtapas, {nivel: 6})
                        this.scene.pause() */
        if (
          this.etapasLocal[7] === false &&
          this.mejorasLocal[16] === true &&
          this.etapasLocal[6] === true
        ) {
          this.sound.play("click");
          this.configModal = this.createWindow(ValidarEtapas, {
            nivel: 7,
            clave: this.claves[7],
          });
          this.scene.pause();
        }
      });

    // septima mejora
    this.add.image(545, this.dimensionLevel.height - 1320, "baseprof");
    this.add.image(440, this.dimensionLevel.height - 1350, "baselazul");
    this.add
      .image(440, this.dimensionLevel.height - 1400, "foco")
      .setInteractive({ cursor: "pointer" })
      .setAlpha(this.opacidadMedalla[7])
      .on("pointerdown", () => {
        if (this.etapasLocal[7] === true && this.mejorasLocal[17] === false) {
          this.sound.stopAll();
          this.sound.play("click");
          this.scene.start("Play", { etapa: 17, tipo: "mejora" });
          localStorage.setItem("mejora", 17 as any);
          localStorage.setItem("etapa", 8 as any);
          funcionActualizarDatosFB({ etapa: 8, mejora: 17 });
        }
      });

    // numero 8
    this.add.image(280, this.dimensionLevel.height - 1510, "baselazul");
    this.add
      .image(280, this.dimensionLevel.height - 1570, this.etapasObjectName[8])
      .setInteractive({ cursor: "pointer" })
      .setDepth(999)
      .on("pointerdown", () => {
        if (
          this.etapasLocal[8] === false &&
          this.mejorasLocal[17] === true &&
          this.etapasLocal[7] === true
        ) {
          this.sound.play("click");
          this.configModal = this.createWindow(ValidarEtapas, {
            nivel: 8,
            clave: this.claves[8],
          });
          this.scene.pause();
        }
      });

    /* 
        this.add.image(600,this.dimensionLevel.height-1300 ,"baselazul")
        this.add.image(600,this.dimensionLevel.height-1360 ,'num8')

        this.add.image(410,this.dimensionLevel.height-1400 ,'bandera')


        this.add.image(280,this.dimensionLevel.height-1510 ,"baselazul")
        this.add.image(280,this.dimensionLevel.height-1570 ,'num9') */

    this.add.image(this.centroCanvas.width, 140, "logolevel");

    this.input.on(
      "pointerdown",
      (pointer: any, gameObject: any, localy: any, event: any) => {
        if (gameObject[0])
          if (gameObject[0].buttonE) {
            gameObject[0].setScale(0.9);
          }
      }
    );

    this.input.on(
      "pointerup",
      (pointer: any, gameObject: any, localy: any, event: any) => {
        if (gameObject[0])
          if (gameObject[0].buttonE) {
            gameObject[0].setScale(1);
          }
      }
    );

    this.mascaraInicio("entrar", true, "", "");

    if (localStorage.getItem("pasanivel") === "true") {
      console.log("eeee");
      this.validandoCasos();
    }

    setTimeout(() => {
      if (this.etapasLocal[1]) {
        this.holograma1 = this.add
          .sprite(370, this.dimensionLevel.height - 360, "holomu")
          .setScale(0.2)
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", () => {
            this.createWindow(IframeModal, {
              url: "https://iseazy.com/dl/7badc23ceb6a4f68aac40807b3bca39c",
            });
            this.scene.pause();
          });
        this.holograma1.anims.play("playholomu");
      }

      if (this.etapasLocal[2]) {
        // if (this.holograma1) {
        //   this.holograma1.destroy();
        // }
        this.holograma2 = this.add
          .sprite(760, this.dimensionLevel.height - 505, "holomu")
          .setScale(0.2)
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", () => {
            this.createWindow(IframeModal, {
              url: "https://iseazy.com/dl/424b71925b95484f87fb6200fac8ddd4",
            });
            this.scene.pause();
          });
        this.holograma2.anims.play("playholomu");
      }

      if (this.etapasLocal[3] && !this.etapasLocal[4]) {
        this.holograma3 = this.add
          .sprite(555, this.dimensionLevel.height - 685, "holomu")
          .setScale(0.2)
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", () => {
            this.createWindow(IframeModal, {
              url: "https://iseazy.com/dl/579d2d3e866e41368f5eeef6560f8d4d",
            });
            this.scene.pause();
          });
        this.holograma3.anims.play("playholomu");
      }
      if (this.etapasLocal[4]) {
        this.holograma4 = this.add
          .sprite(380, this.dimensionLevel.height - 865, "holomu")
          .setScale(0.2)
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", () => {
            this.createWindow(IframeModal, {
              url: "https://iseazy.com/dl/9164a235f3c64fe383d9319be75974c3",
            });
            this.scene.pause();
          });
        this.holograma4.anims.play("playholomu");
      }
      if (this.etapasLocal[5]) {
        this.holograma5 = this.add
          .sprite(475, this.dimensionLevel.height - 1053, "holomu")
          .setScale(0.2)
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", () => {
            this.createWindow(IframeModal, {
              url: "https://iseazy.com/dl/b4197460b0194622bd599c62d4a3f809",
            });
            this.scene.pause();
          });
        this.holograma5.anims.play("playholomu");
      }
      if (this.etapasLocal[6]) {
        this.holograma6 = this.add
          .sprite(550, this.dimensionLevel.height - 1185, "holomu")
          .setScale(0.2)
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", () => {
            this.createWindow(IframeModal, {
              url: "https://iseazy.com/dl/9a82adf1aec0462293b69b7c7bf027b3",
            });
            this.scene.pause();
          });
        this.holograma6.anims.play("playholomu");
      }
      if (this.etapasLocal[7]) {
        this.holograma7 = this.add
          .sprite(545, this.dimensionLevel.height - 1385, "holomu")
          .setScale(0.2)
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", () => {
            this.createWindow(IframeModal, {
              url: "https://iseazy.com/dl/05bae86f0d6a4ee6b3007ec913a9e83e",
            });
            this.scene.pause();
          });
        this.holograma7.anims.play("playholomu");
      }
    }, 2500);

    let valorxInitial = localStorage.getItem("posicionXPersonaje")
      ? localStorage.getItem("posicionXPersonaje")
      : 200;
    let valoryInitial = localStorage.getItem("posicionYPersonaje")
      ? localStorage.getItem("posicionYPersonaje")
      : this.dimensionLevel.height - 300;
    let angeInitial = localStorage.getItem("posicionAngleInitial")
      ? localStorage.getItem("posicionAngleInitial")
      : 30;
    let nombrePersonaje = localStorage.getItem("namePersonaje")
      ? localStorage.getItem("namePersonaje")
      : "personajea1";

    this.personajeLevel = this.add
      .image(
        parseInt(valorxInitial as any),
        parseInt(valoryInitial as any),
        nombrePersonaje as any
      )
      .setScale(0.22)
      .setAngle(parseInt(angeInitial as any));

    //   setTimeout(()=>{
    this.establecerMono();
    //   }, 2500)
    //this.personajeLevel.rotation = 1
  }
  validandoCasos() {
    switch (localStorage.getItem("nivel")) {
      case "2":
        console.log("aquio");
        if (this.etapasLocal[2] === false) {
          console.log("y aqui");
          //this.sound.play('click');
          this.sound.stopAll();
          this.scene.start("Play", { etapa: 2, confirm: true });
          localStorage.setItem("etapa", 2 as any);
        }
        localStorage.setItem("pasanivel", false as any);
        localStorage.setItem("nivel", undefined as any);
        funcionActualizarDatosFB({
          pasanivel: false,
          nivel: undefined,
          etapa: 2,
        });
        this.pasanivel = false;
        this.nivel = 0;
        //  (this.pasanivel = false), (this.nivel = undefined);
        break;
      case "3":
        if (this.etapasLocal[3] === false) {
          this.sound.stopAll();
          this.scene.start("Play", { etapa: 3, confirm: true });
          localStorage.setItem("etapa", 3 as any);
        }
        localStorage.setItem("pasanivel", false as any);
        localStorage.setItem("nivel", undefined as any);
        funcionActualizarDatosFB({
          pasanivel: false,
          nivel: undefined,
          etapa: 3,
        });
        this.pasanivel = false;
        this.nivel = 0;
        break;
      case "4":
        if (this.etapasLocal[4] === false) {
          this.sound.stopAll();
          this.scene.start("Play", { etapa: 4, confirm: true });
          localStorage.setItem("etapa", 4 as any);
        }
        localStorage.setItem("pasanivel", false as any);
        localStorage.setItem("nivel", undefined as any);
        funcionActualizarDatosFB({
          pasanivel: false,
          nivel: undefined,
          etapa: 4,
        });
        this.pasanivel = false;
        this.nivel = 0;
        break;
      case "5":
        if (this.etapasLocal[5] === false) {
          this.sound.stopAll();
          this.scene.start("Play", { etapa: 5, confirm: true });
          localStorage.setItem("etapa", 5 as any);
        }
        localStorage.setItem("pasanivel", false as any);
        localStorage.setItem("nivel", undefined as any);
        funcionActualizarDatosFB({
          pasanivel: false,
          nivel: undefined,
          etapa: 5,
        });
        this.pasanivel = false;
        this.nivel = 0;
        break;
      case "6":
        if (this.etapasLocal[6] === false) {
          this.sound.stopAll();
          this.scene.start("Play", { etapa: 6, confirm: true });
          localStorage.setItem("etapa", 6 as any);
        }
        localStorage.setItem("pasanivel", false as any);
        localStorage.setItem("nivel", undefined as any);
        funcionActualizarDatosFB({
          pasanivel: false,
          nivel: undefined,
          etapa: 6,
        });
        this.pasanivel = false;
        this.nivel = 0;
        break;
      case "7":
        if (this.etapasLocal[7] === false) {
          this.sound.stopAll();
          this.scene.start("Play", { etapa: 7, confirm: true });
          localStorage.setItem("etapa", 7 as any);
        }
        localStorage.setItem("pasanivel", false as any);
        localStorage.setItem("nivel", undefined as any);
        funcionActualizarDatosFB({
          pasanivel: false,
          nivel: undefined,
          etapa: 7,
        });
        this.pasanivel = false;
        this.nivel = 0;
        break;
      case "8":
        if (this.etapasLocal[8] === false) {
          this.sound.stopAll();
          this.scene.start("Play", { etapa: 8, confirm: true });
          localStorage.setItem("etapa", 8 as any);
        }
        localStorage.setItem("pasanivel", false as any);
        localStorage.setItem("nivel", undefined as any);
        funcionActualizarDatosFB({
          pasanivel: false,
          nivel: undefined,
          etapa: 8,
        });
        this.pasanivel = false;
        this.nivel = 0;
        break;
    }
  }

  establecerMono() {
    switch (localStorage.getItem("nivelLevel")) {
      case "2":
        localStorage.setItem("posicionXPersonaje", 450 as any);
        localStorage.setItem(
          "posicionYPersonaje",
          (this.dimensionLevel.height - 420) as any
        );
        localStorage.setItem("posicionAngleInitial", 25 as any);
        localStorage.setItem(
          "scrollY",
          (this.dimensionLevel.height - 460) as any
        );
        this.playAnimationMono(450, this.dimensionLevel.height - 420, 25);
        funcionActualizarDatosFB({
          posicionXPersonaje: 450,
          posicionYPersonaje: this.dimensionLevel.height - 420,
          posicionAngleInitial: 25,
          scrollY: this.dimensionLevel.height - 460,
        });
        break;
      case "11":
        localStorage.setItem("posicionXPersonaje", 550 as any);
        localStorage.setItem(
          "posicionYPersonaje",
          (this.dimensionLevel.height - 520) as any
        );
        localStorage.setItem("posicionAngleInitial", 25 as any);
        localStorage.setItem(
          "scrollY",
          (this.dimensionLevel.height - 500) as any
        );
        this.playAnimationMono(550, this.dimensionLevel.height - 520, 25);
        funcionActualizarDatosFB({
          posicionXPersonaje: 550,
          posicionYPersonaje: this.dimensionLevel.height - 520,
          posicionAngleInitial: 25,
          scrollY: this.dimensionLevel.height - 500,
        });
        break;
      case "3":
        localStorage.setItem("posicionXPersonaje", 820 as any);
        localStorage.setItem(
          "posicionYPersonaje",
          (this.dimensionLevel.height - 620) as any
        );
        localStorage.setItem("posicionAngleInitial", 25 as any);
        localStorage.setItem(
          "scrollY",
          (this.dimensionLevel.height - 675) as any
        );
        funcionActualizarDatosFB({
          posicionXPersonaje: 820,
          posicionYPersonaje: this.dimensionLevel.height - 620,
          posicionAngleInitial: 25,
          scrollY: this.dimensionLevel.height - 675,
        });

        this.playAnimationMono(820, this.dimensionLevel.height - 620, 25);
        break;
      case "12":
        localStorage.setItem("posicionXPersonaje", 770 as any);
        localStorage.setItem(
          "posicionYPersonaje",
          (this.dimensionLevel.height - 720) as any
        );
        localStorage.setItem("posicionAngleInitial", -25 as any);
        localStorage.setItem(
          "scrollY",
          (this.dimensionLevel.height - 700) as any
        );
        funcionActualizarDatosFB({
          posicionXPersonaje: 770,
          posicionYPersonaje: this.dimensionLevel.height - 720,
          posicionAngleInitial: -25,
          scrollY: this.dimensionLevel.height - 700,
        });
        this.playAnimationMono(770, this.dimensionLevel.height - 720, -25);
        break;
      case "4":
        localStorage.setItem("posicionXPersonaje", 380 as any);
        localStorage.setItem(
          "posicionYPersonaje",
          (this.dimensionLevel.height - 740) as any
        );
        localStorage.setItem("posicionAngleInitial", 25 as any);
        localStorage.setItem(
          "scrollY",
          (this.dimensionLevel.height - 800) as any
        );
        funcionActualizarDatosFB({
          posicionXPersonaje: 380,
          posicionYPersonaje: this.dimensionLevel.height - 740,
          posicionAngleInitial: 25,
          scrollY: this.dimensionLevel.height - 800,
        });
        this.playAnimationMono(380, this.dimensionLevel.height - 740, 25);
        break;
      case "13":
        localStorage.setItem("posicionXPersonaje", 480 as any);
        localStorage.setItem(
          "posicionYPersonaje",
          (this.dimensionLevel.height - 940) as any
        );
        localStorage.setItem("posicionAngleInitial", 25 as any);
        localStorage.setItem(
          "scrollY",
          (this.dimensionLevel.height - 850) as any
        );
        funcionActualizarDatosFB({
          posicionXPersonaje: 480,
          posicionYPersonaje: this.dimensionLevel.height - 940,
          posicionAngleInitial: 25,
          scrollY: this.dimensionLevel.height - 850,
        });
        this.playAnimationMono(480, this.dimensionLevel.height - 940, 25);
        break;
      case "5":
        localStorage.setItem("posicionXPersonaje", 220 as any);
        localStorage.setItem(
          "posicionYPersonaje",
          (this.dimensionLevel.height - 940) as any
        );
        localStorage.setItem("posicionAngleInitial", 25 as any);
        (this.dimensionLevel.height - 1150) as any;
        (this.dimensionLevel.height - 1360) as any;
        localStorage.setItem(
          "scrollY",
          (this.dimensionLevel.height - 1020) as any
        );
        funcionActualizarDatosFB({
          posicionXPersonaje: 220,
          posicionYPersonaje: this.dimensionLevel.height - 940,
          posicionAngleInitial: 25,
          scrollY: this.dimensionLevel.height - 1020,
        });
        this.playAnimationMono(220, this.dimensionLevel.height - 940, 25);
        break;
      case "14":
        localStorage.setItem("posicionXPersonaje", 300 as any);
        localStorage.setItem(
          "posicionYPersonaje",
          (this.dimensionLevel.height - 1070) as any
        );
        localStorage.setItem("posicionAngleInitial", 25 as any);
        localStorage.setItem(
          "scrollY",
          (this.dimensionLevel.height - 1150) as any
        );
        funcionActualizarDatosFB({
          posicionXPersonaje: 300,
          posicionYPersonaje: this.dimensionLevel.height - 1070,
          posicionAngleInitial: 25,
          scrollY: this.dimensionLevel.height - 1150,
        });
        this.playAnimationMono(300, this.dimensionLevel.height - 1070, 25);
        break;
      case "6":
        console.log("kjekje");
        localStorage.setItem("posicionXPersonaje", 650 as any);
        localStorage.setItem(
          "posicionYPersonaje",
          (this.dimensionLevel.height - 1070) as any
        );
        localStorage.setItem("posicionAngleInitial", 0 as any);
        localStorage.setItem(
          "scrollY",
          (this.dimensionLevel.height - 1150) as any
        );
        funcionActualizarDatosFB({
          posicionXPersonaje: 650,
          posicionYPersonaje: this.dimensionLevel.height - 1070,
          posicionAngleInitial: 0,
          scrollY: this.dimensionLevel.height - 1150,
        });
        this.playAnimationMono(650, this.dimensionLevel.height - 1070, 0);
        break;
      case "15":
        localStorage.setItem("posicionXPersonaje", 740 as any);
        localStorage.setItem(
          "posicionYPersonaje",
          (this.dimensionLevel.height - 1200) as any
        );
        localStorage.setItem("posicionAngleInitial", -25 as any);
        localStorage.setItem(
          "scrollY",
          (this.dimensionLevel.height - 1200) as any
        );
        funcionActualizarDatosFB({
          posicionXPersonaje: 740,
          posicionYPersonaje: this.dimensionLevel.height - 1200,
          posicionAngleInitial: -25,
          scrollY: this.dimensionLevel.height - 1200,
        });
        this.playAnimationMono(740, this.dimensionLevel.height - 1200, -25);
        break;
      case "7":
        localStorage.setItem("posicionXPersonaje", 380 as any);
        localStorage.setItem(
          "posicionYPersonaje",
          (this.dimensionLevel.height - 1260) as any
        );
        localStorage.setItem("posicionAngleInitial", 25 as any);
        localStorage.setItem(
          "scrollY",
          (this.dimensionLevel.height - 1260) as any
        );
        funcionActualizarDatosFB({
          posicionXPersonaje: 380,
          posicionYPersonaje: this.dimensionLevel.height - 1260,
          posicionAngleInitial: 25,
          scrollY: this.dimensionLevel.height - 1260,
        });
        this.playAnimationMono(380, this.dimensionLevel.height - 1260, 25);
        break;
      case "16":
        localStorage.setItem("posicionXPersonaje", 760 as any);
        localStorage.setItem(
          "posicionYPersonaje",
          (this.dimensionLevel.height - 1400) as any
        );
        localStorage.setItem("posicionAngleInitial", -20 as any);
        localStorage.setItem(
          "scrollY",
          (this.dimensionLevel.height - 1400) as any
        );
        funcionActualizarDatosFB({
          posicionXPersonaje: 760,
          posicionYPersonaje: this.dimensionLevel.height - 1400,
          posicionAngleInitial: -20,
          scrollY: this.dimensionLevel.height - 1400,
        });
        this.playAnimationMono(760, this.dimensionLevel.height - 1400, -20);
        break;
      case "8":
        localStorage.setItem("posicionXPersonaje", 370 as any);
        localStorage.setItem(
          "posicionYPersonaje",
          (this.dimensionLevel.height - 1450) as any
        );
        localStorage.setItem("posicionAngleInitial", 25 as any);
        localStorage.setItem(
          "scrollY",
          (this.dimensionLevel.height - 1450) as any
        );
        funcionActualizarDatosFB({
          posicionXPersonaje: 370,
          posicionYPersonaje: this.dimensionLevel.height - 1450,
          posicionAngleInitial: 25,
          scrollY: this.dimensionLevel.height - 1450,
        });
        this.playAnimationMono(370, this.dimensionLevel.height - 1450, 25);
        break;
      case "17":
        localStorage.setItem("posicionXPersonaje", 200 as any);
        localStorage.setItem(
          "posicionYPersonaje",
          (this.dimensionLevel.height - 1620) as any
        );
        localStorage.setItem("posicionAngleInitial", 25 as any);
        localStorage.setItem(
          "scrollY",
          (this.dimensionLevel.height - 1620) as any
        );
        funcionActualizarDatosFB({
          posicionXPersonaje: 200,
          posicionYPersonaje: this.dimensionLevel.height - 1620,
          posicionAngleInitial: 25,
          scrollY: this.dimensionLevel.height - 1620,
        });
        this.playAnimationMono(200, this.dimensionLevel.height - 1620, 25);
        break;
      case "9":
        break;
      /*          
case '2':
                this.playAnimationMono(440,this.dimensionLevel.height-400,35)
            break;  */
    }
  }

  playAnimationMono(x: any, y: any, angle: any) {
    setTimeout(() => {
      this.tweens.add({
        targets: this.personajeLevel,
        x,
        y,
        angle,
        duration: 600,
        ease: "Power0",
      });
    }, 2500);
  }

  update(time: any, delta: any) {
    // this.controls.update(delta)
  }

  mascaraInicio(tipo: any, activeScroll: any, salirScena: any, callback: any) {
    this.imagenBG = this.add
      .image(this.centroCanvas.width, this.centroCanvas.height, "circlebg")
      .setVisible(false)
      .setDepth(999);

    this.shape1 = this.make.graphics();
    var mask1 = this.shape1.createBitmapMask(this.imagenBG);

    this.cameras.main.setMask(mask1);

    let counter = tipo === "entrar" ? 0 : 2;
    let countTwen = tipo === "entrar" ? 4.8 : 0;
    this.transition = { counter: counter };

    // tween counter variable and set onUpdate function
    let tweenEffect = this.tweens.add({
      targets: this.transition,
      counter: countTwen,
      callbackScope: this,
      onUpdate: this.updateTransition,
      onStart: () => {
        this.music2 = this.sound.add("efecto_scene", { volume: 0.2 });
        this.music2.play();
      },
      ease: "Sine.easeInOut",
      yoyo: false,
      repeat: 0,
      duration: 1500,
    });

    tweenEffect.on("complete", () => {
      if (activeScroll) {
        this.activeScroll();
      }
      if (salirScena) {
        callback();
        this.music.destroy();
      }
    });
  }

  updateTransition() {
    this.imagenBG.setScale(this.transition.counter as any);
  }

  activeScroll() {
    let cameraOptions = {
      contentBounds: {
        length: 2220,
      },
      wheel: {
        enable: false,
        delta: 60,
      },
      drag: 0.9,
      snap: {
        enable: false,
        padding: 20,
        bounces: 3,
      },
      horizontal: false,
    };
    this.myCamera = new ScrollingCamera(this, cameraOptions as any);
    // console.log(this.myCamera, "la camaraaaaaa");
    /*        setInterval(()=>{ */
    // let initialScrollY =

    let posicionYPersonaje = localStorage.getItem("scrollY")
      ? parseInt(localStorage.getItem("scrollY") as any)
      : 1800;
    console.log(posicionYPersonaje, "estaaaa es la posicion");
    this.myCamera.setSpeed(posicionYPersonaje * 5);
    document.addEventListener("wheel", (e) => {
      this.myCamera.setSpeed(e.deltaY * 25);
    });
  }

  salirEscena(callback: any) {
    this.myCamera.setVisible(0);
    this.mascaraInicio("salir", false, true, callback);
  }

  setCamerasEvents() {
    // function getValue(snapIndex: any, initialValue: any, increment: any) {
    //   return snapIndex * increment + initialValue;
    // }
    // Snap event of "drag" camera
    this.myCamera.on("snap", (snapIndex: any) => {
      console.log("jeje");
      /*           const drag = getValue(snapIndex, 0.95, -0.05);
          this.camera1.drag = drag;
          this.camera2.drag = drag; */
    });
  }
  createWindow(func: any, data: any) {
    var handle = "window" + this.count++;

    var win = this.add.zone(0, 0, 0, 0).setDepth(555);

    var demo = new func(handle, win);

    //  this.input.setDraggable(win);
    if (data.nivel) {
      localStorage.setItem("nivel", data.nivel);
      funcionActualizarDatosFB({ nivel: data.nivel });
    }
    return this.scene.add(handle, demo, true, data);
  }
}

export default Levels;
