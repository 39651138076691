import { funcionActualizarDatosFB } from "../../common/utils";

class MapaPared extends Phaser.Scene {
  parent;
  keyScene;
  centroCanvas;
  extremoCanvas;
  tipo;
  pasarNivel;
  background;
  pared;
  pared2;
  btn;
  text;
  constructor(
    handle: any,
    parent: any,
    tipo: any,
    pasarNivel: any,
    background: any,
    pared: any,
    pared2: any,
    btn: any,
    text: any
  ) {
    super(handle);
    this.parent = parent;
    this.keyScene = handle;
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
    this.tipo = tipo;
    this.pasarNivel = pasarNivel;
    this.background = background;
    this.pared = pared;
    this.pared2 = pared2;
    this.btn = btn;
    this.text = text;
  }

  init(data: any) {
    console.log("Se ha iniciado la escena Mapa del techo");

    this.centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    this.extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };
    this.tipo = data.tipo;
    //console.log(data)
    this.pasarNivel = data.pasarNivel;
  }

  preload() {}

  create() {
    this.background = this.add
      .image(this.centroCanvas.width, this.centroCanvas.height, "fondopop")
      .setScale(0.5)
      .setAlpha(0);

    this.cameras.main.setBackgroundColor("rgba(29, 26, 71, 0.69)");
    this.cameras.main.setViewport(
      0,
      0,
      this.sys.game.config.width as number,
      this.sys.game.config.height as number
    );

    if (JSON.parse(localStorage.getItem("mejorasRealizadas") as any)[17]) {
      this.pared = this.add
        .image(
          this.centroCanvas.width - 140,
          this.centroCanvas.height,
          "pared72"
        )
        .setScale(0)
        .setAlpha(0);
      this.pared2 = this.add
        .image(
          this.centroCanvas.width + 100,
          this.centroCanvas.height,
          "pared7"
        )
        .setScale(0)
        .setAlpha(0);
    } else {
      this.pared = this.add
        .image(
          this.centroCanvas.width - 140,
          this.centroCanvas.height,
          "paredmapa"
        )
        .setScale(0)
        .setAlpha(0);
      this.pared2 = this.add
        .image(
          this.centroCanvas.width + 100,
          this.centroCanvas.height,
          "paredmapa2"
        )
        .setScale(0)
        .setAlpha(0);
    }

    // const textStarWar: any = document.querySelector(".textoStarWar");
    // textStarWar.style.display = "none";
    //botones
    this.btn = this.add
      .image(this.centroCanvas.width, this.centroCanvas.height + 150, "boton2")
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.add.tween({
          targets: [
            this.background,
            this.btn,
            this.text,
            this.pared,
            this.pared2,
          ],
          scale: 0,
          alpha: 0,
          ease: "Power3",
          onStart: () => {
            setTimeout(() => {
              let data = this.tipo ? { finalizo: this.tipo } : {};
              let scena = this.tipo ? "Tutorial" : "Play";
              if (this.pasarNivel) {
                localStorage.setItem("pasarNivel", "true");
                funcionActualizarDatosFB({ pasarNivel: "true" });
                this.scene.run(scena, {
                  pasarNivel: true,
                  finalizo: this.tipo,
                });
              } else {
                this.scene.run(scena, data);
              }
              this.sound.play("click");
              this.scene.remove(this.keyScene);
            }, 150);
          },
          repeat: 0,
          duration: 400,
        });
      })
      .setScale(0.5);
    this.text = this.add
      .text(
        this.centroCanvas.width - 30,
        this.centroCanvas.height + 135,
        "Salir",
        { fontFamily: "font1", fontSize: 24 }
      )
      .setScale(0.5)
      .setAlpha(0);

    this.add.tween({
      targets: [this.background, this.btn, this.text],
      scale: 1,
      alpha: 1,
      ease: "Power3",
      repeat: 0,
      duration: 350,
    });
    this.add.tween({
      targets: [this.pared],
      scale: JSON.parse(localStorage.getItem("mejorasRealizadas") as any)[17]
        ? 0.7
        : 0.4,
      alpha: 1,
      ease: "Power3",
      repeat: 0,
      duration: 350,
    });
    this.add.tween({
      targets: [this.pared2],
      scale: 0.8,
      alpha: 1,
      ease: "Power3",
      repeat: 0,
      duration: 350,
    });
  }

  update() {}
}

export default MapaPared;
