/* eslint-disable jsx-a11y/alt-text */
"use client";
import React from "react";
const Loaded = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <img
        style={{
          width: 320,
          objectFit: "contain",
          animationName: "floating",
          animationDuration: "3s",
          animationIterationCount: "infinite",
          animationTimingFunction: "ease-in-out",
        }}
        src="assets/level/logo.png"
      />
    </div>
  );
};

export default Loaded;
