export function generateUUID() {
  const cryptoObj = (window as any).crypto || (window as any).msCrypto; // Compatibilidad con navegadores antiguos
  if (cryptoObj && cryptoObj.getRandomValues) {
    // Usar window.crypto.getRandomValues para generar números aleatorios seguros
    const buffer = new Uint16Array(8);
    cryptoObj.getRandomValues(buffer);
    return (
      pad4(buffer[0]) +
      pad4(buffer[1]) +
      "-" +
      pad4(buffer[2]) +
      "-" +
      pad4(buffer[3]) +
      "-" +
      pad4(buffer[4]) +
      "-" +
      pad4(buffer[5]) +
      pad4(buffer[6]) +
      pad4(buffer[7])
    );
  } else {
    // Generar UUID de manera menos segura si crypto no está disponible
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}

function pad4(num: any) {
  return num.toString(16).padStart(4, "0");
}

///enviando petición a lms kampus
// ID USER = 16679
//?param=14662&tchid=&course=1724
const PATHURL = new URLSearchParams(window.location.search.substr(1));

const URLKAMPUS =
  "https://app.cursovia.com/c-cb46ac8f/c49868c59d03dc485ba93e03aa1dc1f7922a582c/a/apirest";

const URLSERVICE = "https://service.lticonstruye.cl";

export function clearLocalStorageAll() {
  localStorage.clear();
}

export function isMobile() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}

export const fetchRequestKampus = async (
  parametroKampus: any,
  paramCourse: any,
  setLogged: any,
  setLoading: any
) => {
  localStorage.clear();
  fetch(`${URLKAMPUS}/${paramCourse}/${parametroKampus}`, {
    method: "GET",
  })
    .then((res) => res.json())
    .then(async (resp) => {
      const dataUser = resp.user_data;
      if (dataUser.email) {
        const getUser: any = await fetch(`${URLSERVICE}/api/getUser`, {
          method: "POST",
          body: JSON.stringify({ email: dataUser.email }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const respGetUser = await getUser.json();
        // console.log("entrar a jalar info", respGetUser);
        try {
          // console.log();
          if (respGetUser.error) {
            const createUser = await fetch(`${URLSERVICE}/api/createUser`, {
              method: "POST",
              body: JSON.stringify(dataUser),
              headers: {
                "Content-Type": "application/json",
              },
            });
            console.log(createUser);
          }

          sessionStorage.setItem(
            "datosUser",
            JSON.stringify({
              email: dataUser.email,
              unique_id: dataUser.unique_id,
              nombre: `${dataUser.firstname}`,
              apellido: `${dataUser.surname}`,
              course_id: resp.course_data.unique_id,
              course_name: resp.course_data.course_name,
            })
          );
          sessionStorage.setItem("invitado", false as any);
          (window as any).noregistrado.style.display = "none";

          await establecerDataUserRegistrado({
            email: dataUser.email,
            unique_id: dataUser.unique_id,
            nombre: `${dataUser.firstname}`,
            apellido: `${dataUser.surname}`,
            course_id: resp.course_data.unique_id,
            course_name: resp.course_data.course_name,
          });
          await setLogged(true);

          setLogged(true);
        } catch (error) {
          console.log(error);
          await establecerDataInvitado();
          await setLoading(false);
          await setLogged(false);
        }
      } else {
        (window as any).noregistrado.style.display = "block";
        sessionStorage.setItem("invitado", true as any);
        clearSesionUser();
        await establecerDataInvitado();
        await setLogged(false);
      }
      setLoading(false);
    })
    .catch(async (err) => {
      (window as any).noregistrado.style.display = "block";
      console.log("no autenticado", err);
      sessionStorage.setItem("invitado", true as any);
      clearSesionUser();
      (window as any).noregistrado.style.display = "block";
      await establecerDataInvitado();
      await setLoading(false);
      await setLogged(false);
    });
};

const establecerDataInvitado = () => {
  if (!localStorage.getItem("etapasRealizadas")) {
    localStorage.setItem(
      "etapasRealizadas",
      JSON.stringify({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
      })
    );
  }
  if (!localStorage.getItem("mejorasRealizadas")) {
    localStorage.setItem(
      "mejorasRealizadas",
      JSON.stringify({
        11: false,
        12: false,
        13: false,
        14: false,
        15: false,
        16: false,
        17: false,
        18: false,
        19: false,
      })
    );
  }
  if (!localStorage.getItem("molestosBorrados")) {
    localStorage.setItem(
      "molestosBorrados",
      JSON.stringify({
        taladro1: false,
        taladro2: false,
        guincha: false,
        guincha2: false,
        martillo: false,
        martillo2: false,
        martillo3: false,
        perno: false,
        perno2: false,
        casco: false,
        casco2: false,
        audifono: false,
        audifono2: false,
        balde: false,
        balde2: false,
        balde3: false,
        lampa: false,
        lampa2: false,
        lapiz: false,
        lapiz2: false,
        cuadro: false,
        cuadro2: false,
      })
    );
  }
  if (!localStorage.getItem("dinero")) {
    localStorage.setItem("dinero", 0 as any);
  }

  sessionStorage.setItem(
    "datosUser",
    JSON.stringify({
      email: "test@test.com",
      unique_id: "0000",
      nombre: "first",
      apellido: "last",
      course_id: "1111",
      course_name: "course test",
    })
  );
};

const establecerDataUserRegistrado = async (datosUser: any) => {
  // console.log("entre aqui");

  try {
    const respTracking: any = await fetch(
      `${URLSERVICE}/api/juego/getTracking`,
      {
        method: "POST",
        body: JSON.stringify({ email: datosUser.email }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const decodeRespTracking = await respTracking.json();
    if (decodeRespTracking.error) {
      let datosTracking = {
        etapasRealizadas: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false,
          8: false,
          9: false,
        },
        mejorasRealizadas: {
          11: false,
          12: false,
          13: false,
          14: false,
          15: false,
          16: false,
          17: false,
          18: false,
          19: false,
        },
        molestosBorrados: {
          taladro1: false,
          taladro2: false,
          guincha: false,
          guincha2: false,
          martillo: false,
          martillo2: false,
          martillo3: false,
          perno: false,
          perno2: false,
          casco: false,
          casco2: false,
          audifono: false,
          audifono2: false,
          balde: false,
          balde2: false,
          balde3: false,
          lampa: false,
          lampa2: false,
          lapiz: false,
          lapiz2: false,
          cuadro: false,
          cuadro2: false,
        },
        dinero: 0,
        castigo: { count: 0, total: 0 },
        premio: { count: 0, total: 0 },
        profesor: "test",
        nivelesD: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0 },
        course_id: datosUser.course_id,
        course_name: datosUser.course_name,
        cuentaRegresiva: {
          1: {
            minuto: "10",
            segundos: "00",
          },
          2: {
            minuto: "10",
            segundos: "00",
          },
          3: {
            minuto: "10",
            segundos: "00",
          },
          4: {
            minuto: "10",
            segundos: "00",
          },
          5: {
            minuto: "10",
            segundos: "00",
          },
          6: {
            minuto: "10",
            segundos: "00",
          },
          7: {
            minuto: "10",
            segundos: "00",
          },
          8: {
            minuto: "10",
            segundos: "00",
          },
        },
        scrollY: 1800,
      };

      localStorage.setItem(
        "etapasRealizadas",
        JSON.stringify(datosTracking.etapasRealizadas)
      );
      localStorage.setItem(
        "cuentaRegresiva",
        JSON.stringify(datosTracking.cuentaRegresiva)
      );
      localStorage.setItem(
        "mejorasRealizadas",
        JSON.stringify(datosTracking.mejorasRealizadas)
      );
      localStorage.setItem(
        "molestosBorrados",
        JSON.stringify(datosTracking.molestosBorrados)
      );

      localStorage.setItem("scrollY", datosTracking.scrollY as any);

      localStorage.setItem("dinero", 0 as any);

      const setTracking = await fetch(`${URLSERVICE}/api/juego/tracking`, {
        method: "POST",
        body: JSON.stringify({
          email: datosUser.email,
          tracking: datosTracking,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const respSetTracking = await setTracking.json();
      try {
        console.log(respSetTracking);
      } catch (error) {
        console.log(error, "al establecer el trackingi nicial de un usuario");
      }
    } else {
      const {
        dinero,
        etapasRealizadas,
        mejorasRealizadas,
        molestosBorrados,
        escenaActual,
        etapa,
        nivel,
        nivelLevel,
        posicionAngleInitial,
        posicionXPersonaje,
        posicionYPersonaje,
        scrollY,
        piezasCompradas,
        nombrePersonaje,
        namePersonaje,
        nivelesD,
        cuentaRegresiva,
      } = decodeRespTracking;

      localStorage.setItem("dinero", dinero);
      localStorage.setItem(
        "mejorasRealizadas",
        JSON.stringify(mejorasRealizadas)
      );
      localStorage.setItem(
        "molestosBorrados",
        JSON.stringify(molestosBorrados)
      );
      localStorage.setItem("cuentaRegresiva", JSON.stringify(cuentaRegresiva));
      // localStorage.setItem("mejorasRealizadas", JSON.stringify(mejorasRealizadas))
      // localStorage.setItem("molestosBorrados", JSON.stringify(molestosBorrados))
      if (typeof etapasRealizadas == "string") {
        localStorage.setItem("etapasRealizadas", etapasRealizadas);
      } else {
        localStorage.setItem(
          "etapasRealizadas",
          JSON.stringify(etapasRealizadas)
        );
      }

      if (escenaActual) {
        localStorage.setItem("escenaActual", escenaActual);
      }
      if (etapa) {
        localStorage.setItem("etapa", etapa);
      }
      if (nivel) {
        localStorage.setItem("nivel", nivel);
      }

      if (nivelLevel) {
        localStorage.setItem("nivelLevel", nivelLevel); //modificado para que jueguen todos
      }
      if (posicionAngleInitial) {
        localStorage.setItem("posicionAngleInitial", posicionAngleInitial);
      }
      if (posicionXPersonaje) {
        localStorage.setItem("posicionXPersonaje", posicionXPersonaje);
      }
      if (posicionYPersonaje) {
        localStorage.setItem("posicionYPersonaje", posicionYPersonaje);
      }
      if (scrollY) {
        localStorage.setItem("scrollY", scrollY);
      }

      if (piezasCompradas) {
        localStorage.setItem("piezasCompradas", piezasCompradas);
      }

      if (namePersonaje) {
        localStorage.setItem("namePersonaje", namePersonaje);
      }
      if (nombrePersonaje) {
        localStorage.setItem("nombrePersonaje", nombrePersonaje);
      }

      if (nivelesD) {
        if (typeof nivelesD == "string") {
          localStorage.setItem("nivelesD", nivelesD);
        } else {
          localStorage.setItem("nivelesD", JSON.stringify(nivelesD));
        }
        localStorage.setItem("nivelesD", JSON.stringify(nivelesD));
      }
    }
  } catch (error) {
    console.log(error, "el error al trackear");
  }
};

function warning() {
  sessionStorage.removeItem("init");
}
window.onbeforeunload = warning;

export function clearSesionUser() {
  localStorage.setItem(
    "etapasRealizadas",
    JSON.stringify({
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
    })
  );
  localStorage.setItem(
    "mejorasRealizadas",
    JSON.stringify({
      11: false,
      12: false,
      13: false,
      14: false,
      15: false,
      16: false,
      17: false,
      18: false,
      19: false,
    })
  );
  localStorage.setItem(
    "molestosBorrados",
    JSON.stringify({
      taladro1: false,
      taladro2: false,
      guincha: false,
      guincha2: false,
      martillo: false,
      martillo2: false,
      martillo3: false,
      perno: false,
      perno2: false,
      casco: false,
      casco2: false,
      audifono: false,
      audifono2: false,
      balde: false,
      balde2: false,
      balde3: false,
      lampa: false,
      lampa2: false,
      lapiz: false,
      lapiz2: false,
      cuadro: false,
      cuadro2: false,
    })
  );
  localStorage.setItem("dinero", 0 as any);
  localStorage.setItem("countInvitado", 1 as any);
}

export async function funcionActualizarDatosFB(dato: any) {
  const datosTracking = {
    ...dato,
  };
  const email = JSON.parse(sessionStorage.getItem("datosUser") as any)?.email;
  if (email) {
    try {
      const respRequest = await fetch(`${URLSERVICE}/api/juego/tracking`, {
        method: "POST",
        body: JSON.stringify({ email: email, tracking: datosTracking }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const respJson = await respRequest.json();
      console.log(respJson);
    } catch (error) {
      console.log(error, "error trackeo");
    }
  }
}
