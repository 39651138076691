class CohetesBegli extends Phaser.Scene {
  constructor(handle: any, parent: any) {
    super({ key: "CohetesBegli" });
  }

  init(data: any) {
    console.log("Se ha iniciado la escena CohetesBegli");

    (this as any).centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    (this as any).extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };
  }

  preload() {
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexuiplugin.min.js",
      sceneKey: "rexUI",
    });
  }

  create() {
    (this as any).background = this.add
      .image(0, 0, "finalbgnew")
      .setOrigin(0, 0);
    this.mascaraInicio("entrar", false, null, null);

    setTimeout(() => {
      const callBack = () => {};
      this.salirEscena(callBack);
    }, 9500);
  }

  update() {}

  createTextBox(
    scene: any,
    x: any,
    y: any,
    config: any,
    fondo: any,
    altura: any,
    tipo: any
  ) {
    const GetValue = Phaser.Utils.Objects.GetValue;
    var wrapWidth = GetValue(config, "wrapWidth", 0);
    var fixedWidth = GetValue(config, "fixedWidth", 0);
    var fixedHeight = GetValue(config, "fixedHeight", 0);
    let spaceJson = {
      left: 70,
      right: 20,
      top: 20,
      bottom: 20,
      icon: 10,
      text: 10,
    };
    if (tipo === "left") {
      spaceJson = {
        left: 20,
        right: 70,
        top: 20,
        bottom: 20,
        icon: 10,
        text: 10,
      };
    }
    if (tipo === "center") {
      console.log("centrado");
      spaceJson = {
        left: 20,
        right: 20,
        top: 40,
        bottom: 20,
        icon: 10,
        text: 10,
      };
    }
    var textBox = scene.rexUI.add
      .textBox({
        x: x,
        y: y,
        background: fondo,
        // icon: scene.add.image(0, 0, 'nextPage').setTint(COLOR_LIGHT).setVisible(false),
        text: this.getBBcodeText(
          scene,
          wrapWidth,
          fixedWidth,
          fixedHeight,
          altura
        ),
        // action: scene.add.image(0, 0, 'nextPage').setTint(COLOR_LIGHT).setVisible(false),
        space: spaceJson,
      })
      .setOrigin(0)
      .layout();
    textBox
      .setInteractive({ cursor: "pointer" })
      .on(
        "pointerdown",
        function () {
          // var icon = this.getElement('action').setVisible(false);
          //this.resetChildVisibleState(icon);
          if (scene.isTyping) {
            //  this.stop(true);
          } else {
            scene.typeNextPage();
          }
        },
        textBox
      )
      .on(
        "pageend",
        function () {
          if (scene.isLastPage) {
            if (scene.mostrarOtro) {
              scene.mostrarOtro = false;
              scene.btnGrupo.setVisible(1);
              clearInterval(scene.intervaloConversa);
              return;
            }
            //  this.scene.btnGrupo.setVisible(1)
          }
          /*                 var icon = this.getElement('action').setVisible(true);
                this.resetChildVisibleState(icon);
                icon.y -= 30;
                var tween = scene.tweens.add({
                    targets: icon,
                    y: '+=30',
                    ease: 'Bounce',
                    duration: 500,
                    repeat: 0,
                    yoyo: false
                }); */
        },
        textBox
      )(this as any).intervaloConversa = setInterval(() => {
      textBox.emit("pointerdown");
    }, 800);

    (this.input.keyboard as any).on("keydown-ENTER", function (event: any) {
      textBox.emit("pointerdown");
    });
    return textBox;
  }

  getBuiltInText(
    scene: any,
    wrapWidth: any,
    fixedWidth: any,
    fixedHeight: any
  ) {
    return scene.add
      .text(0, 0, "", {
        fontSize: "16px",
        wordWrap: {
          width: wrapWidth,
        },
        maxLines: 3,
        color: "#0B2167",
      })
      .setFixedSize(fixedWidth, fixedHeight);
  }

  getBBcodeText(
    scene: any,
    wrapWidth: any,
    fixedWidth: any,
    fixedHeight: any,
    altura: any
  ) {
    return scene.rexUI.add.BBCodeText(0, 0, "", {
      fixedWidth: fixedWidth,
      fixedHeight: fixedHeight,
      fontSize: "16px",
      wrap: {
        mode: "word",
        width: wrapWidth,
      },
      maxLines: altura ? altura : 5,
      color: "#000000",
    });
  }

  salirEscena(callback: any) {
    this.mascaraInicio("salir", false, true, callback);
  }

  updateTransition() {
    (this as any).imagenBG.setScale((this as any).transition.counter);
  }

  mascaraInicio(tipo: any, activeScroll: any, salirScena: any, callback: any) {
    (this as any).imagenBG = this.add
      .image(
        (this as any).centroCanvas.width,
        (this as any).centroCanvas.height,
        "circlebg"
      )
      .setVisible(false)
      .setDepth(999);

    (this as any).shape1 = this.make.graphics();
    var mask1 = (this as any).shape1.createBitmapMask((this as any).imagenBG);

    this.cameras.main.setMask(mask1);

    let counter = tipo === "entrar" ? 0 : 2;
    let countTwen = tipo === "entrar" ? 4.8 : 0;
    (this as any).transition = { counter: counter };

    // tween counter variable and set onUpdate function
    let tweenEffect = this.tweens.add({
      targets: (this as any).transition,
      counter: countTwen,
      callbackScope: this,
      onUpdate: this.updateTransition,
      ease: "Sine.easeInOut",
      yoyo: false,
      repeat: 0,
      duration: 1500,
    });

    tweenEffect.on("complete", () => {
      if (activeScroll) {
        (this as any).activeScroll();
      }
      if (salirScena) {
        callback();
      }
    });
  }
}

export default CohetesBegli;
