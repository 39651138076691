import * as Phaser from "phaser";

//(function(){
// let root = (this as any);

export default class SliderPhaser extends Phaser.Plugins.ScenePlugin {
  constructor(
    scene: Phaser.Scene,
    pluginManager: Phaser.Plugins.PluginManager
  ) {
    super(scene, pluginManager, "SliderPhaser");
    (this as any).locked = false;
  }

  init(options: {
    x: number;
    y: number;
    width: number;
    height: number;
    targets: string[];
    loop: boolean;
    arrow: { left: string; right: string };
    autoAnimate: boolean;
    scaleImage: number;
    enableBG: boolean;
  }) {
    const {
      x,
      y,
      width,
      height,
      targets,
      loop,
      arrow,
      autoAnimate,
      scaleImage,
      enableBG,
    } = options as any;

    (this as any).initW = width;
    (this as any).initH = height;
    (this as any).iniX = x;
    (this as any).iniY = y;
    (this as any).targets = targets;
    (this as any).arrow = arrow;
    (this as any).loop = loop;
    (this as any).autoAnimate = autoAnimate;
    (this as any).slideIndex = 0;

    ///objects slide
    (this as any).sliderBGGroup = (this as any).scene.add.group();
    (this as any).sliderMainGroup = (this as any).scene.add.group();
    (this as any).sliderBGGroup.width = width;
    (this as any).sliderBGGroup.height = height;
    (this as any).sliderBGGroup.x = x;
    (this as any).sliderBGGroup.y = y;

    (this as any).sliderMainGroup.width =
      width * targets.length === 0 ? 1 : targets.length;

    (this as any).sliderMainGroup.height = height;
    (this as any).sliderMainGroup.x = x;
    (this as any).sliderMainGroup.y = y;

    // CONTROLES
    (this as any).sliderControlsGroup = (this as any).scene.add.group();
    (this as any).sliderControlsGroup.width = width;
    (this as any).sliderControlsGroup.height = height;
    (this as any).sliderControlsGroup.x = x;
    (this as any).sliderControlsGroup.y = y;

    // MASCARA
    if (enableBG) {
      let maskGraphics = (this as any).scene.add.graphics(0, 0);
      maskGraphics.fillPath(0xffffff);
      maskGraphics.fillRect(x, y, width, height);
      (this as any).sliderMainGroup.mask = maskGraphics;
    }
    // CONTROLES

    let chevronRight: any;
    let chevronLeft: any;

    chevronLeft = (this as any).scene.add
      .image(0, 0, arrow.left)
      .setScale(0.15);
    chevronRight = (this as any).scene.add
      .image(0, 0, arrow.right)
      .setScale(0.15);

    chevronRight.x = width / 2 + x + chevronRight.width * 0.5;
    chevronRight.y = height / 2 + y;

    chevronRight.setInteractive({ cursor: "pointer" }).on("pointerdown", () => {
      if (!(this as any).locked) {
        (this as any).locked = true as any;
        (this as any).goToNext();
      }
    });

    chevronLeft.x = width / 2 + x - chevronLeft.width * 0.5;
    chevronLeft.y = height / 2 + y;

    chevronLeft.setTint(0x8d0582);
    chevronRight.setTint(0x8d0582);

    chevronLeft.setAlpha(0);
    if (targets.length === 1) {
      chevronRight.setAlpha(0);
    }

    chevronLeft.setInteractive({ cursor: "pointer" }).on("pointerdown", () => {
      if (!(this as any).locked) {
        (this as any).locked = true as any;
        (this as any).goToPrev();
      }
    });

    (this as any).sliderControlsGroup.add(chevronRight);
    (this as any).sliderControlsGroup.add(chevronLeft);
    (this as any).sliderControlsGroup.setDepth(22);

    targets.forEach((element: any, index: any) => {
      let image;
      if (index === 0) {
        image = (this as any).scene.add.image(
          (width / 2 + x) * 1,
          height / 2 + y,
          element
        );
      } else {
        image = (this as any).scene.add.image(
          (width / 2 + x) * 1,
          height / 2 + y,
          element
        );
        image.setAlpha(0);
      }

      image.setScale(scaleImage);
      (this as any).sliderMainGroup.add(image);
    });
    console.log(targets.length);
  }

  startSlider() {
    // console.log()
    let time = (this as any).scene.time.addEvent({
      delay: 500, // ms
      callback: (this as any).goToNext,
      callbackScope: (this as any).scene,
      loop: true,
    });
    (this as any).timer = time;
  }

  stopSlider() {}

  goToNext() {
    let finalX = (this as any).sliderMainGroup.x + (this as any).initW * 1;

    if (
      (this as any).slideIndex >= (this as any).targets.length - 1 &&
      (this as any).autoAnimate === false
    ) {
      (this as any).stopSlider();
      return false;
    }

    // animate loop
    if ((this as any).autoAnimate === true) {
      if ((this as any).slideIndex >= (this as any).targets.length - 1) {
        (this as any).slideIndex = 0;
        (this as any).sliderMainGroup.x = (this as any).iniX;
        (this as any).locked = false;
        return true;
      }
    }

    (this as any).tweenObj = (this as any).scene.add.tween({
      targets: (this as any).sliderMainGroup.children.entries[
        (this as any).slideIndex
      ],
      x: finalX,
      alpha: 0,
      ease: "Line",
      repeat: 0,
      duration: 150,
      onComplete: () => {
        //  let indexActual = (this as any).slideIndex
        let nextIndex = ((this as any).slideIndex + 1) as any;
        (this as any).slideIndex = nextIndex;
        (this as any).sliderMainGroup.children.entries[nextIndex].width =
          finalX * -1;
        let centerWidth = ((this as any).initW / 2 + (this as any).iniX) as any;
        (this as any).scene.add.tween({
          targets: (this as any).sliderMainGroup.children.entries[nextIndex],
          x: centerWidth,
          alpha: 1,
          ease: "Line",
          repeat: 0,
          duration: 150,
          onComplete: () => {
            (this as any).locked = false;
          },
        });

        if (
          (this as any).autoAnimate === false &&
          (this as any).slideIndex >= (this as any).targets.length - 1
        ) {
          (this as any).sliderControlsGroup.children.entries[0].setAlpha(0);
        }
        // console.log((this as any).sliderControlsGroup)
        (this as any).sliderControlsGroup.children.entries[1].setAlpha(1);
        console.log((this as any).slideIndex, "next");
      },
    });
  }

  goToPrev() {
    let finalX = (this as any).sliderMainGroup.x + (this as any).initW * -1;

    if ((this as any).slideIndex <= 0 && (this as any).autoAnimate === false) {
      (this as any).stopSlider();
      return false;
    }

    // animate loop
    if ((this as any).autoAnimate === true) {
      if ((this as any).slideIndex >= (this as any).targets.length - 1) {
        (this as any).slideIndex = 0;
        (this as any).sliderMainGroup.x = (this as any).iniX;
        (this as any).locked = false;
        return true;
      }
    }

    //  console.log((this as any).slideIndex, "prev")

    (this as any).tweenObj = (this as any).scene.add.tween({
      targets: (this as any).sliderMainGroup.children.entries[
        (this as any).slideIndex
      ],
      x: finalX,
      alpha: 0,
      ease: "Line",
      repeat: 0,
      duration: 150,
      onComplete: () => {
        // let indexActual = (this as any).slideIndex
        let nextIndex = ((this as any).slideIndex - 1) as any;
        (this as any).slideIndex = nextIndex;
        if ((this as any).slideIndex < 0) {
          (this as any).slideIndex = 0;
          nextIndex = 0;
        }
        (this as any).sliderMainGroup.children.entries[nextIndex].width =
          (finalX * +1) as any;

        (this as any).scene.add.tween({
          targets: (this as any).sliderMainGroup.children.entries[nextIndex],
          x: (this as any).initW / 2 + (this as any).iniX,
          alpha: 1,
          ease: "Line",
          repeat: 0,
          duration: 150,
          onComplete: () => {
            (this as any).locked = false;
          },
        });

        if ((this as any).slideIndex <= 0) {
          (this as any).sliderControlsGroup.children.entries[1].setAlpha(0);
        }
        //  console.log((this as any).sliderControlsGroup)
        (this as any).sliderControlsGroup.children.entries[0].setAlpha(1);
      },
    });
  }

  hideAllElements() {
    (this as any).sliderBGGroup.setAlpha(0);
    (this as any).sliderControlsGroup.setAlpha(0);
    (this as any).sliderMainGroup.setAlpha(0);
  }
}

// if (typeof exports !== 'undefined') {
//     if (typeof module !== 'undefined' && module.exports) {
//         exports = module.exports = SliderPhaser;
//     }
//     exports.SliderPhaser = SliderPhaser;
// } else if (typeof define !== 'undefined' && define.amd) {
//     define('SliderPhaser', (function() { return root.SliderPhaser = SliderPhaser; })() );
// } else {
//     root.SliderPhaser = SliderPhaser;
// }

// return SliderPhaser;

//}).call((this as any))
