class IframeModal extends Phaser.Scene {
  parent;
  keyScene;
  centroCanvas;
  extremoCanvas;
  nivel;
  background;
  clave;
  error;
  modal;
  buttonImage;
  respuestaT;
  url;
  constructor(handle: any, parent: any) {
    super(handle);
    this.parent = parent;
    this.keyScene = handle;
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
    this.nivel = 0;
    this.clave = "";
    this.background = "" as any;
    this.error = "" as any;
    this.modal = "" as any;
    this.buttonImage = "" as any;
    this.respuestaT = "" as any;
    this.url = "" as any;
  }

  init(data: any) {
    console.log("Se ha iniciado la escena IframeModal");
    this.centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    this.extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };
    this.nivel = parseInt(localStorage.getItem("nivel") as any);
    this.clave = data.clave;
    this.url = data.url;
  }

  preload() {}

  create() {
    this.add
      .dom(this.centroCanvas.width, this.centroCanvas.height)
      .createFromHTML(
        `<iframe width="800px" height="500px" src="${this.url}" frameborder="0" allowfullscreen/>`
      );

    this.cameras.main.setBackgroundColor("rgba(29, 26, 71, 0.69)");
    this.cameras.main.setViewport(
      0,
      0,
      this.sys.game.config.width as number,
      this.sys.game.config.height as number
    );

    this.add
      .image(
        this.centroCanvas.width + 390,
        this.centroCanvas.height - 285,
        "close"
      )
      .setScale(0.1)
      .setDepth(9999)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.scene.resume("Levels", {
          etapa: this.nivel,
          abrir: false,
        });
        this.scene.remove(this.keyScene);
        //this.scene.resume("Levels");
      });
  }
}

export default IframeModal;
