import YoutubePlayer from "phaser3-rex-plugins/plugins/youtubeplayer.js";

class VideoBienvenida extends Phaser.Scene {
  centroCanvas;
  extremoCanvas;
  video;
  constructor() {
    super({ key: "VideoBienvenida" });
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
    this.video = "" as any;
  }

  init() {
    this.centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    this.extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };
  }

  create() {
    // Agrega el video a la escena
    let youtubePlayer = new YoutubePlayer(
      this,
      this.centroCanvas.width,
      this.centroCanvas.height,
      1024,
      770,
      {
        autoPlay: true,
        controls: false,
        keyboardControl: false,
        modestBranding: false,
        videoId: "p1hALDRueMQ",
      }
    );

    youtubePlayer.on(
      "ended",
      (player: any) => {
        console.log("acabo", this);
        this.scene.run("Personaje", { etapa: 1, confirm: true });
        this.scene.stop("VideoBienvenida");
      },
      this
    );

    this.add.existing(youtubePlayer);
    // Puedes configurar eventos para el video si lo deseas
  }
}

export default VideoBienvenida;
