import { funcionActualizarDatosFB } from "../../common/utils";

class MapaVentana extends Phaser.Scene {
  parent;
  keyScene;
  centroCanvas;
  extremoCanvas;
  tipo;
  pasarNivel;
  background;
  constructor(
    handle: any,
    parent: any,
    tipo: any,
    pasarNivel: any,
    background: any
  ) {
    super(handle);
    this.parent = parent;
    this.keyScene = handle;
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
    this.tipo = tipo;
    this.pasarNivel = pasarNivel;
    this.background = background;
  }

  init(data: any) {
    console.log("Se ha iniciado la escena Mapa del techo");

    this.centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    this.extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };
    this.tipo = data.tipo;
    //console.log(data)
    this.pasarNivel = data.pasarNivel;
  }

  preload() {}

  create() {
    this.background = this.add.image(
      this.centroCanvas.width,
      this.centroCanvas.height,
      "fondopop"
    );
    this.cameras.main.setBackgroundColor("rgba(29, 26, 71, 0.69)");
    this.cameras.main.setViewport(
      0,
      0,
      this.sys.game.config.width as number,
      this.sys.game.config.height as number
    );

    // const textStarWar: any = document.querySelector(".textoStarWar");
    // textStarWar.style.display = "none";

    this.add
      .image(
        this.centroCanvas.width - 140,
        this.centroCanvas.height,
        "ventanamapa"
      )
      .setScale(0.3);

    this.add
      .image(
        this.centroCanvas.width + 100,
        this.centroCanvas.height,
        "ventanamapa2"
      )
      .setScale(0.7);

    //botones
    this.add
      .image(this.centroCanvas.width, this.centroCanvas.height + 150, "boton2")
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        let data = this.tipo ? { finalizo: this.tipo } : {};
        let scena = this.tipo === "Tutorial" ? "Tutorial" : "Play";
        console.log(scena);
        if (this.pasarNivel) {
          localStorage.setItem("pasarNivel", "true");
          funcionActualizarDatosFB({ pasarNivel: "true" });
          this.scene.run(scena, { pasarNivel: true, finalizo: this.tipo });
        } else {
          this.scene.run(scena, data);
        }
        this.sound.play("click");
        this.scene.remove(this.keyScene);
      });
    this.add.text(
      this.centroCanvas.width - 30,
      this.centroCanvas.height + 135,
      "Salir",
      { fontFamily: "font1", fontSize: 24 }
    );
  }

  update() {}
}

export default MapaVentana;
