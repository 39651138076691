class PausaGame extends Phaser.Scene {
  parent;
  keyScene;
  centroCanvas;
  extremoCanvas;
  nivel;
  background;
  clave;
  error;
  modal;
  buttonImage;
  respuestaT;
  constructor(handle: any, parent: any) {
    super(handle);
    this.parent = parent;
    this.keyScene = handle;
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
    this.nivel = 0;
    this.clave = "";
    this.background = "" as any;
    this.error = "" as any;
    this.modal = "" as any;
    this.buttonImage = "" as any;
    this.respuestaT = "" as any;
  }

  init(data: any) {
    console.log("Se ha iniciado la escena PauseGame");
    this.centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    this.extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };
    this.nivel = parseInt(localStorage.getItem("nivel") as any);
    this.clave = data.clave;
  }

  preload() {}

  create() {
    this.background = this.add.image(
      this.centroCanvas.width,
      this.centroCanvas.height,
      "fondopop"
    ) as any;

    this.add
      .image(
        this.centroCanvas.width + 220,
        this.centroCanvas.height - 150,
        "close"
      )
      .setScale(0.1)
      .setDepth(15)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.scene.resume("Play", {
          etapa: this.nivel,
          abrir: false,
        });
        sessionStorage.setItem("toggleTime", "true");
        this.scene.remove(this.keyScene);
      });

    //document.querySelector(".textoStarWar").style.display = "none";
    this.cameras.main.setBackgroundColor("rgba(29, 26, 71, 0.69)");
    this.cameras.main.setViewport(
      0,
      0,
      this.sys.game.config.width as number,
      this.sys.game.config.height as number
    );

    this.add
      .text(
        this.centroCanvas.width - 200,
        this.centroCanvas.height + -40,
        `Juego pausado, vuelve cuando gustes`,
        {
          fontFamily: "font1",
          fontSize: 25,
          wordWrap: { width: 450, useAdvancedWrap: true },
          color: "#1C2A55",
        }
      )
      .setDepth(2);

    this.modal = this.add.image(
      this.centroCanvas.width,
      this.centroCanvas.height,
      "fondopop"
    );

    this.add
      .text(
        this.centroCanvas.width - 60,
        this.centroCanvas.height + 140,
        `JUGAR`,
        { fontFamily: "font1", fontSize: 32 }
      )
      .setDepth(2);

    this.buttonImage = this.add
      .image(
        this.centroCanvas.width,
        this.centroCanvas.height + 155,
        "buttonConfig"
      )
      .setInteractive({ cursor: "pointer" });

    this.buttonImage.on(Phaser.Input.Events.POINTER_DOWN, () => {
      this.sound.play("click");
      sessionStorage.setItem("toggleTime", "true");
      this.scene.resume("Play", {
        etapa: this.nivel,
        abrir: false,
      });
      this.scene.remove(this.keyScene);
    });
  }
}

export default PausaGame;
