import {
  materialesPuerta,
  materialesPared,
  materialesTecho,
  materialesVentana,
  mejorasPrecios,
  costoMejoras,
} from "../common/schema/Piezas";

import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";
import BBCodeText from "phaser3-rex-plugins/plugins/bbcodetext.js";

import SliderPhaser from "./complemento/jamSlider";
import { funcionActualizarDatosFB } from "../common/utils";

class Final extends Phaser.Scene {
  rexUI;
  sliderPlugin;
  centroCanvas;
  extremoCanvas;
  datosTableGastos;
  elementPricesPiezas;
  mejorasRealizadasAll;
  casasRealizadas;
  piezasUsadasTotal;
  gastosPorEtapa;
  objetosUsados;
  etapa;
  casasMalasCapturas;
  devolucion;
  bodega;
  gastosG;
  maxAndMin;
  music;
  dinero;
  personaje;
  dineroGen;
  puntajeMayorT;
  puntajeMenorT;
  puntajeMayorV;
  puntajeMenorV;
  casasBuenas;
  casasBuenasv;
  casasMalas;
  casasMalasv;
  piezasUsadas;
  piezasUsadasv;
  etapas;
  ejemploText;
  maloText;
  iconEjemplo;
  iconIncorrecto;
  grupoEstatic;
  casabuena;
  encuesta1T;
  intervalRegresivo;
  imagenBG;
  shape1;
  mask1;
  transition;
  constructor(
    handle: any,
    parent: any,
    centroCanvas: any,
    extremoCanvas: any,
    datosTableGastos: any,
    elementPricesPiezas: any,
    mejorasRealizadasAll: any,
    casasRealizadas: any,
    piezasUsadasTotal: any,
    gastosPorEtapa: any,
    objetosUsados: any,
    etapa: any,
    casasMalasCapturas: any,
    devolucion: any,
    bodega: any,
    gastosG: any,
    maxAndMin: any,
    music: any,
    dinero: any,
    personaje: any,
    dineroGen: any,
    puntajeMayorT: any,
    puntajeMenorT: any,
    puntajeMayorV: any,
    puntajeMenorV: any,
    casasBuenas: any,
    casasBuenasv: any,
    casasMalas: any,
    casasMalasv: any,
    piezasUsadas: any,
    piezasUsadasv: any,
    etapas: any,
    ejemploText: any,
    maloText: any,
    iconEjemplo: any,
    iconIncorrecto: any,
    grupoEstatic: any,
    casabuena: any,
    slider: any,
    encuesta1T: any,
    intervalRegresivo: any,
    imagenBG: any,
    shape1: any,
    mask1: any,
    transition: any,
    rexUI: RexUIPlugin,
    sliderPlugin: SliderPhaser
  ) {
    super({ key: "Final" });
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
    this.datosTableGastos = datosTableGastos;
    this.elementPricesPiezas = elementPricesPiezas;
    this.mejorasRealizadasAll = mejorasRealizadasAll;
    this.casasRealizadas = casasRealizadas;
    this.piezasUsadasTotal = piezasUsadasTotal;
    this.gastosPorEtapa = gastosPorEtapa;
    this.objetosUsados = objetosUsados;
    this.etapa = etapa;
    this.casasMalasCapturas = casasMalasCapturas;
    this.devolucion = devolucion;
    this.bodega = bodega;
    this.gastosG = gastosG;
    this.maxAndMin = maxAndMin;
    this.music = music;
    this.dinero = dinero;
    this.personaje = personaje;
    this.dineroGen = dineroGen;
    this.puntajeMayorT = puntajeMayorT;
    this.puntajeMenorT = puntajeMenorT;
    this.puntajeMayorV = puntajeMayorV;
    this.puntajeMenorV = puntajeMenorV;
    this.casasBuenas = casasBuenas;
    this.casasBuenasv = casasBuenasv;
    this.casasMalas = casasMalas;
    this.casasMalasv = casasMalasv;
    this.piezasUsadas = piezasUsadas;
    this.piezasUsadasv = piezasUsadasv;
    this.etapas = etapas;
    this.ejemploText = ejemploText;
    this.maloText = maloText;
    this.iconEjemplo = iconEjemplo;
    this.iconIncorrecto = iconIncorrecto;
    this.grupoEstatic = grupoEstatic;
    this.casabuena = casabuena;
    this.encuesta1T = encuesta1T;
    this.intervalRegresivo = intervalRegresivo;
    this.imagenBG = imagenBG;
    this.shape1 = shape1;
    this.mask1 = mask1;
    this.transition = transition;
    this.rexUI = RexUIPlugin;
    this.sliderPlugin = sliderPlugin;
  }

  init(data: any) {
    console.log("Se ha iniciado la escena Final");
    this.centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    this.extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };
    this.datosTableGastos = [];
    this.elementPricesPiezas = {};
    this.mejorasRealizadasAll = [];
    this.casasRealizadas = data.casasRealizadas
      ? data.casasRealizadas
      : { buenas: 0, malas: 0 };
    this.piezasUsadasTotal = data.piezasUsadas;
    this.gastosPorEtapa = data.gastosPorEtapa;
    this.objetosUsados = data.objetosUsados;
    this.etapa = data.etapa;
    this.casasMalasCapturas = data.casasMalasCapturas;
    this.devolucion = data.devolucion;
    this.bodega = data.bodega;
    this.gastosG = 0;
    this.maxAndMin = {};
  }

  async preload() {
    this.load.path = "./assets/";

    this.load.scenePlugin({
      key: "SliderPhaser",
      url: SliderPhaser,
      sceneKey: "sliderPlugin",
    });
    console.log(this.etapa);
  }

  getMaxAndMin(curso: any, nivel: any) {
    return {
      max: Math.floor(Math.random() * (50 - 20 + 1)) + 5,
      min: Math.floor(Math.random() * (20 - 5 + 1)) + 5,
    };
    // console.log(curso, nivel);
    // return fetch(
    //   `https://service.lticonstruye.cl/api/juego/getMaxAndMin?curso=${curso}&nivel=${nivel}`
    // )
    //   .then((resp) => resp.json())
    //   .then((res) => {
    //     console.log(res);
    //     return res;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     return {};
    //   });
  }

  async create() {
    this.atribuyendoGastos();
    const PATHURL = new URLSearchParams(window.location.search.substr(1));

    const maxAndMin = await this.getMaxAndMin(
      PATHURL.get("course"),
      this.etapa
    );
    this.music = this.sound.add("sonid_mesa2", { volume: 0.1 });
    this.music.setLoop(true);
    this.music.play();

    //   this.background = this.add.image(0, 0, "textostar").setOrigin(0, 0);
    //   document.querySelector(".textoStarWar").style.display = "none";

    if (!localStorage.getItem("allGastos")) {
      localStorage.setItem("allGastos", JSON.stringify([]));
    }

    this.add
      .image(this.centroCanvas.width, this.centroCanvas.height, "fondopopg")
      .setScale(1.2);

    this.dinero = this.add.image(
      this.centroCanvas.width,
      this.centroCanvas.height - 230,
      "dineroimg"
    );
    let nombrePersonaje = localStorage.getItem("namePersonaje")
      ? localStorage.getItem("namePersonaje")
      : "personajea1";

    this.personaje = this.add
      .image(
        this.centroCanvas.width - 330,
        this.centroCanvas.height,
        nombrePersonaje as any
      )
      .setScale(0.6);

    this.dineroGen = this.add
      .text(
        this.centroCanvas.width - 100,
        this.centroCanvas.height - 150,
        `$ ${localStorage.getItem("dinero")}`,
        { fontFamily: "font1", fontSize: 55, color: "#1C2A55" }
      )
      .setDepth(12);

    this.puntajeMayorT = this.add
      .text(
        this.centroCanvas.width - 180,
        this.centroCanvas.height - 60,
        "P. mayor",
        { fontFamily: "font2", fontSize: 20, color: "#808080" }
      )
      .setDepth(12);

    this.puntajeMenorT = this.add
      .text(
        this.centroCanvas.width - 20,
        this.centroCanvas.height - 60,
        "P. menor",
        { fontFamily: "font2", fontSize: 20, color: "#808080" }
      )
      .setDepth(12);

    (this as any).posicionFText = this.add
      .text(
        this.centroCanvas.width + 130,
        this.centroCanvas.height - 60,
        "Posicion",
        { fontFamily: "font2", fontSize: 20, color: "#808080" }
      )
      .setDepth(12);

    this.puntajeMayorV = this.add
      .text(
        this.centroCanvas.width - 200,
        this.centroCanvas.height - 30,
        `$ ${maxAndMin.max}`,
        { fontFamily: "font1", fontSize: 35, color: "#FFA900" }
      )
      .setDepth(12);

    this.puntajeMenorV = this.add
      .text(
        this.centroCanvas.width - 40,
        this.centroCanvas.height - 30,
        `$ ${maxAndMin.min}`,
        { fontFamily: "font1", fontSize: 35, color: "#92278F" }
      )
      .setDepth(12);

    try {
      const ranking = await fetch(
        `https://service.lticonstruye.cl/api/getRacking`,
        {
          method: "POST",
          body: JSON.stringify({
            etapa: this.etapa,
            email: JSON.parse(sessionStorage.getItem("datosUser") as any)
              ?.email,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const rankingJson = await ranking.json();
      (this as any).posicionF = this.add
        .text(
          this.centroCanvas.width + 130,
          this.centroCanvas.height - 30,
          `${rankingJson.data.posicionActual} / ${rankingJson.data.cantidad}`,
          { fontFamily: "font1", fontSize: 35, color: "#92278F" }
        )
        .setDepth(12);
    } catch (error) {
      console.log(error, "un error");
    }

    // console.log(rankingJson);

    this.casasBuenas = this.add
      .text(
        this.centroCanvas.width - 150,
        this.centroCanvas.height + 40,
        "Casas buenas",
        { fontFamily: "font1", fontSize: 18, color: "#1C2A55" }
      )
      .setDepth(12);

    this.casasBuenasv = this.add
      .text(
        this.centroCanvas.width + 110,
        this.centroCanvas.height + 40,
        `${this.casasRealizadas.buenas}`,
        { fontFamily: "font2", fontSize: 18, color: "#1C2A55" }
      )
      .setDepth(12);

    this.casasMalas = this.add
      .text(
        this.centroCanvas.width - 150,
        this.centroCanvas.height + 70,
        "Casas malas",
        { fontFamily: "font1", fontSize: 18, color: "#1C2A55" }
      )
      .setDepth(12);

    this.casasMalasv = this.add
      .text(
        this.centroCanvas.width + 110,
        this.centroCanvas.height + 70,
        `${this.casasRealizadas.malas}`,
        { fontFamily: "font2", fontSize: 18, color: "#1C2A55" }
      )
      .setDepth(12);

    this.piezasUsadas = this.add
      .text(
        this.centroCanvas.width - 150,
        this.centroCanvas.height + 100,
        "Piezas usadas",
        { fontFamily: "font1", fontSize: 18, color: "#1C2A55" }
      )
      .setDepth(12);

    this.piezasUsadasv = this.add
      .text(
        this.centroCanvas.width + 110,
        this.centroCanvas.height + 100,
        `${this.piezasUsadasTotal}`,
        { fontFamily: "font2", fontSize: 18, color: "#1C2A55" }
      )
      .setDepth(12);

    //botones
    this.etapas = 0;
    this.add
      .image(this.centroCanvas.width, this.centroCanvas.height + 210, "boton2")
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.sound.play("click");
        // console.log("jeje");
        this.etapas++;
        this.siguienteEscena();
      });
    this.add.text(
      this.centroCanvas.width - 60,
      this.centroCanvas.height + 195,
      "Siguiente",
      { fontFamily: "font1", fontSize: 24 }
    );
  }

  getMaxAndMinPrice() {
    if (parseInt(this.etapa) === 1) {
      return {
        max: this.gastosPorEtapa,
        min: 0,
      };
    } else {
      let allGastos: any = localStorage.getItem("allGastos")
        ? localStorage.getItem("allGastos")
        : [];
      if (allGastos) {
        allGastos = localStorage.getItem("allGastos")
          ? JSON.parse(localStorage.getItem("allGastos") as any)
          : [];
        if (allGastos.length === undefined) {
          let arrayTemporal = [];
          arrayTemporal.push(allGastos);
          arrayTemporal.push(this.gastosPorEtapa);
          localStorage.setItem("allGastos", JSON.stringify(arrayTemporal));
        } else {
          allGastos.push(this.gastosPorEtapa);
        }
        if (allGastos.length > 1) {
          return {
            max: Math.max(...allGastos),
            min: Math.min(...allGastos),
          };
        } else {
          return {
            max: this.gastosPorEtapa,
            min: 0,
          };
        }
      } else {
        return {
          max: this.gastosPorEtapa,
          min: 0,
        };
      }
    }
  }

  atribuyendoGastos() {
    // let datosUsados = JSON.parse(localStorage.getItem("objetosUsados"))
    let datosUsados = this.objetosUsados ? this.objetosUsados : {};
    materialesPuerta.forEach((element) => {
      if (element.tipo === "info") {
        this.elementPricesPiezas[element.name] = element;
      }
    });
    materialesPared.forEach((element) => {
      if (element.tipo === "info") {
        this.elementPricesPiezas[element.name] = element;
      }
    });
    materialesVentana.forEach((element) => {
      if (element.tipo === "info") {
        this.elementPricesPiezas[element.name] = element;
      }
    });
    materialesTecho.forEach((element) => {
      if (element.tipo === "info") {
        this.elementPricesPiezas[element.name] = element;
      }
    });
    Object.values(datosUsados).forEach((element: any, index: any) => {
      Object.values(element).forEach((elementb: any, indexb: any) => {
        if (elementb > 0) {
          console.log(
            Object.keys(datosUsados)[index],
            Object.keys(element)[indexb],
            elementb
          );
          this.datosTableGastos.push({
            tipo: Object.keys(datosUsados)[index],
            name: Object.keys(element)[indexb],
            cantidad: elementb,
          });
        }
      });
    });

    const templateBodyGastos = (nombre: any, valor: any, cantidad: any) => `<tr>
          <td> ${nombre}</td>
          <td> ${valor} </td>
          <td> ${cantidad} </td>
          <td> ${valor * cantidad} </td>
          </tr>`;

    let casasRealizadas: any = JSON.parse(
      localStorage.getItem("casasRealizadas") as any
    );
    let mejorasRealizadas = JSON.parse(
      localStorage.getItem("mejorasRealizadas") as any
    );
    if (this.casasRealizadas.buenas > 0) {
      (window as any).tbodyIngreso.innerHTML = "";
      (window as any).tbodyIngreso.insertAdjacentHTML(
        "beforeend",
        templateBodyGastos(`Casa construida`, 50, this.casasRealizadas.buenas)
      );
    }

    // Object.values(mejorasRealizadas).forEach((element, index) => {
    //   if (element) {
    //     this.mejorasRealizadasAll.push({
    //       name: Object.keys(mejorasRealizadas)[index],
    //       precio: mejorasPrecios[Object.keys(mejorasRealizadas)[index]],
    //     });
    //   }
    // });

    //console.log(this.mejorasRealizadasAll);

    if (this.datosTableGastos.length > 0) {
      (window as any).tbodyCostos.innerHTML = "";
      this.datosTableGastos.forEach((element: any, index: any) => {
        this.gastosG =
          parseInt(this.gastosG) +
          parseInt(this.elementPricesPiezas[element.name].precio);
        (window as any).tbodyCostos.insertAdjacentHTML(
          "beforeend",
          templateBodyGastos(
            `${element.tipo} - ${element.name}`,
            this.elementPricesPiezas[element.name].precio,
            element.cantidad
          )
        );
      });
    }

    if (this.mejorasRealizadasAll.length > 0) {
      let scene: any = this;
      this.mejorasRealizadasAll.forEach(function (element: any, index: any) {
        //console.log(element)
        // (window as any).tbodyCostos.insertAdjacentHTML(
        //   "beforeend",
        //   templateBodyGastos(
        //     `${scene.getNameMejora(element.name)}`,
        //     parseInt(element.precio),
        //     1
        //   )
        // );
      });
    }

    ///console.log(this.datosTableGastos);
    //console.log(this.elementPricesPiezas);
    let dineroMejorado: any = mejorasRealizadas[`1${parseInt(this.etapa) - 1}`]
      ? costoMejoras[`1${parseInt(this.etapa) - 1}`]
      : 0;
    // console.log(
    //   mejorasRealizadas[`1${this.etapa}`],
    //   costoMejoras[`1${this.etapa}`],
    //   "Mejoras"
    // );
    funcionActualizarDatosFB({
      [`datosPorpieza${this.etapa}`]: {
        casasConstruidas: this.casasRealizadas,
        monto: localStorage.getItem("dinero"),
        mejora: dineroMejorado,
        devolucion: this.devolucion,
        bodega: this.bodega,
        gastos: this.gastosG,
      },
    });
  }
  siguienteEscena() {
    switch (this.etapas) {
      case 1:
        this.dinero.setVisible(0);
        this.personaje.setVisible(0);
        this.dineroGen.setVisible(0);
        this.puntajeMayorT.setVisible(0);
        this.puntajeMenorT.setVisible(0);
        this.puntajeMayorV.setVisible(0);
        this.puntajeMenorV.setVisible(0);
        this.casasBuenas.setVisible(0);
        this.casasBuenasv.setVisible(0);
        this.casasMalas.setVisible(0);
        this.casasMalasv.setVisible(0);
        this.piezasUsadas.setVisible(0);
        this.piezasUsadasv.setVisible(0);
        (this as any).posicionFText.setVisible(0);
        (this as any).posicionF.setVisible(0);
        (window as any).tableFinal.style.display = "block";
        if (this.casasRealizadas.malas === 0) {
          this.etapas = 2;
        }
        console.log("1");
        break;
      case 2:
        (window as any).tableFinal.style.display = "none";
        console.log("2");
        this.ejemploText = this.add.text(
          this.centroCanvas.width - 210,
          this.centroCanvas.height - 120,
          "EJEMPLO",
          { fontFamily: "font1", fontSize: 22, color: "#8d0582" }
        );

        this.maloText = this.add.text(
          this.centroCanvas.width + 80,
          this.centroCanvas.height - 120,
          "INCORRECTO",
          { fontFamily: "font1", fontSize: 22, color: "#8d0582" }
        );

        this.iconEjemplo = this.add.image(
          this.centroCanvas.width - 155,
          this.centroCanvas.height - 155,
          "buenoca"
        );
        this.iconIncorrecto = this.add.image(
          this.centroCanvas.width + 155,
          this.centroCanvas.height - 155,
          "maloca"
        );

        this.grupoEstatic = this.add.group();
        this.grupoEstatic.add(this.ejemploText);
        this.grupoEstatic.add(this.maloText);
        this.grupoEstatic.add(this.iconEjemplo);
        this.grupoEstatic.add(this.iconIncorrecto);

        this.casabuena = this.add
          .image(
            this.centroCanvas.width - 160,
            this.centroCanvas.height + 60,
            "casamodalf"
          )
          .setScale(0.65);

        console.log(this.sliderPlugin);

        this.sliderPlugin.init({
          x: this.centroCanvas.width - 20,
          y: this.centroCanvas.height - 120,
          width: 350,
          height: 350,
          enableBG: false,
          autoAnimate: false,
          scaleImage: 0.6,
          // targets: ["area", "paredmapa2", "paredmapa", "puertamapa2"],
          targets: this.casasMalasCapturas,
          loop: false,
          arrow: {
            left: "leftarrow",
            right: "rightarrow",
          },
        });

        break;
      case 6:
        console.log("3");
        if (this.casasRealizadas.malas === 0) {
          (window as any).tableFinal.style.display = "none";
        } else {
          this.sliderPlugin.hideAllElements();
          this.casabuena.setAlpha(0);
          this.grupoEstatic.setAlpha(0);
        }

        this.encuesta1T = this.add
          .text(
            this.centroCanvas.width - 210,
            this.centroCanvas.height - 120,
            "¿Cuanto fue tu costo por casa?",
            { fontFamily: "font1", fontSize: 25, color: "#1C2A55" }
          )
          .setDepth(12);

        this.encuesta1T = this.add
          .text(
            this.centroCanvas.width - 225,
            this.centroCanvas.height + 30,
            "¿Cuanto fue el ingreso por casa?",
            { fontFamily: "font1", fontSize: 25, color: "#1C2A55" }
          )
          .setDepth(12);

        var txt1 = new BBCodeText(
          this.scene as any,
          this.centroCanvas.width - 40,
          this.centroCanvas.height - 50,
          "123",
          {
            color: "#1C2A55",
            fontSize: "24px",
            fixedWidth: 200,
            fixedHeight: 80,
            backgroundColor: "rgba(0,0,0,0)",
            valign: "center",
            fontFamily: "font2",
          } as any
        )
          .setOrigin(0.5)
          .setInteractive({ cursor: "pointer" })
          .setDepth(99)
          .on(
            "pointerdown",
            (e: any) => {
              var config = {
                type: "number",
                onTextChanged: function (textObject: any, text: any) {
                  textObject.text = text;
                  console.log(`Text: ${text}`, text);
                },
                selectAll: true,
              };
              (this.plugins.get("rextexteditplugin") as any).edit(txt1, config);
            },
            this
          );
        this.add.existing(txt1);

        // var printText2 = this.add
        //   .rexBBCodeText(
        //     this.centroCanvas.width - 40,
        //     this.centroCanvas.height + 100,
        //     "123",
        //     {
        //       color: "#1C2A55",
        //       fontSize: "24px",
        //       fixedWidth: 200,
        //       fixedHeight: 80,
        //       backgroundColor: "rgba(0,0,0,0)",
        //       valign: "center",
        //       fontFamily: "font2",
        //     }
        //   )
        //   .setOrigin(0.5)
        //   .setInteractive({ cursor: "pointer" })
        //   .setDepth(99)
        //   .on(
        //     "pointerdown",
        //     function (e: any) {
        //       var config = {
        //         type: "number",
        //         onTextChanged: function (textObject: any, text: any) {
        //           textObject.text = text;
        //           console.log(`Text: ${text}`, text);
        //         },
        //         selectAll: true,
        //       };

        //       this.plugins.get("rextexteditplugin").edit(printText2, config);
        //     },
        //     this
        //   );
        break;
      case 3:
        (window as any).tableFinal.style.display = "none";
        console.log("3");
        if (this.casasRealizadas.malas === 0) {
          (window as any).tableFinal.style.dispflay = "none";
        } else {
          this.sliderPlugin.hideAllElements();
          this.casabuena.setAlpha(0);
          this.grupoEstatic.setAlpha(0);
        }
        const callBack = () => {
          if (
            parseInt(this.etapa) ===
            parseInt(localStorage.getItem("etapa") as any)
          ) {
            const etapas = JSON.parse(
              localStorage.getItem("etapasRealizadas") as any
            );
            localStorage.setItem(
              "etapasRealizadas",
              JSON.stringify({
                ...etapas,
                [localStorage.getItem("etapa") as any]: true,
              })
            );
            let etapa = localStorage.getItem("etapa");
            localStorage.setItem("etapa", (parseInt(etapa as any) + 1) as any);
            localStorage.setItem(
              "nivelLevel",
              (parseInt(etapa as any) + 1) as any
            );
            localStorage.setItem(
              "nivelDinero",
              parseInt(localStorage.getItem("dinero") as any) as any
            );
            let allGastos = localStorage.getItem("allGastos")
              ? JSON.parse(localStorage.getItem("allGastos") as any)
              : [];
            localStorage.setItem(
              "allGastos",
              typeof allGastos === "object"
                ? allGastos.push(this.gastosPorEtapa)
                : ([] as any).push(this.gastosPorEtapa)
            );
            let niveles, nivelf;
            if (localStorage.getItem("nivelesD")) {
              niveles = JSON.parse(localStorage.getItem("nivelesD") as any);
              nivelf = {
                ...niveles,
                [etapa as any]: parseInt(localStorage.getItem("dinero") as any),
              };
              console.log(nivelf);
              localStorage.setItem("nivelesD", JSON.stringify(nivelf));
            } else {
              niveles = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0 };
              nivelf = {
                ...niveles,
                [etapa as any]: parseInt(localStorage.getItem("dinero") as any),
              };
              localStorage.setItem("nivelesD", JSON.stringify(nivelf));
            }
            console.log(niveles, "nivellll");

            funcionActualizarDatosFB({
              ["datosEtapa" + etapa]: {
                nivelDinero: parseInt(localStorage.getItem("dinero") as any),
              },
              etapa: parseInt(etapa as any) + 1,
              nivelLevel: parseInt(etapa as any) + 1,
              nivelesD: nivelf,
              etapasRealizadas: JSON.stringify({
                ...etapas,
                [parseInt(localStorage.getItem("etapa") as any) - 1]: true,
              }),
            });

            this.sound.stopAll();

            if (
              JSON.parse(localStorage.getItem("etapasRealizadas") as any)[8]
            ) {
              this.scene.start("FinalF");
            } else {
              this.scene.start("Levels");
            }
          } else {
            const etapas = JSON.parse(
              localStorage.getItem("etapasRealizadas") as any
            );
            localStorage.setItem(
              "etapasRealizadas",
              JSON.stringify({
                ...etapas,
                [localStorage.getItem("etapa") as any]: true,
              })
            );
            let etapa = localStorage.getItem("etapa");
            localStorage.setItem("etapa", (parseInt(etapa as any) + 1) as any);
            localStorage.setItem(
              "nivelLevel",
              (parseInt(etapa as any) + 1) as any
            );

            funcionActualizarDatosFB({
              etapa: parseInt(etapa as any) + 1,
              nivelLevel: parseInt(etapa as any) + 1,
              etapasRealizadas: { ...etapas, [parseInt(this.etapa) - 1]: true },
            });

            this.sound.stopAll();

            if (
              JSON.parse(localStorage.getItem("etapasRealizadas") as any)[8]
            ) {
              this.scene.start("FinalF");
            } else {
              this.scene.start("Levels");
            }
          }
          if (this.intervalRegresivo) {
            clearInterval(this.intervalRegresivo);
          }
        };
        this.salirEscena(callBack);
        break;
    }
  }

  getNameMejora(name: any) {
    switch (name) {
      case "11":
        return "Mejora 5S";
      case "12":
        return "Gestión visual";
      case "13":
        return "Pokayoke";
      case "14":
        return "Layout";
      default:
        return "Mejora";
    }
  }

  update() {}

  salirEscena(callback: any) {
    this.mascaraInicio("salir", false, true, callback);
  }

  updateTransition() {
    this.imagenBG.setScale(this.transition.counter);
  }

  mascaraInicio(tipo: any, activeScroll: any, salirScena: any, callback: any) {
    this.imagenBG = this.add
      .image(this.centroCanvas.width, this.centroCanvas.height, "circlebg")
      .setVisible(false)
      .setDepth(999);

    this.shape1 = this.make.graphics();
    var mask1 = this.shape1.createBitmapMask(this.imagenBG);

    this.cameras.main.setMask(mask1);

    let counter = tipo === "entrar" ? 0 : 2;
    let countTwen = tipo === "entrar" ? 4.8 : 0;
    this.transition = { counter: counter };

    // tween counter variable and set onUpdate function
    let tweenEffect = this.tweens.add({
      targets: this.transition,
      counter: countTwen,
      callbackScope: this,
      onUpdate: this.updateTransition,
      ease: "Sine.easeInOut",
      yoyo: false,
      repeat: 0,
      duration: 1500,
    });

    tweenEffect.on("complete", () => {
      if (activeScroll) {
        //  this.activeScroll();
      }
      if (salirScena) {
        callback();
      }
    });

    // console.log("jeje");
  }
}

export default Final;
