import ScrollingCamera from "phaser3-scrolling-camera";
import { funcionActualizarDatosFB } from "../common/utils";

class TutorialLevel extends Phaser.Scene {
  parent;
  keyScene;
  centroCanvas;
  extremoCanvas;
  nivel;
  background;
  clave;
  error;
  modal;
  buttonImage;
  respuestaT;
  dimensionLevel;
  etapasLocal;
  mejorasLocal;
  etapaLocal;
  mejoraLocal;
  etapasObjectName;
  opacidadMedalla;
  pasanivel;
  claves;
  urlZoom;
  music;
  audioI;
  configImg;
  tutorialLevel;
  configModal;
  holograma1;
  holograma2;
  holograma3;
  holograma4;
  holograma5;
  holograma6;
  holograma7;
  holograma8;
  personajeLevel;
  imagenBG;
  shape1;
  transition;
  music2;
  myCamera;
  count;
  activeHologram;
  mostrarNext;
  renew;
  beforeTutorial;
  constructor(handle: any, parent: any) {
    super({ key: "TutorialLevel" });
    this.parent = parent;
    this.keyScene = handle;
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
    this.nivel = 0;
    this.clave = "";
    this.background = "" as any;
    this.error = "" as any;
    this.modal = "" as any;
    this.buttonImage = "" as any;
    this.respuestaT = "" as any;
    this.dimensionLevel = "" as any;
    this.etapasLocal = "" as any;
    this.mejorasLocal = "" as any;
    this.etapaLocal = "" as any;
    this.mejoraLocal = "" as any;
    this.etapasObjectName = "" as any;
    this.opacidadMedalla = "" as any;
    this.pasanivel = "" as any;
    this.claves = "" as any;
    this.urlZoom = "" as any;
    this.music = "" as any;
    this.audioI = "" as any;
    this.configImg = "" as any;
    this.tutorialLevel = "" as any;
    this.configModal = "" as any;
    this.holograma1 = "" as any;
    this.holograma2 = "" as any;
    this.holograma3 = "" as any;
    this.holograma4 = "" as any;
    this.holograma5 = "" as any;
    this.holograma6 = "" as any;
    this.holograma7 = "" as any;
    this.holograma8 = "" as any;
    this.personajeLevel = "" as any;
    this.imagenBG = "" as any;
    this.shape1 = "" as any;
    this.transition = "" as any;
    this.music2 = "" as any;
    this.myCamera = "" as any;
    this.count = "" as any;
    this.activeHologram = "" as any;
    this.mostrarNext = "" as any;
    this.renew = "" as any;
    this.beforeTutorial = "" as any;
  }

  init(data: any) {
    console.log("Se ha iniciado la escena TutorialLevel");
    this.centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    this.extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };

    this.dimensionLevel = {
      width: this.sys.game.config.width,
      height: 2224,
    };
    this.etapasLocal = JSON.parse(
      localStorage.getItem("etapasRealizadas") as any
    );
    this.mejorasLocal = JSON.parse(
      localStorage.getItem("mejorasRealizadas") as any
    );
    //console.log(etapasLocal)
    this.etapaLocal = localStorage.getItem("etapa");
    this.mejoraLocal = localStorage.getItem("mejora");
    this.etapasObjectName = {
      1: this.etapasLocal[1] ? "mor1" : "num1",
      2: this.etapasLocal[2] ? "mor2" : "num2",
      3: this.etapasLocal[3] ? "mor3" : "num3",
      4: this.etapasLocal[4] ? "mor4" : "num4",
      5: this.etapasLocal[5] ? "mor5" : "num5",
      6: this.etapasLocal[6] ? "mor6" : "num6",
      7: this.etapasLocal[7] ? "mor7" : "num7",
      8: this.etapasLocal[8] ? "mor8" : "num8",
      9: this.etapasLocal[9] ? "mor9" : "num9",
    };
    this.pasanivel = data.pasanivel;
    this.nivel = data.nivel;
    this.activeHologram = false;
    this.mostrarNext = false;
    this.renew = data.renew;
    this.beforeTutorial = data.beforeTutorial ? data.beforeTutorial : false;
  }

  preload() {
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexuiplugin.min.js",
      sceneKey: "rexUI",
    });

    this.load.image(
      "nextPage",
      "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/assets/images/arrow-down-left.png"
    );
  }

  create() {
    localStorage.setItem("escenaActual", "TutorialLevel");
    funcionActualizarDatosFB({ escenaActual: "TutorialLevel" });
    this.background = this.add.image(0, 0, "fondolevel").setOrigin(0, 0);

    this.music = this.sound.add("music_comienzo", { volume: 0.1 });
    this.music.setLoop(true);
    this.music.play();

    (this as any).ya_aprendiste = this.sound.add("ya_aprendiste");
    (this as any).listo_audio = this.sound.add("listo_audio");

    let nombrePersonaje = localStorage.getItem("namePersonaje")
      ? localStorage.getItem("namePersonaje")
      : "personajea1";
    this.add
      .image(900, 1900, nombrePersonaje as any)
      .setScale(0.7)
      .setDepth(11);

    if (this.beforeTutorial) {
    } else {
      (this as any).personajeF1 = this.add
        .image(120, 1900, "personajef1")
        .setDepth(11);
    }

    (this as any).mostrarOtro = false;

    this.add
      .renderTexture(
        0,
        1500,
        this.extremoCanvas.width * 2,
        this.extremoCanvas.height * 4
      )
      .fill(0x211844, 0.7)
      .setDepth(10);

    this.configImg = this.add
      .image(70, this.dimensionLevel.height - 70, "audio")
      .setScale(0.5);

    (this as any).tutorialB = this.add.image(
      190,
      this.dimensionLevel.height - 70,
      "tutorialevel"
    );

    this.configImg.buttonE = true;

    // primera base
    (this as any).base1 = this.add.image(
      260,
      this.dimensionLevel.height - 180,
      "basel"
    );
    (this as any).etapa1 = this.add.image(
      260,
      this.dimensionLevel.height - 240,
      this.etapasObjectName[1]
    );

    // primera mejora
    (this as any).mejoraB1 = this.add.image(
      500,
      this.dimensionLevel.height - 300,
      "basel"
    );
    (this as any).mejora1 = this.add.image(
      500,
      this.dimensionLevel.height - 350,
      "5s"
    );

    // segunda etapa
    (this as any).base2 = this.add.image(
      620,
      this.dimensionLevel.height - 400,
      "baselazul"
    );
    (this as any).etapa2 = this.add.image(
      620,
      this.dimensionLevel.height - 460,
      this.etapasObjectName[2]
    );

    /// mejora segunda
    (this as any).mejoraB2 = this.add.image(
      860,
      this.dimensionLevel.height - 480,
      "baselazul"
    );
    (this as any).mejora2 = this.add.image(
      860,
      this.dimensionLevel.height - 530,
      "eti"
    );

    /// tercera etapa
    (this as any).base3 = this.add.image(
      680,
      this.dimensionLevel.height - 615,
      "baselazul"
    );
    (this as any).etapa3 = this.add.image(
      680,
      this.dimensionLevel.height - 675,
      this.etapasObjectName[3]
    );

    // tercera mejora
    (this as any).mejoraB3 = this.add.image(
      450,
      this.dimensionLevel.height - 650,
      "baselazul"
    );
    (this as any).mejora3 = this.add.image(
      450,
      this.dimensionLevel.height - 700,
      "marcar"
    );

    // cuarta etapa
    this.add.image(520, this.dimensionLevel.height - 800, "baselazul");
    this.add.image(
      520,
      this.dimensionLevel.height - 860,
      this.etapasObjectName[4]
    );

    // cuarta mejora
    this.add.image(260, this.dimensionLevel.height - 820, "baselazul");
    this.add.image(260, this.dimensionLevel.height - 870, "marcaare");

    // quinta etapa
    this.add.image(380, this.dimensionLevel.height - 960, "baselazul");
    this.add.image(
      380,
      this.dimensionLevel.height - 1020,
      this.etapasObjectName[5]
    );

    // quinta mejora
    this.add.image(580, this.dimensionLevel.height - 990, "baselazul");
    this.add.image(580, this.dimensionLevel.height - 1040, "balance");

    // sexta etapa
    this.add.image(670, this.dimensionLevel.height - 1090, "baselazul");
    this.add.image(
      670,
      this.dimensionLevel.height - 1150,
      this.etapasObjectName[6]
    );

    // sexta mejora
    this.add.image(440, this.dimensionLevel.height - 1150, "baselazul");
    this.add.image(440, this.dimensionLevel.height - 1200, "auto");

    // septima etapa
    this.add.image(670, this.dimensionLevel.height - 1300, "baselazul");
    this.add.image(670, this.dimensionLevel.height - 1360, "num7");

    // septima mejora
    this.add.image(440, this.dimensionLevel.height - 1350, "baselazul");
    this.add.image(440, this.dimensionLevel.height - 1400, "foco");

    // numero 8
    this.add.image(280, this.dimensionLevel.height - 1510, "baselazul");
    this.add.image(280, this.dimensionLevel.height - 1570, "num8");

    this.add.image(this.centroCanvas.width, 140, "logolevel").setDepth(11);

    if (this.beforeTutorial) {
    } else {
      setTimeout(() => {
        (this as any).primero = this.createTextBox(
          this,
          330,
          1900,
          {
            wrapWidth: 300,
          },
          this.add.image(0, 0, "tutorialnube1").setScale(1.1),
          7,
          true,
          null,
          () => {
            (this as any).ya_aprendiste.play();
          }
        ).start("¡Ya haz aprendido a construir!.", 50);

        (this as any).primero.setDepth(11);

        setTimeout(() => {
          (this as any).primero.destroy();
          (this as any).primero = this.createTextBox(
            this,
            330,
            1900,
            {
              wrapWidth: 300,
            },
            this.add.image(0, 0, "tutorialnube1").setScale(1.1),
            7,
            true,
            null,
            () => {
              (this as any).primero.destroy();
              (this as any).tutorialB.setDepth(9999);
            }
          ).start(
            "Si se te olvida algo, puedes ver esta explicación las veces que quieras en este botón.",
            50
          );

          setTimeout(() => {
            (this as any).primero.destroy();
            (this as any).primero2 = this.createTextBox(
              this,
              330,
              1900,
              {
                wrapWidth: 300,
              },
              this.add.image(0, 0, "tutorialnube1").setScale(1.1),
              7,
              true,
              null,
              () => {
                (this as any).primero.destroy();

                (this as any).tutorialB.setDepth(1);
                (this as any).configImg.setDepth(9999);
              }
            ).start(
              "También puedes activar y desactivar el sonido del juego con este botón",
              50
            );
            setTimeout(() => {
              (this as any).primero2.destroy();
              (this as any).primero2 = this.createTextBox(
                this,
                330,
                1900,
                {
                  wrapWidth: 300,
                },
                this.add.image(0, 0, "tutorialnube1").setScale(1.1),
                7,
                true,
                null,
                () => {
                  (this as any).primero2.destroy();
                  (this as any).listo_audio.play();
                  (this as any).configImg.setDepth(1);
                  (this as any).btnGrupo.setVisible(1);
                }
              ).start(
                "¿Estás listo? ¡Vamos a la ronda 1 para comenzar a construir!",
                50
              );
            }, 6500);
          }, 5000);
        }, 4000);
      }, 2500);
    }
    (this as any).etapa = 0;

    (this as any).botonFin = this.add
      .image(780, 2150, "comprar_btn")
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.sound.stopAll();
        this.scene.stop("TutorialLvel");
        this.scene.start("Levels");
      })
      .on("pointerup", () => {
        (this as any).botonFin.setScale(1);
      });

    (this as any).textoFin = this.add.text(
      `${this.beforeTutorial ? 710 : 690}` as any,
      2132,
      `${this.beforeTutorial ? "¡VAMOS!" : "SIGUIENTE"}`,
      { fontFamily: "font1", fontSize: 32 }
    );

    (this as any).btnGrupo = this.add.group();
    (this as any).btnGrupo.add((this as any).botonFin);
    (this as any).btnGrupo.add((this as any).textoFin);
    (this as any).btnGrupo.setDepth(11);
    (this as any).btnGrupo.setVisible(0);

    if (this.beforeTutorial) {
    } else {
      // (this as any).base1.setDepth(13);
      // (this as any).etapa1.setDepth(13);
      // (this as any).base2.setDepth(13);
      // (this as any).etapa2.setDepth(13);
      // (this as any).base3.setDepth(13);
      // (this as any).etapa3.setDepth(13);
    }

    if (this.beforeTutorial) {
      (this as any).holograma = this.add
        .sprite(120, 1900, "holomu")
        .setScale(0.5)
        .setDepth(11);
      (this as any).holograma.anims.play("playholomu");

      (this as any).segundo = this.createTextBox(
        this,
        260,
        1870,
        {
          wrapWidth: 280,
        },
        this.add.image(0, 0, "tutorialnube1"),
        4,
        null,
        null
      ).start("¡Ya has aprendido a construir!", 50);
    }

    this.mascaraInicio("entrar", true, null, null);

    if (this.renew) {
      (this as any).botonFin = this.add
        .image(380, 2150, "boton1")
        .setInteractive({ cursor: "pointer" })
        .setDepth(11)
        .on("pointerdown", () => {
          this.sound.play("click");
          setTimeout(() => {
            this.sound.stopAll();
            this.scene.start("Levels");
          }, 200);
        });
      (this as any).textoFin = this.add
        .text(330, 2132, `SALIR`, { fontFamily: "font1", fontSize: 32 })
        .setDepth(12);
    }

    if (this.beforeTutorial) {
      this.mostrarNext = false;
    } else {
      this.mostrarNext = true;
    }
  }
  pasarSiguiente() {
    switch ((this as any).etapa) {
      case -1:
        (this as any).primero.destroy();
        (this as any).segundo = this.createTextBox(
          this,
          260,
          1870,
          {
            wrapWidth: 280,
          },
          this.add.image(0, 0, "tutorialnube1"),
          4,
          null,
          null
        ).start(
          "Para eso, tenemos que ir mejorando nuestros espacios, procesos, materiales y métodos.",
          50
        );
        (this as any).segundo.setDepth(11);
        (this as any).mostrarNext = false;
        (this as any).btnGrupo.setVisible(0);
        (this as any).base1.setDepth(2);
        (this as any).etapa1.setDepth(2);
        (this as any).base2.setDepth(2);
        (this as any).etapa2.setDepth(2);
        (this as any).base3.setDepth(2);
        (this as any).etapa3.setDepth(2);
        break;
      case -2:
        (this as any).segundo.destroy();
        (this as any).tercero = this.createTextBox(
          this,
          260,
          1870,
          {
            wrapWidth: 280,
          },
          this.add.image(0, 0, "tutorialnube1"),
          3,
          null,
          null
        ).start("Iremos poco a poco, aplicando la mejora continua.", 50);
        (this as any).tercero.setDepth(11);
        (this as any).mostrarNext = false;
        (this as any).btnGrupo.setVisible(0);
        break;
      case 1:
        (this as any).base1.setDepth(2);
        (this as any).etapa1.setDepth(2);
        (this as any).base2.setDepth(2);
        (this as any).etapa2.setDepth(2);
        (this as any).base3.setDepth(2);
        (this as any).etapa3.setDepth(2);
        (this as any).base2.setDepth(2);
        (this as any).etapa2.setDepth(2);
        (this as any).base3.setDepth(2);
        (this as any).etapa3.setDepth(2);
        (this as any).primero.destroy();

        (this as any).cuarto = this.createTextBox(
          this,
          230,
          1950,
          {
            wrapWidth: 280,
          },
          this.add.image(0, 0, "tutorialnube2").setScale(0.5),
          8,
          this,
          "center"
        ).start(
          "Tras cada ronda, tendrás sesiones de entrenamiento con expertos de la Tierra, que te contactarán a través de estos hologramas para ayudarte a mejorar tu proceso. ",
          50
        );
        (this as any).cuarto.setDepth(15);
        setTimeout(() => {
          (this as any).mostrarNext = true;
          (this as any).btnGrupo.setVisible(1);
          //escena.cuarto.destroy()
          this.activarHolograma();
        }, 800);
        (this as any).mostrarOtro = false;
        (this as any).btnGrupo.setVisible(0);
        break;
      case 2:
        (this as any).cuarto.destroy();
        (this as any).mejoraB1.setDepth(15);
        (this as any).mejora1.setDepth(15);
        (this as any).mejoraB2.setDepth(11);
        (this as any).mejora2.setDepth(11);
        (this as any).mejoraB3.setDepth(15);
        (this as any).mejora3.setDepth(15);
        (this as any).base2.setDepth(2);
        (this as any).etapa2.setDepth(2);
        (this as any).base3.setDepth(2);
        (this as any).etapa3.setDepth(2);

        (this as any).holograma.destroy();
        (this as any).quinto = this.createTextBox(
          this,
          350,
          1950,
          {
            wrapWidth: 280,
          },
          this.add.image(0, 0, "tutorialnube2").setScale(0.5),
          8,
          null,
          "center"
        ).start(
          "En cada entrenamiento irás ganando conocimientos. Con eso luego tendrás que decidir si quieres pagar para aplicar mejoras a tu proceso en la etapa siguiente.",
          50
        );
        (this as any).quinto.setDepth(15);
        (this as any).mostrarNext = true;
        (this as any).btnGrupo.setVisible(0);
        break;
      case 3:
        /*                 this.mejoraB1.setDepth(4)
                this.mejora1.setDepth(4)
                this.mejoraB2.setDepth(4)
                this.mejora2.setDepth(4)
                this.mejoraB3.setDepth(4)
                this.mejora3.setDepth(4) */
        (this as any).quinto.destroy();

        (this as any).sexto = this.createTextBox(
          this,
          350,
          1950,
          {
            wrapWidth: 280,
          },
          this.add.image(0, 0, "tutorialnube2").setScale(0.5),
          8,
          null,
          "center"
        ).start(
          "Cuando consigas una medalla, tendrás que decidir si quieres o no implementar la mejora en tu proceso. Si las implementas, se aplicará automáticamente en la siguiente ronda.",
          50
        );
        (this as any).sexto.setDepth(15);
        (this as any).mostrarNext = true;
        (this as any).btnGrupo.setVisible(0);
        break;
      case 4:
        (this as any).sexto.destroy();
        (this as any).mejoraB1.setDepth(4);
        (this as any).mejora1.setDepth(4);
        (this as any).mejoraB2.setDepth(4);
        (this as any).mejora2.setDepth(4);
        (this as any).mejoraB3.setDepth(4);
        (this as any).mejora3.setDepth(4);
        (this as any).septimo = this.createTextBox(
          this,
          240,
          1780,
          {
            wrapWidth: 280,
          },
          this.add.image(0, 0, "tutorialnube1").setScale(1.4),
          8,
          null,
          null
        ).start(
          "Recuerda: Los objetivos son construir la mayor cantidad de casas en el menor tiempo posible y tener suficientes recursos para pagarle a los síodas.",
          50
        );

        setTimeout(() => {
          (this as any).septimo.destroy();
          (this as any).septimo = this.createTextBox(
            this,
            240,
            1780,
            {
              wrapWidth: 280,
            },
            this.add.image(0, 0, "tutorialnube1").setScale(1.1),
            8,
            null,
            null
          ).start(
            "¡No desperdicies el dinero! Si aprovechas bien los recursos, los créditos interestelares que sobren serán para ti.",
            50
          );
          (this as any).septimo.setDepth(15);
          setTimeout(() => {
            (this as any).septimo.destroy();
            (this as any).septimo = this.createTextBox(
              this,
              240,
              1780,
              {
                wrapWidth: 280,
              },
              this.add.image(0, 0, "tutorialnube1").setScale(1.2),
              8,
              null,
              null
            ).start(
              "Es una gran responsabilidad, pero confiamos en ti. No pierdas las oportunidades de aplicar mejora continua. ",
              50
            );
            (this as any).septimo.setDepth(15);
            (this as any).mostrarNext = true;
          }, 7500);
        }, 9500);

        (this as any).septimo.setDepth(15);
        (this as any).mostrarNext = false;
        (this as any).btnGrupo.setVisible(0);
        break;
      case 5:
        (this as any).septimo.destroy();

        (this as any).noveno = this.createTextBox(
          this,
          320,
          1970,
          {
            wrapWidth: 250,
          },
          this.add.image(0, 0, "tutorialnube1"),
          8,
          null,
          null
        ).start(
          " Aquí me despido por hoy. Ahora, entra al taller para comunicarte con Lina. ",
          50
        );
        (this as any).noveno.setDepth(11);
        (this as any).base1.setDepth(11);
        (this as any).etapa1.setDepth(11);
        (this as any).etapa1
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", () => {
            this.sound.stopAll();
            this.scene.start("TutorialMaterial");
            console.log("jeje case 5");
          });

        var circle = new Phaser.Geom.Circle(0, 0, 50);
        let graphics = this.add.graphics({
          x: 260,
          y: this.dimensionLevel.height - 240,
        });
        graphics.fillCircleShape(circle);
        graphics.fillStyle(0xffff00, 0.75);
        graphics.setDepth(16);

        this.tweens.add({
          targets: graphics,
          alpha: 0,
          ease: "Cubic.easeOut",
          duration: 600,
          repeat: -1,
          yoyo: true,
        });
        //this.mostrarOtro = false
        (this as any).btnGrupo.setVisible(0);
        /*                 this.octavo = this.createTextBox(this, 240, 1780, {
                    wrapWidth: 280,
                }, this.add.image(0,0,"tutorialnube1"), 8)
                .start("Es una gran responsabilidad, pero confiamos en que aprenderás todo lo necesario para optimizar los procesos.", 50);
                this.octavo.setDepth(15)
                this.mostrarNext = false
                this.btnGrupo.setVisible(0)    */
        break;
      case 6:
        (this as any).octavo.destroy();

        (this as any).noveno = this.createTextBox(
          this,
          320,
          1970,
          {
            wrapWidth: 250,
          },
          this.add.image(0, 0, "tutorialnube1"),
          8,
          null,
          null
        ).start("Ahora entremos al taller.", 50);
        (this as any).noveno.setDepth(11);
        (this as any).base1.setDepth(11);
        (this as any).etapa1.setDepth(11);
        (this as any).etapa1
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", () => {
            this.sound.stopAll();
            this.scene.start("TutorialMaterial");
          });

        var circle = new Phaser.Geom.Circle(0, 0, 50);
        var graphics1 = this.add.graphics({
          x: 260,
          y: this.dimensionLevel.height - 240,
        });
        graphics1.fillCircleShape(circle);
        graphics1.fillStyle(0xffff00, 0.75);
        graphics1.setDepth(16);
        console.log("case 6");

        this.tweens.add({
          targets: graphics1,
          alpha: 0,
          ease: "Cubic.easeOut",
          duration: 600,
          repeat: -1,
          yoyo: true,
        });
        this.mostrarNext = true;
        //this.mostrarOtro = false
        (this as any).btnGrupo.setVisible(0);
        break;
      case 15:
        console.log("case 15");
        this.sound.stopAll();
        this.scene.start("Levels");
        var circle = new Phaser.Geom.Circle(0, 0, 50);
        break;
    }
  }

  validandoCasos() {
    switch (localStorage.getItem("nivel")) {
      case "2":
        if (this.etapasLocal[2] === false) {
          this.sound.play("click");
          this.scene.start("Play", { etapa: 2 });
          localStorage.setItem("etapa", 2 as any);
        }
        localStorage.setItem("pasanivel", false as any);
        localStorage.setItem("nivel", undefined as any);
        this.pasanivel = false;
        this.nivel = undefined as any;
        break;
      case "3":
        if (this.etapasLocal[3] === false) {
          this.sound.play("click");
          this.scene.start("Play", { etapa: 3 });
          localStorage.setItem("etapa", 3 as any);
        }
        localStorage.setItem("pasanivel", false as any);
        localStorage.setItem("nivel", undefined as any);
        this.pasanivel = false;
        (this as any).nivel = undefined;
        break;
      case "4":
        if (this.etapasLocal[4] === false) {
          this.sound.play("click");
          this.scene.start("Play", { etapa: 4 });
          localStorage.setItem("etapa", 4 as any);
        }
        localStorage.setItem("pasanivel", false as any);
        localStorage.setItem("nivel", undefined as any);
        this.pasanivel = false;
        this.nivel = undefined as any;
        break;
      case "5":
        if (this.etapasLocal[5] === false) {
          this.sound.play("click");
          this.scene.start("Play", { etapa: 5 });
          localStorage.setItem("etapa", 5 as any);
        }
        localStorage.setItem("pasanivel", false as any);
        localStorage.setItem("nivel", undefined as any);
        this.pasanivel = false;
        (this as any).nivel = undefined;
        break;
      case "6":
        if (this.etapasLocal[6] === false) {
          this.sound.play("click");
          this.scene.start("Play", { etapa: 6 });
          localStorage.setItem("etapa", 6 as any);
        }
        localStorage.setItem("pasanivel", false as any);
        localStorage.setItem("nivel", undefined as any);
        this.pasanivel = false;
        (this as any).nivel = undefined;
        break;
    }
  }

  mascaraInicio(tipo: any, activeScroll: any, salirScena: any, callback: any) {
    this.imagenBG = this.add
      .image(this.centroCanvas.width, this.centroCanvas.height, "circlebg")
      .setVisible(false)
      .setDepth(999);

    this.shape1 = this.make.graphics();
    var mask1 = this.shape1.createBitmapMask(this.imagenBG);

    this.cameras.main.setMask(mask1);

    let counter = tipo === "entrar" ? 0 : 2;
    let countTwen = tipo === "entrar" ? 4.8 : 0;
    this.transition = { counter: counter };

    // tween counter variable and set onUpdate function
    let tweenEffect = this.tweens.add({
      targets: this.transition,
      counter: countTwen,
      callbackScope: this,
      onUpdate: this.updateTransition,
      ease: "Sine.easeInOut",
      onStart: () => {
        this.music2 = this.sound.add("efecto_scene", { volume: 0.2 });
        this.music2.play();
      },
      yoyo: false,
      repeat: 0,
      duration: 1500,
    });

    tweenEffect.on("complete", () => {
      if (activeScroll) {
        this.activeScroll();
      }
      if (salirScena) {
        callback();
      }
    });

    console.log("jeje");
  }

  updateTransition() {
    this.imagenBG.setScale(this.transition.counter);
  }

  activeScroll() {
    let cameraOptions = {
      contentBounds: {
        length: 2220,
      },
      wheel: {
        enable: false,
        delta: 60,
      },
      drag: 0.9,
      snap: {
        enable: false,
        padding: 20,
        bounces: 3,
      },
      horizontal: false,
    };
    this.myCamera = new ScrollingCamera(this, cameraOptions as any);

    let posicionYPersonaje = localStorage.getItem("scrollY")
      ? parseInt(localStorage.getItem("scrollY") as any)
      : 1800;
    console.log(posicionYPersonaje, "estaaaa es la posicion");
    this.myCamera.setSpeed(posicionYPersonaje * 5);
    /*        setInterval(()=>{ */
    //this.myCamera.setSpeed(1800 * 5);
    /*         console.log(this.myCamera)
       },5000) */
  }

  salirEscena(callback: any) {
    this.myCamera.setVisible(0);
    this.mascaraInicio("salir", false, true, callback);
  }

  setCamerasEvents() {
    function getValue(snapIndex: any, initialValue: any, increment: any) {
      return snapIndex * increment + initialValue;
    }
    // Snap event of "drag" camera
    this.myCamera.on("snap", (snapIndex: any) => {
      console.log("jeje");
      /*           const drag = getValue(snapIndex, 0.95, -0.05);
          this.camera1.drag = drag;
          this.camera2.drag = drag; */
    });
  }
  createWindow(func: any, data: any) {
    /*         var x = Phaser.Math.Between(400, 600);
          var y = Phaser.Math.Between(64, 128); */
    var handle = "window" + this.count++;

    var win = this.add.zone(0, 0, 0, 0).setDepth(555);

    var demo = new func(handle, win);

    //  this.input.setDraggable(win);

    return this.scene.add(handle, demo, true, data);
  }

  activarHolograma() {
    (this as any).holograma = this.add
      .sprite(375, 1870, "holomu")
      .setScale(0.2)
      .setDepth(11)
      .on("animationcomplete", () => {});
    (this as any).holograma.anims.play("playholomu");
  }

  createTextBox(
    scene: any,
    x: any,
    y: any,
    config: any,
    fondo: any,
    altura: any,
    escena: any,
    tipo: any,
    handleStart = () => {},
    handleFinish = () => {}
  ) {
    const GetValue = Phaser.Utils.Objects.GetValue;
    var wrapWidth = GetValue(config, "wrapWidth", 0);
    var fixedWidth = GetValue(config, "fixedWidth", 0);
    var fixedHeight = GetValue(config, "fixedHeight", 0);
    console.log(tipo);

    var textBox = scene.rexUI.add
      .textBox({
        x: x,
        y: y,
        background: fondo.setDepth(11),
        //icon: scene.add.image(0, 0, 'nextPage').setTint(COLOR_LIGHT).setVisible(false),
        text: this.getBBcodeText(
          scene,
          wrapWidth,
          fixedWidth,
          fixedHeight,
          altura
        ),
        /*                 action: scene.add.image(0, 0, 'nextPage').setTint(COLOR_LIGHT).setVisible(false), */
        space: {
          left: tipo === "center" ? 20 : 70,
          right: 20,
          top: tipo === "center" ? 50 : 20,
          bottom: 20,
          icon: 10,
          text: 10,
        },
      })
      .setOrigin(0)
      .layout();
    textBox
      .setInteractive({ cursor: "pointer" })
      .on("start", handleStart)
      .on("pageend", handleFinish)
      .on(
        "pointerdown",
        function () {
          /*                 var icon = this.getElement('icon').setVisible(false); */
          /*                 this.resetChildVisibleState(icon); */
          // if (escena.isTyping) {
          //   //this.stop(true);
          // } else {
          //   //this.typeNextPage();
          // }
        },
        textBox
      )
      .on(
        "pageend",
        function () {
          // if (escena.isLastPage) {
          //   if (escena.mostrarNext) {
          //     escena.mostrarNext = false;
          //     escena.btnGrupo.setVisible(1);
          //     clearInterval(escena.intervaloConversa);
          //     return;
          //   }
          // }
          /*                 var icon = this.getElement('icon').setVisible(true);
                this.resetChildVisibleState(icon);
                icon.y -= 30;
                var tween = scene.tweens.add({
                    targets: icon,
                    y: '+=30',
                    ease: 'Bounce',
                    duration: 500,
                    repeat: 0,
                    yoyo: false
                }); */
        },
        textBox
      );

    (this as any).intervaloConversa = setInterval(() => {
      textBox.emit("pointerdown");
    }, 800);
    (this as any).input.keyboard.on("keydown-ENTER", function (event: any) {
      textBox.emit("pointerdown");
    });
    return textBox;
  }

  getBuiltInText(
    scene: any,
    wrapWidth: any,
    fixedWidth: any,
    fixedHeight: any
  ) {
    return scene.add
      .text(0, 0, "", {
        fontSize: "20px",
        wordWrap: {
          width: wrapWidth,
        },
        maxLines: 3,
        color: "#0B2167",
      })
      .setFixedSize(fixedWidth, fixedHeight);
  }

  getBBcodeText(
    scene: any,
    wrapWidth: any,
    fixedWidth: any,
    fixedHeight: any,
    altura: any
  ) {
    return scene.rexUI.add
      .BBCodeText(0, 0, "", {
        fixedWidth: fixedWidth,
        fixedHeight: fixedHeight,
        fontSize: "18px",
        wrap: {
          mode: "word",
          width: wrapWidth,
        },
        maxLines: altura ? altura : 5,
        color: "#000000",
      })
      .setDepth(11);
  }
}

export default TutorialLevel;
