const COLOR_BASE = "#0B2167";
class ModalError extends Phaser.Scene {
  parent;
  keyScene;
  centroCanvas;
  extremoCanvas;
  constructor(handle: any, parent: any) {
    super(handle);
    this.parent = parent;
    this.keyScene = handle;
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
    this.extremoCanvas = {
      width: 0,
      height: 0,
    };
  }

  init(data: any) {
    console.log("Se ha iniciado la escena Modal Error");

    this.centroCanvas = {
      width: (this.sys.game.config.width as number) / 2,
      height: (this.sys.game.config.height as number) / 2,
    };
    this.extremoCanvas = {
      width: this.sys.game.config.width as number,
      height: this.sys.game.config.height as number,
    };

    (this as any).title = data.title ? data.title : "Titulo";
    (this as any).mensaje = data.mensaje ? data.mensaje : "Mensaje";
    (this as any).tipoEtapa = data.tipo;
  }

  preload() {}

  create() {
    (this as any).background = this.add.image(
      this.centroCanvas.width,
      this.centroCanvas.height,
      "modal"
    );
    this.cameras.main.setBackgroundColor("rgba(29, 26, 71, 0.69)");
    this.cameras.main.setViewport(
      0,
      0,
      (this as any).sys.game.config.width,
      (this as any).sys.game.config.height
    );

    this.add.text(
      this.centroCanvas.width - 140,
      this.centroCanvas.height - 50,
      (this as any).title,
      {
        fontFamily: "font1",
        fontSize: 26,
        color: COLOR_BASE,
        wordWrap: { width: 450, useAdvancedWrap: true },
        align: "center",
      }
    );

    this.add.text(
      this.centroCanvas.width - (210 - (this as any).mensaje.length),
      this.centroCanvas.height,
      (this as any).mensaje,
      {
        fontFamily: "font2",
        fontSize: 22,
        color: COLOR_BASE,
        wordWrap: { width: 400, useAdvancedWrap: true },
        align: "center",
      }
    );

    this.add
      .image(this.centroCanvas.width, this.centroCanvas.height + 150, "boton2")
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => {
        this.scene.resume("Personaje");
        this.scene.remove(this.keyScene);
        const tipoPersonaje = localStorage.getItem("tipoPersonaje");
        if (tipoPersonaje === "hombre") {
          (document.querySelector(".carrucelhombre") as any).style.display =
            "block";
        }
        if (tipoPersonaje === "mujer") {
          (document.querySelector(".carrucelmujer") as any).style.display =
            "block";
        }
      });
    this.add.text(
      this.centroCanvas.width - 50,
      this.centroCanvas.height + 135,
      "Aceptar",
      { fontFamily: "font1", fontSize: 24 }
    );
  }

  update() {}
}

export default ModalError;
