class BootLoader extends Phaser.Scene {
  centroCanvas: { width: number; height: number };
  constructor() {
    super({ key: "BootLoader" });
    this.centroCanvas = {
      width: 0,
      height: 0,
    };
  }

  init() {
    this.centroCanvas = {
      width:
        ((this.cameras.main.worldView.x + this.cameras.main.width) as number) /
        2,
      height:
        ((this.cameras.main.worldView.y + this.cameras.main.height) as number) /
        2,
    };
  }

  preload() {
    this.load.path = "./assets/";
    ///console.log(this.load, "sdss");
    //////////////////// INICIO PRECARGA /////////////////////////////////////
    var progressBar = this.add.graphics();
    var progressBox = this.add.graphics();
    var width = this.cameras.main.width;
    var height = this.cameras.main.height;

    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(
      this.centroCanvas.width - 170,
      this.centroCanvas.height - 80,
      320,
      50
    );

    var loadingText = this.make.text({
      x: width / 2,
      y: height / 2,
      text: "INICIALIZANDO...",
      style: {
        font: "20px monospace",
      },
    });
    loadingText.setOrigin(0.5, 0.5);
    var percentText = this.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: "0%",
      style: {
        font: "18px monospace",
      },
    });
    percentText.setOrigin(0.5, 0.5);

    var assetText = this.make.text({
      x: width / 2,
      y: height / 2 + 26,
      text: "",
      style: {
        font: "18px monospace",
      },
    });
    assetText.setOrigin(0.5, 0.5);
    this.load.on("progress", (value: number) => {
      // logo.style.display = "block";
      //  console.log(value, "valor");
      percentText.setText(value * 100 + "%");
      progressBar.clear();
      progressBar.fillStyle(0xffffff, 1);
      progressBar.fillRect(
        this.centroCanvas.width - 160,
        this.centroCanvas.height - 70,
        300 * value,
        30
      );
    });

    this.load.on("fileprogress", function (file: any) {
      // console.log("progreso");
      assetText.setText("");
      assetText.setText("Cargando archivos: " + file.key);
    });

    this.load.on("complete", () => {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      percentText.destroy();
      assetText.destroy();
      // const PATHURL = window.location.search.substr(1).split("=")[1];
      let escena = localStorage.getItem("escenaActual")
        ? localStorage.getItem("escenaActual")
        : "VideoBienvenida";
      console.log(escena);
      //this.scene.run("Play", { etapa: 1, confirm: true });
      this.scene.run(escena as any, { etapa: 16, tipo: "mejora" });
    });

    /////////////////// FIN PRECARGA ///////////////////////////////////////
    ///sonidos
    this.load.audio("click", "sonidos/boton.wav");
    this.load.audio("clock", "sonidos/alien_job.wav");
    this.load.audio("alarma", "sonidos/alarm.mp3");
    this.load.audio("segundero", "sonidos/segunderos.mp3");
    this.load.audio("starwar", "sonidos/star-wars.mp3");
    this.load.audio("music_comienzo", "sonidos/musica_comienzo.mp3");
    this.load.audio("music_comienzo2", "sonidos/musica_comienzo.mp3");
    this.load.audio("efecto_scene", "sonidos/efecto_scene.mp3");
    this.load.audio("nuevo_planeta", "sonidos/nuevo_planeta.mp3");
    this.load.audio("meteorito", "sonidos/meteorito.wav");
    this.load.audio("paises_unidos", "sonidos/paises_unidos.mp3");
    this.load.audio("contratoMusic", "sonidos/contrato.wav");
    this.load.audio("cohete_volando", "sonidos/cohete_volando.wav");
    this.load.audio("puerta_cohete", "sonidos/puerta_cohete.mp3");

    this.load.audio("sonid_mesa2", "sonidos/sonid_mesa2.mp3");
    this.load.audio("soltar_pieza", "sonidos/soltar_pieza.wav");
    this.load.audio("coge_pieza", "sonidos/coge_pieza.wav");
    this.load.audio("eliminar_pieza", "sonidos/eliminar_pieza.wav");
    this.load.audio("poco_tiempo", "sonidos/poco_tiempo.mp3");
    this.load.audio("gana_etapa", "sonidos/gana_etapa.mp3");
    this.load.audio("alarma_terminada", "sonidos/alarma_terminada.wav");
    //fin sonidos

    this.load.image("background", "fondo.jpg");
    this.load.image("fondolevel", "fondolevel.png");
    this.load.image("close", "close.png");
    this.load.image("agrandar", "agrandar.png");
    this.load.image("cuadrilla", "cuadrilla.png");
    this.load.image("aream", "area-marciano.png");
    this.load.image("circlemap", "circle-map.png");
    this.load.image("taladro", "taladro.png");
    this.load.image("guincha", "guincha.png");
    this.load.image("martillo", "martillo.png");
    this.load.image("audifono", "audifono.png");
    this.load.image("casco", "casco.png");
    this.load.image("perno", "perno.png");
    this.load.image("balde", "balde.png");
    this.load.image("lampa", "lampa.png");
    this.load.image("lapiz", "lapiz.png");
    this.load.image("cuadro", "cuadro.png");
    this.load.image("fulls", "fs.png");

    this.load.image("buenoca", "buenoca.png");
    this.load.image("maloca", "maloca.png");
    // panel monos fondo
    this.load.image("fondo_panel", "fondopanel.png");
    this.load.image("comprar_btn", "comprarbtn.png");
    this.load.image("rojo_btn", "rojo.png");
    /// puerta
    // this.load.image('p1', 'p1.png')
    this.load.image("p2", "p2.png");
    this.load.image("p3", "p3.png");
    this.load.image("p4", "p4.png");
    this.load.image("p22", "puerta_estacion/p2.png");
    this.load.image("p33", "puerta_estacion/p3.png");
    this.load.image("p44", "puerta_estacion/p4.png");
    /// pared
    this.load.image("pa0", "pared/pa0.png");
    this.load.image("pa1", "pared/pa1.png");
    this.load.image("pa2", "pared/pa2.png");
    this.load.image("pa3", "pared/pa3.png");
    this.load.image("pa4", "pared/pa4.png");
    this.load.image("pa5", "pared/pa5.png");
    this.load.image("pa6", "pared/pa6.png");
    this.load.image("pa7", "pared/pa7.png");
    this.load.image("pa8", "pared/pa8.png");
    this.load.image("pa2n", "pared/pa2n.png");
    this.load.image("pa8n", "pared/pa8n.png");

    this.load.image("pa00", "pared/estacion/pa0.png");
    this.load.image("pa22", "pared/estacion/pa2.png");
    this.load.image("pa33", "pared/estacion/pa3.png");
    this.load.image("pa44", "pared/estacion/pa4.png");
    this.load.image("pa55", "pared/estacion/pa5.png");
    this.load.image("pa66", "pared/estacion/pa6.png");
    this.load.image("pa77", "pared/estacion/pa7.png");
    this.load.image("pa88", "pared/estacion/pa8.png");
    this.load.image("pa99", "pared/estacion/pa9.png");
    this.load.image("pa2ne", "pared/estacion/pa2n.png");
    this.load.image("pa8ne", "pared/estacion/pa8n.png");

    /// techo
    this.load.image("t1", "techo/t1.png");
    this.load.image("t2", "techo/t2.png");
    this.load.image("t2n", "techo/t2n.png");
    this.load.image("t3", "techo/t3.png");
    this.load.image("t4", "techo/t4.png");
    this.load.image("t5", "techo/t5.png");
    this.load.image("t11", "techo/estacion/t1.png");
    this.load.image("t22", "techo/estacion/t2.png");
    this.load.image("t33", "techo/estacion/t3.png");
    this.load.image("t44", "techo/estacion/t4.png");
    this.load.image("t55", "techo/estacion/t5.png");
    this.load.image("t2ne", "techo/estacion/t2n.png");
    /// ventana
    this.load.image("v1", "ventana/v1.png");
    this.load.image("v2", "ventana/v2.png");
    this.load.image("v3", "ventana/v3.png");
    this.load.image("v11", "ventana/estacion/v1.png");
    this.load.image("v22", "ventana/estacion/v2.png");
    this.load.image("v33", "ventana/estacion/v3.png");

    // this.load.image('perno', 'perno.png')

    this.load.image("toptitle", "titletop.png");
    // this.load.image('moneda', 'moneda.png')
    this.load.image("base_moneda", "base_moneda.png");
    this.load.image("config", "config.png");
    this.load.image("info", "info.png");
    this.load.image("menu", "menu.png");
    this.load.image("reloj", "reloj.png");

    // total casas
    this.load.image("totalcasa", "casastotal.png");

    //MONEDA
    this.load.spritesheet("moneda", "moneda3.png", {
      frameWidth: 51,
      frameHeight: 48,
    });
    //RELOJ MONO
    this.load.spritesheet("relojmono", "relojmono.png", {
      frameWidth: 69,
      frameHeight: 51,
    });
    //MONOS
    this.load.atlas(
      "mono1",
      "personajes/mono1.png",
      "personajes/mono1_atlas.json"
    );
    this.load.animation("monoAnim", "personajes/mono1_anim.json");

    //MONO 2
    this.load.atlas(
      "mono2",
      "personajes/mono2.png",
      "personajes/mono2_atlas.json"
    );
    this.load.animation("monoAnim2", "personajes/mono2_anim.json");

    //MONO3
    this.load.atlas(
      "mono3",
      "personajes/mono3.png",
      "personajes/mono3_atlas.json"
    );
    this.load.animation("monoAnim3", "personajes/mono3_anim.json");

    //MONO4
    this.load.atlas(
      "mono4",
      "personajes/mono4.png",
      "personajes/mono4_atlas.json"
    );
    this.load.animation("monoAnim4", "personajes/mono4_anim.json");

    //// CONFIG MODAL
    this.load.image("modal", "config/modal.png");
    this.load.image("user", "config/user.png");
    this.load.image("sonido", "config/sonido.png");
    this.load.image("tutorial", "config/tutorial.png");
    this.load.image("buttonConfig", "config/button.png");

    //modal mapas
    this.load.image("fondopop", "popup.png");
    this.load.image("fondopopg", "popgrande.png");
    this.load.image("boton1", "btn1.png");
    this.load.image("boton2", "btn2.png");
    this.load.image("techomapa", "modal/techo.png");
    this.load.image("techomapa2", "modal/techo2.png");

    this.load.image("paredmapa", "modal/pared.png");
    this.load.image("paredmapa2", "modal/pared2.png");
    this.load.image("puertamapa", "modal/puerta.png");
    this.load.image("puertamapa2", "modal/puerta2.png");
    this.load.image("ventanamapa", "modal/ventana.png");
    this.load.image("ventanamapa2", "modal/ventana2.png");
    this.load.image("casamodal", "modal/casa.png");
    this.load.image("casamodal2", "modal/casa2.png");
    this.load.image("casamodalf", "modal/casafinal.png");
    this.load.image("casamodalff", "modal/casaff.png");
    this.load.image("buena", "buena.png");
    this.load.image("mala", "mala.png");

    ///////////////////////////////////////////////////////
    /// LEVEL ARCHIVOS /////////////
    this.load.image("configlevel", "level/configuracion.png");
    this.load.image("tutorialevel", "level/tutorial.png");
    this.load.image("basel", "level/base.png");
    this.load.image("baselazul", "level/baseazul.png");
    this.load.image("num1", "level/num1.png");
    this.load.image("monedalev", "level/moneda.png");
    this.load.image("libroabierto", "level/libroabierto.png");
    this.load.image("libro", "level/libro.png");
    this.load.image("nave", "level/nave.png");
    this.load.image("bandera", "level/bandera.png");
    this.load.image("bandera2", "level/bandera2.png");
    this.load.image("num2", "level/num2.png");
    this.load.image("num3", "level/num3.png");
    this.load.image("num4", "level/num4.png");
    this.load.image("num5", "level/num5.png");
    this.load.image("num6", "level/num6.png");
    this.load.image("num7", "level/num7.png");
    this.load.image("num8", "level/num8.png");
    this.load.image("num9", "level/num9.png");
    this.load.image("logolevel", "level/logo.png");
    this.load.image("mor1", "level/mor1.png");
    this.load.image("mor2", "level/mor2.png");
    this.load.image("mor3", "level/mor3.png");
    this.load.image("mor4", "level/mor4.png");
    this.load.image("mor5", "level/mor5.png");
    this.load.image("mor6", "level/mor6.png");
    this.load.image("mor7", "level/mor7.png");
    this.load.image("mor8", "level/mor8.png");
    this.load.image("mor9", "level/mor9.png");

    ///////////////////////////////////////////////////////
    /// TUTORIAL     ARCHIVOS /////////////
    this.load.image("tutorialnube", "tutorialnube.png");
    this.load.image("tutorialnube1", "tutorialnube1.png");
    this.load.image("tutorialnube2", "tutorialnube2.png");

    //////////////////////////////////////////////////////////
    //////////// MODAL MEJORAS ARCHIVOS /////////////////////
    this.load.image("deletepieza", "etapas/borrar.png");
    this.load.image("etiquetapieza", "etapas/etiquetas.png");
    this.load.image("separarpieza", "etapas/separar.png");
    this.load.image("armado", "etapas/armado.png");
    this.load.image("piezapor", "etapas/piezapor.png");

    /////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////
    //////////////// TIERRA ////////////////////
    this.load.image("backgroundtierra", "tierra/background.png");
    this.load.image("navea1", "tierra/nave.png");
    this.load.image("navea2", "tierra/nave2.png");
    this.load.image("navea3", "tierra/nave3.png");

    this.load.image("personajea1", "tierra/personajes/personajea1.png");
    this.load.image("personajea2", "tierra/personajes/personajea2.png");
    this.load.image("personajea3", "tierra/personajes/personajea3.png");
    this.load.image("personajea4", "tierra/personajes/personajea4.png");
    this.load.image("personajea5", "tierra/personajes/personajea5.png");
    this.load.image("personajea9", "tierra/personajes/personajea9.png");
    this.load.image("personajea90", "tierra/personajes/personajea90.png");

    this.load.image("personajeb1", "tierra/personajes/personajeb1.png");
    this.load.image("personajeb2", "tierra/personajes/personajeb2.png");
    this.load.image("personajeb3", "tierra/personajes/personajeb3.png");
    this.load.image("personajeb4", "tierra/personajes/personajeb4.png");
    this.load.image("personajeb5", "tierra/personajes/personajeb5.png");
    this.load.image("personajeb9", "tierra/personajes/personajeb9.png");
    this.load.image("personajeb90", "tierra/personajes/personajeb90.png");

    this.load.image("personajec1", "tierra/personajes/personajec1.png");
    this.load.image("personajec2", "tierra/personajes/personajec2.png");
    // this.load.image("personajec3", "tierra/personajes/personajec3.png");
    this.load.image("personajec4", "tierra/personajes/personajec4.png");
    this.load.image("personajec5", "tierra/personajes/personajec5.png");
    this.load.image("personajec9", "tierra/personajes/personajec9.png");
    this.load.image("personajec90", "tierra/personajes/personajec90.png");

    this.load.image("personajed1", "tierra/personajes/personajed1.png");
    this.load.image("personajed2", "tierra/personajes/personajed2.png");
    this.load.image("personajed3", "tierra/personajes/personajed3.png");
    this.load.image("personajed4", "tierra/personajes/personajed4.png");
    this.load.image("personajed5", "tierra/personajes/personajed5.png");
    this.load.image("personajed9", "tierra/personajes/personajed9.png");
    this.load.image("personajed90", "tierra/personajes/personajed90.png");

    this.load.image("personajef1", "tierra/personajes/personajef1.png");

    this.load.image("personajeg1", "tierra/personajes/personajeg1.png");
    this.load.image("personajeg3", "tierra/personajes/personajeg3.png");
    this.load.image("personajeg9", "tierra/personajes/personajeg9.png");
    this.load.image("personajeg90", "tierra/personajes/personajeg90.png");

    this.load.image("personajeh1", "tierra/personajes/personajeh1.png");
    this.load.image("personajeh3", "tierra/personajes/personajeh3.png");
    this.load.image("personajeh9", "tierra/personajes/personajeh9.png");
    this.load.image("personajeh90", "tierra/personajes/personajeh90.png");

    this.load.image("personajej1", "tierra/personajes/personajej1.png");
    this.load.image("personajej3", "tierra/personajes/personajej3.png");
    this.load.image("personajej9", "tierra/personajes/personajej9.png");
    this.load.image("personajej90", "tierra/personajes/personajej90.png");

    this.load.image("personajek1", "tierra/personajes/personajek1.png");
    this.load.image("personajek3", "tierra/personajes/personajek3.png");
    this.load.image("personajek9", "tierra/personajes/personajek9.png");
    this.load.image("personajek90", "tierra/personajes/personajek90.png");

    this.load.image("amarillom", "tierra/personajes/monos/amarillo.png");
    this.load.image("moradom", "tierra/personajes/monos/morado.png");
    this.load.image("rojom", "tierra/personajes/monos/rojo.png");
    this.load.image("verdem", "tierra/personajes/monos/verde.png");

    this.load.image("aterrizabg", "tierra/aterriza.png");
    this.load.image("aterrizabg2", "tierra/aterriza2.png");

    /////////// Persoanej //////////////////////////////////////
    this.load.image("personajebg", "tierra/personajebg.png");
    this.load.image("nombrebg", "tierra/nombrebg.png");
    this.load.image("leftarrow", "left-arrow.png");
    this.load.image("rightarrow", "right-arrow.png");
    /*         this.load.atlas('hombreatlas', 'tierra/personajes/hombre/hombre.png', 'tierra/personajes/hombre/hombre_atlas.json'); */

    ///////////// Nuevos iconos de stacion ///////////
    this.load.image("5s", "nuevo/5s.png");
    this.load.image("auto", "nuevo/auto.png");
    this.load.image("balance", "nuevo/balance.png");
    this.load.image("eti", "nuevo/eti.png");
    this.load.image("foco", "nuevo/foco.png");
    this.load.image("marcaare", "nuevo/marcaare.png");
    this.load.image("marcar", "nuevo/marcar.png");

    this.load.image("circlebg", "circle.png");

    //bienvenida

    this.load.image("bienvenida", "portada.png");
    this.load.image("textostar", "fondoEstrella.png");

    /// conversa
    this.load.image("conversabg", "conversabg.png");
    this.load.image("conversamu", "personajes/mujer.png");
    this.load.image("conversaho", "personajes/hombre.png");
    this.load.image("conversahos", "personajes/hombresor.png");
    this.load.image("nave3", "personajes/nave2.png");

    this.load.image("piedras", "tierra/piedras.png");

    ///
    this.load.atlas(
      "holomu",
      "HOLOGRAMAS/holomu/holomu.png",
      "HOLOGRAMAS/holomu/holomu_atlas.json"
    );
    this.load.animation("holomuanim", "HOLOGRAMAS/holomu/holomu_anim.json");

    this.load.image("audio", "audio.png");
    this.load.image("audio2", "audio2.png");
    this.load.image("sinaudio", "sin-audio.png");
    this.load.image("sinaudio2", "sin-audio2.png");

    ////
    this.load.image("dineroimg", "nuevo/dinero.png");

    this.load.image("conversa1", "conversa/primero.png");
    this.load.image("conversa2", "conversa/segundo.png");
    this.load.image("conversa3", "conversa/tercero.png");
    this.load.image("conversa4", "conversa/cuarto.png");
    this.load.image("contratobg", "conversa/contrato.png");
    this.load.image("leftPage", "arrow-left.png");
    this.load.image("baseprof", "baseprof.png");
    ///// FUEGOS ANIMATION
    this.load.atlas(
      "fuego",
      "nuevo/despego/fuego/fuego.png",
      "nuevo/despego/fuego/fuego_atlas.json"
    );
    this.load.animation("fuegoanim", "nuevo/despego/fuego/fuego_anim.json");

    this.load.atlas(
      "fuego2",
      "nuevo/despego/fuego2/fuego2.png",
      "nuevo/despego/fuego2/fuego2_atlas.json"
    );
    this.load.animation("fuegoanim2", "nuevo/despego/fuego2/fuego2_anim.json");

    this.load.atlas(
      "fuegoM",
      "nuevo/despego/chico/fuego/fuego.png",
      "nuevo/despego/chico/fuego/fuego_atlas.json"
    );

    this.load.atlas(
      "abre",
      "nuevo/abre/abre/abre.png",
      "nuevo/abre/abre/abre_atlas.json"
    );
    this.load.animation("abreanim", "nuevo/abre/abre/abre_anim.json");

    this.load.atlas(
      "abrem",
      "nuevo/abre/chico/abrem/abrem.png",
      "nuevo/abre/chico/abrem/abrem_atlas.json"
    );

    this.load.atlas(
      "profhabla",
      "tierra/profhabla/profhabla.png",
      "tierra/profhabla/profhabla_atlas.json"
    );
    this.load.animation("profhablanim", "tierra/profhabla/profhabla_anim.json");

    /// piezas lineas
    this.load.image("papunta", "lineas/PARED-LINEAS/PARED-PUNTA.png");
    this.load.image("pa0l", "lineas/PARED-LINEAS/PARED-0.png");
    this.load.image("pa2l", "lineas/PARED-LINEAS/PARED-02.png");
    this.load.image("pa3l", "lineas/PARED-LINEAS/PARED-03.png");
    this.load.image("pa4l", "lineas/PARED-LINEAS/PARED-04.png");
    this.load.image("pa5l", "lineas/PARED-LINEAS/PARED-05.png");
    this.load.image("pa6l", "lineas/PARED-LINEAS/PARED-06.png");
    this.load.image("pa7l", "lineas/PARED-LINEAS/PARED-07.png");
    this.load.image("pa8l", "lineas/PARED-LINEAS/PARED-08.png");
    this.load.image("paddd11l", "lineas/PARED-LINEAS/PARED-DDD-11.png");
    this.load.image("paddd12l", "lineas/PARED-LINEAS/PARED-DDD-12.png");

    this.load.image("t1l", "lineas/TECHO-LINEAS/TECHO-01.png");
    this.load.image("t2l", "lineas/TECHO-LINEAS/TECHO-02.png");
    this.load.image("t3l", "lineas/TECHO-LINEAS/TECHO-03.png");
    this.load.image("t4l", "lineas/TECHO-LINEAS/TECHO-04.png");
    this.load.image("t5l", "lineas/TECHO-LINEAS/TECHO-05.png");
    this.load.image("tddd6l", "lineas/TECHO-LINEAS/TECHO-DDD-06.png");

    this.load.image("p2l", "lineas/PUERTA-LINEAS/PUERTA-02.png");
    this.load.image("p3l", "lineas/PUERTA-LINEAS/PUERTA-03.png");
    this.load.image("p4l", "lineas/PUERTA-LINEAS/PUERTA-04.png");

    this.load.image("v1l", "lineas/VENTANA-LINEAS/VENTANA-01.png");
    this.load.image("v2l", "lineas/VENTANA-LINEAS/VENTANA-02.png");
    this.load.image("v3l", "lineas/VENTANA-LINEAS/VENTANA-03.png");

    // mapa con mejora 7
    this.load.image("pared7", "modal/pared7.png");
    this.load.image("pared72", "modal/pared72.png");
    this.load.image("techo7", "modal/techo7.png");
    this.load.image("techo72", "modal/techo72.png");

    this.load.image("finalbg", "finalbg.jpg");
    this.load.image("beglibg", "beglibg.jpg");

    // nuevos
    this.load.image("pausaicon", "icono-pausa.png");
    this.load.image("saliricon", "icono-salir.png");
    this.load.image("modal8", "modal8.png");

    this.load.audio("mesa_trabajo", "audios/tutorial/mesa_trabajo.mp3");
    this.load.audio("cada_ronda", "audios/tutorial/cada_ronda.mp3");
    this.load.audio(
      "aca_podras_ver",
      "audios/tutorial/aca_podras_ver_casas.mp3"
    );
    this.load.audio("aqui_encontraras", "audios/tutorial/aqui_encontraras.mp3");
    this.load.audio(
      "cada_fabrica_tiene",
      "audios/tutorial/cada_fabrica_tiene.mp3"
    );
    this.load.audio("cuando_termines", "audios/tutorial/cuando_termines.mp3");
    this.load.audio("estas_listo", "audios/tutorial/estas_listo.mp3");
    this.load.audio(
      "estas_son_fabricas",
      "audios/tutorial/estas_son_fabricas.mp3"
    );
    this.load.audio("pon_cada_pieza", "audios/tutorial/pon_cada_pieza.mp3");
    this.load.audio(
      "selecciona_comprar",
      "audios/tutorial/selecciona_comprar.mp3"
    );
    this.load.audio("selecciona_mas", "audios/tutorial/selecciona_mas.mp3");
    this.load.audio("ya_aprendiste", "audios/tutorial/ya_aprendiste.mp3");
    this.load.audio("hola_terricola", "audios/tutorial/hola_terricola.mp3");
    this.load.audio("casa_construir", "audios/tutorial/casa_construir.mp3");

    this.load.image("finalbgnew", "finalbg.jpg");

    this.load.audio("ya_aprendiste", "audios/tutorialLevel/ya_aprendiste.mp3");
    this.load.audio("listo_audio", "audios/tutorialLevel/listo_audio.mp3");

    // audios mejoras

    this.load.audio("mejora1", "audios/mejoras/mejora1.mp3");
    this.load.audio("mejora2", "audios/mejoras/mejora2.mp3");
    this.load.audio("mejora3", "audios/mejoras/mejora3.mp3");
    this.load.audio("mejora4", "audios/mejoras/mejora4.mp3");
    this.load.audio("mejora6", "audios/mejoras/mejora6.mp3");
    this.load.audio("mejora5", "audios/mejoras/mejora5.mp3");
    this.load.audio("mejora7", "audios/mejoras/mejora7.mp3");
  }

  create() {}
}

export default BootLoader;
