/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import { useEffect } from "react";
import Phaser from "phaser";
import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";
// import BBCodeTextPlugin from "phaser3-rex-plugins/plugins/bbcodetext-plugin";
import BootLoader from "../scenes/BootLoader";
import Levels from "../scenes/Levels";
import ValidarEtapas from "../scenes/ValidarEtapas";
import Play from "../scenes/Play";
import FinalF from "../scenes/FinalF";
import Final from "../scenes/Final";
import VideoBienvenida from "../scenes/VideoBienvenida";
import Tutorial from "../scenes/Tutorial";
import TierraBegli from "../scenes/TierraBegli";
import CohetesBegli from "../scenes/CoheteBegli";
import TutorialLevel from "../scenes/TutorialLevel";
import Personaje from "../scenes/Personaje";

const GameContainer = () => {
  useEffect(() => {
    const config = {
      title: "Construye App",
      type: Phaser.AUTO,
      scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        parent: "contenedor",
        width: 1026,
        height: 770,
        zoom: 1.5,
      },
      render: {
        maxTextures: 15,
        pixelArt: true, // Activa el renderizado de estilo pixelado para mantener la calidad de píxeles
        antialias: true, // Activa el suavizado de bordes para mejorar la calidad de los gráficos
        roundPixels: true, // Asegura que los píxeles se rendericen en coordenadas enteras para evitar el desenfoque
      },
      url: "https://somosjam.cl",
      version: "0.2.0",
      pixelArt: true,
      backgroundColor: "#160922",
      banner: {
        hidePhaser: true,
        text: "#fff00f",
        background: ["#0000"],
      },
      dom: {
        createContainer: true,
      },
      powerPreference: "high-performance",
      scene: [
        BootLoader,
        VideoBienvenida,
        Levels,
        ValidarEtapas,
        Play,
        Final,
        FinalF,
        Tutorial,
        TierraBegli,
        CohetesBegli,
        TutorialLevel,
        Personaje,
      ],
      plugins: {
        scene: [
          {
            key: "rexUI",
            plugin: RexUIPlugin,
            mapping: "rexUI",
          },
        ],
      },
    };
    const game = new Phaser.Game(config);
    console.log(game);
    return () => {
      game.destroy(true);
    };
  }, []);
  return <div id="game-container"></div>;
};

export default GameContainer;
